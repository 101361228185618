import styled from 'styled-components';

export const HeaderStyle = styled.div`


.header-section .nav-collapse {
    .nav {
        display: flex;
        align-items: center;
    }
}

.cstm-country {
    position: absolute;
    top: -8px;
    right: 10px;
    width: auto;
    padding: 0px;
    z-index: 2;

    @media only screen and (max-width: 991px) {
        right: 0px;
        display: none;
    }

    &.mobile-country {
        display: none;

        @media only screen and (max-width: 991px) {
            display: block;
            top: 26px;
            right: 40px
        }

        @media only screen and (max-width: 568px) {
            top: 20px;
            right: 35px
        }
    }
        
    button {
        background: transparent;
        border: none;
        border-radius: 5px;
        height: auto;
        padding: 0;
        display: block;

        span {
            display: block;
        }
    }
    .country-drop {
        position: absolute;
        top:calc(100% + 10px);
        right: 0;
        background-color: #fff;
        border-radius: 10px;
        padding: 5px;
        display: none;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
        list-style: none;
            
        @media only screen and (max-width: 991px) {
            display: none !important;
        }
    
        &.open-country {
            display: block;
    
            @media only screen and (max-width: 991px) {
                display: block !important;
            }
        }
    
        li {
            &:last-child {
                margin: 0;
            }
        }
    }
}
.mobile_user {
    display: none;
    @media (max-width: 991px){
        display: block;
        &.user_details {
            display: flex;
            align-items: center;
            justify-content: right;
            gap: 10px;
            right: 67px;
            top: 17px;

            @media (max-width: 568px){
                top: 10px;
                right: 58px;
            }
        
        }
    }
}
.desktop {
    display: block;
    @media (max-width: 991px){
        display: none;
    }
}
.header-section {
    top:0;
    background: #2A533F;
    padding: 20px;
    position: absolute;
    width: 100%;
    z-index: 99;
    @media(max-width:1199px){
        padding:20px 0;
    }
    @media(max-width:568px){
        padding:0;
    }
}
.header-section.homePageHeader {
    background: #2A533FCC;
}
.navbar-inner a img {
    width: 100%;
    max-width: 284px;
    @media(max-width:1079px){
    max-width: 184px;
    }
    @media(max-width:568px){
    max-width:140px;
    }
}

.header-section .navbar {
    display: flex;
    width: 100%;
}
.header-section .nav-collapse.collapse ul li:hover ul {
    display: block;
    padding: 0 10px;
    border: navajowhite;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    top: 29px;
    background: rgba(255, 255, 255, 0.87);
    min-width: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
 .header-section .nav-collapse.collapse li:hover a {
    padding:0;

}
.header-section .nav-collapse.collapse ul.header-icon li:hover a{
    background: transparent;
    padding: 0;
    border-top-left-radius: none;
    border-top-right-radius: none;
}
.header-section  .nav-collapse.collapse li:hover span {
    color:#2A533F;
}
.header-section .nav-collapse.collapse ul li span {
    color: #F4F0EB;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    position: static;
}
.header-section .nav-collapse.collapse ul li:hover ul.about-drop.product-drop {
    min-width: auto;
    left: 0px;
    top: 34px;
    padding-left: 14px;
    padding-bottom: 10px;
}
.header-section.homePageHeader ul li a.active span {
    /* border-bottom: 2px solid #fff;
    padding-bottom: 5px; */
}
.header-section .nav-collapse.collapse ul li {
    margin: 0px 8px;
    /* padding: 6px 26px 4px; */
    padding: 6px 20px 4px;

    @media (max-width:1500px){
        margin: 0px 10px;
    }
    @media (max-width:1500px){
        margin: 0px 2px;
        /* padding: 6px 20px 4px; */
        padding: 6px 17px 4px;
    }
    @media (max-width:1280px){
    padding: 6px 9px;

    }
}
.header-section .nav-collapse.collapse ul li ul li {
    padding: 0 5px !important;
    margin: 0;
}
.header-section .nav-collapse.collapse {
    display: flex;
    align-items: center;
}
.header-section .nav-collapse.collapse ul ul ul li a {
    color: #000;
    padding: 10px;
}
.header-section .nav-collapse.collapse ul ul ul li {
    padding: 0 0 4px;
}
/* .header-section .nav-collapse.collapse ul.header-icon li:nth-child(2) { */
.header-section .nav-collapse.collapse ul.header-icon li:last-child {
    padding-right: 0;
    margin: 0 10px;
}
.header-section ul.dropdown-menu li span {
    font-size: 13px !important;
    font-weight: 400 !important;
    font-family: 'Helvetica Neue LT Std';
}
.header-section .nav-collapse.collapse li:nth-child(4):hover a {
    background: transparent;
}
 .header-section .nav-collapse.collapse ul li:hover ul.about-drop {
    max-width: auto;
    width: 100%;
    display: block;
    top: 34px;
    left: 0;
}
.header-section .nav-collapse.collapse li:hover span {
    color: rgb(42, 83, 63);
    /* position: relative; */
    z-index: 999999;
}
 .header-section .nav-collapse.collapse ul li:hover ul.about1-drop {
    padding-bottom: 6px;
    max-width: auto;
    width: 100%;
    display: block;
    top: 100%;
    left: 0;
}
 .header-section .nav-collapse.collapse li:hover a i {
    transform: rotate(180deg);
    position: relative;
    left: 7px;
    color: rgb(42, 83, 63);
    top:1px;
}
.header-section .nav-collapse.collapse > ul.header-icon > li:hover {
    background: transparent;
}
.header-section.bgChange {
    background: #2A533FCC;
}
 .header-section .nav-collapse.collapse ul.header-icon li a.active {
    border: none;
}
 ul.header-icon {
    display: flex;
    /* margin: 9px 0 0; */
    align-items: center;
    margin: 0;
}
.header-section .nav-collapse.collapse ul.header-icon li a:hover {
    padding: 0;
}
.header-section .nav-collapse.collapse ul.header-icon li a{
    padding: 0;
}
span.btn.btn-login {
    background: #F4F0EB;
    color: #2A533F!important;
    text-transform: capitalize;
    font-size: 16px;
    border-radius: 15px;
    padding: 3px 20px;
    position: relative;
    top: -4px;
    left: 14px;
    font-weight: bold;
}
ul.header-icon li {
    list-style: none;

}
.header-section  .dropdown-toggle::after {
  display:none;
}
.header-section  ul.nav i {
    padding-left: 6px;
    font-size: 16px;
}
span.number-cart {
    color: #2A533F !important;
    font-size: 11px;
    background: #F4F0EB;
    border-radius: 50%;
    max-width: 18px;
    height: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -2px;
    bottom: -6px;
}
.cart-icon i {
    font-size: 20px;
    margin: 8px 4px 0;
  
}
.cart-icon {
    position: relative;
    max-width: 21px;
    img {
        width: 100%;
    }
}
.header-section .nav-collapse.collapse>ul>li:hover {
    background: rgba(255, 255, 255, 0.87);
    border-radius: 15px;
    @media(max-width:991px){
        background:transparent;
        border-radius:0;
    }
}
span.btn.btn-login:active {
    background: #fff !important;
}
.header-section .nav-collapse.collapse>ul>li.dropdown:hover {
    border-radius: 10px 10px 0px 0px;
}
/* .header-section .nav-collapse.collapse > ul > li:first-child:hover {
	border-radius: 15px !important;
}
.header-section .nav-collapse.collapse > ul > li:last-child:hover {
	border-radius: 15px !important;
} */
@media(max-width:1439px){

span.number-cart {
    font-size: 12px !important;
}
}

@media(max-width:991px){
    .navbar  ul.dropdown-menu {
    display: block;
    background: transparent;
    position: relative;
}
 .header-section ul.dropdown-menu li span {
    font-size: 14px !important;
    font-weight: 400 !important;
}
.nav-collapse.collapse.open_collapse_nav ul li ul {
    width: 100%;
    border: none;
    border-left: 1px dashed #fff;
    border-radius: inherit;
}
.header-section ul.dropdown-menu li span {
    padding-left: 10px !important;
}
.header-section ul.dropdown-menu {
    margin: 10px;
}
.header-section .nav-collapse.collapse li:hover span {
    color: #fff;
}
 .header-section .nav-collapse.collapse ul li:hover ul.about-drop {
    max-width: 100%;
    width: 100%;
    border: transparent;
    max-width: 100%;
    width: 100%;
    border: none;
    background: transparent;
    padding: 0;
    border-left: 1px dashed #fff;
    border-radius: 0;
}
 .header-section .nav-collapse.collapse li:hover a i {
    left: 0;
    color: #fff;
    top: 0;
    right: 0;
}
.header-section .nav-collapse.collapse ul li:hover ul.about1-drop {
    max-width: 100%;
    width: 100%;
    border: transparent;
    max-width: 100%;
    width: 100%;
    border: none;
    background: transparent;
    padding: 0;
    border-left: 1px dashed #fff;
    border-radius: 0;
    top: 0;
}
.header-section .nav-collapse.collapse ul li:hover ul.about-drop.product-drop {
    min-width: 100%;
    left: 0px;
    top: 0;
    padding-left: 14px;
    padding-bottom: 10px;
}
.header-section .nav-collapse.collapse ul li:hover ul.about-drop {
    max-width: 100%;
    top: 0;
    left: 0px;
}

}

.user_details {
    position: absolute;
    text-align: right;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    &.desktop {
        top: -15px;
        right: 30px;
        width: auto;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 991px) {
            display: none;
        }

        .MuiAvatar-root{
            margin-left: 10px;
            width: 35px;
            height: 35px;
        }
    }
}
.user_details-1 {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}
.refer-name {
    text-align: right;
    margin: 0;
    padding-right: 50px;
}

`;
