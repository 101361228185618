import styled from "styled-components";

const ContactUsStyleCss = styled.div`
    
    &.contact_use_main {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        .contact_banner-section {
            img {
                width: 100%;
            }
        }
        .main_contact_us {
            text-align: center;

            .contact_hading_section {
                text-align: center;
                margin:58px 0px 82px;
                @media(max-width:1199px){
                     margin:20px 0;
                }
                h2 {
                    font-size:60px; 
                    color: #2A533F; 
                    @media(max-width:1439px){
                        font-size:45px;
                    }
                    @media(max-width:568px){
                        font-size:22px;
                    }
                }
                h3 {
                    font-size: 25px;
                    padding: 6px 0;
                    @media(max-width:568px){
                        font-size:18px;
                        line-height: 24px;
                     }
                }
            }
        }
        .location_Section {
                display: flex;
                flex-direction: column;
                row-gap:45px;
                @media(max-width:568px){
                    row-gap:20px;
                }
                .contact_paragraph_line {
                   p {
                    font-size: 25px;
                    font-weight: 600;
                    color: #39332E;
                    line-height: 46px;
                    margin-bottom: 0;
                    font-weight:500;
                    @media(max-width:991px){
                        font-size:30px;
                    }
                    @media(max-width:568px){
                        font-size:16px;
                        line-height: 24px;
                    }
                   
                   } 
                }
                .loction_address_phone_email {
                    display: flex;
                    justify-content: space-evenly;
                    margin: 16px 0;
                    @media(max-width:767px){
                        flex-wrap: wrap;
                        row-gap:30px;
                    }

                    .common_css {
                        p,svg { 
                            font-size: 24px;
                             row-gap:45px;
                             @media(max-width:568px){
                                font-size: 16px;
                    }
                        }
                        p {
                            color: #39332E;
                            font-weight: 600;
                            svg {
                                opacity: 0.3;
                                @media(max-width:568px){
                                    width: 30px;
                                    height:30px;
                    }
                            }
                        }
                       
                    }
                }
            }
            .contact_form_section {

                border: 1px solid #eee;
                padding: 76px 61px;
                width: 100%;
                max-width: 1158px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 4px 40px #0000001A;
                border-radius: 10px;
                opacity: 1;
                margin: 38px auto 135px;
                @media(max-width:767px){
                    margin: 15px auto;
                    padding:20px;
                }

                form {
                    display: flex;
                    flex-direction: column;
                    row-gap:20px;
                      label{
                        font-size: 18px;
                        font-family: "Helvetica Neue LT Std";
                        font-weight: 300;
                        color: rgba(85, 85, 85, 0.4);
                        left: 7px;
                        @media (max-width:568px){
                            font-size: 13px !important;
                            left: 0 !important;
                        }
                      }
                
                input {
                    width: 100%;
                    border: 1px solid #C4C4C4;
                    border-radius: 5px;
                    opacity: 1;
                    @media(max-width:568px){
                      padding:10px;
                    }
                }
            }
            .contact_textArea label {
                  padding-bottom: 7px;
                   }  
                .email_name {
                    gap: 40px;
                    @media(max-width:767px){
                        gap:20px;
                        
                    }
                    @media(max-width:568px){
                      flex-wrap:wrap;
                        
                    }
                     .form-group {
                        flex:1;
                        @media(max-width:568px){
                           flex:0 0 100%;
                            }
                        }
                    input {
                        width:100%;
                        padding: 17.5px 14px;
                        @media(max-width:568px){
                            padding: 10px;
                        }
                    }
                }
                .subject_section {
                    input {
                        max-width: 1037px;
                    }
                }
                .contact_textArea {
                    text-align: left;
                    color: #39332E;
                      label{
                        color:#000;
                      }
                }
                .contact_textArea textarea {
                   border: 1px solid #C4C4C4;
                    }
                .submitButton {
                    text-align: left;
                    margin: 12px 0 0;
                    button{
                        /* border: 2px solid rgb(42, 83, 63); */
                        margin-bottom: 10px;
                
                 &:hover {
                    background: transparent;
                    color: rgb(42, 83, 63);
                    border: 2px solid rgb(42, 83, 63);
                }
            }
        }
        
    }
            .form-control:focus {
            color: #212529;
            background-color: #fff;
            border-color: none;
            outline: 0;
            box-shadow:none;
        } 

    }
    
       @media (max-width:1500px){
        .contact_banner-section {
            padding: 50px 0 0;
        }
    .contact_use_main .main_contact_us .contact_hading_section {
            margin-bottom: 20px!important;
        }
       }   
       @media(max-width:767px){
        .contact_address.common_css {
            max-width: 100%;
            flex: 0 0 100%;
        }
        .contact_phonenumber.common_css {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 20px 0;
        }
        .contact_banner-section {
            padding: 115px 0 0;
        }
       }
       @media(max-width:767px){
        .contact_banner-section {
             padding: 60px 0px 0px;
             }
             .submitButton button {
    font-size: 16px;
    max-width: 150px;
}
       }
       a.contact-mail-cstm {
        color: rgb(57, 51, 46);
    }

 
`;

export default ContactUsStyleCss;