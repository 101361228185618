import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductApiPagination } from "../../../services/Redux/Reducer/AllProductSlice";
import _, { ceil } from "lodash";
import { SEQUENCE_PRODUCTS, storeAssigned, _pointsPrice, _productPrice } from "../../../services/Methods/normalMethods";
import { CATEGROY_LIST } from "../../../services/Methods/commonPayload";

const useAllProductHook = (props) => {
    let CUSTOMER_TYPE = localStorage?.getItem('customerTypeDescr') ? JSON.parse(localStorage?.getItem('customerTypeDescr')) : null;
    const dispatch = useDispatch();

    // states *************************************
    const [offset, setOffSet] = useState(1);

    const { isLoading, ...ProductList } = useSelector(state => {
        const PRODUCT_STRUCTURE = _.compact(_.map(state?.AllProductSlice?.paginationData?.data, (row) => {
            const FilterData = row?.itemPricing?.filter((items) => (items?.priceType === (CUSTOMER_TYPE?.priceType || 1)) && (items?.countryCode === _.upperCase(storeAssigned())));

            if (FilterData?.length > 0) {
                return ({
                    id: row?.itemId,
                    image: row?.mediumImage || require('../assets/product1.png'),
                    para: row?.title,
                    price: _productPrice(row?.itemPricing, 'oneTime', row?.isEligibleForAutoOrder),
                    stock: "",
                    points: _pointsPrice(row?.itemPricing, 'oneTime', row?.isEligibleForAutoOrder),
                    isEligibleForAutoOrder: row?.isEligibleForAutoOrder,
                    salePercentage: row?.otherFields?.field10
                });
            }
            // else {
            //     return ({
            //         id: row?.itemId,
            //         image: row?.mediumImage || require('../assets/product1.png'),
            //         para: row?.title,
            //         price: _productPrice(row?.itemPricing, 'oneTime', row?.isEligibleForAutoOrder),
            //         stock: "",
            //         points: _pointsPrice(row?.itemPricing, 'oneTime', row?.isEligibleForAutoOrder),
            //         isEligibleForAutoOrder: row?.isEligibleForAutoOrder
            //     });
            // }
        }))
        let PRODUCT_SEQUENCE = [];
        // _.forEach(SEQUENCE_PRODUCTS, (row) => {
        //     const filter = _.filter(PRODUCT_STRUCTURE, (val) => (_.includes(_.lowerCase(val?.para), row)));
        //     PRODUCT_SEQUENCE = [...PRODUCT_SEQUENCE, ...filter];
        // })
        return {
            PRODUCT_STRUCTURE: _.uniq([...PRODUCT_SEQUENCE, ...PRODUCT_STRUCTURE], 'id'),
            totalCount: storeAssigned() === 'us' ? state?.AllProductSlice?.totalCount : _.uniq([...PRODUCT_SEQUENCE, ...PRODUCT_STRUCTURE], 'id')?.length,
            isLoading: state?.AllProductSlice?.isLoading
        };
    });

    function _onPaginationChange(e) {
        const selectedPage = e.selected;
        const offsetpage = selectedPage + 1;
        setOffSet(offsetpage)
        const FindCategory = _.find(CATEGROY_LIST, { hashname: props?.category })
        dispatch(getAllProductApiPagination(offsetpage - 1, (FindCategory?.id || 1000)));
        window.scrollTo({ top: 1300, behavior: 'smooth' });
        // paginateData(Logintoken, offsetpage)
    }

    function _handleButtonChange(hashId) {
        props?.navigate(`/${storeAssigned()}/shop/${hashId || 'products'}`);
    }

    useEffect(() => {
        const FindCategory = _.find(CATEGROY_LIST, { hashname: props?.category })
        if (FindCategory?.id !== 1000 && offset === 2) {
            dispatch(getAllProductApiPagination(offset - 2, (FindCategory?.id || 1000)));
            setOffSet(1);
        }
        else {
            dispatch(getAllProductApiPagination(offset - 1, (FindCategory?.id || 1000)));
        }
    }, [props?.category]);


    return {
        isLoading,
        ProductList: ProductList?.PRODUCT_STRUCTURE,
        totalCount: Math.ceil(ProductList?.totalCount / 12),
        offset, _onPaginationChange, _handleButtonChange, CATEGROY_LIST,
    }
}

export default useAllProductHook;