import React from 'react';
import withRouter from '../services/HOC/withRouterHOC';
import { Footer } from './components/Footer';
import Header from './components/Header';

const MainLayout = ({ children, token, cartValues, selectedStore, setSelectedStore, countyObjects, countryCodes, alluserData, webAliseFilterData, ...props }) => {
    console.log("location.paht", props.location.pathname)
    return (
        <div className='main_layout_section'>
            {props.location.pathname.includes('WellnessEssentials') || props.location.pathname.includes('detoxification') ? "" :

                <Header {...{ token, cartValues, alluserData, selectedStore, setSelectedStore, countyObjects, countryCodes, webAliseFilterData, ...props }} />}
            {children}
            {props.location.pathname.includes('WellnessEssentials') || props.location.pathname.includes('detoxification') ? "" :
                <Footer />
            }
        </div>
    )
}

export default withRouter(MainLayout);