import { Clear } from '@mui/icons-material';
import { Box, FormControl, FormControlLabel, IconButton, Radio } from '@mui/material';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react'
import styled from 'styled-components';

const SavedCardStyle = styled.div`

    .savedcard_methods {
        border: 1px solid #ddd;
        width: 100%;
        max-width: 500px;
        padding: 10px;
        margin: 0 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            width: 100%;
            max-width: 90px;
            padding: 7px;
            border-radius: 0;
            margin-left: 10px;
        }
    }   

    .paymentOptions {
        display: flex;
        gap: 40px;
        margin-bottom: 30px;

        .box_container {
            cursor: pointer;
            padding: 20px 10px;
            width:100%;
            /* max-width: 30%; */
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #000;
            box-shadow: 0 0 7px 0px #285440;
            border-radius: 10px;


            /* width: auto;
            text-align: center;
            font-size: 20px;
            line-height: 1.5;
            font-weight: 600;
            color: #fff;
            border: 2px solid;
            border-radius: 50px;
            padding: 13px 30px 10px;
            background: #2a533f; */

            &.selected {
                background: #285440;
                color: #fff;
            }
        }
    }
    .credit_card_section {
        select {
            width: calc(50% - 17px/2) ;
            background: transparent;
            font-size: 18px;
            line-height: 1.5;
            border: 1px solid rgb(196, 196, 196);
            border-radius: 5px;
            color: rgba(85, 85, 85, 0.4);
            height: 50px;
            @media only screen and (max-width:568px) {
                margin-top: 20px;
                width: 100%;
            }
        }
    }
`;


const OptionsSelection = (props) => {
    const { title, optionData, checked, _onChangeFunction, section } = props;
    return (
        <>
            {/* <h3 style={{ textTransform: "capitalize" }}>{title}</h3> */}
            {(optionData?.length > 0) ? (
                optionData?.map((data, index) => {
                    return (
                        <Box className="savedcard_methods" key={index + 1}>
                            <FormControl className="radio_option">
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Radio
                                            checked={(section === "savedCard") ? (checked === data?.customerAccountId) : (checked === data?.paymentOptionId)}
                                            onChange={() => _onChangeFunction(data)}
                                            name="radio-button-demo"
                                            inputProps={{ "aria-label": "A" }}
                                        />
                                    }
                                    label={
                                        <p style={{ marginBottom: 0 }}>{(section === "savedCard") ? data?.creditCardDisplay : data?.description}</p>
                                    }
                                />
                            </FormControl>
                            {(checked) &&
                                <div className='cvv_section'>
                                    <Clear style={{ cursor: "pointer" }} onClick={() => _onChangeFunction(null)} />
                                </div>
                            }
                        </Box>
                    );
                })
            ) : (
                <p>No Saved Payment Method</p>
            )}
        </>
    )
}


const SavedCard = (props) => {

    const {
        paymentsOptions, selectedOptionForPayment, setSelectedOptionForPayment, setSelectedCard, savedCards, _handleCardChange, selectedCard,
        selectedPaymentOption, setSameAsBillingChecked, section
    } = props;

    return (
        <SavedCardStyle className='savedcard_option_div'>
            {(savedCards?.length > 0) &&
                <div className='paymentOptions'>
                    <div onClick={() => {
                        setSelectedOptionForPayment(1);
                        setSameAsBillingChecked(false);
                        if (section === 'autoship') {
                            setSelectedCard((prv) => ({ ...prv, previousAmount: savedCards[0], nextAmount: savedCards[0] }));
                        } else {
                            setSelectedCard(savedCards[0]);
                        }
                    }} className={classNames('box_container', { 'selected': (selectedOptionForPayment === 1) })}>Saved Payment Method</div>
                    <div onClick={() => {
                        setSelectedOptionForPayment(2);
                        setSameAsBillingChecked(false);
                        if (section === 'autoship') {
                            setSelectedCard((prv) => ({ ...prv, previousAmount: null, nextAmount: null }));
                        } else {
                            setSelectedCard(null);
                        }
                    }} className={classNames('box_container', { 'selected': (selectedOptionForPayment === 2) })}>Add New Payment Method</div>
                </div>
            }

            {(selectedOptionForPayment === 1) &&
                <div className='saved_card_section'>
                    <OptionsSelection
                        title=""
                        section="savedCard"
                        optionData={savedCards}
                        checked={selectedCard?.customerAccountId}
                        _onChangeFunction={(data) => _handleCardChange(data, 'savedCard')}
                    />
                </div>
            }
            {(selectedOptionForPayment === 2 && paymentsOptions?.length > 1) &&
                <div className='credit_card_section'>
                    <select value={selectedPaymentOption || ""} onChange={(e) => _handleCardChange(+e.target.value, 'paymentOption')}>
                        {_.map(paymentsOptions, (row) => (<option key={row?.paymentOptionId} value={row?.paymentOptionId}>{row?.description}</option>))}
                    </select>
                </div>
            }

        </SavedCardStyle>

    )
}

export default SavedCard;