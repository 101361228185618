import React from 'react';
import useAutoshipEditHook from './components/AutohsipEdit.hook';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import AutoshipAddressEdit from './components/AutoshipAddressEdit';
import { AddressSuggestionDialog } from '../../services/Methods/commonPayload';
import { setErrorAddressData } from '../../services/Redux/Reducer/CheckoutSlice';
import LoaderComponent from '../../Layout/components/Footer/components/Loader';
import AutoshipProductDetail from './components/AutoshipProducts';
import AutoshipMainPageStyle from './styles/AutoshipMainPage.style';
import ButtonComponent from '../../inputs/ButtonCompnent';
import { ErrorDisplay, SEQUENCE_PRODUCTS, SuccessDisplay, _productPrice } from '../../services/Methods/normalMethods';
import DialogComponent from '../../inputs/DailogComponent';
import AutoshipProductsAddStyle from './styles/AutoshipProductsAdd.style';
import _ from 'lodash';
import PaymentAndSavedCard from './components/PaymentAndSavedCard';
import { DialogActions } from '@material-ui/core';
import withRouter from '../../services/HOC/withRouterHOC';
import withPayment from '../../services/HOC/withPaymentsHOC';
import AddressOptions from '../Checkout/components/AddressOptions';
import { SHIPPING_VALIDATION_OBJECT } from '../../services/Methods/validationSchema';
import * as Yup from 'yup';

const AutoshipEditPage = (props) => {
    const [searchParams] = useSearchParams();
    const { ProductsDetail, token, alluserData, countryCodes } = useOutletContext();
    const { paymentsOptions, paymentFormHtml, sameAsBillingChecked, setSameAsBillingChecked, _paymentOptionSetBilling } = props;
    const {
        _handleUpdateAutoship, updateSuccess,
        autoshipOrderDetail, _handleQuantiyIncDec,
        _handleProductAdd, _handleProductDelete,
        addressEditOpen, setAddressEditOpen,
        openProductAdd, setOpenProductAdd,
        _handleAddressEditSubmit,
        countryState, setCountryState,
        frequencyTYpe, setFrequencyType,
        addressDetails, setAddressDetails,
        commonLoader,
        shippingMethods,
        selectedAutoshipCheck,
        _handleOnSelectedAutoshipChange,
        calculatedAutoshipData,
        dispatch,
        errorAddressData, normalAddressError,
        useDefaultAddressOrNot, setUseDefaultAddressOrNot,
        _callAutoshipCalculateApi,
        savedThisCard, setSavedThisCard,
        savedCards, _handleCardChange, selectedCard, setSelectedCard, _addNewCard,
        deletePopUp, _openCloseAskPopup,
        selectedOptionForPayment, setSelectedOptionForPayment, selectedPaymentOption,
        handleChangeFunction, _setUseDefaultAddressFunction,
        selectedDate, setSelectedDate, setOpenDialog, openDialog
    } = useAutoshipEditHook({ paymentFormHtml, token, autoshipId: searchParams.get('orderid') });


    let PRODUCT_SEQUENCE = [];
    _.forEach(SEQUENCE_PRODUCTS, (row) => {
        const filter = _.filter(ProductsDetail, (val) => (_.includes(_.lowerCase(val?.title), row)));
        PRODUCT_SEQUENCE = [...PRODUCT_SEQUENCE, ...filter];
    })
    const VALIDATION_SCHEMA = Yup.object().shape({ ...SHIPPING_VALIDATION_OBJECT });

    return (
        <AutoshipMainPageStyle>
            <div className='main_autoship_edit container'>
                {commonLoader && <LoaderComponent />}
                <div className='autohsip_banner'>
                    <h2>Edit Autoship</h2>
                </div>
                <div className='contents'>
                    <AutoshipProductDetail {...{
                        selectedDate, setSelectedDate,
                        setOpenProductAdd, _openCloseAskPopup, _handleQuantiyIncDec,
                        shippingMethods, frequencyTYpe, _handleOnSelectedAutoshipChange,
                        selectedAutoshipCheck, setFrequencyType, calculatedAutoshipData,
                        ...props
                    }}>
                        <AddressOptions {...{
                            section: 'autoship_edit',
                            _setUseDefaultAddressFunction, setOpenDialog, openDialog,
                            alluserData, handleChangeFunction, userData: addressDetails, setUserData: setAddressDetails,
                            countryCodes, normalAddressError, countryState, setCountryState,
                        }} />
                    </AutoshipProductDetail>

                    {/* {normalAddressError && <div className='errorMsg'><ErrorDisplay message={normalAddressError} /></div>} */}
                    {/* <AutoshipAddressEdit {...{
                        countryCodes,
                        _handleAddressEditSubmit,
                        countryState, setCountryState,
                        addressEditOpen, setAddressEditOpen,
                        addressDetails, ...props
                    }} /> */}

                    <PaymentAndSavedCard selectedPaymentOption={selectedPaymentOption} {...{
                        paymentFormHtml, paymentsOptions, selectedOptionForPayment, setSelectedOptionForPayment, addressDetails,
                        _addNewCard, token, savedCards, _handleCardChange, selectedCard, setSelectedCard,
                        savedThisCard, setSavedThisCard, setSameAsBillingChecked, sameAsBillingChecked, _paymentOptionSetBilling
                    }} />
                </div>
                <div className='update_autoship'>
                    <ButtonComponent classes="update_button" onClick={() => _handleUpdateAutoship()}>Update Autoship</ButtonComponent>
                    {updateSuccess && <div className='errorMsg'><SuccessDisplay message={updateSuccess} /></div>}
                </div>
                <AddressSuggestionDialog {...{
                    errorAddressData, normalAddressError, section: "autoshipEdit", useDefaultAddressOrNot, closeFunction: () => dispatch(setErrorAddressData({ objectData: null, section: "object" })),
                    setUseDefaultAddressOrNot: (data) => {
                        if (typeof data === 'boolean') {
                            setUseDefaultAddressOrNot(data);
                            _callAutoshipCalculateApi(autoshipOrderDetail, selectedAutoshipCheck, addressDetails, data);

                        } else {
                            setUseDefaultAddressOrNot(data);
                            setCountryState({ ...countryState, country: data?.country, state: data?.state_region });
                            const ADDRESS_DATA = {
                                ...addressDetails,
                                shipping_street: data?.address_1,
                                shipping_street2: data?.address_2,
                                shipping_city: data?.city,
                                shipping_country: data?.country,
                                shipping_state: data?.state_region,
                                shipping_zipcode: data?.postal_code
                            }
                            setAddressDetails(ADDRESS_DATA)
                            _callAutoshipCalculateApi(autoshipOrderDetail, selectedAutoshipCheck, ADDRESS_DATA, data);
                        }
                    }, userData: addressDetails
                }} />
                <DialogComponent opend={openProductAdd} handleClose={() => setOpenProductAdd(false)} title="Add Product" classFor="addProduts">
                    <AutoshipProductsAddStyle>
                        <div className="product_listing">
                            {_.map(_.reject(_.uniq([...PRODUCT_SEQUENCE, ...ProductsDetail], 'itemId'), (r) => _.map(autoshipOrderDetail, 'itemId')?.includes(r.itemId)), (row, index) => (
                                <div className="product_section" key={"Allproduct" + index}>
                                    <div className="product_image">
                                        <img src={row?.mediumImage || ""} alt="product" />
                                    </div>
                                    <div className='description_section'>
                                        <h3>{row?.title}</h3>
                                        <div className="price">
                                            <h4>${_productPrice(row?.itemPricing, 'autoship', row?.isEligibleForAutoOrder)}</h4>
                                        </div>
                                    </div>
                                    <div className="select_option">
                                        <a className='btn btn-show' onClick={() => _handleProductAdd(row)}>Add +</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </AutoshipProductsAddStyle>
                </DialogComponent>
            </div>
            <DialogComponent opend={deletePopUp?.open} handleClose={() => _openCloseAskPopup(null, 'close')} title="Alert" classFor="isDistributer deleteAsking_popup">
                <div className='py-[20px]'>
                    <p className='' style={{
                        fontSize: '24px',
                        fontWeight: 'bold',

                    }}> Are you sure want to delete this ?</p>
                </div>
                <DialogActions className='action_button' style={{ justifyContent: "center" }}>
                    <ButtonComponent onClick={() => _handleProductDelete(deletePopUp?.id)}> Yes</ButtonComponent>
                    <ButtonComponent onClick={() => _openCloseAskPopup(null, 'close')}>  No </ButtonComponent>
                </DialogActions>
            </DialogComponent>
        </AutoshipMainPageStyle >
    )
}

export default withRouter(withPayment(AutoshipEditPage));