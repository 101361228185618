import React, { useEffect } from 'react';
import TextFieldComponent from '../../../inputs/TextFieldComponent';
import { Formik, useFormik } from 'formik';
import EmailStyle from '../style/EmailSection.style';


const initialValues = {
    password: "",
    confirm_password: "",
    key: "",
};


const NewPassWord = ({ newpasswordvalidation, _onHandlePasswordSubmit, emailState }) => {

    const { values, errors, touched, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema: newpasswordvalidation,
            onSubmit: (values, action) => {
                _onHandlePasswordSubmit(values);
                // action.resetForm();
                // localStorage.setItem("PasswordKey", values?.key)
            },
        });

    const handleChangeWithLocalStorage = (event) => {
        const { name, values } = event.target;
        handleChange(event);
        localStorage.setItem("PasswordKey", event.target.value)

    };


    return (
        <EmailStyle>

            <div className='parent' >
                <form className='form-class' onSubmit={handleSubmit}>

                    <div className='login-section'>
                        <h4 className='pass-recover1' >Hello!</h4>
                        <h3 className='pass-recover'>Password Recovery.</h3><br />
                    </div>
                    <div className='temporary-key'>
                        <p><strong>DO NOT REFRESH THIS PAGE.</strong></p>
                        <p>Please retrieve the temporary key sent to your email</p>
                        <p><strong>(it may be in your spam folder)</strong></p>
                    </div>
                    <div className="field-class">
                        <TextFieldComponent
                            type="text"
                            className='input-container'
                            name="key" label=' Temporary Key'
                            value={values?.key}
                            onChange={handleChangeWithLocalStorage}
                            error={!!errors?.key}
                            errorMessage={errors?.key}
                        />

                    </div>
                    <div className="field-class">
                        <TextFieldComponent
                            type="password"
                            className='input-container'
                            name="password" label='New Password'
                            value={values?.password}
                            onChange={handleChange}
                            error={!!errors?.password}
                            errorMessage={errors?.password}
                        />
                    </div>
                    <div className="field-class">
                        <TextFieldComponent
                            type="password"
                            className='input-container'
                            name="confirm_password" label='Confirm Password'
                            value={values?.confirm_password}
                            onChange={handleChange}
                            error={!!errors?.confirm_password}
                            errorMessage={errors?.confirm_password}
                        />

                    </div>

                    <button className='button-container-pass' type="submit" >RESET PASSWORD</button>
                    {emailState?.successmsg && <div style={{ color: "#295340", fontSize: "20px" }}>{emailState?.successmsg}</div>}

                    {emailState?.emailerror && <div style={{ color: "red", fontSize: "14px", textAlign: "center" }}>{emailState?.emailerror}</div>}
                    <div className='email-temp' style={{ fontSize: "13px", textAlign: "center" }}>An email with a temporary key will be sent to the email address associated with your account</div>


                </form>
            </div >

        </EmailStyle >
    )

}
export default NewPassWord;