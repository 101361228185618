import { combineReducers } from '@reduxjs/toolkit';
import AllProductSlice from './Reducer/AllProductSlice';
import LoginSlice from './Reducer/LoginSlice';
import CheckoutSlice from './Reducer/CheckoutSlice';
import OrdersSlice from './Reducer/OrdersSlice';
import CustomerSlice from './Reducer/CustomerSlice';
import PaymentSlice from './Reducer/PaymentSlice';

const rootReducer = combineReducers({
    // [createDelete.reducerPath]: createDelete.reducer,
    AllProductSlice,
    LoginSlice,
    CheckoutSlice,
    OrdersSlice,
    CustomerSlice,
    PaymentSlice
});

export default rootReducer;