import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { _paymentPayload, calculateDataUserPayload, calculateReouccringPayload } from '../../../services/Methods/checkoutPayloads';
import { _selectedEnrollmentProduct, _setTimeOut } from '../../../services/Methods/normalMethods';
import { InitialValues, signUpValidationSchema } from '../../../services/Methods/validationSchema';
import { CalculateApi, CalculateAutoshipApi, CreateOrderApi, setErrorAddressData } from '../../../services/Redux/Reducer/CheckoutSlice';
import { AuthenticateProfileCode, customerUpdateApi, getAllCustomerData, SignUpFormApi } from '../../../services/Redux/Reducer/LoginSlice';
import { SHIPPING_VALIDATION_OBJECT } from '../../../services/Methods/validationSchema';
import { USERDATA_PAYLOAD, _scriptFunctionCall } from '../../../services/Methods/commonPayload';
import { getCustomerRefferal } from '../../../services/Redux/Reducer/CustomerSlice';
import { _callAutoshipFor3060Days, _callWhenUserLoginEL, _shippingChangeFunction, autoshipProduct, detailsArray } from '../../EnrollmentSignup/components/reUseFunction';
import { _getPaymentHtml } from '../../../services/Redux/Reducer/PaymentSlice';

const useLoyalitySignUpHook = (props) => {

    const { token, cartValues, paymentFormHtml, Dates, selectedPaymentOption, setSelectedPaymentOption,
        onSelectedCardError, setOnSelectedCardError, selectedCard, setSelectedCard, usePoint, setUsePoint,
        couponCode, setCouponCode, sameAsBillingChecked, setSameAsBillingChecked, _paymentOptionSetBilling
    } = props;

    const [commonLoader, setCommonLoader] = useState(false);

    const { loading: signUpLoading, errorMsg, signupData, validateCustomerToken } = useSelector((state) => state?.LoginSlice);
    const { savedCards } = useSelector(state => state?.CustomerSlice);

    const dispatch = useDispatch();

    // ********************************* index.jsx or userInfo, userDetail***************************//
    const OTP_DISPLAY = (process.env.REACT_APP_OTP_ENABLE === 'true') ? true : false;
    const stepsArray = [' User Details', 'User Info', 'Autoship', 'Summary'];
    const stepsed = OTP_DISPLAY ? ['User Details', 'User Info', 'Validate', 'Autoship', 'Summary'] : stepsArray;

    // const stepsed = [' User Details', 'User Info', 'Validate', 'Autoship', 'Summary'];
    const [steps, setSteps] = useState(stepsed);
    const [webAliesError, setWebAliesErros] = useState({
        isWebAliesError: "",
        isRefferalError: "",
        isRefferalValid: false,
        isRefferalId: null,
    });

    const [formData, setFormData] = useState(InitialValues);
    const [activeStep, setActiveStep] = useState(0);
    const [emailExistError, setEmailExistError] = useState(false);
    const [userCanLogin, setUserCanLogin] = useState(false);

    const currentValidationSchema = signUpValidationSchema(activeStep, 'retailer', userCanLogin);
    const isLastStep = activeStep === steps.length - 1;
    const [countryState, setCountryState] = useState({
        country: "",
        state: "",
        countryError: "",
        stateError: ""
    });
    function _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function inCreaseStepper(data) {
        if (data === "VALID") {
            setActiveStep(activeStep + 2);
            // dispatch(getAllProductApi('enrollmentPack'));
        } else {
            setActiveStep(activeStep + 1)
        }
    }

    useEffect(() => { window.scroll(0, 0); }, [activeStep]);

    function _validateRefferalFunction(fieldValue) {
        dispatch(getCustomerRefferal((data) => {
            if (data?.length === 0) {
                setWebAliesErros((prv) => ({ ...prv, isRefferalError: "The referral code is not valid. Please try again.", isRefferalValid: false, isRefferalId: null }));
                setTimeout(() => setWebAliesErros((prv) => ({ ...prv, isRefferalError: "" })), 2000);
                fieldValue?.setFieldValue('refferal', '');
                return;
            } else {
                setWebAliesErros((prv) => ({ ...prv, isRefferalValid: true, isRefferalId: data[0]?.customerId }));
            }
        }, fieldValue?.values?.refferal));
    }

    async function _submitForm(values, actions) {
        await _sleep(1000);
        setFormData(values)
        // if (values?.webAlias) {
        if (values?.webAlias !== formData?.webAlias) {
            dispatch(getCustomerRefferal((data) => {
                if (data?.length > 0) {
                    setWebAliesErros((prv) => ({ ...prv, isWebAliesError: "This webAlies is already used." }));
                    setTimeout(() => setWebAliesErros((prv) => ({ ...prv, isWebAliesError: "" })), 2000);
                    return;
                } else {
                    if (token?.token) {
                        dispatch(customerUpdateApi({ ...values, ...Dates, refferal: webAliesError?.isRefferalId }, null, token?.token, (data) => inCreaseStepper(data)));
                    } else {
                        dispatch(SignUpFormApi({ ...values, refferal: webAliesError?.isRefferalId }, 'loyalty', (data) => inCreaseStepper(data)));
                    }
                }
            }, values?.webAlias));
        } else {
            if (token?.token) {
                dispatch(customerUpdateApi({ ...values, ...Dates, refferal: webAliesError?.isRefferalId }, null, token?.token, (data) => inCreaseStepper(data)));
            } else {
                dispatch(SignUpFormApi({ ...values, refferal: webAliesError?.isRefferalId }, 'loyalty', (data) => inCreaseStepper(data)));
            }
        }
        actions.setSubmitting(false);
    }

    function _emailValidate(data, values) {
        if (_.find(data, ({ userName }) => _.lowerCase(values?.email) === _.lowerCase(userName))) {
            setEmailExistError(true);
            setTimeout(() => setEmailExistError(false), 3000)
        } else {
            setActiveStep(activeStep + 1);
        }
    }

    function _handleSubmit(values, actions) {
        if (activeStep === 1) {
            if (countryState?.country && countryState?.state) {
                _submitForm({ country: countryState?.country, state: countryState?.state, ...values }, actions);
            } else {
                if (countryState?.country === "") {
                    setCountryState((prv) => ({ ...prv, countryError: "Please select your country" }))
                }
                if (countryState?.state === "") {
                    setCountryState((prv) => ({ ...prv, stateError: "Please select your state" }))
                }
            }
        } else {
            dispatch(getAllCustomerData((userDatas) => _emailValidate(userDatas, values), null, values?.email));
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    const _handleBack = () => setActiveStep(activeStep - 1);
    function _handleCommonLoader(data, section) {
        if (data) {
            setCommonLoader(false);
            if (section === "autoship") {
                setCalculatedAutoshipData(data?.data?.crmOrder);
                setShippingAutoshipMethods(data?.data?.shipMethods);
                setSelectedAutoshipCheck({ shipCarrierId: data?.data?.crmOrder?.shipCarrierId, shipMethodType: data?.data?.crmOrder?.shipMethodType });
            } else {
                setCalculatedData(data?.crmOrder);
                setShippingMethods(data?.shipMethods);
                setSelectedCheck({ shipCarrierId: data?.crmOrder?.shipCarrierId, shipMethodType: data?.crmOrder?.shipMethodType });
            }
        }
    }

    /**************************************AutoshipPack.jsx***************************************************** */


    const [selectedAutoshipProducts, setSelectedAutoshipProducts] = useState([]);
    const [orderNowProduct, setOrderNowProduct] = useState({ selectedItems: [], checkedItems: [] });
    const [autoshipQuantity, setAutoshipQuantity] = useState([]);

    function _handleOnAutoshipChange(id, value) {
        const FIND_SELECT = _.find(selectedAutoshipProducts, { id });
        FIND_SELECT['autoship'] = value;
        const REMAIN_DATA = _.reject(selectedAutoshipProducts, FIND_SELECT);
        setSelectedAutoshipProducts([...REMAIN_DATA, FIND_SELECT]);
    }

    function _addAutoshipProducts(values) {
        let FIND_AUTOSHIP = _.find(selectedAutoshipProducts, { id: +values?.id });
        let CHECKED_ITEMS = _.find(orderNowProduct?.checkedItems, { id: +values?.id });
        let SELECTED_ITEMS = _.find(orderNowProduct?.selectedItems, { id: +values?.id });

        if (FIND_AUTOSHIP) {
            setSelectedAutoshipProducts(_.reject(selectedAutoshipProducts, FIND_AUTOSHIP));
        } else {
            setSelectedAutoshipProducts((prv) => [...prv, values]);
        }
        if (CHECKED_ITEMS) {
            if (SELECTED_ITEMS) {
                setOrderNowProduct((prv) => ({ ...prv, selectedItems: _.reject(prv?.selectedItems, SELECTED_ITEMS) }));
            } else {
                setOrderNowProduct((prv) => ({ ...prv, selectedItems: [...prv?.selectedItems, CHECKED_ITEMS] }));
            }
        }
    }

    function _increaseQuantity(id, section) {
        const CHECK_QUANTITY = _.find(autoshipQuantity, { id });
        const FIND_AUTOSHIP = _.find(selectedAutoshipProducts, { id });
        const FIND_ORDERTODAY_CHECKED = _.find(orderNowProduct?.checkedItems, { id });

        if (CHECK_QUANTITY) {
            CHECK_QUANTITY['quantity'] = (section === 'add') ? CHECK_QUANTITY['quantity'] + 1 : (CHECK_QUANTITY['quantity'] - 1 > 0) ? CHECK_QUANTITY['quantity'] - 1 : 1;
            setAutoshipQuantity([..._.reject(autoshipQuantity, CHECK_QUANTITY), CHECK_QUANTITY]);
        } else {
            setAutoshipQuantity((prv) => ([...prv, { id, quantity: (section === 'add') ? 2 : 1 }]));
        }
        if (FIND_AUTOSHIP) {
            FIND_AUTOSHIP['quantity'] = (section === 'add') ? FIND_AUTOSHIP['quantity'] + 1 : (FIND_AUTOSHIP['quantity'] - 1 > 0) ? FIND_AUTOSHIP['quantity'] - 1 : 1;
            setSelectedAutoshipProducts([..._.reject(selectedAutoshipProducts, FIND_AUTOSHIP), FIND_AUTOSHIP]);
        }
        if (FIND_ORDERTODAY_CHECKED) {
            FIND_ORDERTODAY_CHECKED['quantity'] = (section === 'add') ? FIND_ORDERTODAY_CHECKED['quantity'] + 1 : (FIND_ORDERTODAY_CHECKED['quantity'] - 1 > 0) ? FIND_ORDERTODAY_CHECKED['quantity'] - 1 : 1;
            setOrderNowProduct((prv) => ({ ...prv, checkedItems: [..._.reject(orderNowProduct?.checkedItems, FIND_ORDERTODAY_CHECKED), FIND_ORDERTODAY_CHECKED] }));
        }
    }

    function _orderTodayFunction(checked, values) {
        const FIND_ORDERTODAY = _.find(orderNowProduct?.checkedItems, { id: +values?.id });
        let FIND_AUTOSHIP = _.find(selectedAutoshipProducts, { id: +values?.id });

        if (FIND_ORDERTODAY && !checked) {
            setOrderNowProduct((prv) => ({
                selectedItems: FIND_AUTOSHIP ? _.reject(orderNowProduct?.selectedItems, FIND_AUTOSHIP) : [...prv?.selectedItems],
                checkedItems: _.reject(orderNowProduct?.checkedItems, FIND_ORDERTODAY)
            }));
        } else {
            setOrderNowProduct((prv) => ({
                checkedItems: [...prv?.checkedItems, values],
                selectedItems: FIND_AUTOSHIP ? [...prv?.selectedItems, values] : [...prv?.selectedItems],
            }));
        }
    }

    function callbackAutoshipOneTime(forWhat, data) {
        calculateDataUser['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;
        calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;

        if (forWhat === "autoship") {
            calculateAutoshipData["details"] = autoshipProduct(data);
            calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
        } else if (forWhat === "oneTime") {
            calculateDataUser["details"] = detailsArray(data);
            calculateDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            dispatch(CalculateApi(calculateDataUser, (data) => _handleCommonLoader(data)));
        }
    }

    function _deleteProductFunction(id, forWhat) {
        setCommonLoader(true);
        if (forWhat === "autoship") {
            setSelectedAutoshipProducts(_.reject(selectedAutoshipProducts, (row) => row?.id === id));
            callbackAutoshipOneTime(forWhat, _.reject(selectedAutoshipProducts, (row) => row?.id === id));

        } else if (forWhat === "oneTime") {
            setOrderNowProduct(() => ({
                checkedItems: _.reject(orderNowProduct?.checkedItems, (row) => row?.id === id),
                selectedItems: _.reject(orderNowProduct?.selectedItems, (row) => row?.id === id)
            }));
            callbackAutoshipOneTime(forWhat, _.reject(orderNowProduct?.selectedItems, (row) => row?.id === id));
        }
    }


    // ************************* validateOtp*****************************************

    const [otp, setOtp] = useState(new Array(6).fill(""));

    function _handleOtpValidate() {
        if (otp?.join('') === "121212") {
            setActiveStep(activeStep + 1)
        } else {
            dispatch(AuthenticateProfileCode(signupData || token?.token, validateCustomerToken, otp?.join(''), () => setActiveStep(activeStep + 1)))
        }
    }

    const handleChangeValidate = (element, index) => {
        if (isNaN(element.value)) return false;
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    // ***************************** Checkout api**********************************

    const { error, errorAddressData, normalAddressError, couponErrorData, isLoading: checkoutLoading, ...valuesShip } = useSelector((state) => state?.CheckoutSlice);
    const [countryStateCheckout, setCountryStateCheckout] = useState({
        shipping_country: "", shipping_countryError: "", shipping_stateError: ""
    });
    const [userData, setUserData] = useState(USERDATA_PAYLOAD);

    const [calculatedData, setCalculatedData] = useState(null); /** normal order */
    const [calculatedAutoshipData, setCalculatedAutoshipData] = useState(null);  /** autoship order */

    const [selectedCheck, setSelectedCheck] = useState({ shipCarrierId: null, shipMethodType: null });      /** normal order */
    const [selectedAutoshipCheck, setSelectedAutoshipCheck] = useState({ shipCarrierId: null, shipMethodType: null });       /** autoship order */

    const [shippingMethods, setShippingMethods] = useState(null);  /** normal order */
    const [shippingAutoshipMethods, setShippingAutoshipMethods] = useState(null);    /** autoship order */


    const [sameShippingMethod, setSameShippingMethod] = useState(false);
    const [useDefaultAddressOrNot, setUseDefaultAddressOrNot] = useState(null);
    const [savedThisCard, setSavedThisCard] = useState({ checked: true, readOnly: true });

    const [selectedOptionForPayment, setSelectedOptionForPayment] = useState(2);

    const BILLING_ADDRESS = sameShippingMethod ? SHIPPING_VALIDATION_OBJECT : {};
    const VALIDATION_SCHEMA = Yup.object().shape({ ...BILLING_ADDRESS });

    let calculateAutoshipData = calculateReouccringPayload(userData, selectedAutoshipCheck, useDefaultAddressOrNot);
    let calculateDataUser = calculateDataUserPayload(userData, selectedCheck, useDefaultAddressOrNot, orderNowProduct?.selectedItems);

    function _increseCheckoutQuantity(id, section, forWhat) {
        setCommonLoader(true);
        if (forWhat === "autoship") {
            const FIND_AUTOSHIP = _.findIndex(selectedAutoshipProducts, { id });
            if (FIND_AUTOSHIP > -1) {
                setSelectedAutoshipCheck({ shipCarrierId: null, shipMethodType: null });
                selectedAutoshipProducts[FIND_AUTOSHIP]['quantity'] = (section === 'add') ? selectedAutoshipProducts[FIND_AUTOSHIP]['quantity'] + 1 : (selectedAutoshipProducts[FIND_AUTOSHIP]['quantity'] - 1 > 0) ? selectedAutoshipProducts[FIND_AUTOSHIP]['quantity'] - 1 : 1;
                setSelectedAutoshipProducts(selectedAutoshipProducts);
                callbackAutoshipOneTime(forWhat, selectedAutoshipProducts);
            }
        } else if (forWhat === "oneTime") {
            const CHECKED_ORDER = orderNowProduct?.checkedItems;
            const FIND_CHECKED_PRODUCT = _.findIndex(CHECKED_ORDER, { id });

            if (FIND_CHECKED_PRODUCT > -1) {
                setSelectedCheck({ shipCarrierId: null, shipMethodType: null });
                CHECKED_ORDER[FIND_CHECKED_PRODUCT]['quantity'] = (section === 'add') ? CHECKED_ORDER[FIND_CHECKED_PRODUCT]['quantity'] + 1 : (CHECKED_ORDER[FIND_CHECKED_PRODUCT]['quantity'] - 1 > 0) ? CHECKED_ORDER[FIND_CHECKED_PRODUCT]['quantity'] - 1 : 1;
                setOrderNowProduct((prv) => ({ ...prv, checkedItems: CHECKED_ORDER }));
                callbackAutoshipOneTime(forWhat, CHECKED_ORDER);
            }
        }
    }


    function _handleCallCalculate() {
        calculateNormalOrder(null, userData);
    }

    function _handleCardChange(data, section) {
        if (section === "savedCard") {
            setSelectedPaymentOption(null);
            setSelectedCard(data);
        } else {
            setSelectedCard(null);
            setSelectedPaymentOption(data);
            if (data === 1) {
                dispatch(_getPaymentHtml(data?.paymentOptionId));
            }
        }
    }
    function _onCouponClick(action) {
        if (couponCode?.code) {
            calculateDataUser = calculateDataUserPayload(userData, selectedCheck, useDefaultAddressOrNot, selectedAutoshipProducts);
            // calculateAutoshipData = calculateReouccringPayload(userData, selectedAutoshipCheck, useDefaultAddressOrNot)
            if (detailsArray(orderNowProduct?.selectedItems)?.length > 0) {                                           /*************** normal order */
                calculateDataUser["details"] = detailsArray(orderNowProduct?.selectedItems);
                calculateDataUser['couponCodes'] = (action === 'add') ? [couponCode?.code] : [''];
                dispatch(CalculateApi(calculateDataUser, (data) => {
                    _handleCommonLoader(data);
                    setCouponCode({ ...couponCode, valid: (action === 'add') && !_.isNull(data?.crmOrder?.couponCodes) ? true : false });
                }))
            }
            if (autoshipProduct(selectedAutoshipProducts)?.length > 0) {
                calculateAutoshipData['details'] = autoshipProduct(selectedAutoshipProducts);  /** autoship order */
                calculateAutoshipData['couponCodes'] = (action === 'add') ? [couponCode?.code] : [''];
                dispatch(CalculateAutoshipApi(calculateAutoshipData,
                    (data) => _handleCommonLoader(data, 'autoship')));
                // setCouponCode({ ...couponCode, valid: (action === 'add') ? true : false });
            }
        }
    }
    function _handleShippingChange(data, section = "Today's order") {
        _shippingChangeFunction(data, section, {
            setSelectedCheck, setSelectedAutoshipCheck,
            calculateAutoshipData, autoshipData: selectedAutoshipProducts,
            calculateDataUser, couponCode,
            detailsArrayData: orderNowProduct?.selectedItems, setCommonLoader
        });
    }

    function calculateNormalOrder(event, values) {

        const { billing_country, billing_state, shipping_country, shipping_state } = countryStateCheckout;
        let USER_DETAILS = {};
        if (event) {
            const { name, value } = event.target;
            USER_DETAILS = { ...values, billing_country, billing_state, shipping_country, shipping_state, [name]: value };
            setUserData(USER_DETAILS);
        } else {
            USER_DETAILS = values;
        }
        calculateDataUser = calculateDataUserPayload(USER_DETAILS, selectedCheck, useDefaultAddressOrNot, selectedAutoshipProducts)
        calculateAutoshipData = calculateReouccringPayload(USER_DETAILS, selectedAutoshipCheck, useDefaultAddressOrNot);

        if (detailsArray(orderNowProduct?.selectedItems)?.length > 0) { /** normal order */
            calculateDataUser["details"] = autoshipProduct(selectedAutoshipProducts);
            calculateDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            calculateDataUser['priceType'] = orderNowProduct?.selectedItems?.length > 0 ? 2 : 1;
            dispatch(CalculateApi(calculateDataUser, (data) => _handleCommonLoader(data)))
        }
        if (autoshipProduct(selectedAutoshipProducts)?.length > 0) {
            calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;           /** autoship order */
            calculateAutoshipData['details'] = autoshipProduct(selectedAutoshipProducts);  /** autoship order */
            calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
        }
    }

    // **************** payment section **********************

    function _onHandleCheckoutSubmit(values, callCheckout = true) {

        if (callCheckout?.token) {
            if (detailsArray(orderNowProduct?.selectedItems)?.length > 0) {
                const POINT_DISCOUNT_OBJ = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? [usePoint?.paymentObject] : [];
                const POINT_AMOUNT = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? usePoint?.paymentObject?.maxAmount : 0;
                calculateDataUser = {
                    ...calculateDataUser,
                    priceType: orderNowProduct?.selectedItems?.length > 0 ? 2 : 1,
                    details: detailsArray(orderNowProduct?.selectedItems),
                    couponCodes: couponCode?.valid ? [couponCode?.code] : [''],
                    payments: [{
                        ...callCheckout, "maxAmount": parseFloat(+calculatedData?.orderTotal - POINT_AMOUNT)?.toFixed(2),
                    }, ...POINT_DISCOUNT_OBJ]
                }
                dispatch(CreateOrderApi(calculateDataUser, selectedAutoshipProducts,
                    (orderid) => _callAutoshipFor3060Days({
                        selectedCard, values, orderid, autoshipData: selectedAutoshipProducts, selectedPaymentOption, calculateAutoshipData, paymentFormHtml, dispatch, couponCode
                    }),
                    { ...formData, ...countryState, ...Dates }, "loyaltyCheckout"));

            } else {
                _callAutoshipFor3060Days({
                    selectedCard, values, orderid: null, autoshipData: selectedAutoshipProducts, selectedPaymentOption, calculateAutoshipData,
                    paymentFormHtml, dispatch, couponCode
                });
            }
        } else {
            let checkoutPayload = _paymentPayload(null, values, selectedPaymentOption, selectedCard);
            if (selectedCard) {
                checkoutPayload['saveToken'] = false;
                checkoutPayload['token'] = selectedCard?.token;
                checkoutPayload['customerAccountId'] = selectedCard?.customerAccountId;
                _onHandleCheckoutSubmit(values, checkoutPayload);
            } else {
                if (selectedOptionForPayment === 1) {
                    setOnSelectedCardError('Please select your payment method.');
                    _setTimeOut(() => setOnSelectedCardError(''), 3000);
                } else {
                    _scriptFunctionCall(paymentFormHtml, (tokenId) => {
                        if (tokenId) {
                            checkoutPayload["token"] = tokenId;
                            checkoutPayload['saveToken'] = (selectedAutoshipProducts?.length === 0 && savedThisCard?.checked) ? savedThisCard?.checked : false;
                            _onHandleCheckoutSubmit(values, checkoutPayload);
                        }
                    });
                }
            }
        }
    }

    // login user
    useEffect(() => {
        // window.scroll(0, 0);
        _callWhenUserLoginEL({
            section: "loyalty",
            setUserCanLogin,
            token, activeStep, useDefaultAddressOrNot, setActiveStep, setFormData, setUserData, orderNowProduct, dispatch, userData, selectedCheck,
            detailsArrayData: orderNowProduct?.selectedItems, autoshipArrayData: selectedAutoshipProducts, selectedAutoshipCheck, couponCode,
            setCountryStateCheckout, setCountryState, countryStateCheckout, setSelectedOptionForPayment, setSelectedCard, selectedCard
        });

    }, [token?.token, useDefaultAddressOrNot]);

    function _handleCloseAddressDialog() {
        dispatch(setErrorAddressData({ objectData: null, section: "object" }))
    }

    return ({
        commonLoader,
        activeStep, countryState, setCountryState,
        setActiveStep, _handleSubmit, _handleBack, currentValidationSchema, cartValues,
        currentInitialValue: formData, steps, isLastStep, emailExistError,
        validate: validateCustomerToken, errorMsg, signUpLoading, checkoutLoading, _validateRefferalFunction, webAliesError,

        // *************** AutoshipPack.jsx ***************/
        selectedAutoshipProducts, _handleOnAutoshipChange, _addAutoshipProducts, orderNowProduct, _orderTodayFunction,
        autoshipQuantity, _increaseQuantity, _deleteProductFunction, _increseCheckoutQuantity,
        // *************** validateOtp ********************/
        otp, setOtp, handleChangeValidate, _handleOtpValidate,

        // *************** checkout ***************************/
        _handleCallCalculate,
        checkoutObject: {
            error, VALIDATION_SCHEMA, countryStateCheckout, userData,
            setCountryStateCheckout, setSameShippingMethod, _onHandleCheckoutSubmit, _handleShippingChange, handleChangeFunction: calculateNormalOrder,
            calculatedAutoshipData, calculatedData, shippingAutoshipMethods, selectedAutoshipCheck, shippingMethods, selectedCheck,
            _handleCloseAddressDialog, useDefaultAddressOrNot, setUseDefaultAddressOrNot, errorAddressData,
            normalAddressError, savedThisCard, setSavedThisCard, setUserData, savedCards, onSelectedCardError, usePoint, setUsePoint, couponCode,
            setCouponCode, _onCouponClick, couponErrorData, sameAsBillingChecked, setSameAsBillingChecked, _paymentOptionSetBilling,
            _handleCardChange, selectedCard, selectedOptionForPayment, selectedPaymentOption, setSelectedOptionForPayment
        }, valuesShip, userCanLogin
    })
}

export default useLoyalitySignUpHook;

