import React from 'react'
import styled from 'styled-components'

export const ThankyoyStyle = styled.div`
.thanks-section {
    padding: 250px 0 100px;
    @media(max-width:1199px){
        padding: 160px 0 100px;
                        }
                        @media(max-width:568px){
                            padding: 80px 0 30px;
                        }
        h2 {
        text-align: center;
        color: #2A533F;
        padding-bottom: 20px;
           }
           .container {
                max-width: 1145px;
                margin: 0 auto;
            }
           .thanks-order {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                .order-number {
                    float: left;
                    padding: 0 40px;
                    min-height: 104px;
                    position: relative;
                    @media(max-width:991px){
                        max-width: 50%;
                         flex: 0 0 50%;
                         padding:0;
                    }
                    @media(max-width:568px){
                        max-width: 100%;
                         flex: 0 0 100%;
                         padding:0;
                         min-height: 60px;
                }
                        h3 {
                        font-size:30px;
                        line-height: 24px;
                        font-weight: 400;
                        color: #555555;
                        margin-bottom: 0;
                        @media(max-width:1199px){
                            font-size:22px;
                        }
                        @media(max-width:568px){
                            font-size:18px;
                }
                        
                    }
                    .tanks-price {
                        font-size:30px;
                        color: #555555;
                        font-weight: 600;
                        @media(max-width:1199px){
                            font-size:22px;
                        }
                        @media(max-width:568px){
                            font-size:18px;
                        }
                    }

                    &:not(:nth-last-child(-n+2)):after {
                        content: '';
                        position: absolute;
                        height: 62px;
                        width: 1px;
                        background: black;
                        top: 0;
                        right: 0;
                        @media(max-width:991px){
                            display:none;
                        }
                    }  
                    &:nth-child(5) {
                            padding-left: 0;
                            @media(max-width:991px){
                         padding-left:20px;
                    }
                    @media(max-width:568px){
                         padding-left:0;
                    }
                        } 
                        &:nth-child(1) {
                            padding-left: 0;
                            @media(max-width:991px){
                         padding-left:20px;
                    }
                    @media(max-width:568px){
                         padding-left:0;
                    }
                        } 
                
            }
}
            .table-data  {
                padding: 50px 0 0;
                @media(max-width:991px){
                        padding:20px 0 0;
                    }
                h3 strong{
                    @media(max-width:991px){
                        font-size:30px !important;
                    }
                    @media(max-width:767px){
                        font-size:24px !important;
                    }
                    @media(max-width:568px){
                        font-size:20px !important;
                    }
                }
                table{
                    border-collapse: collapse !important;
                    width:100%; 
                
                thead {
                    background: #C4C4C44D;
                border: 1px solid #C4C4C4;
                border-radius: 5px;
                color: #295340;
                th {
                padding: 15px 17px 10px;
                border: none;
                font-size:36px;
                @media(max-width:1199px){
                            font-size:24px;
                        }
                        @media(max-width:767px){
                            font-size:22px;
                        }
                        @media(max-width:568px){
                            font-size:18px;
                        }
                
                
                &:nth-child(2) {
                    text-align: center;
                }
                &:last-child{
                    text-align: right;
                }
               
            }
        }
                td {
            padding: 20px 20px 0;
            border-bottom: 1px solid #ddd;
            font-size:30px;
            font-weight: 600;
            color: #555555;
            @media(max-width:1199px){
                            font-size:22px;
                        }
                        @media(max-width:767px){
                            font-size:20px;
                            padding: 10px 0;
                        }
                        @media(max-width:568px){
                            font-size:16px;
                        }
            &:nth-child(2) {
                            text-align: center;
                        }
                        &:last-child{
                            text-align: right;
                        }
                }
                }
                .shipping-billing {
                display: flex;
                margin: 70px 0;
                 .address {
                    max-width: 50%;
                    flex: 0 0 50%;
                    @media(max-width:568px){
                        max-width: 100%;
                    flex: 0 0 100%;
                    }
                }
                @media(max-width:568px){
                    flex-wrap: wrap;
                    margin: 30px 0;
                }
                    h3 {
                    color: #39332E;
                    font-size: 40px;
                    font-weight: 600;
                    padding-bottom: 16px;
                    @media(max-width:1199px){
                            font-size:24px;
                            padding-bottom:6px;
                            margin-bottom: 10px;
                        }
                        @media(max-width:767px){
                            font-size:18px;
                    
                        }
                      
               
                }
}
}
        .empty-td td {
            border: none !important;
        }
        .subtotal-td td {
            border-bottom: none !important;
        }
        .shipping1 {
            color: #39332E;
            font-weight: 400;
            font-size: 24px;
            line-height: 40px;
            padding-left: 22px;
            @media(max-width:1199px){
                            font-size:22px;
                        }
                        @media(max-width:767px){
                            font-size:20px;
                        }
                        @media(max-width:568px){
                            font-size: 16px;
                            line-height: 30px;
                            padding: 0 0 10px;
                        }
        }
        .table-data h3 {
            margin-bottom: 26px;
        }
        }
`;