import React from 'react';
import styled from 'styled-components';
import TopRelatedProducts from '../../../Components/TopRelatedProducts';
import { useEffect } from 'react';
const RewardProgramStyle = styled.div`
    margin-top: 104px;
    
    .reward_banner_section {
        height: 925px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        .banner-text {
            position: absolute;
            right: 121px;
            bottom: 75px;
            width: 100%;

            .banner_titles {
                margin: 0px 130px 0 auto;
                max-width: fit-content;
                width: 100%;

                h2 {
                    color: rgb(42, 83, 63);
                    margin-bottom: 24px;
                }

                h3 {
                    font-size: 30px;
                    &:last-child {
                        font-weight: 600;
                    }
                }
            }
        }

        @media(max-width: 1440px){
            .banner-text {
                .banner_titles {
                    margin: 0px 0px 0px auto;
                }
            }
        }
        @media(max-width: 991px) {
            .banner-text {
                right: 0;
                /* left: 90px; */

                .banner_titles {
                    margin-left:45px;
                    h2 {
                        font-size:45px;
                    }
                }
            }
        }

    }

    .reward_container {
        padding-top: 90px;

        .reward_content_section {
            display: flex;
            width: 100%;
            /* gap: 60px; */
            justify-content: space-evenly;
            
            @media(max-width: 1280px){
                gap: 30px;
            }
            /* @media(min-width: 1280px){
                gap: 60px
            } */

            .image_container {
                /* height: 715px; */
                width: 100%;
                max-width: 480px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
            .text_container {
                width: 100%;
                max-width: 682px;

                p {
                    font-size: 18px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            @media(max-width: 1440px){
                   gap: 30px;
                    .text_container {
                        p{
                            font-size: 20px;
                        } 
                    }
            }

            @media(max-width: 1280px){
                .text_container {
                    p{
                        font-size: 19px;
                    } 
                }
            }

            @media(max-width: 1024px){
                .text_container {
                    p{
                        font-size: 18px;
                    } 
                } 
            }

            @media(max-width: 991px){
                flex-direction : column;
                .image_container {
                    max-width: 100%;
                }   
                .text_container {
                    max-width: 100%;
                    p{
                        font-size: 21px;
                    } 
                    p:last-child{
                       margin-bottom:10px;
                    } 
                } 
            }
        }
    }
`;

const RewardProgram = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <RewardProgramStyle className='main_reward_page_section'>
            <div className="reward_banner_section" style={{ backgroundImage: `url(${require('../assets/reward_page_banner.jpg')})` }}>
                <div className="banner-text">
                    <div className="banner_titles">
                        <h2>Rewards Program</h2>
                        <h3>Welcome to our exclusive</h3>
                        <h3>Abeytu´&reg; Customer Rewards Program!</h3>
                    </div>
                </div>
            </div>
            <div className='container reward_container'>
                <div className='reward_content_section'>
                    <div className='image_container'>
                        <img src={require('../assets/rewardPageSecond.jpg')} />
                    </div>
                    <div className='text_container'>
                        <p>
                            We are delighted to unveil the continuation of our Loyalty Points Program here at Abeytu´&reg; Naturals! Every transaction you complete with us contributes to a gratifying points system.
                        </p>
                        <p>
                            <strong>Here's how it operates:</strong> Each purchased item accumulates points based on its assigned point value. These points transcend mere numerical values; they represent your hard-earned dollars, poised to enhance your nextAbeytu´&reg; Naturals shopping experience.
                        </p>
                        <p>
                            <strong>Key Features:</strong>
                        </p>
                        <p>
                            <strong>Accumulate and Save:</strong> Witness the electronic tally of your points every week as your points multiply with each purchase, creating a reserve for future shopping sprees.
                        </p>
                        <p>
                            <strong>Flexible Redemption:</strong> Harness the power of your accumulated points by applying them to any forthcoming purchase, seamlessly converting your loyalty into tangible savings.
                        </p>
                        <p>
                            Our Loyalty Points Program is more than a rewards system; it's a heartfelt expression of gratitude for choosingAbeytu´&reg; Naturals as your go-to destination for natural wellness essentials.
                        </p>
                        <p>Start your points accumulation journey on each purchase and unlock a cascade of benefits that await you!<br />
                            Note: All points from May 18th, 2023 when our new web platform began have been included in your current total.</p>
                    </div>
                </div>
            </div>
            <TopRelatedProducts />
        </RewardProgramStyle>
    )
}

export default RewardProgram;