import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { LoginApi, SignUpFormApi } from "../../../services/Redux/Reducer/LoginSlice";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { storeAssigned } from "../../../services/Methods/normalMethods";

const useLoginRegisterHook = (props) => {
    const { navigate, cartValues } = props;
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    // ******************************************** LOGIN FUNCTIONALITY ************************************************************************* /
    const { errorMsg, ...loginState } = useSelector((state) => state?.LoginSlice);

    const LoginValidationSchema = Yup.object().shape({
        email: Yup.string()
            .required('this field is required')
            .email('Please enter valid email'),
        password: Yup.string()
            .required('this field is required')
    });

    function _onHandleLoginSubmit(values) {
        dispatch(LoginApi(values, (data) => {
            if (data) {
                if (searchParams.get('redirectTo')) {
                    navigate(searchParams.get('redirectTo'));
                } else {
                    if (cartValues?.length > 0) {
                        try {
                            window.location.assign(`/${storeAssigned()}/cart`)
                        } catch (e) {
                            console.log(e)
                        }
                    } else {
                        navigate('/');
                    }
                }
            }
        }));
    }

    // ******************************************** REGISTER FUNCTIONALITY ************************************************************************* /

    const signupState = useSelector((state) => state?.LoginSlice);

    const SignUpValidationSchema = Yup.object({
        email: Yup.string().email().required("Please enter your email"),
        password: Yup.string().min(6).required("Please enter your password"),
        birthday: Yup.string().required("please select month"),
        day: Yup.string().required("please select day"),
        year: Yup.string().required("please select Year"),
    });

    function _onHandleSigUpSubmit(values) {
        dispatch(SignUpFormApi(values));
    }
    // useEffect(() => {
    //     if (signupState?.data) {
    //         window?.location.reload();
    //         window.scroll(0, 0);
    //     }
    // }, [signupState?.data]);

    return {
        errorMsg,
        LoginValidationSchema,
        SignUpValidationSchema,
        _onHandleLoginSubmit,
        _onHandleSigUpSubmit,
        loginState
    }
}

export default useLoginRegisterHook;