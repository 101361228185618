import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const Error = () => {
    const data = useParams();
    const location = useLocation();
    return (
        <div className='main_error_section'>
            <h1>Ooop&apos;s</h1>
            <span>Somthing went wrong</span>
        </div>
    );
};

export default Error;