import React from 'react';
import MainSingleProduct from './components/MainSingleProduct';
import useSingleProductHook from './components/SingleProduct.hook';
import { useOutletContext, useParams } from 'react-router-dom';

function SingleProduct(props) {
    const { token, setOpenMiniCart, ProductsDetail, isProductLoading } = useOutletContext();
    const { id, name } = useParams();

    const data = useSingleProductHook({ ProductsDetail, setOpenMiniCart, token, id, ...props });

    return (
        <div>
            <MainSingleProduct {...{ id, isProductLoading, name, ...data, ...props }} />
        </div>
    )
}

export default SingleProduct;