import * as Yup from 'yup';


export const InitialValues = { email: "", firstName: "", lastName: "", phone: "", city: "", zipcode: "", address: "", address_2: "", webAlias: "", company: "" };
export const signUpValidationSchema = (activestep, section = "", userCanLogin) => {
    const validationArray = [
        Yup.object().shape({ email: Yup.string().required('Email is required').email('Invalid email') }),
        Yup.object().shape({
            firstName: Yup.string().required('First Name is required'),
            lastName: Yup.string().required('Last Name is required'),
            phone: Yup.string()
                .required('Phone number is required')
                // .test('is-10-digits-or-valid-format', 'Invalid phone number', value => {
                //     return /^\d{10}$/.test(value) || /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
                // }),
                .test('is-valid-format', 'Invalid phone number', (value) => {
                    const ValidateNumberValue = value?.replace(/\s/g, '');
                    return /^(?:\+?[0-9]{1,3}[-‑. ]?)?(?:\([0-9]{3}\)|[0-9]{3})[-‑. ]?[0-9]{3}[-‑. ]?[0-9]{4}$/.test(ValidateNumberValue) ||
                        /^\d{10,14}$/.test(ValidateNumberValue);
                }),
            password: userCanLogin ? Yup.string().notRequired() : Yup.string().required('Password is required.'),
            confirm_password: userCanLogin ? Yup.string().notRequired() : Yup.string().required('Confirm Password is required.').test('confirm_password', 'password and confirm password should be same', function (val) {
                const password = this.resolve(Yup.ref('password'));
                if (val !== password) return false;
                return true;
            }),
            city: Yup.string().required('City is required'),
            zipcode: Yup.string().required('Zip code is required'),
            address: Yup.string().required('address is required'),
            address_2: Yup.string().notRequired(),
            webAlias: (section === "enrollment") ? Yup.string().required('webAlias is required') : Yup.string().notRequired()
        })
    ];

    return validationArray[activestep];
};

export const SHIPPING_VALIDATION_OBJECT = {
    shipping_urname: Yup.string().required('Name is required.'),
    shipping_lastname: Yup.string().required('Last name is required.'),
    shipping_street: Yup.string().required('Street address is required.'),
    shipping_street2: Yup.string().notRequired(),
    shipping_city: Yup.string().required('City is required.'),
    shipping_zipcode: Yup.string().required('Zipcode is required.'),
    // shipping_country: Yup.string().required('country is required'),
    // shipping_state: Yup.string().required('state is required'),
    shipping_phone: Yup.string().notRequired(),
    // .test('is-10-digits-or-valid-format', 'Invalid phone number', value => {
    //     return /^\d{10}$/.test(value) || /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
    // }),
    // .test('is-valid-format', 'Invalid phone number', (value) => {
    //     const ValidateNumberValue = value?.replace(/\s/g, '');
    //     return /^(?:\+?[0-9]{1,3}[-‑. ]?)?(?:\([0-9]{3}\)|[0-9]{3})[-‑. ]?[0-9]{3}[-‑. ]?[0-9]{4}$/.test(ValidateNumberValue) ||
    //         /^\d{10,14}$/.test(ValidateNumberValue);
    // }),
    termscondition: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),

};

export const BILLING_VALIDATION_OBJECT = {
    billing_urname: Yup.string().required('Name is required.'),
    billing_lastname: Yup.string().required('Last name is required.'),
    billing_email: Yup.string().required('E-mail is required').email('Please enter valid email'),
    billing_street: Yup.string().required('Street address is required.'),
    billing_street2: Yup.string().notRequired(),
    billing_city: Yup.string().required('City is required.'),
    billing_zipcode: Yup.string().required('Zipcode is required.'),
    termscondition: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
    // billing_state:Yup.string().required('state is required'),

}

export const CARD_VALIDATION_OBJECT = {
    card_name: Yup.string().required('Card number is required.').matches(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
        "Please Enter Valid card Number"),
    card_year: Yup.string().required('Card year is required.'),
    card_cvv: Yup.string().required('Card cvv is required.'),
}