import React from "react";
import WellnessLandingStyleCss from "./style/WellnessLanding.style";
import { storeAssigned } from "../../services/Methods/normalMethods";
import _ from 'lodash';

const WellnessLanding = () => {

    const Unique_SECTION = [
        { image: require('./assets/madeinusa.png'), para: "Our premium quality products are manufactured in Utah, USA." },
        { image: require('./assets/AwardWinning.png'), para: "Most innovated beauty product featured in Elle Magazine." },
        { image: require('./assets/quality.png'), para: "Quality Management System" },
        { image: require('./assets/settings.png'), para: "Superlative Quality & Compliance" },
    ];
    return (
        <WellnessLandingStyleCss>
            {/* <h1>Landing Page</h1> */}
            <div className="Main-wellness-landing">
                <div className="allProduct_banner-section">
                    <figure>
                        <img src={require("./assets/landingpageHero_new.jpg")} alt="allproduct page" />
                    </figure>
                </div>
                <div className="wellness-content-section container">
                    <div className="wellness-capsule-section">
                        <h1 className="text-center">Evolution of the Abeytu´ Naturals <br></br>Wellness Essentials Capsules</h1>

                    </div>
                    <div className='wellness_container'>
                        <div className='wellness_product_content_section'>
                            <div className='image_container'>
                                <img src={require('./assets/product_image_landing.png')} />
                                <a className="link_button" href={`/${storeAssigned()}/shop/products/1112/wellness_essentials`}>Shop Now</a>

                            </div>
                            <div className='text_container'>
                                <div className="wellness-product-journet">
                                    <div>
                                        <p className="year_sec">2019</p>
                                    </div>
                                    <div className="product-journey-content">
                                        <p>In 2019, we set our sights on helping relieve discomfort while also assisting with unwanted fat.
                                            The first-generation capsules were formulated with CBD Isolate as the active ingredient.</p>
                                    </div>
                                </div>
                                <div className="wellness-product-journet">
                                    <div>
                                        <p className="year_sec">2020</p>
                                    </div>
                                    <div>
                                        <p>In 2020, we shifted our focus to supporting the fight-or-flight response and reducing stress, while addressing inflammation.
                                            Our second-generation capsules not only relieved discomfort but also modulated the immune system.</p>
                                    </div>
                                </div>
                                <div className="wellness-product-journet">
                                    <div>
                                        <p className="year_sec">2024</p>
                                    </div>
                                    <div>
                                        <p>You mean we don’t have to take Ibuprofen for relief anymore???!
                                            <div className="inner">The <b>3rd Generation Wellness Essentials Capsules are here!</b> This powerhouse formulation introduces E.purpura alkamides as the delivery driver, targeting essential metabolic pathways. Alkamides from E.purpura interact with Cannabinoid receptors, COX inflammatory pathways, and more, working alongside ingredients like Organic Black Sesame, Organic Wild Blueberry, Organic Ashwaganda, and Shilajit. Together, they provide bioavailable cellular nutrition, relieving discomfort and enhancing mood.</div></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="community-section">
                    <h1>WHY CHOOSE ABEYTU´ NATURALS?</h1>
                    <h3>We spent many months perfecting this formula to offer:</h3>
                    <div className="why-choose-content-section container">
                        <div className="left content">
                            <div className="why-choose-points">
                                <ul>
                                    <li>Relief from discomfort</li>
                                    <li>Hormone support</li><li>Metabolic balance</li><li>Emotional equilibrium</li>
                                </ul>
                                <p>Our capsules are designed for use before, during, and after detoxification, and are a natural alternative to vitamins, weight loss agents, anti-depressants, and pain relievers.
                                    The long-chain fatty acids in Organic Black Sesame ensure bioavailability, making this a truly effective daily supplement.</p>
                            </div>
                        </div>
                        <div className="right-content-section">
                            <img src={require("./assets/big-Image-2.jpg")} alt="allproduct page" />
                        </div>

                    </div>

                    <div className="unique-logo container">
                        <div className="unique-logo-inner">
                            {_.map(Unique_SECTION, ({ image, para }, index) => (
                                <div className="logo-unique" key={"unique" + index}>
                                    <div className="logo1">
                                        <img src={image} />
                                    </div>
                                    <p>{para}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="key-ingredentes-section">
                        <div className="container">
                            <h3>Key Ingredients</h3>
                            <div className="key_inner">
                                <div className="key-ingredentes-content-section">
                                    <div className="left-image-section">
                                        <img src={require("./assets/blacksesame.png")} />
                                    </div>
                                    <div className="right-content-section">
                                        <h4>Black Sesame Seed Powder</h4>

                                        <p>Rich in trace minerals like iron, copper, and manganese—essential for metabolism, cell function, immune support, and oxygen circulation.</p>

                                    </div>
                                </div>

                                <div className="key-ingredentes-content-section">
                                    <div className="left-image-section">
                                        <img src={require("./assets/blackberry.png")} />
                                    </div>
                                    <div className="right-content-section">
                                        <h4>Organic Wild Blueberry Extract </h4>
                                        <p>Supports heart and brain health, regulates blood sugar, fights inflammation, enhances gut and skin health, and aids in weight management.</p>

                                    </div>
                                </div>

                                <div className="key-ingredentes-content-section">
                                    <div className="left-image-section">
                                        <img src={require("./assets/aswaganda.png")} />
                                    </div>
                                    <div className="right-content-section">
                                        <h4>Organic Ashwaganda </h4>
                                        <p>Relieves stress and anxiety, lowers blood sugar, boosts muscle strength, enhances fertility, and supports heart health.</p>

                                    </div>
                                </div>


                                <div className="key-ingredentes-content-section">
                                    <div className="left-image-section">
                                        <img src={require("./assets/Shilajit.png")} />
                                    </div>
                                    <div className="right-content-section">
                                        <h4>Shilajit</h4>
                                        <p>Known for its potential to prevent Alzheimer’s, raise testosterone, treat chronic fatigue, and support heart health.</p>

                                    </div>
                                </div>
                            </div>
                            {/* <button class="try-wellness-button btn">Learn More About Our Ingredients</button> */}
                            <a className="try-wellness-button btn" href={`/${storeAssigned()}/about_us#ourStory`}>Learn More About Abeytu´ Naturals</a>
                        </div>
                    </div>

                    <div className="statement-content-section" style={{
                        backgroundImage: `url(${require('./assets/large-leafbackground.jpg')})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '100% 100%',
                    }}>
                        <div className="container">
                            <p>“New wellness. Both of my shoulders were hurting very bad. Could hardly sleep on them or use them much. After 3 days on the wellness capsules (3 capsules 3x a day for two days), I was able to sleep on my sides again. After saturating my body, I was off and running!”</p>
                            <h6 className="text-center">— KW, 10/2024</h6>
                        </div>
                    </div>
                    <div className="experience-section"
                        style={{
                            backgroundImage: `url(${require('./assets/productwithnew.jpg')})`,
                            backgroundSize: 'cover',
                            backgroundPosition: '100% 100%',
                        }}>
                        <div className="experience-content-section container">
                            <div className="experience-inner">
                                <h3>Experience the Next Evolution in Wellness</h3>
                                <p>We hope you love this all-in-one daily formulation as much as we do. Developed by our award-winning Chief Scientific Officer, the 3rd Generation Wellness Essentials Capsules retain and expand upon the original intentions of our first and second-generation formulas—providing even greater relief and wellness.</p>
                                {/* <button className="try-wellness-button">Try Wellness Essentials</button> */}
                                <a className="try-wellness-button" href={`/${storeAssigned()}/shop/products/1112/wellness_essentials`}>Try Wellness Essentials</a>
                            </div>
                        </div>

                    </div>
                    <div className="footer_sec">
                        <div className="container">
                            <div className="inner_wrapper">
                                <div className="img_sec">
                                    <img src='/images/headerLogo.png' alt="headerLogo" />

                                </div>

                                <div className="discription_sec">
                                    <p>For questions and additional information, contact us at info@abeytunaturals.com.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WellnessLandingStyleCss>
    )
}

export default WellnessLanding;