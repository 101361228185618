import { Box, FormControl, FormControlLabel, Radio } from '@mui/material';
import React from 'react';

function ShippingSection(props) {

    const { section, shippingMethods, selectedCheck, _handleShippingChange } = props;
    return (
        <div className='shipping_option_div'>
            <h3 style={{ textTransform: "capitalize" }}>{section} Shipping Method</h3>
            <div className='shipping_methodOptions'>
                {(shippingMethods?.length > 0) ? (
                    shippingMethods?.map((data, index) => {
                        return (
                            <Box className="shiping_methods" key={data?.shipMethodType}>
                                <FormControl className="radio_option">
                                    <FormControlLabel
                                        value="end"
                                        control={
                                            <Radio
                                                defaultValue={data?.amount}
                                                checked={selectedCheck?.shipMethodType === data?.shipMethodType}
                                                onChange={() => _handleShippingChange(data, section)}
                                                name="radio-button-demo"
                                                inputProps={{ "aria-label": "A" }}
                                            />
                                        }
                                        label={
                                            <p style={{ marginBottom: 0 }}>{`${data?.shipMethodDescription}: $${data?.amount}`}</p>
                                        }
                                    />
                                </FormControl>
                            </Box>
                        );
                    })
                ) : (
                    <span>No shipping method on this address.</span>
                )}
            </div>
        </div>
    )
}

export default ShippingSection