import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
const AccordianComponentStyle = styled.div`
    
    /* .accordian-section { */
        .card {
            border-top: 1px solid #707070;
            border-bottom:none;
            border-left: none;
            background: transparent;
            border-right: none;
            border-radius: 0;

            .card-header {
                background: none;
                padding: 0;
                border-bottom: none;
            }    
        }
        button {
            color: #707070;
            font-size: 24px;
            font-family: 'Helvetica Neue LT Std';
            font-weight: 500;
            padding: 20px 0;
            width:100%;
            text-align: left;
            position: relative;

            &:hover {
                color: #2a533f;
                text-decoration: none;
            }
            &.btn.btn-link.collapsed::after {
                font-family: '';
                right: 0;
                position: absolute;
                content: "";
                background: url("/images/plus.png");
                background-size: 100%;
                width: 30px;
                height: 30px;
                background-repeat: no-repeat;
                background-position: center;
                top:29px;
            }
            &.btn.btn-link::after {
                content: "";
                right: 0;
                position: absolute;
                background: url("/images/minus.png");
                background-size: 100%;
                width: 40px;
                height: 40px;
                background-repeat: no-repeat;
                background-position: center
            }
        }
        h5 {
            margin: 0;
            padding: 0;
        }
        .card-body {
            padding: 0 0 20px;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            font-family: 'Helvetica Neue LT Std';
            img,svg {
                width: 100%;
            }
        }
    /* } */

 button.btn.btn-link:focus {
    box-shadow: none;
    outline: none;
    text-decoration: none;
}
 button.btn.btn-link:focus {
    outline: none;
    box-shadow: none;
}
 .card:last-child {
    border-bottom: 1px solid #707070;
}
/* .accordian-section {
    margin: 45px 0 0;
} */

`;

const AccordianComponent = ({ title, display, content, active }) => {
    console.log("display", display)
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let check = active ? true : false;
        setIsActive(check);
    }, [active])

    return (
        <AccordianComponentStyle>
            <div className="card">
                {display &&
                    <div className="card-header" id="headingOne">
                        <h5>
                            <button style={{ fontSize: "20px" }} className={`btn btn-link ${isActive ? '' : 'collapsed'}`} onClick={() => setIsActive(!isActive)}> {title}</button>
                        </h5>
                    </div>
                }
                {isActive &&
                    <div id="collapseOne" className={classNames("collapse", { 'show': isActive })} aria-labelledby="headingOne"
                        data-parent="#accordion">
                        <div className="card-body">
                            {content}
                        </div>
                    </div>
                }
            </div>

        </AccordianComponentStyle>
    )
}

export default AccordianComponent