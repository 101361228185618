import TextFieldComponent from "../../../inputs/TextFieldComponent";
import { storeAssigned } from "../../../services/Methods/normalMethods";

const RetailUser = (props) => {
    const { fieldValue, emailExistError } = props;
    return (
        <div className="container" style={{ marginTop: "100px" }}>
            <div className="email_address_field">
                <TextFieldComponent
                    name="email"
                    label="Email"
                    fullWidth
                    error={!!(fieldValue?.touched?.email && fieldValue?.errors?.email) || emailExistError}
                    onChange={fieldValue?.handleChange}
                    value={fieldValue?.values?.email}
                    errorMessage={fieldValue?.errors?.email}
                />
                {emailExistError ? <p style={{
                    marginLeft: '10px',
                    marginTop: '5px',
                    color: "#f00"
                }}>you are already a user please <a href={'/' + storeAssigned() + '/login'} onClick={() => localStorage.removeItem("Token")}> Login</a></p> : ''}
            </div>

        </div>
    )
}

export default RetailUser;