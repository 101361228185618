import styled from "styled-components";

const LoginStyle = styled.div`
    box-shadow: 0px 4px 40px #0000001A;
    border-radius: 10px;
    flex: 1;
    padding: 25px 40px 40px;
    background: #fff;
    @media(max-width:1199px){
        flex: 0 0 48%;
        max-width:48%
    }
    @media(max-width:991px){
        flex: 0 0 80%;
        max-width:80%;
        margin:0 auto 40px;
        padding: 15px;
    }
    @media(max-width:568px){
        flex: 0 0 94%;
        max-width:94%;
    }
    form.form-class {
    width: 100%;
    .login-section{
         label {
                font-size: 40px;
                color: #2A533F;
                font-family: Playfair Display;
                font-weight: bold;
                margin-bottom: 27px;
                @media(max-width:568px){
                   font-size:24px;
                   margin-bottom:15px;
                  }
         }
                }
   .field-class {
     margin-bottom:20px;
     position: relative;
       input {
                border: 1px solid #C4C4C4;
                border-radius: 5px;
                width: 100%;
                padding-left: 18px;
                /* height:58px;
                padding: 0 20px; */
                &:focus {
				~ {
					label {
						display: none;
					}
				}
			}
       
    
            ::placeholder {
                color: rgba(85, 85, 85, 0.4);
                font-size: 18px;
                font-family: "Helvetica Neue LT Std";
                font-weight: 300;
            }
            }
        label{
                color: #55555566;
                font-size: 18px;
                font-family: 'Helvetica Neue LT Std';
                font-weight: 300;
                position: absolute;
                left:7px;
                top: 0;
                pointer-events: none;
            span.red-star {
                color: #D50B0B;
                font-size: 18px;
                }
        }

               }
               button.button-container {
                    background: #2A533F 0% 0% no-repeat padding-box;
                    border-radius: 28px;
                    max-width: 206px;
                    width: 100%;
                    height: 57px;
                    border: none;
                    color: #fff;
                    font-weight: bold;
                    font-size: 24px;
                    margin: 25px 0px 30px;
                    justify-content: center;
                    align-items: center;
                    @media(max-width:568px){
                    max-width: 110px;
                    height: 40px;
                    font-size: 16px;
                    margin: 25px 0px 30px;
                                    }
                    &:hover {
                        color:#2A533F;
                        background: transparent;
                        border: 2px solid #2A533F;
			    }
                      }
                 .check-forgot {
                    justify-content: space-between;
                    padding: 20px 0 0;
                    .forgot-pass{
                         a {
                                color: #2A533F;
                                font-size: 18px;
                                font-family: 'Helvetica Neue LT Std'; 
                             }
                        }
                       
                        input[type="checkbox"] {
                            width: 18px;
                            height: 18px;
                            border: 1px solid #555555;
                            border-radius: 5px;
                            vertical-align: top;
                            position: relative;
                            top: 4px;
                            margin-right: 5px;
                        }
                        .checkbox {
                                color: #555555;
                                font-size: 18px;
                                font-family: 'Helvetica Neue LT Std';
                                font-weight: 300;
                                position: relative;
                            }

                   }
               
        }
`;

export default LoginStyle;