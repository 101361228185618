import React, { useState } from 'react'
import { ThankyoyStyle } from './components/Thankyoy.Style'
import { useOutletContext, useSearchParams } from 'react-router-dom';
import useThankYouHook from './components/Thankyou.hook';
import _ from 'lodash';
import { FloatValues } from '../../services/Methods/normalMethods';

const Thankyou = (props) => {

    const [searchParams] = useSearchParams();

    const { data, autoshiporder } = useThankYouHook({
        orderId: searchParams.get('orderId'),
        autoshipId: searchParams.get('autoshipId'), ...props
    });

    return (
        <ThankyoyStyle>

            <div className='thanks-section'>
                <div className='container'>
                    <h2>Thank you for your order</h2>
                    {_.map(data, (row) => (
                        <div className="thanks-order" key={row?.orderId + "_normal"}>
                            <div className='order-number'>
                                <h3>Order Number:</h3>
                                <div className='tanks-price'>{row?.orderId}</div>
                            </div>
                            <div className='order-number'>
                                <h3>Date:</h3>
                                <div className='tanks-price'>{row?.orderDate?.split('T')[0]}</div>
                            </div>
                            <div className='order-number'>
                                <h3>Email:</h3>
                                <div className='tanks-price'>{row?.emailAddress}</div>
                            </div>
                            <div className='order-number'>
                                <h3>Total:</h3>
                                <div className='tanks-price'>${FloatValues(row?.orderTotal)}</div>
                            </div>
                            <div className='order-number'>
                                <h3>Payment Method:</h3>
                                <div className='tanks-price'>Credit Card</div>
                            </div>
                        </div>
                    ))}
                    <div className='table-data'>
                        {_.map(data, (row) => (

                            <React.Fragment key={row?.orderId + '_order'}>
                                <h3><strong style={{ fontSize: "40px" }}>Order Details</strong></h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>PRODUCT</th>
                                            <th>QTY.</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {row?.details?.map((data, index) => {
                                            return (
                                                <tr key={index + 1}>
                                                    <td>{data?.itemDescr}</td>
                                                    <td>{data?.quantity}</td>
                                                    <td>${FloatValues(data?.itemPriceTotal)}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr className='empty-td'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className='empty-td'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr className='subtotal-td'>
                                            <td><strong>Subtotal</strong></td>
                                            <td></td>
                                            <td>${FloatValues(row?.subTotal)}</td>

                                        </tr>
                                        <tr className='subtotal-td'>
                                            <td>Tax</td>
                                            <td></td>
                                            <td>${FloatValues(row?.taxTotal)}</td>


                                        </tr>
                                        <tr className='subtotal-td'>
                                            <td>Shipping</td>
                                            <td></td>
                                            <td>${FloatValues(row?.shippingTotal)}</td>

                                        </tr>
                                        {row?.payments?.[1]?.amount && <tr className='subtotal-td'>
                                            <td>Points Redeemed</td>
                                            <td></td>
                                            <td>${FloatValues(row?.payments?.[1]?.amount)}</td>
                                        </tr>}
                                        <tr className='subtotal-td'>
                                            <td>Total</td>
                                            <td></td>
                                            <td>${row?.payments?.[0]?.amount ? FloatValues(row?.payments?.[0]?.amount) : row?.orderTotal}</td>
                                        </tr>
                                        <tr className='subtotal-td'>
                                            <td>Payment method</td>
                                            <td></td>
                                            <td>Credit Card</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </React.Fragment>
                        ))}
                        <br></br><br></br>

                        {_.map(autoshiporder, (row) => (
                            <div className='auto-order' key={row?.orderId + 'autoship'}>
                                <>
                                    <h3><strong style={{ fontSize: "40px" }}>Autoship Order Details</strong></h3>
                                    <div className="thanks-order">
                                        <div className='order-number'>
                                            <h3>Order Number:</h3>
                                            <div className='tanks-price'>{row?.recurringOrderId}</div>
                                        </div>
                                        <div className='order-number'>
                                            <h3>Date:</h3>
                                            <div className='tanks-price'>{row?.startDate?.split('T')[0]}</div>
                                        </div>

                                        <div className='order-number'>
                                            <h3>Total:</h3>
                                            <div className='tanks-price'>${FloatValues(row?.total)}</div>
                                        </div>
                                        <div className='order-number'>
                                            <h3>Payment Method:</h3>
                                            <div className='tanks-price'>Credit Card</div>
                                        </div>
                                    </div>
                                </>

                                <div className='table-data'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>PRODUCT</th>
                                                <th>QTY.</th>
                                                <th>TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {row?.details?.map((data, index) => {
                                                return (
                                                    <tr key={index + 1}>
                                                        <td>{data?.title}</td>
                                                        <td>{data?.quantity}</td>
                                                        <td>${FloatValues(data?.priceEach)}</td>
                                                    </tr>
                                                )
                                            })}

                                            <tr className='empty-td'>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr className='empty-td'>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>

                                            <tr className='subtotal-td'>
                                                <td><strong>Subtotal</strong></td>
                                                <td></td>
                                                <td>${FloatValues(row?.subTotal)}</td>

                                            </tr>
                                            <tr className='subtotal-td'>
                                                <td>Tax</td>
                                                <td></td>
                                                <td>${FloatValues(row?.totalTax)}</td>


                                            </tr>
                                            <tr className='subtotal-td'>
                                                <td>Shipping</td>
                                                <td></td>
                                                <td>${FloatValues(row?.total - row?.subTotal - row?.totalTax)}</td>

                                            </tr>
                                            <tr className='subtotal-td'>
                                                <td>Total</td>
                                                <td></td>
                                                <td>${FloatValues(row?.total)}</td>

                                            </tr>
                                            <tr className='subtotal-td'>
                                                <td>Payment method</td>
                                                <td></td>
                                                <td>Credit Card</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        ))}
                        {_.map(data?.length > 0 ? data : autoshiporder, (row) => (

                            <div className='shipping-billing' key={row?.orderId}>
                                {/* <div className='address'>
                                    <h3>Billing Address</h3>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='shipping1'>
                                                <div>{row?.firstName} {row?.lastName}</div>
                                                <div>{row?.shipAddress1}</div>
                                                <div>{row?.shipAddress2 ? row?.shipAddress2 : ""}</div>
                                                <div> {row?.shipCity}, {row?.shipRegionProvState} {row?.shipPostalCode}</div>
                                                <div>{row?.emailAddress}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='address-shipping'>
                                    <h3 className='checkout_heading_address'>Shipping Address</h3>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='shipping1'>
                                                <div>{row?.firstName} {row?.lastName}</div>
                                                <div>{row?.shipAddress1}</div>
                                                <div>{row?.shipAddress2 ? row?.shipAddress2 : ""}</div>
                                                <div> {row?.shipCity}, {row?.shipRegionProvState} {row?.shipPostalCode}</div>
                                                <div>{row?.emailAddress}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </ div>
        </ThankyoyStyle >
    )
}

export default Thankyou