import { DialogActions } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import ButtonComponent from '../../inputs/ButtonCompnent';
import DialogComponent from '../../inputs/DailogComponent';
import { getAllCustomerData } from '../Redux/Reducer/LoginSlice';
import { getAutoShipOrders } from '../Redux/Reducer/OrdersSlice';
import localStorageCall from './localstorage.hook';
import { REFFERAL_VALUES } from './normalMethods';

function DATES_VALUES(data, e = false, s = false, d = false) {
    console.log("data?.billingAddress", data?.billingAddress)

    return ({
        entryDate: e ? data?.entryDate : moment.utc().format(),
        signUpDate: s ? data?.signUpDate : moment.utc().format(),
        distributorDate: d ? data?.distributorDate : moment.utc().format()
    });
}

export const _signUpPayload = (data, customerType = 1, section, commingFrom) => {
    let DATES = {};
    let CUSTOMER_TYPE = localStorage?.getItem('customerTypeDescr') ? JSON.parse(localStorage?.getItem('customerTypeDescr')) : null;
    if (commingFrom === "updated") {
        if (CUSTOMER_TYPE?.type === 1 && customerType === 2) {
            DATES = DATES_VALUES(data, true, false, true);
        } else if ((CUSTOMER_TYPE?.type === 1 && customerType === 4) || (CUSTOMER_TYPE?.type === 1 && customerType === 3)) {
            DATES = DATES_VALUES(data, true);
        } else if ((CUSTOMER_TYPE?.type === 2 && customerType === 4) || (CUSTOMER_TYPE?.type === 2 && customerType === 3)) {
            DATES = DATES_VALUES(data, true, true);
        } else if ((CUSTOMER_TYPE?.type === 2 && customerType === 2) || (CUSTOMER_TYPE?.type === 1 && customerType === 1) || (CUSTOMER_TYPE?.type === 4 && customerType === 4)) {
            DATES = DATES_VALUES(data, true, true, true);
        } else {
            DATES = DATES_VALUES(data, true, true, true);
        }
    } else {
        if (customerType === 1) {
            DATES = DATES_VALUES(data, false, false, true);
        } else if (customerType === 3) {
            DATES = DATES_VALUES(data);
        } else {
            DATES = DATES_VALUES(data, true);
        }
    }

    return ({
        ...DATES,
        "customerType": customerType,
        "customerSubType": 1,
        "customerStatusType": 1,
        "customerSubStatusType": (customerType === 3) ? 2 : 1,
        "firstName": data?.firstName ? data?.firstName : "Test",
        "lastName": data?.lastName ? data?.lastName : "CommandConcepts",
        "middleName": "",
        "companyName": data?.company ? data?.company : "",
        "fullName": (data?.firstName && data?.lastName) ? (data?.firstName + ' ' + data?.lastName) : "",
        "suffix": "",
        "payToName": "",
        "genderType": 1,
        "userName": data?.email,
        "userPassword": data?.password,
        "userCanLogIn": (section === "leadUser") ? false : true,
        "SmsChecked": true,
        "currencyCode": "usd",
        "countryCode": data?.country ? data?.country : "US",
        "membershipRenewalDate": null,
        "birthDate": null,
        "phoneNumbers": {
            "cellPhone": data?.phone,
            "homePhone": data?.phone,
            "workPhone": data?.phone,
            "otherPhone1": "",
            "otherPhone2": ""
        },
        "emailAddress": data?.email,
        "emailSubscribed": false,
        "emailSubscribedIp": "",
        "emailSubscribedDate": String(moment.utc().format()),
        "emailUnsubscribedDate": String(moment.utc().format()),
        "email2Address": "",
        "email2Subscribed": false,
        "email2SubscribedIp": "",
        "email2SubscribedDate": String(moment.utc().format()),
        "email2UnsubscribedDate": String(moment.utc().format()),

        "mailingAddress": data?.mailingAddress || {
            "address1": data?.address ? data?.address : "",
            "address2": data?.address_2 ? data?.address_2 : "",
            "address3": "",
            "city": data?.city ? data?.city : "",
            "regionProvState": data?.state ? data?.state : "",
            "postalCode": data?.zipcode ? data?.zipcode : "",
            "countryCode": data?.country ? data?.country : ""
        },
        "shippingAddress": data?.shippingAddress || {
            "address1": data?.address ? data?.address : "",
            "address2": data?.address_2 ? data?.address_2 : "",
            "address3": "",
            "city": data?.city ? data?.city : "",
            "regionProvState": data?.state ? data?.state : "",
            "postalCode": data?.zipcode ? data?.zipcode : "",
            "countryCode": data?.country ? data?.country : ""
        },
        "billingAddress": data?.billingAddress || {
            "address1": data?.address ? data?.address : "",
            "address2": data?.address_2 ? data?.address_2 : "",
            "address3": "",
            "city": data?.city ? data?.city : "",
            "regionProvState": data?.state ? data?.state : "",
            "postalCode": data?.zipcode ? data?.zipcode : "",
            "countryCode": data?.country ? data?.country : ""
        },
        "otherAddress1": data?.otherAddress1 || {
            "address1": "",
            "address2": "",
            "address3": "",
            "city": "",
            "regionProvState": "",
            "postalCode": "",
            "countryCode": ""
        },
        "otherAddress2": data?.otherAddress2 || {
            "address1": "",
            "address2": "",
            "address3": "",
            "city": "",
            "regionProvState": "",
            "postalCode": "",
            "countryCode": ""
        },
        "profileLink": "",
        "enrollerId": REFFERAL_VALUES?.id ? REFFERAL_VALUES?.id : data?.refferal || 2,
        "enrollerBusinessCenterId": 0,
        "webAlias": data?.webAlias || '',
        "publicProfile": {
            "firstName": data?.firstName ? data?.firstName : "Test",
            "lastName": data?.lastName ? data?.lastName : "CommandConcepts",
            "fullName": (data?.firstName && data?.lastName) ? (data?.firstName + ' ' + data?.lastName) : "CommandConcepts",
            "phone": `${data?.phone}`,
            "email": data?.email,
            "city": data?.city ? data?.city : "",
            "regionProvState": data?.state ? data?.state : "",
            "countryCode": data?.country ? data?.country : ""
        }
    })
};

export const AddressSuggestionDialog = ({
    errorAddressData, useDefaultAddressOrNot, userData, section,
    setUseDefaultAddressOrNot, closeFunction, normalAddressError, setOpenDialog,
}) => (
    <>
        {console.log("errorAddressData", errorAddressData)}
        <DialogComponent opend={!!errorAddressData && (useDefaultAddressOrNot !== false)} handleClose={() => closeFunction()} title="" classFor="address_data">
            <div style={{ padding: "0px 20px 0px" }}>
                <div className='content-popup'>
                    <p className='select-shipping-popup'><strong>Please select a shipping address</strong></p>
                    <p className='select-shipping-address'>We suggest a modified shipping address</p>
                </div>
                <div className='suggested-addresses'>
                    <p className='suggested-address1'><strong>Suggested Address</strong></p>
                </div>
                <div className='content-address'>

                    <p className='address1-format'>{errorAddressData?.address_1}</p>
                    <p className='address2-format'>{`${errorAddressData?.city}, ${errorAddressData?.state_region} ${errorAddressData?.postal_code}`}</p>
                    <p className='address3-format'>{errorAddressData?.country}</p>
                </div>

                <div className='suggested-addresses'>
                    <p className='suggested-address1'><strong>Original Address</strong></p>
                </div>
                <div className='content-address'>
                    <p className='address1-format'>{userData?.shipping_street || userData?.billing_street}</p>
                    <p className='address2-format'>{`${userData?.shipping_city || userData?.billing_city}, ${userData?.shipping_state || userData?.billing_state} ${userData?.shipping_zipcode || userData?.billing_zipcode}`}</p>
                    <p className='address3-format'>{userData?.shipping_country || userData?.billing_country}</p>
                </div>
            </div>
            <DialogActions className='button_action'>

                <ButtonComponent classes={"use_address"} onClick={() => {
                    setUseDefaultAddressOrNot(errorAddressData)
                    closeFunction()
                }}>Use Suggested</ButtonComponent>
                <ButtonComponent classes={"use_oringal"} onClick={() => {
                    closeFunction()
                    setUseDefaultAddressOrNot(false);
                }}>Use Original</ButtonComponent>
            </DialogActions>
        </DialogComponent>

        <DialogComponent opend={normalAddressError} handleClose={() => closeFunction()} title="" classFor="address_data">
            <div style={{ padding: "0px 4px 0px" }}>
                <p>There is an issue with the address you have entered. Please update the shipping address or,</p>
            </div>
            <DialogActions className='button_action' style={{ justifyContent: 'center' }}>
                <ButtonComponent onClick={() => {
                    closeFunction()
                    setUseDefaultAddressOrNot(false);
                    // if (section === "maincheckout" || section === "autoshipEdit") {
                    //     setOpenDialog(null);
                    // }
                }}>Use address as entered
                </ButtonComponent>
            </DialogActions>
        </DialogComponent>
    </>

);

export const CATEGROY_LIST = [
    { id: 1006, name: 'Tinctures', hashname: "tinctures" },
    { id: 1008, name: 'Capsules', hashname: "capsules" },
    { id: 1005, name: 'Detox', hashname: "detox" },
    { id: 1000, name: 'All Products', hashname: "products" },
    // { id: 1007, name: 'Creams', hashname: "creams" },
    // { id: 1010, name: 'Gummies', hashname: "gummies" }
];

export function _storeCustomerTypes(dispatch, token, searchParams, callback = null) {
    try {
        dispatch(getAllCustomerData((data) => {
            if (data?.customerType === 3) {
                localStorage.setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, customerType: data?.customerTypeDescr, priceType: 3 }));
            } else {
                if (!searchParams?.get('autoshipId')) {
                    dispatch(getAutoShipOrders(null, token, (values) => {
                        if (values?.data?.length > 0) {
                            localStorage.setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, customerType: data?.customerTypeDescr, priceType: 2 }));
                        } else {
                            localStorage.setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, customerType: data?.customerTypeDescr, priceType: 1 }));
                        }
                        if (callback) {
                            callback();
                        }
                    }, 1));
                }
            }
            if (data?.customerType === 6) {
                localStorage.setItem('customerTypeDescr', JSON.stringify({ type: data?.customerType, customerType: data?.customerTypeDescr, priceType: 1 }));
            }
        }, token));
    } catch (e) {
        console.log(e);
    }
}

export const USERDATA_PAYLOAD = {
    billing_urname: "", billing_lastname: "", billing_email: "", billing_phone: "", billing_street: "", billing_street2: "",
    billing_city: "", billing_zipcode: "", billing_country: "", billing_state: "",
    shipping_urname: "", shipping_lastname: "", shipping_email: "", shipping_phone: "", shipping_street: "", shipping_street2: "",
    shipping_city: "", shipping_zipcode: "", shipping_country: "", shipping_state: "",
};

export function _sameAsShippingCall(resetForm, countryState, setCountryState, userData, setUserData, values, handleChangeFunction, e) {
    let ADDRESS_STORE = ['urname', 'lastname', 'email', 'phone', 'street', 'street2', 'city', 'zipcode', 'country', 'state'];
    let SHIPPING_ADDRESS = {};
    _.map(ADDRESS_STORE, (row) => {
        _.assign(SHIPPING_ADDRESS, { [`shipping_${row}`]: values[`billing_${row}`] })
    });
    if (e.target.checked) {
        setUserData({ ...values, ...SHIPPING_ADDRESS });
        setCountryState({ ...countryState, shipping_country: countryState?.billing_country, shipping_state: countryState?.billing_state });
        resetForm({ ...values, SHIPPING_ADDRESS });
        handleChangeFunction(null, { ...values, ...SHIPPING_ADDRESS, shipping_country: countryState?.billing_country, shipping_state: countryState?.billing_state });
    }
}


export function onErrorFunction(res) {
    console.log(res, "gauravnnnnnnnnnnnnnnnnnnn")
}

function loopFunction(paymentFormHtml, callBackFunction) {
    let tokenId = localStorage.getItem('order_token') ? localStorage.getItem('order_token') : "";
    let errorPayment = localStorage.getItem('error-payment') ? localStorage.getItem('error-payment') : "";
    if (tokenId) {
        if (errorPayment) {
            localStorageCall().removeItem('error-payment');
        }
        callBackFunction(tokenId);
        localStorageCall().removeItem('order_token');
    } else if (errorPayment) {
        localStorageCall().removeItem('error-payment');
        // _scriptFunctionCall(paymentFormHtml, callBackFunction)
    } else {
        setTimeout(() => {
            loopFunction(paymentFormHtml, callBackFunction);
            console.log('tell')
        }, 2000)
    }
}

export function _scriptFunctionCall(paymentFormHtml, callBackFunction) {
    let i = 0;
    if (i < 1) {
        localStorageCall().removeItem('order_token');
        i++
        window.submitHandle();
        console.log('jellll')
        loopFunction(paymentFormHtml, callBackFunction);
    }


} 
