import { useDispatch, useSelector } from "react-redux";
import { getAllProductApi } from "../../services/Redux/Reducer/AllProductSlice";
import { useState } from "react";
import _ from "lodash";
import { useEffect } from "react";
import { _productPrice } from "../../services/Methods/normalMethods";

const useMiniCartHook = (props) => {
    const { navigate, setOpenMiniCart, cartValues } = props;
    const dispatch = useDispatch();

    const SHIPPING_SECTION = [
        { text: "One Time", value: "oneTime" },
        { text: "Subscription Every 30 Days", value: "30Days" },
        { text: "Subscription Every 60 Days", value: "60Days" },
    ];

    const [miniCartData, setMiniCartData] = useState([]);
    const [totalItemsInMini, setTotalItemInMini] = useState(null);
    const [miniSubtotal, setMiniSubtotal] = useState(null);
    const { isProductLoading, productsDetail } = useSelector(state => {
        return { productsDetail: state?.AllProductSlice?.data?.data || [], isProductLoading: state?.AllProductSlice?.isLoading };
    });

    useEffect(() => {
        if (productsDetail?.length === 0) {
            dispatch(getAllProductApi());
        }
    }, []);

    useEffect(() => {
        if (cartValues?.length > 0) {
            setMiniCartData(cartValues);
            setMiniSubtotal(_.sum(_.map(cartValues, (row) => row?.quantity * _productPrice(row?.prices, (row?.autoship !== "oneTime" ? 'autoship' : row?.autoship), row?.isEligibleForAutoOrder))))
            setTotalItemInMini(_.sum(_.map(cartValues, (row) => row?.quantity)));
        }
    }, [cartValues]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenMiniCart(open);
    };

    function _IncreaseDecreaseFunction(section, id) {
        const FIND_VALUE = _.findIndex(miniCartData, { id: +id });
        if (FIND_VALUE > -1) {
            miniCartData[FIND_VALUE]['quantity'] = (section === "sub") ? (miniCartData[FIND_VALUE]['quantity'] - 1) > 0 ? miniCartData[FIND_VALUE]['quantity'] - 1 : 1 : miniCartData[FIND_VALUE]['quantity'] + 1;
            localStorage.setItem('cartData', JSON.stringify(miniCartData))
            const CartData = localStorage.getItem('cartData') ? localStorage.getItem('cartData') : [];
            setMiniCartData(JSON.parse(CartData));
            setTotalItemInMini(_.sum(_.map(JSON.parse(CartData), (row) => row?.quantity)));
            setMiniSubtotal(_.sum(_.map(JSON.parse(CartData), (row) => row?.quantity * _productPrice(row?.prices, (row?.autoship !== "oneTime" ? 'autoship' : row?.autoship), row?.isEligibleForAutoOrder))))
        }
    }
    function _changeAutoshipFunction(id, value) {
        const FIND_VALUE = _.findIndex(miniCartData, { id: +id });
        if (FIND_VALUE > -1) {
            miniCartData[FIND_VALUE]['autoship'] = value;
            localStorage.setItem('cartData', JSON.stringify(miniCartData))
            const CartData = localStorage.getItem('cartData') ? localStorage.getItem('cartData') : [];
            setMiniCartData(JSON.parse(CartData));
            setTotalItemInMini(_.sum(_.map(JSON.parse(CartData), (row) => row?.quantity)));
            setMiniSubtotal(_.sum(_.map(JSON.parse(CartData), (row) => row?.quantity * _productPrice(row?.prices, (row?.autoship !== "oneTime" ? 'autoship' : row?.autoship), row?.isEligibleForAutoOrder))));
            // setSelectedPrice(_.map(JSON.parse(CartData), ({ id, autoship }) => ({ id, value: autoship })))
        }
    }
    return ({
        miniSubtotal,
        totalItemsInMini,
        miniCartData,
        SHIPPING_SECTION,
        navigate,
        toggleDrawer,
        _IncreaseDecreaseFunction,
        _changeAutoshipFunction,
        productsDetail, isProductLoading
    })
}

export default useMiniCartHook;