import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { _getToken } from "../Methods/normalMethods";

const isBrowser = () => typeof window !== "undefined";

const withAuthRedirect = ({ WrappedComponent, expextedAuth, location }) => {

    const WrappedComponentRedirect = () => {
        const token = _getToken();
        const navigate = useNavigate();
        const params = useParams();

        useEffect(() => {
            // const isLogin = localStorage.getItem("Login") === "true" ? true : false;
            if (isBrowser() && expextedAuth !== !!token?.token) {
                navigate(location);
            }
        }, [token?.token])

        return <WrappedComponent {...{ token: token?.token, params, navigate }} />
    }
    return WrappedComponentRedirect;
}

export default withAuthRedirect;
