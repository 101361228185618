import React from 'react'
import { Box, Stepper, Step, StepLabel, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import RetailInfo from './components/RetailInfo';
import RetailUser from './components/RetailUser';
import { RetailStyle } from './components/Retail.style';
import useRetailSignUpHook from './components/Retail.hook';
import ButtonComponent from '../../inputs/ButtonCompnent';
import { useOutletContext } from 'react-router-dom';
import _ from 'lodash';
import OneTimePassRetail from './components/OneTimePass';
import LoaderComponent from '../../Layout/components/Footer/components/Loader';
import { REFFERAL_VALUES } from '../../services/Methods/normalMethods';
import SummerySection from '../EnrollmentSignup/components/SummerySection';
import withPayment from '../../services/HOC/withPaymentsHOC';

const RetailSignup = (props) => {

    const { token, cartValues, setCartValues, countryCodes, Dates } = useOutletContext();
    const { paymentFormHtml, paymentsOptions, ...otherProps } = props;

    const {
        activeStep, setActiveStep, _handleSubmit, _handleBack, currentValidationSchema, currentInitialValue, steps, isLastStep, loading,
        setCountryState, countryState, emailExistError, _handleCallCalculate, _handleOtpValidate, checkoutObject, validate,
        setOtp, handleChangeValidate, otp, errorMsg, _validateRefferalFunction, webAliesError, setWebAliesErros, ...others
    } = useRetailSignUpHook({ token, cartValues, setCartValues, Dates, paymentFormHtml, ...otherProps });

    const formContent = (step, fieldValue) => {

        const payload = { activeStep, setActiveStep, fieldValue, _handleBack, validate, setOtp, handleChangeValidate, otp, };
        if (process.env.REACT_APP_OTP_ENABLE === "true") {
            switch (step) {
                case 0:
                    return <RetailUser {...{ emailExistError, ...payload }} />;
                case 1:
                    return <RetailInfo {...{
                        countryCodes, _validateRefferalFunction, webAliesError, countryState, setCountryState, setWebAliesErros, ...payload
                    }} />;
                case 2:
                    return <OneTimePassRetail {...{ setActiveStep, otp, setOtp, handleChangeValidate }} />
                case 3:
                    return <div className='checkout_summery'>
                        <SummerySection {...{
                            countryCodes, currentInitialValue, setCartValues, checkoutObject, fieldValue, paymentFormHtml, paymentsOptions
                        }} />
                    </div>
                default:
                    return <div className='not-found'>404: Not Found</div>
            }
        } else {
            switch (step) {
                case 0:
                    return <RetailUser {...{ emailExistError, ...payload }} />;
                case 1:
                    return <RetailInfo {...{ countryCodes, _validateRefferalFunction, webAliesError, countryState, setCountryState, setWebAliesErros, ...payload }} />;
                case 2:
                    return <div className='checkout_summery'>
                        <SummerySection {...{
                            countryCodes, currentInitialValue, setCartValues, checkoutObject, fieldValue,
                            paymentFormHtml, paymentsOptions
                        }} />
                    </div>
                default:
                    return <div className='not-found'>404: Not Found</div>
            }
        }
    };

    return (
        <RetailStyle>
            {loading && <LoaderComponent />}
            <div className='linear-form'>
                <Box sx={{ maxWidth: '600px', padding: 2 }}>
                    <Stepper activeStep={activeStep} alternativeLabel orientation="horizontal">
                        {steps?.map((label, index) => (
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box component="div">
                        <Grid item xs={12} sx={{ padding: '20px' }}>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    ...currentInitialValue,
                                    refferal: REFFERAL_VALUES?.webalies,
                                }}
                                validationSchema={currentValidationSchema}
                                onSubmit={(values, action) => {
                                    _handleSubmit(values, action)
                                }}
                            >
                                {({ isSubmitting, ...fieldValue }) => (
                                    <>
                                        {(activeStep < 2) ?
                                            <Form>
                                                {formContent(activeStep, fieldValue)}
                                                <div className="product-button-back">
                                                    {(activeStep !== 0 && !token?.token) && (
                                                        <ButtonComponent onClick={_handleBack} className="">Back</ButtonComponent>
                                                    )}
                                                    <ButtonComponent type="submit" classes="">Next</ButtonComponent>
                                                </div>
                                                {<p className='error'>{errorMsg}</p>}
                                            </Form>
                                            :
                                            <div className='products_section'>
                                                {formContent(activeStep, {
                                                    ...{
                                                        currentInitialValue, setCartValues, cartValues, setActiveStep, payload: others, fieldValue: fieldValue
                                                    }
                                                })}
                                                <div className="product-button-back">
                                                    {
                                                        (!isLastStep) &&
                                                        (<ButtonComponent classes="" onClick={() => {
                                                            if (isLastStep) {
                                                            } else if (steps[activeStep] === "Validate") {
                                                                _handleOtpValidate();
                                                                _handleCallCalculate();
                                                            } else {
                                                                setActiveStep(activeStep + 1);
                                                                // _handleCallCalculate();
                                                            }
                                                        }}>Next</ButtonComponent>)
                                                    }
                                                </div>
                                                {<p className='' style={{ color: "red" }}>{errorMsg}</p>}
                                            </div>
                                        }

                                    </>
                                )}
                            </Formik>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </RetailStyle>
    )
}

export default withPayment(RetailSignup);