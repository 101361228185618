import AllProductStyleCss from "./style/AllProduct.style";
import MainAllProduct from "./components/MainAllProduct"
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import useAllProductHook from "./components/AllProduct.hook";
import withRouter from "../../services/HOC/withRouterHOC";

const AllProductsPage = (props) => {

    const { token } = useOutletContext();
    const { category } = useParams();

    const data = useAllProductHook({ category, hash: props?.location?.hash, token, ...props });
    return (
        <AllProductStyleCss>
            <MainAllProduct {...{ category, hash: props?.location?.hash, ...data, ...props }} />
        </AllProductStyleCss>
    )
}

export default withRouter(AllProductsPage);