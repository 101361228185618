import ButtonComponent from "../../../inputs/ButtonCompnent";
import EnrollmentFirstStyleCss from "../styles/EnrollmentFirst.style";


const EnrollmentFirstSection = () => {

    return (
        <EnrollmentFirstStyleCss>
            <div className="enrollment_banner_section" style={{ backgroundImage: `url(${require('../assets/enrollmentBanner.png')})` }}>
                <div className="banner-text">
                    <div className="container">
                        <h1>Enrollment Programs</h1>
                    </div>
                </div>
            </div>
            <div className="main_subscriptionProgram" id="#subscription">
                <h2>Subscription Program</h2>
                <div className="subscriptionProgram">
                    <div>
                        <div className="image_section">
                            <img src={require('../assets/HowItWork.png')} />
                        </div>
                        <div className="description_of_subscription">
                            <h3>Save up to 15% with Abetyu´ Subscription program. Easy to setup.</h3>
                            <ul >
                                <li>Earn rewards with your orders.</li>
                                <li> Save 10% and more with your subscriptions.</li>
                                <li>Earn Reward Points on every order.</li>
                                <li>Cancel Anytime. No contracts.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="how_to_use_section">
                        <h3>How it works</h3>
                        <div className="commonEnrollment SubscribeForProduct">
                            <div className="number">
                                <span> 1</span>
                            </div>
                            <div className="content">
                                <h4>Subscribe for a product</h4>
                                <p>Create a subscription by going to the product page and using the subscription option instead of single checkout. Select the duration of subscription – 1 month, 3 months or 1 year. Add to cart and proceed to checkout.</p>
                            </div>
                        </div>
                        <div className="commonEnrollment Nohassle">
                            <div className="number">
                                <span>2</span>
                            </div>
                            <div className="content">
                                <h4>No hassle</h4>
                                <p>Your products will be shipped to you based on your subscription frequency. You can check your subscription status by going to your account.</p>
                            </div>
                        </div>
                        <div className="commonEnrollment EasyManagement">
                            <div className="number">
                                <span>3</span>
                            </div>
                            <div className="content">
                                <h4>Easy management</h4>
                                <p>You will be charged using the method you used at the checkout. You have the option to manually renew with a different method if the previous method fails.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </EnrollmentFirstStyleCss >
    )
}

export default EnrollmentFirstSection;