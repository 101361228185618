import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { _productPrice } from "../../../services/Methods/normalMethods";

const useViewCartHook = (props) => {

    const { cartValues } = props;
    const [viewCartData, setViewCartData] = useState(null);
    const [qtyValues, setViewCartQty] = useState(null);
    const [totalAmount, setTotalAmount] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState({ id: null, value: 'oneTime' });

    useEffect(() => {
        // window.scroll(0, 0);
        // const cartValues = localStorage.getItem('cartData') ? JSON.parse(localStorage.getItem('cartData')) : [];
        const QTYS = _.map(cartValues, ({ id, quantity, prices, autoship, isEligibleForAutoOrder }) => ({ id, quantity, subtotalPriceQTY: quantity * _productPrice(prices, (autoship !== "oneTime" ? 'autoship' : autoship), isEligibleForAutoOrder) }));
        setViewCartQty(QTYS);
        const SUB_TOTAL = _.sum(_.map(cartValues, ({ quantity, prices, autoship, isEligibleForAutoOrder }) => quantity * _productPrice(prices, (autoship !== "oneTime" ? 'autoship' : autoship), isEligibleForAutoOrder)));
        const SELECTED_FREQUANCY = _.map(cartValues, ({ id, autoship }) => ({ id, value: autoship }));
        setSelectedPrice(SELECTED_FREQUANCY);
        setTotalAmount(SUB_TOTAL);
        setViewCartData(cartValues);
    }, [cartValues])


    function _IncreaseDecreaseFunction(section, id) {
        const FIND_VALUE = _.findIndex(viewCartData, { id: +id });
        if (FIND_VALUE > -1) {
            viewCartData[FIND_VALUE]['quantity'] = section === "sub" ? (viewCartData[FIND_VALUE]['quantity'] - 1) > 0 ? viewCartData[FIND_VALUE]['quantity'] - 1 : 1 : viewCartData[FIND_VALUE]['quantity'] + 1;
            // const REJECT_CURRENT = _.reject(JSON.parse(viewCartData), FIND_VALUE);
            // FIND_VALUE['quantity'] = section === "sub" ? (FIND_VALUE['quantity'] - 1) > 0 ? FIND_VALUE['quantity'] - 1 : 1 : FIND_VALUE['quantity'] + 1;
            localStorage.setItem('cartData', JSON.stringify(viewCartData))
            // setCartValues([...REJECT_CURRENT, FIND_VALUE])
            const CartData = localStorage.getItem('cartData') ? localStorage.getItem('cartData') : [];
            const QTYS = _.map(JSON.parse(CartData), ({ id, quantity, prices, autoship, isEligibleForAutoOrder }) => ({ id, quantity, subtotalPriceQTY: quantity * _productPrice(prices, (autoship !== "oneTime" ? 'autoship' : autoship), isEligibleForAutoOrder) }));
            setViewCartQty(QTYS);
            setViewCartData(JSON.parse(CartData));
            setTotalAmount(_.sum(_.map(JSON.parse(CartData), (row) => row?.quantity * _productPrice(row?.prices, (row?.autoship !== "oneTime" ? 'autoship' : row?.autoship), row?.isEligibleForAutoOrder))))
        }
    }

    function _changeAutoshipFunction(id, value) {
        const FIND_VALUE = _.findIndex(viewCartData, { id: +id });
        if (FIND_VALUE > -1) {
            viewCartData[FIND_VALUE]['autoship'] = value;
            localStorage.setItem('cartData', JSON.stringify(viewCartData))
            const CartData = localStorage.getItem('cartData') ? localStorage.getItem('cartData') : [];
            const QTYS = _.map(JSON.parse(CartData), ({ id, quantity, prices, autoship, isEligibleForAutoOrder }) => ({ id, quantity, subtotalPriceQTY: quantity * _productPrice(prices, (autoship !== "oneTime" ? 'autoship' : autoship), isEligibleForAutoOrder) }));
            setViewCartQty(QTYS);
            setViewCartData(JSON.parse(CartData));
            setTotalAmount(_.sum(_.map(JSON.parse(CartData), (row) => row?.quantity * _productPrice(row?.prices, (row?.autoship !== "oneTime" ? 'autoship' : row?.autoship), row?.isEligibleForAutoOrder))));
            setSelectedPrice(_.map(JSON.parse(CartData), ({ id, autoship }) => ({ id, value: autoship })))
        }
    }

    return ({
        selectedPrice,
        setSelectedPrice,
        viewCartData,
        qtyValues,
        totalAmount,
        _IncreaseDecreaseFunction,
        _changeAutoshipFunction,

    })
}

export default useViewCartHook;