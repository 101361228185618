// import { CircularProgress } from '@mui/material';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import Error from './Error';
import AboutPage from './pages/AboutUs';
import AllProductsPage from './pages/AllProducts';
import Cart from './pages/Cart';
import ViewCart from './pages/Cart/components/ViewCart';
import ContectUsPage from './pages/ContectUs';
import HomePage from './pages/Home';
import LoginRegisterPage from './pages/LoginRegister';
import ResetPasswordPage from './pages/Reset-password/index'
import SingleProduct from './pages/SingleProduct';
import CheckoutPage from './pages/Checkout';
import ALLStore from './AllStores';
import EnrollmentPage from './pages/Enrollment';
import EnrollmentSignUp from './pages/EnrollmentSignup';
import RetailSignUp from './pages/RetailerSignup';
import Thankyou from './pages/ThankyouPage';
import TermsConditions from './Layout/components/Footer/components/TermsConditions';
import LoyaltyProgramSignup from './pages/LoyaltyProgramSignup';
import WholesaleProgramSignup from './pages/WholesaleProgram';
import AutoshipEditPage from './pages/AutoshipEdit';
import RewardProgram from './pages/Enrollment/components/RewardProgram';
import Shipping from './Layout/components/Footer/components/Shipping';
import WellnessLanding from './pages/WellnessLanding';
import Detoxification from './pages/detox';
import * as Sentry from "@sentry/react";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const routers = sentryCreateBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <Error />,
        children: [
            {
                path: `:store`,
                element: <ALLStore />,
                children: [
                    { path: '', element: <HomePage /> },
                    { path: 'autoship_edit', element: <AutoshipEditPage /> },
                    { path: 'about_us', element: <AboutPage /> },
                    { path: 'terms-and-conditions', element: <TermsConditions /> },
                    { path: 'shipping', element: <Shipping /> },

                    { path: 'contact_us', element: <ContectUsPage /> },
                    { path: 'shop', element: <AllProductsPage />, children: [{ path: ':category', element: <AllProductsPage /> }] },
                    // { path: 'shop/:category', element: <AllProductsPage /> },
                    { path: 'enrollment', element: <EnrollmentPage /> },
                    { path: 'reward_program', element: <RewardProgram /> },
                    { path: 'shop/:category/:id/:name', element: <SingleProduct /> },
                    { path: 'login', element: <LoginRegisterPage /> },
                    { path: 'reset-password', element: <ResetPasswordPage /> },
                    { path: 'cart', element: <Cart /> },
                    { path: 'checkout', element: <CheckoutPage /> },
                    // { path: 'enrollment_signup', element: <EnrollmentSignUp /> },
                    { path: 'loyalty_program', element: <LoyaltyProgramSignup /> },
                    // { path: 'wholesale_program', element: <WholesaleProgramSignup /> },
                    { path: 'retail_signup', element: <RetailSignUp /> },
                    { path: 'thankyou', element: <Thankyou /> },
                    // { path: ':refferal', element: <HomePage /> },
                ]
            },
            { path: 'WellnessEssentials', element: <WellnessLanding /> },
            { path: 'detoxification', element: <Detoxification /> },

        ],
    },
]);

function Routes() {
    return (
        <RouterProvider router={routers} />
    );
}

export default Routes;