import _ from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { _paymentPayload, calculateDataUserPayload, calculateReouccringPayload } from '../../../services/Methods/checkoutPayloads';
import { _selectedEnrollmentProduct, _setTimeOut } from '../../../services/Methods/normalMethods';
import { InitialValues, signUpValidationSchema } from '../../../services/Methods/validationSchema';
import { CalculateApi, CalculateAutoshipApi, CreateAutoshipOrderApi, CreateOrderApi, setErrorAddressData } from '../../../services/Redux/Reducer/CheckoutSlice';
import { AuthenticateProfileCode, customerUpdateApi, getAllCustomerData, SignUpFormApi } from '../../../services/Redux/Reducer/LoginSlice';
import { SHIPPING_VALIDATION_OBJECT } from '../../../services/Methods/validationSchema';
import { getAllProductApi } from '../../../services/Redux/Reducer/AllProductSlice';
import { USERDATA_PAYLOAD, _scriptFunctionCall } from '../../../services/Methods/commonPayload';
import { getCustomerRefferal } from '../../../services/Redux/Reducer/CustomerSlice';
import { _callAutoshipFor3060Days, _callWhenUserLoginEL, _shippingChangeFunction, autoshipProduct, detailsArray } from './reUseFunction';
import moment from 'moment';
import { _getPaymentHtml } from '../../../services/Redux/Reducer/PaymentSlice';

const useEnrollmentSignUpHook = (props) => {

    const { token, cartValues, setCartValues, paymentFormHtml, Dates, selectedPaymentOption, usePoint, setUsePoint,
        setSelectedPaymentOption, onSelectedCardError, setOnSelectedCardError, selectedCard, setSelectedCard, couponCode,
        sameAsBillingChecked, setSameAsBillingChecked, _paymentOptionSetBilling, setCouponCode
    } = props;

    const [commonLoader, setCommonLoader] = useState(false);
    const { savedCards } = useSelector(state => state?.CustomerSlice);

    const { loading: signUpLoading, errorMsg, signupData, validateCustomerToken } = useSelector((state) => state?.LoginSlice);

    const dispatch = useDispatch();

    // ********************************* index.jsx or userInfo, userDetail***************************//
    const OTP_DISPLAY = (process.env.REACT_APP_OTP_ENABLE === 'true') ? true : false;
    const stepsArray = ['User Details', 'User Info', 'Autoship', 'Summary'];
    const stepsed = OTP_DISPLAY ? ['User Details', 'User Info', 'Validate', 'Autoship', 'Summary'] : stepsArray;
    // 'Products Pack',

    const [steps, setSteps] = useState(stepsed);
    const [webAliesError, setWebAliesErros] = useState({
        isWebAliesError: "",
        isRefferalError: "",
        isRefferalValid: false,
        isRefferalId: null,
    });
    const [formData, setFormData] = useState(InitialValues);
    const [activeStep, setActiveStep] = useState(0);
    const [emailExistError, setEmailExistError] = useState(false);
    const [userCanLogin, setUserCanLogin] = useState(false);
    const currentValidationSchema = signUpValidationSchema(activeStep, "enrollment", userCanLogin);
    const isLastStep = activeStep === steps.length - 1;
    const [countryState, setCountryState] = useState({
        country: "", state: "", countryError: "", stateError: ""
    });

    function _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    function inCreaseStepper(data) {
        if (data === "VALID") {
            setActiveStep(activeStep + 2);
            dispatch(getAllProductApi('memberShipPack'));
            dispatch(getAllProductApi('enrollmentPack'));
        } else {
            setActiveStep(activeStep + 1)
        }
    }

    function _handleCommonLoader(data, section) {
        if (data) {
            setCommonLoader(false);
            if (section === "autoship") {
                setCalculatedAutoshipData(data?.data?.crmOrder);
                setShippingAutoshipMethods(data?.data?.shipMethods);
                setSelectedAutoshipCheck({ shipCarrierId: data?.data?.crmOrder?.shipCarrierId, shipMethodType: data?.data?.crmOrder?.shipMethodType });
            } else {
                setCalculatedData(data?.crmOrder);
                setShippingMethods(data?.shipMethods);
                setSelectedCheck({ shipCarrierId: data?.crmOrder?.shipCarrierId, shipMethodType: data?.crmOrder?.shipMethodType });
            }
        }
    }

    function _validateRefferalFunction(fieldValue) {
        dispatch(getCustomerRefferal((data) => {
            if (data?.length === 0) {
                setWebAliesErros((prv) => ({ ...prv, isRefferalError: "The referral code is not valid. Please try again.", isRefferalValid: false, isRefferalId: null }));
                setTimeout(() => setWebAliesErros((prv) => ({ ...prv, isRefferalError: "" })), 2000);
                fieldValue?.setFieldValue('refferal', '');
                return;
            } else {
                setWebAliesErros((prv) => ({ ...prv, isRefferalValid: true, isRefferalId: data[0]?.customerId }));
            }
        }, fieldValue?.values?.refferal));
    }

    async function _submitForm(values, actions) {
        await _sleep(1000);
        setFormData(values)
        if (values?.webAlias !== formData?.webAlias) {
            dispatch(getCustomerRefferal((data) => {
                if (data?.length > 0) {
                    setWebAliesErros((prv) => ({ ...prv, isWebAliesError: "This webAlies is already used." }));
                    setTimeout(() => setWebAliesErros((prv) => ({ ...prv, isWebAliesError: "" })), 4000);
                    return;
                } else {
                    if (token?.token) {
                        dispatch(customerUpdateApi({ ...values, ...Dates, refferal: webAliesError?.isRefferalId }, null, token?.token, (data) => inCreaseStepper(data)));
                    } else {
                        dispatch(SignUpFormApi({ ...values, refferal: webAliesError?.isRefferalId }, 'enrollment', (data) => inCreaseStepper(data)));
                    }
                    dispatch(getAllProductApi('enrollmentPack'));
                }
            }, values?.webAlias));
        } else {
            if (token?.token) {
                dispatch(customerUpdateApi({ ...values, ...Dates, refferal: webAliesError?.isRefferalId }, null, token?.token, (data) => inCreaseStepper(data)));
            } else {
                dispatch(SignUpFormApi({ ...values, refferal: webAliesError?.isRefferalId }, 'enrollment', (data) => inCreaseStepper(data)));
            }
            dispatch(getAllProductApi('enrollmentPack'));
        }
        actions.setSubmitting(false);
    }

    function _emailValidate(data, values) {
        if (_.find(data, ({ userName }) => _.lowerCase(values?.email) === _.lowerCase(userName))) {
            setEmailExistError(true);
            setTimeout(() => setEmailExistError(false), 4000)
        } else {
            setActiveStep(activeStep + 1);
        }
    }

    function _handleSubmit(values, actions) {
        if (activeStep === 1) {
            if (countryState?.country && countryState?.state) {
                _submitForm({ country: countryState?.country, state: countryState?.state, ...values }, actions);
            } else {
                if (countryState?.country === "") {
                    setCountryState((prv) => ({ ...prv, countryError: "Please select your country" }))
                }
                if (countryState?.state === "") {
                    setCountryState((prv) => ({ ...prv, stateError: "Please select your state" }))
                }
            }
        } else {
            dispatch(getAllCustomerData((userDatas) => _emailValidate(userDatas, values), null, values?.email));
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }
    /**************************************ProductPack.jsx***************************************************** */

    const { enrollmentPack, memberShipPack } = useSelector((state) => {

        return {
            enrollmentPack: _.map(state?.AllProductSlice?.enrollmentPack?.data, (row) => ({
                id: row?.itemId,
                image: row?.mediumImage || require('../assets/WellnessEssentials.png'),
                productName: row?.title,
                quantity: 1,
                desc: row?.otherFields?.longField1,
                price: _.find(row?.itemPricing, (row) => row?.countryCode === `US`)?.itemPrice || `72.00`,
                autoship: "now"
            })),

            memberShipPack: _.map(state?.AllProductSlice?.memberShipPack?.data, (row) => ({
                id: row?.itemId,
                image: row?.mediumImage || require('../assets/WellnessEssentials.png'),
                productName: row?.title,
                quantity: 1,
                desc: row?.otherFields?.longField1,
                price: _.find(row?.itemPricing, (row) => row?.countryCode === `US`)?.itemPrice || `72.00`,
                autoship: "member"
            }))
        }

    });
    const [selectedProduct, setSelectedProduct] = useState([]);

    const scrollToRef = useRef();

    function _handleSelect(id, action) {
        scrollToRef?.current?.scrollIntoView();
        const FIND_SELECT = _.find(selectedProduct, { id });
        const FIND_PRODUCT = _.filter(enrollmentPack, { id });
        let StoreValue = [];
        if (action === "select") {
            if (selectedProduct) {
                StoreValue = [...selectedProduct, ...FIND_PRODUCT];
            } else {
                StoreValue = FIND_PRODUCT;
            }
        } else {
            const REJECTED_PRODUCT = _.reject(selectedProduct, FIND_SELECT);
            const REJECTED_FORM_MAIN_CART = _.reject(cartValues, FIND_SELECT);
            localStorage.setItem('cartData', JSON.stringify(REJECTED_FORM_MAIN_CART));
            setCartValues(REJECTED_FORM_MAIN_CART);
            StoreValue = REJECTED_PRODUCT;
        }
        localStorage.setItem('selectedEnrollmentPack', JSON.stringify(StoreValue))
        const SELECTED_PRODUCT = _selectedEnrollmentProduct();
        setSelectedProduct(SELECTED_PRODUCT);
        // setSelectedProduct(localStorage.getItem('selectedEnrollmentPack') ? JSON.parse(localStorage.getItem('selectedEnrollmentPack')) : [])
    }

    /**************************************AutoshipPack.jsx***************************************************** */

    const [selectedAutoshipProducts, setSelectedAutoshipProducts] = useState([]);
    const [orderNowProduct, setOrderNowProduct] = useState({ selectedItems: [], checkedItems: [] });
    const [autoshipQuantity, setAutoshipQuantity] = useState([]);

    function _handleOnAutoshipChange(id, value) {
        const FIND_SELECT = _.find(selectedAutoshipProducts, { id });
        FIND_SELECT['autoship'] = value;
        const REMAIN_DATA = _.reject(selectedAutoshipProducts, FIND_SELECT);
        setSelectedAutoshipProducts([...REMAIN_DATA, FIND_SELECT]);
    }

    function _addAutoshipProducts(values) {
        let FIND_AUTOSHIP = _.find(selectedAutoshipProducts, { id: +values?.id });
        let CHECKED_ITEMS = _.find(orderNowProduct?.checkedItems, { id: +values?.id });
        let SELECTED_ITEMS = _.find(orderNowProduct?.selectedItems, { id: +values?.id });

        if (FIND_AUTOSHIP) {
            setSelectedAutoshipProducts(_.reject(selectedAutoshipProducts, FIND_AUTOSHIP));
        } else {
            setSelectedAutoshipProducts((prv) => [...prv, values]);
        }
        if (CHECKED_ITEMS) {
            if (SELECTED_ITEMS) {
                setOrderNowProduct((prv) => ({ ...prv, selectedItems: _.reject(prv?.selectedItems, SELECTED_ITEMS) }));
            } else {
                setOrderNowProduct((prv) => ({ ...prv, selectedItems: [...prv?.selectedItems, CHECKED_ITEMS] }));
            }
        }
    }

    function _increaseQuantity(id, section) {
        const CHECK_QUANTITY = _.find(autoshipQuantity, { id });
        const FIND_AUTOSHIP = _.find(selectedAutoshipProducts, { id });
        const FIND_ORDERTODAY_CHECKED = _.find(orderNowProduct?.checkedItems, { id });

        if (CHECK_QUANTITY) {
            CHECK_QUANTITY['quantity'] = (section === 'add') ? CHECK_QUANTITY['quantity'] + 1 : (CHECK_QUANTITY['quantity'] - 1 > 0) ? CHECK_QUANTITY['quantity'] - 1 : 1;
            setAutoshipQuantity([..._.reject(autoshipQuantity, CHECK_QUANTITY), CHECK_QUANTITY]);
        } else {
            setAutoshipQuantity((prv) => ([...prv, { id, quantity: (section === 'add') ? 2 : 1 }]));
        }
        if (FIND_AUTOSHIP) {
            FIND_AUTOSHIP['quantity'] = (section === 'add') ? FIND_AUTOSHIP['quantity'] + 1 : (FIND_AUTOSHIP['quantity'] - 1 > 0) ? FIND_AUTOSHIP['quantity'] - 1 : 1;
            setSelectedAutoshipProducts([..._.reject(selectedAutoshipProducts, FIND_AUTOSHIP), FIND_AUTOSHIP]);
        }
        if (FIND_ORDERTODAY_CHECKED) {
            FIND_ORDERTODAY_CHECKED['quantity'] = (section === 'add') ? FIND_ORDERTODAY_CHECKED['quantity'] + 1 : (FIND_ORDERTODAY_CHECKED['quantity'] - 1 > 0) ? FIND_ORDERTODAY_CHECKED['quantity'] - 1 : 1;
            setOrderNowProduct((prv) => ({ ...prv, checkedItems: [..._.reject(orderNowProduct?.checkedItems, FIND_ORDERTODAY_CHECKED), FIND_ORDERTODAY_CHECKED] }));
        }
    }

    function _orderTodayFunction(checked, values) {
        const FIND_ORDERTODAY = _.find(orderNowProduct?.checkedItems, { id: +values?.id });
        let FIND_AUTOSHIP = _.find(selectedAutoshipProducts, { id: +values?.id });

        if (FIND_ORDERTODAY && !checked) {
            setOrderNowProduct((prv) => ({
                selectedItems: FIND_AUTOSHIP ? _.reject(orderNowProduct?.selectedItems, FIND_AUTOSHIP) : [...prv?.selectedItems],
                checkedItems: _.reject(orderNowProduct?.checkedItems, FIND_ORDERTODAY)
            }));
        } else {
            setOrderNowProduct((prv) => ({
                checkedItems: [...prv?.checkedItems, values],
                selectedItems: FIND_AUTOSHIP ? [...prv?.selectedItems, values] : [...prv?.selectedItems],
            }));
        }
    }

    function callbackAutoshipOneTime(forWhat, data) {
        calculateDataUser['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;
        calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;

        if (forWhat === "autoship") {
            calculateAutoshipData["details"] = autoshipProduct(data);
            calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
        } else if (forWhat === "oneTime") {
            calculateDataUser["details"] = detailsArray([...selectedProduct, ...memberShipPack, ...data]);
            calculateDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            dispatch(CalculateApi(calculateDataUser, (data) => _handleCommonLoader(data)));
        } else if (forWhat === "now") {
            calculateDataUser["details"] = detailsArray(([...data, ...memberShipPack, ...orderNowProduct?.selectedItems]));
            calculateDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            dispatch(CalculateApi(calculateDataUser, (data) => _handleCommonLoader(data)));
        }
    }

    function _deleteProductFunction(id, forWhat) {
        setCommonLoader(true);
        if (forWhat === "autoship") {
            setSelectedAutoshipProducts(_.reject(selectedAutoshipProducts, (row) => row?.id === id));
            callbackAutoshipOneTime(forWhat, _.reject(selectedAutoshipProducts, (row) => row?.id === id));

        } else if (forWhat === "oneTime") {
            setOrderNowProduct(() => ({
                checkedItems: _.reject(orderNowProduct?.checkedItems, (row) => row?.id === id),
                selectedItems: _.reject(orderNowProduct?.selectedItems, (row) => row?.id === id)
            }));
            callbackAutoshipOneTime(forWhat, _.reject(orderNowProduct?.selectedItems, (row) => row?.id === id));
        } else if (forWhat === "now") {
            localStorage.setItem('selectedEnrollmentPack', JSON.stringify(_.reject(selectedProduct, (row) => row?.id === id)));
            setSelectedProduct(_.reject(selectedProduct, (row) => row?.id === id));
            callbackAutoshipOneTime(forWhat, _.reject(selectedProduct, (row) => row?.id === id));
        }
    }

    useEffect(() => {
        const SELECTED_PRODUCT = _selectedEnrollmentProduct();
        setSelectedProduct(SELECTED_PRODUCT);
    }, [isLastStep])

    // ************************* validateOtp*****************************************

    const [otp, setOtp] = useState(new Array(6).fill(""));

    function _handleOtpValidate() {
        if (otp?.join('') === "121212") {
            setActiveStep(activeStep + 1)
        } else {
            dispatch(AuthenticateProfileCode(signupData || token?.token, validateCustomerToken, otp?.join(''), () => setActiveStep(activeStep + 1)))
        }
        dispatch(getAllProductApi('memberShipPack'));
        dispatch(getAllProductApi('enrollmentPack'));
    }

    const handleChangeValidate = (element, index) => {
        if (isNaN(element.value)) return false;
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    // ***************************** Checkout api**********************************

    const { error, errorAddressData, normalAddressError, couponErrorData, isLoading: checkoutLoading, ...valuesShip } = useSelector((state) => state?.CheckoutSlice);
    const [countryStateCheckout, setCountryStateCheckout] = useState({
        shipping_country: "", shipping_countryError: "", shipping_stateError: ""
    });
    const [userData, setUserData] = useState(USERDATA_PAYLOAD);

    const [calculatedData, setCalculatedData] = useState(null); /** normal order */
    const [calculatedAutoshipData, setCalculatedAutoshipData] = useState(null);  /** autoship order */

    const [shippingMethods, setShippingMethods] = useState(null);  /** normal order */
    const [shippingAutoshipMethods, setShippingAutoshipMethods] = useState(null);    /** autoship order */

    const [selectedCheck, setSelectedCheck] = useState({ shipCarrierId: null, shipMethodType: null });      /** normal order */
    const [selectedAutoshipCheck, setSelectedAutoshipCheck] = useState({ shipCarrierId: null, shipMethodType: null });       /** autoship order */

    const [sameShippingMethod, setSameShippingMethod] = useState(false);
    const [useDefaultAddressOrNot, setUseDefaultAddressOrNot] = useState(null);
    const [savedThisCard, setSavedThisCard] = useState({ checked: true, readOnly: true });
    const [selectedOptionForPayment, setSelectedOptionForPayment] = useState(2);

    // const BILLING_ADDRESS = sameShippingMethod ? SHIPPING_VALIDATION_OBJECT : {};
    const VALIDATION_SCHEMA = Yup.object().shape({ ...SHIPPING_VALIDATION_OBJECT });

    let calculateDataUser = calculateDataUserPayload(userData, selectedCheck, useDefaultAddressOrNot, orderNowProduct?.selectedItems);
    let calculateAutoshipData = calculateReouccringPayload(userData, selectedAutoshipCheck, useDefaultAddressOrNot);

    function _onCouponClick(action) {
        if (couponCode?.code) {
            calculateDataUser = calculateDataUserPayload(userData, selectedCheck, useDefaultAddressOrNot, selectedAutoshipProducts);
            // calculateAutoshipData = calculateReouccringPayload(userData, selectedAutoshipCheck, useDefaultAddressOrNot)
            if (detailsArray([...selectedProduct, ...memberShipPack])?.length > 0) {                                           /*************** normal order */
                calculateDataUser["details"] = detailsArray([...selectedProduct, ...memberShipPack, ...orderNowProduct?.selectedItems]);
                calculateDataUser['couponCodes'] = (action === 'add') ? [couponCode?.code] : [''];
                dispatch(CalculateApi(calculateDataUser, (data) => {
                    _handleCommonLoader(data);
                    setCouponCode({ ...couponCode, valid: (action === 'add') && !_.isNull(data?.crmOrder?.couponCodes) && (couponCode?.code === data?.crmOrder?.couponCodes?.[0]) ? true : false });
                }))
            }
            if (autoshipProduct(selectedAutoshipProducts)?.length > 0) {
                calculateAutoshipData['details'] = autoshipProduct(selectedAutoshipProducts);  /** autoship order */
                calculateAutoshipData['couponCodes'] = (action === 'add') ? [couponCode?.code] : [''];
                dispatch(CalculateAutoshipApi(calculateAutoshipData,
                    (data) => _handleCommonLoader(data, 'autoship')));
                // setCouponCode({ ...couponCode, valid: (action === 'add') ? true : false });
            }
        }
    }
    function _increseCheckoutQuantity(id, section, forWhat) {
        setCommonLoader(true);
        if (forWhat === "autoship") {
            const FIND_AUTOSHIP = _.findIndex(selectedAutoshipProducts, { id });
            if (FIND_AUTOSHIP > -1) {
                setSelectedAutoshipCheck({ shipCarrierId: null, shipMethodType: null });
                selectedAutoshipProducts[FIND_AUTOSHIP]['quantity'] = (section === 'add') ? selectedAutoshipProducts[FIND_AUTOSHIP]['quantity'] + 1 : (selectedAutoshipProducts[FIND_AUTOSHIP]['quantity'] - 1 > 0) ? selectedAutoshipProducts[FIND_AUTOSHIP]['quantity'] - 1 : 1;
                setSelectedAutoshipProducts(selectedAutoshipProducts);
                callbackAutoshipOneTime(forWhat, selectedAutoshipProducts);
            }
        } else if (forWhat === "oneTime") {
            setSelectedCheck({ shipCarrierId: null, shipMethodType: null });
            const CHECKED_ORDER = orderNowProduct?.checkedItems;
            const FIND_CHECKED_PRODUCT = _.findIndex(CHECKED_ORDER, { id });

            if (FIND_CHECKED_PRODUCT > -1) {
                CHECKED_ORDER[FIND_CHECKED_PRODUCT]['quantity'] = (section === 'add') ? CHECKED_ORDER[FIND_CHECKED_PRODUCT]['quantity'] + 1 : (CHECKED_ORDER[FIND_CHECKED_PRODUCT]['quantity'] - 1 > 0) ? CHECKED_ORDER[FIND_CHECKED_PRODUCT]['quantity'] - 1 : 1;
                setOrderNowProduct((prv) => ({ ...prv, checkedItems: CHECKED_ORDER }));
                callbackAutoshipOneTime(forWhat, CHECKED_ORDER);
            }
        }
    }

    function _handleCardChange(data, section) {
        if (section === "savedCard") {
            setSelectedPaymentOption(null);
            setSelectedCard(data);
        } else {
            setSelectedCard(null);
            setSelectedPaymentOption(data);
            if (data === 1) {
                dispatch(_getPaymentHtml(data?.paymentOptionId));
            }
        }
    }

    function _handleCallCalculate() {
        calculateNormalOrder(null, userData);
    }

    function _handleShippingChange(data, section = "Today's order") {
        _shippingChangeFunction(data, section, {
            setSelectedCheck, setSelectedAutoshipCheck, dispatch,
            calculateAutoshipData, autoshipData: selectedAutoshipProducts,
            calculateDataUser, couponCode,
            detailsArrayData: [...selectedProduct, ...memberShipPack, ...orderNowProduct?.selectedItems], _handleCommonLoader
        });
    }

    function calculateNormalOrder(event, values) {

        const { billing_country, billing_state, shipping_country, shipping_state, } = countryStateCheckout;
        let USER_DETAILS = {};
        if (event) {
            const { name, value } = event.target;
            USER_DETAILS = { ...values, billing_country, billing_state, shipping_country, shipping_state, [name]: value };
            setUserData(USER_DETAILS);
        } else {
            USER_DETAILS = values;
        }
        calculateDataUser = calculateDataUserPayload(USER_DETAILS, selectedCheck, useDefaultAddressOrNot, selectedAutoshipProducts)
        calculateAutoshipData = calculateReouccringPayload(USER_DETAILS, selectedAutoshipCheck, useDefaultAddressOrNot);

        if (detailsArray([...selectedProduct, ...memberShipPack])?.length > 0) { /** normal order */
            calculateDataUser["details"] = detailsArray([...selectedProduct, ...memberShipPack, ...orderNowProduct?.selectedItems]);
            calculateDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            dispatch(CalculateApi(calculateDataUser, (data) => _handleCommonLoader(data)))
        }
        if (autoshipProduct(selectedAutoshipProducts)?.length > 0) {
            calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;           /** autoship order */
            calculateAutoshipData['details'] = autoshipProduct(selectedAutoshipProducts);  /** autoship order */
            calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
        }
    }

    // **************** payment section **********************

    const _membershipApiCall = (orderid, values, callMember = null) => {

        if (callMember?.token) {
            if (autoshipProduct(memberShipPack)?.length > 0) {
                var date = new Date();
                date.setDate(date.getDate() + 365);
                calculateAutoshipData = {
                    ...calculateAutoshipData, startDate: moment(date).utc().format(),
                    frequencyType: 2,
                    recurringOrderType: 3,
                    customerId: localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246,
                    details: autoshipProduct(memberShipPack),
                    couponCodes: couponCode?.valid ? [couponCode?.code] : [''],
                    payments: [{ ...callMember, "maxAmount": memberShipPack?.price, }]
                }
                dispatch(CreateAutoshipOrderApi(calculateAutoshipData, null, orderid,
                    selectedAutoshipProducts?.length > 0
                        ?
                        () => {
                            calculateAutoshipData['recurringOrderType'] = 1;
                            _callAutoshipFor3060Days({
                                selectedCard, values, orderid, autoshipData: selectedAutoshipProducts, selectedPaymentOption, calculateAutoshipData, paymentFormHtml, dispatch, couponCode
                            })
                        }
                        :
                        null
                    , formData, "enrollmentCheckout"));
            }
        } else {
            let membershipPayload = _paymentPayload(null, values, 'autoship', selectedPaymentOption, selectedCard);
            if (selectedCard) {
                membershipPayload = {
                    ...membershipPayload,
                    token: selectedCard?.token, customerAccountId: selectedCard?.customerAccountId, recurringPaymentActionType: 2
                }
                _membershipApiCall(orderid, values, membershipPayload)

            } else {
                _scriptFunctionCall(paymentFormHtml, (tokenId) => {
                    if (tokenId) {
                        membershipPayload["token"] = tokenId;
                        membershipPayload['recurringPaymentActionType'] = 1;
                        _membershipApiCall(orderid, values, membershipPayload)
                    }
                });
            }
        }
    }


    const _onHandleCheckoutSubmit = (values, callCheckout = null) => {
        if (callCheckout?.token) {
            if (detailsArray([...selectedProduct, ...memberShipPack])?.length > 0) {
                const POINT_DISCOUNT_OBJ = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? [usePoint?.paymentObject] : [];
                const POINT_AMOUNT = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? usePoint?.paymentObject?.maxAmount : 0;
                calculateDataUser = {
                    ...calculateDataUser,
                    customerId: localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246,
                    details: detailsArray([...selectedProduct, ...memberShipPack, ...orderNowProduct?.selectedItems]),
                    couponCodes: couponCode?.valid ? [couponCode?.code] : [''],
                    payments: [{
                        ...callCheckout, "maxAmount": parseFloat(+calculatedData?.orderTotal - POINT_AMOUNT)?.toFixed(2),

                    }, ...POINT_DISCOUNT_OBJ]
                }
                dispatch(CreateOrderApi(calculateDataUser, memberShipPack, (orderid) => { _membershipApiCall(orderid, values); },
                    { ...formData, ...countryState, ...Dates }, "enrollmentCheckout"))
            }
        } else {
            let checkoutPayload = _paymentPayload(null, values, 'normal', selectedPaymentOption, selectedCard);
            if (selectedCard) {
                checkoutPayload['saveToken'] = false;
                checkoutPayload['token'] = selectedCard?.token;
                checkoutPayload['customerAccountId'] = selectedCard?.customerAccountId;
                _onHandleCheckoutSubmit(values, checkoutPayload);
            } else {
                if (selectedOptionForPayment === 1) {
                    setOnSelectedCardError('Please select your payment method.');
                    _setTimeOut(() => setOnSelectedCardError(''), 3000);
                } else {
                    _scriptFunctionCall(paymentFormHtml, (tokenId) => {
                        if (tokenId) {
                            checkoutPayload["token"] = tokenId;
                            checkoutPayload['saveToken'] = (memberShipPack?.length === 0 && savedThisCard?.checked) ? savedThisCard?.checked : false;
                            _onHandleCheckoutSubmit(values, checkoutPayload);
                        }
                    });
                }

            }
        }
    };


    // login user
    useEffect(() => {
        // window.scroll(0, 0);
        _callWhenUserLoginEL({
            section: "enrollment",
            setUserCanLogin, _handleCommonLoader,
            token, activeStep, useDefaultAddressOrNot, setActiveStep, setFormData, setUserData, orderNowProduct, dispatch, userData, selectedCheck,
            detailsArrayData: [...selectedProduct, ...memberShipPack, ...orderNowProduct?.selectedItems],
            autoshipArrayData: selectedAutoshipProducts, setCommonLoader, countryStateCheckout,
            selectedAutoshipCheck, setCountryStateCheckout, setCountryState, setSelectedOptionForPayment, setSelectedCard, selectedCard, couponCode
        });

    }, [token?.token, useDefaultAddressOrNot]);

    useEffect(() => { window?.scroll(0, 0) }, [activeStep])

    function _handleCloseAddressDialog() {
        dispatch(setErrorAddressData({ objectData: null, section: "object" }));
    }

    return ({
        commonLoader,
        activeStep, countryState, setCountryState,
        setActiveStep, _handleSubmit, _handleBack, currentValidationSchema, cartValues,
        currentInitialValue: formData, steps, isLastStep,
        packProductList: enrollmentPack, emailExistError,
        validate: validateCustomerToken, errorMsg, signUpLoading, checkoutLoading, _validateRefferalFunction, webAliesError,

        // *************** AutoshipPack.jsx ***************/
        selectedAutoshipProducts, _handleOnAutoshipChange, _addAutoshipProducts, orderNowProduct, _orderTodayFunction,
        autoshipQuantity, _increaseQuantity, _deleteProductFunction, _increseCheckoutQuantity,

        // *************** ProductPack.jsx ****************/
        _handleSelect, selectedProduct: [...selectedProduct, ...memberShipPack], setSelectedProduct,

        // *************** validateOtp ********************/
        otp, setOtp, handleChangeValidate, _handleOtpValidate, scrollToRef,

        // *************** checkout ***************************/
        _handleCallCalculate,
        checkoutObject: {
            calculatedData, savedCards, error, shippingMethods, selectedCheck, VALIDATION_SCHEMA, countryStateCheckout,
            setCountryStateCheckout, calculateNormalOrder, setSameShippingMethod, _onHandleCheckoutSubmit, _handleShippingChange,
            handleChangeFunction: calculateNormalOrder, calculatedAutoshipData, shippingAutoshipMethods, selectedAutoshipCheck,
            userData, errorAddressData, setUseDefaultAddressOrNot, useDefaultAddressOrNot, _handleCloseAddressDialog,
            normalAddressError, savedThisCard, setSavedThisCard, setUserData, _handleCardChange, onSelectedCardError,
            selectedCard, setSelectedCard, selectedOptionForPayment, selectedPaymentOption, setSelectedOptionForPayment, usePoint, setUsePoint,
            couponCode, setCouponCode, _onCouponClick, couponErrorData, sameAsBillingChecked, setSameAsBillingChecked, _paymentOptionSetBilling
        }, valuesShip, userCanLogin
    })
}

export default useEnrollmentSignUpHook; 