import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import api from "../../AxiosHelper";
import { MIDDLE_CREDENTIAL, storeAssigned } from "../../Methods/normalMethods";

const PaymentSlice = createSlice({
    name: "productSlice",
    initialState: {
        paymentOptions: [],
        paymentFormHtml: "",
        paymentScript: "",
        pointData: [],
    },
    reducers: {
        setPaymentOptions: (state, actions) => { state['paymentOptions'] = actions?.payload; },
        setFormHtml: (state, actions) => { state['paymentFormHtml'] = actions.payload; },
        setScriptFunction: (state, actions) => { state['paymentScript'] = actions.payload; },
        setPointData: (state, actions) => { state['pointData'] = actions?.payload }
    }
});
export const { setPaymentOptions, setFormHtml, setScriptFunction, setPointData } = PaymentSlice?.actions;

export const _getPaymentOptions = (callback) => dispatch => {
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "GET",
        endpoint: `/Money/PaymentOptions/${_.upperCase(storeAssigned())}/USD`
    }).success((res) => {
        if (res?.data?.length > 0) {
            dispatch(setPaymentOptions(res?.data));
            callback(res?.data)
        }
    }).error((err) => {

    }).send();
}

export const _getPaymentHtml = (paymentOptionId) => dispatch => {
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "POST",
        endpoint: `/Money/PaymentOptions/Form`,
        data: JSON.stringify({
            "paymentOptionId": paymentOptionId,
            "frameId": "collect_form_pay",
            "formSubmitFunctionName": 'submitHandle',
            "onSuccessFunctionName": 'onSucessFunciton',
            "onErrorFunctionName": 'onErrorFunction',
            "url": window.location.origin || process.env.REACT_APP_SITE_URL,
            // "url": "http://localhost:3000",
            "customCSS": '#lacore-payment-form h4 { background-color:#000 }'
        })
    }).success((res) => {
        if (res?.data) {
            dispatch(setScriptFunction(res?.data?.scriptRaw))
            dispatch(setFormHtml(res?.data?.frameSource));

        }
    }).error((err) => {

    }).send();
}




export default PaymentSlice?.reducer;