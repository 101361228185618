import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import api from "../../AxiosHelper";
import { MIDDLE_CREDENTIAL, REFFERAL_VALUES, storeAssigned } from "../../Methods/normalMethods";

const CustomerSlice = createSlice({
    name: "customerSlice",
    initialState: {
        webAliseFilterData: [],
        savedCards: [],
        isLoading: false,
        userAccount: "",
        errorMsg: "",
        resetEmail: "",
        resetPass: "",
        emailerror: "",
        successmsg: null,
        countryData: []
    },
    reducers: {
        setWebAliesFilter: (state, action) => {
            state['webAliseFilterData'] = action?.payload;
        },
        setLoading: (state, action) => {
            state['isLoading'] = action?.payload;
        },
        setError: (state, action) => {
            state['errorMsg'] = action.payload;
        },
        setSavedCards: (state, action) => {
            state['savedCards'] = action.payload?.data;
        },
        setResetEmail: (state, action) => {
            state['resetEmail'] = action.payload?.data;
            localStorage.setItem('TokenEmail', action.payload?.data);
        },
        setResetPassword: (state, action) => {
            state['resetPass'] = action.payload?.data;
        },
        setSuccessMsg: (state, action) => {
            state['successmsg'] = action.payload;
        },
        setEmailError: (state, action) => {
            state['emailerror'] = action.payload;
        },
        setCountryData: (state, action) => {
            state['countryData'] = action.payload?.data
        }
    }
});

export const {
    setWebAliesFilter, setLoading, setError, setSavedCards, setUserAccount, setResetEmail, setResetPassword, setEmailError, setCountryData,
    setSuccessMsg
} = CustomerSlice?.actions;

const ErrorMsg = (errorResult) => dispatch => {
    if (errorResult?.length > 0) {
        _.map(errorResult, ({ errorMessage }) => {
            if (errorMessage) {
                dispatch(setError(errorMessage));
                setTimeout(() => dispatch(setError("")), 4000);
                return;
            }
        });
    }
}

export const getCustomerRefferal = (callback, webAlies = null) => dispatch => {
    const URL = `/Crm/Customers/?WebAlias=eq:${webAlies}`;
    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "GET",
        endpoint: URL
    }).success((res) => {
        dispatch(setLoading(false));
        if (res?.data) {
            if (callback) {
                callback(res?.data);
            }
            dispatch(setWebAliesFilter(res));
            return res?.data;
        }
    }).error((err) => {
        dispatch(ErrorMsg(err?.errorResults));
        dispatch(setLoading(false));
    }).send();
}

export const getCustomerSavedCard = (id = null, callback,) => dispatch => {
    const URL = `/Crm/Customers/${id}/AccountBilling`;
    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "GET",
        endpoint: URL
    }).success((res) => {
        dispatch(setLoading(false));
        if (res?.data) {
            if (callback) {
                callback(res?.data);
            }
            dispatch(setSavedCards(res));
        }
    }).error((err) => {
        dispatch(ErrorMsg(err?.errorResults));
        dispatch(setLoading(false));
    }).send();
}
//
export const getUserCredentialApi = (ssokey, navigate, REDIRECT_PAGE) => dispatch => {
    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "GET",
        endpoint: `/Authorize/DecryptSSOKey?ssokey=${ssokey}`
    }).success((res) => {
        dispatch(setLoading(false));
        // dispatch(setUserAccount(res));
        localStorage.setItem('Token', res?.data);
        window.location.assign(REDIRECT_PAGE ? `/${storeAssigned()}${REDIRECT_PAGE}` : `/${storeAssigned()}`)

        // navigate(REDIRECT_PAGE ? `/${_.replace(_.lowerCase(REDIRECT_PAGE), ' ', '/')}` : `/${storeAssigned()}`)
    }).error((err) => {
        navigate(`/${storeAssigned()}`)
        dispatch(ErrorMsg(err?.errorResults));
        dispatch(setLoading(false));
    }).send();
}

//*************reset email**************************************************

export const ResetEmailApi = ({ email }) => dispatch => {
    const header = { CustomerUsername: email };
    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        endpoint: '/Crm/Customers/PasswordRecovery',
        method: "resetPassword",
        ...header
    }).success((res) => {
        dispatch(setLoading(false));
        dispatch(setResetEmail(res));
        window.location.assign(`/${storeAssigned()}/reset-password`)

    }).error((err) => {
        dispatch(setEmailError(err?.errorMessage));
        dispatch(setLoading(false));
    }).send();
}
//**********************************reset password******************************************/

export const NewPasswordApi = ({ password }) => dispatch => {
    const SESSIONID = localStorage.getItem("TokenEmail")
    const PASSWORDKEY = localStorage.getItem("PasswordKey")
    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "updatePassword",
        endpoint: `/Crm/Customers/SubmitPasswordRecovery?sessionId=${SESSIONID}&key=${PASSWORDKEY}`,
        CustomerPassword: password
    }).success((res) => {
        dispatch(setLoading(false));
        dispatch(setResetPassword(res));
        dispatch(setSuccessMsg('You have changed your password successfully'));
        localStorage.removeItem("PasswordKey")
        localStorage.removeItem("TokenEmail")
        setTimeout(() => {
            window.location.assign(`/${storeAssigned()}/login`)
        }, 3000);
    }).error((err) => {
        dispatch(setEmailError(err?.errorMessage));
        dispatch(setLoading(false));
    }).send();
}

export const _unilevelTreeUpdate = (customerId, treeType = 2) => dispatch => {
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "POST",
        endpoint: `/Crm/Customers/${customerId}/TreeMove`,
        data: JSON.stringify({
            "treeType": treeType,
            "parentId": REFFERAL_VALUES?.id || 2,
        })
    }).success((res) => {
        dispatch(setLoading(false));
    }).error((err) => {
        dispatch(setEmailError(err?.errorMessage));
        dispatch(setLoading(false));
    }).send();
}

//  country fetching api call
export const _getCountryCodeApi = (callback) => dispatch => {
    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "GET",
        endpoint: `/Settings/Countries`
    }).success((res) => {
        dispatch(setLoading(false));
        dispatch(setCountryData(res));
        if (res?.data?.length > 0 && callback) {
            callback(res?.data)
        }
    }).error((err) => {
        // dispatch(setEmailError(err?.errorMessage));
        dispatch(setLoading(false));
    }).send();
}

export default CustomerSlice?.reducer;

