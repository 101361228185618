import React, { forwardRef } from "react";
import Slider from "react-slick";
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import styled from "styled-components";

const SliderStyles = styled.div`
    &&
    svg.slick-arrow {
        color: #000 !important;
        font-size: 30px;
    }           
`;

const SlickSliderComp = forwardRef(({ children, ...props }, ref) => {
    const { firstTimeDisplay, arrowDisplay } = props;
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: firstTimeDisplay,
        slidesToScroll: firstTimeDisplay,
        nextArrow: <ChevronRight className="arrow_class" />,
        prevArrow: <ChevronLeft className="arrow_class" />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    // vertical: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // vertical: true,
                    dots: true
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // vertical: true,
                    dots: true
                }
            },
        ]
    };
    return (
        <SliderStyles>
            <div className="slider_values">
                <Slider ref={ref} {...settings}>
                    {children}
                </Slider>
            </div>
        </SliderStyles>
    );

})

export default SlickSliderComp;