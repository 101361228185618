import styled from "styled-components";

export const SingleProductStyle = styled.div`
/* padding-top: 213px; */
.learn-more-section a {
    color: #2a533f;
    font-weight: 600;
    border-bottom: 2px solid;
    &:hover {
        
    }
}

.breadcrumb-bar {
    padding: 192px 0 0;

    .breadcrumb-text {
        ul {
            background: transparent;
            padding-left: 0;

            li {
                &.active {
                    a {
                        font-weight: 700;
                    }
                }
                &:last-child { 
                    a:after {
                        display:none;
                    }
                }
                a {
                    color: #555555;
                    font-size: 24px;
                    padding: 0 30px 0 0;
                    line-height: 36px;
                    position: relative;

                    &::after {
                        position: absolute;
                        right: 8px;
                        content: ">";
                        font-size: 23px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}



.unique-logo {
    display: flex;
    margin: 50px 0;
    justify-content: space-evenly;
    text-align: center;
    .logo-unique {
        /* max-width: 20%;
        flex: 0 0 20%; */
        img {
            width: 100%;
            max-width: 179px;
        }
        p {
            letter-spacing: 0.07px;
            padding: 20px 0;
            max-width: 187px;
            margin: 0 auto;
            width: 100%;
            line-height: 20px;
        }
    } 
}
    
.single-product-section .container , .breadcrumb-bar .container , .client-review1 .container {
    max-width: 1292px;
    margin: 0 auto;
}
.left-side-text {
    max-width: 636px;
    width: 100%;

    p:last-child{
        margin: 0;
        padding: 0;
    }
}
.right-side-image img {
    max-width: 100%;
    width: 100%;
}
.single-product-section .left-side-text h2 {
    color: #2A533F;
}
.single-product-section .price-section {
    padding: 30px 0 0;
}
.single-product-section span.availabe-text {
    font-size: 26px;
    font-weight: 400;
}
.review-section ul li {
    padding: 0 11px 0 0;
    font-size: 28px;
    color: #F4BC61;
}
.review-section ul {
    display: flex;
    margin: 0;
}
.review-section ul li {
    list-style: none;
}
.single-product-section .price-section h3 {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    font-family: 'Helvetica Neue LT Std';
    margin-bottom: 16px;
}
span.text-review-text {
    color: #555555;
    font-size: 25px;
    margin-left: 15px;
}
.left-side-text h5 {
    color: #555;
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 15px;
}
.review-section {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
}
.right-side-image {
    box-shadow: 0px 4px 40px #0000001A;
    border-radius: 10px;
    background: #fff;
}
.product-details-now {
    padding: 20px 48px 30px;
}
.product-details-now h5 {
    color: #2A533F;
    font-family: 'Playfair Display';
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
}
.accordian-section .card {
    border-top: 1px solid #707070;
    border-bottom:none;
    border-left: none;
    background: transparent;
    border-right: none;
	    border-radius: 0;
}
.accordian-section button {
    color: #707070;
    font-size: 24px;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 500;
    padding: 20px 0;
	width:100%;
	text-align: left;
	position: relative;
    text-decoration:none;
}
.box-quality span {
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
}
.box-quality span {
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    width: 100%;
    display: block;
}
.quality-box i {
    padding: 5px 0 0;
}
input#quantity {
    margin-top:-2px;
}
.accordian-section .card .card-header {
    background: none;
    padding: 0;
	border-bottom: none;
}
.accordian-section h5 {
    margin: 0;
    padding: 0;
}
.accordian-section .card-body {
    padding: 0 0 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    font-family: 'Helvetica Neue LT Std';
}
.accordian-section button:hover {
    color: #2a533f;
    text-decoration: none;
}
/* .accordian-section button.btn.btn-link.collapsed::after {
    font-family: 'FontAwesome';
    right: 0;
    width: 100%;
    position: absolute;
    width: 20px;
    font-size: 20px;
    content: "\f067";
}
.accordian-section button.btn.btn-link::after {
	    content: "\f068";
		 font-family: 'FontAwesome';
			right: 0;
			width: 100%;
			position: absolute;
			width: 20px;
			font-size: 20px;
} */


.accordian-section button.btn.btn-link::after {
    background: url("/images/minusone.png") center center / 100% no-repeat;
    background-size: 26px;
}
.accordian-section button.btn.btn-link.collapsed::after {
    background: url("/images/plusone.png") center center / 100% no-repeat;
    background-size: 26px;
    position: absolute;
    top: 24px;
}

.accordian-section button.btn.btn-link:focus {
    box-shadow: none;
    outline: none;
    text-decoration: none;
}
.accordian-section button.btn.btn-link:focus {
    outline: none;
    box-shadow: none;
}
/* .accordian-section .card:last-child {
    border-bottom: 1px solid #707070;
} */
.accordian-section {
    margin: 45px 0 0;
}
.right-side-image h6 {
    font-size: 28px;
    font-weight: 600;
    font-family: 'Helvetica Neue LT Std';
    line-height: 28px;
    color: #39332E;
    padding: 40px 0 0;
}
.box-quality {
    border: 1px solid #707070;
    display: flex;
   
}
.oneTime h6 {
    margin: 0;
    padding: 0;
    color: #39332E;
    font-size: 22px;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 400;
    text-align: center;
    height: 45px;
}
.everyTime  h6 {
    margin: 0;
    padding: 0;
    color: #39332E;
    font-size: 2px;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 600;
    text-transform: capitalize;
    line-height: 22px;
    text-align: center;
}
.box-quality > div {
    padding: 0 10px;
    max-width: 33.3%;
    flex: 0 0 33.3%;
    text-align: center;
}
.everyTime  h6 span {
    font-size: 14px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-weight: 300;
}
@media (max-width:1024px){
    .everyTime  h6 span{
        font-size: 13px;
    }
}
@media (max-width:480px){
    .everyTime  h6 span{
        font-size: 11px;
    }
}
span.price-box {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Helvetica Neue LT Std';
    padding:0;
    display: flex;
    justify-content: center;
}
.oneTime .price-box {
    padding-top:10px;
}
.box-quality div.selected {
    background: #f4f0eb;
}
.everyTime {
    border-left: 1px solid rgb(112, 112, 112);
    /* border-right: 1px solid rgb(112, 112, 112); */
}
 .most-popular h6 {
    font-size: 18px;
    letter-spacing: 0.36px;
    color: rgba(255, 255, 255, 0.9);
    background: rgb(42, 83, 63);
    max-width: 279px;
    padding: 5px 0px 0;
    text-align: center;
    margin: 32px 0px 0px auto;
}

.btn-add-bag {
    max-width: 253px;
    background: #2A533F 0% 0% no-repeat padding-box;
    border-radius: 28px;
    color: #fff;
    height: 39px;
    width: 100%;
    color: #F4F0EB;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-self: center;
    padding-bottom: 9px;
}
.add-bag-btn {
    margin: 30px 0 0;
}
a.btn.btn-add-bag:hover {
    background: transparent;
    border: 1px solid #2A533F;
    color: #2A533F;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus{
	    background: transparent;
		box-shadow: none;
}
.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show>.btn-danger.dropdown-toggle:focus {
     background: transparent;
		box-shadow: none
}
.client-review-1 {
    padding: 70px 0px 30px;

    h2 {
        font-size: 50px !important;
    }
}
.client-review1 h2 {
    color: #2A533F;
}
.review-section-now ul {
    display: flex;
}
.review-section-now ul li {
    padding: 0 6px;
    list-style: none;
}
.review-section-now li i {
    color: #F4BC61;
    font-size: 31px;
}
.review-section-now {
    padding: 0px;
    display: flex;
    margin-bottom: 0.5rem;
}
.out-five-part h5 {
    color: #55555566;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Helvetica Neue LT Std';
}
span.out-text {
    color: #555555;
}
.profile-pic-client {
    background: #F4F0EB;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    text-align: center;
    color: #000;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 54px;
    font-weight: 600;
}
.client-view {
    display: flex;
}
.profile-pic-comment h3 {
    color: #555555;
    font-size: 24px;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 700;
}
.profile-pic-comment {
    padding: 0 0 0 40px;
    width: 76%;
}
.profile-pic-icon {
    justify-content: flex-end;
}
.rating-comment ul {
    display: flex;
    margin-bottom: 0;
}
.rating-comment ul li {
   list-style:none;
   padding:0 5px;
}
.rating-comment ul li i {
    color: #F4BC61;
    font-size: 20px;
}
.rating-comment {
    display: flex;
}
span.date {
    font-size: 20px;
    color: #555555;
    padding: 0;
    margin: -3px 6px 0;
}
span.circle-tick {
    border-radius: 50px;
    background: #2A533F;
    width: 18px;
    height: 18px;
    color: #fff;
    padding: 2px;
    font-size: 13px;
    margin-right: 10px;
}
.profile-pic-icon h6 {
    font-size: 20px;
    color: #555555;
}
.comment-part {
    padding: 0 16px 0 139px;
}
.comment-part h4 {
    line-height: 40px;
    font-size: 30px;
    color: #555555;
    font-family: 'Helvetica Neue LT Std';
}
.main-comment-part {
    border-top: 1px solid #55555566;
    padding: 50px 0 0;
    margin-bottom: 50px;

    .comment-part  h4{
        font-size: 24px;
    }
}
.sort-by-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sort-part-button a {
    border: 1px solid #707070;
    border-radius: 28px;
    max-width: 260px;
    height: 57px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 36px;
    font-size: 24px;
    font-weight: 700;
    color: #555;
}
.sort-part-button a:hover {
    border: 1px solid #707070;
    background:#707070;
	color:#fff;
}
span.sort-text {
    font-size: 26px;
    font-weight: 400;
}

.swiper-button-next:after {
    color: #555555;
    font-size: 22px;
    font-weight: 600;
    background: #FFFFFFCC;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    right: 23px;
    position: absolute;
    border-radius: 5px;
}

.swiper-button-prev:after{
    color: #555555;
    font-size: 22px;
    font-weight: 600;
    background: #FFFFFFCC;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    left: 23px;
    position: absolute;
    border-radius: 5px;

}
.product-details-now .box-quality h6 {
    padding: 10px 0 0 !important;
    font-size: 20px;
    margin-bottom: 0;
}
.quality-box input {
    background: transparent !important;
    max-width: 38px;
}
.review-section span {
    font-size: 34px;
    color: #000;
}
.unique-logo.single-unique-logo {
    margin: 100px auto 0;
    max-width: 1358px;
    width: 100%;
}
.unique-logo.single-unique-logo p {
    font-size: 18px;
    letter-spacing: 0.09px;
    color: #555555;
}
.unique-logo.single-unique-logo .logo1 {
    max-width: 117px;
    margin: 0 auto;
    height: 117px;
}
.review-section-now span {
    font-size: 38px;
    color: #000;
    margin-bottom: 5px;
    padding: 4px 0 0;

}
.rating-comment span {
    color: #000;
}
.accordian-section p {
    font-size: 16px;
}
.single-product-section {
    margin: 30px 0;
}
.quality-box .input-group {
    max-width: 111px;
    padding: 0px;
}
input#quantity {
    padding: 0 !important;
}
.single-product-section .left-side-text h2 {
    word-wrap: break-word;
}
@media (max-width:1439px){
.single-product-section .container,  .breadcrumb-bar .container,  .client-review1 .container {
    max-width: 1140px;
    margin: 0px auto;
}
.single-product-section {
    margin: 30px 0;
}
.unique-logo.single-unique-logo {
    max-width: 1140px;
}
.profile-pic-comment {
    width: 73%;
}
 .product-details-now {
    padding: 20px 18px 30px;
}
 .btn-add-bag {
    max-width: max-content;
    font-size: 16px;
    /* padding: 8px 13px; */
    padding-bottom: 6px;
}

 span.price-box {
    font-size: 22px;
}
.most-popular h6 {
    font-size: 18px;
    padding: 5px 20px;
}
}

@media (max-width:1280px){
.single-product-section .container,  .breadcrumb-bar .container,  .client-review1 .container {
    max-width: 1000px;
}
.unique-logo.single-unique-logo {
    max-width: 1000px;
    flex-wrap: wrap;
}
.unique-logo {
    justify-content: center;

}
.logo-unique {
    max-width: 25%;
    flex: 0 0 25%;
}
.profile-pic-comment {
    width: 70%;
} .most-popular h6 {
    max-width: 237px;
}
}

@media (max-width:1199px){
.single-product-section .container, .breadcrumb-bar .container, .client-review1 .container {
    max-width: 100%;
}
.left-side-text h2 {
    font-size: 30px;
}
.single-product-section .price-section h3 {
    font-size: 23px;
    margin-bottom: 0;
}
.single-product-section .price-section {
    padding: 0;
}
.review-section span {
    font-size: 26px;
}
.left-side-text h5 {
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 0;
}
.accordian-section button {
    font-size: 20px;
}
 .accordian-section button.btn.btn-link.collapsed::after {
    background: url("/images/plusone.png") center center / 21px no-repeat;
    top: 20px;
}
.accordian-section button.btn.btn-link::after {
    background: url("/images/minusone.png") center center / 21px no-repeat;
    top: 14px;
}
 .product-details-now h5 {
    font-size: 24px;
    line-height: normal;
}
 .right-side-image h6 {
    font-size: 20px;
    line-height: normal;
    padding: 0;
}
 .most-popular h6 {
    max-width: 284px;
    font-size: 16px;
}
.client-view {
    justify-content: space-between;
}
 .profile-pic-client {
    width: 80px;
    height: 80px;
    font-size: 38px;
}
.comment-part h4 {
    line-height: normal;
    font-size: 23px;
}
.profile-pic-comment {
    padding: 0px 0px 0px 25px;
}
.comment-part {
    padding: 10px 0;
}
.breadcrumb-bar {
    padding: 170px 0px 0px;
}
.unique-logo.single-unique-logo {
    max-width: 100%;
    padding: 0 30px;
}
.most-popular h6 {
    padding: 10px 0;
}
}

@media(max-width:1120px){
.most-popular h6 {
    max-width: 100%;
}
.product-details-now .box-quality h6 {
    font-size: 16px;
}
 span.price-box {
    font-size: 20px;
}
 .oneTime .price-box {
    padding-top: 0px;
}
.breadcrumb-bar .breadcrumb-text ul li a {
    font-size: 18px;
}
}

@media(max-width:991px){
.single-product-section .col-md-7 {
    max-width: 100%;
    flex: 0 0 100%;
    width: 100%;
}
.left-side-text {
    max-width: 100%;
    width: 100%;
}
 .review-section {
    margin-bottom: 8px;
}
.single-product-section  .col-md-5 {
    width: 100%;
    max-width: 60%;
    flex: 0 0 60%;
    margin: 0 auto;
}
.profile-pic-comment {
    padding: 0 0 0 10px;
    width: 50%;
}
 .accordian-section {
    margin: 0;
}
.single-product-section {
    margin: 0;
}
.breadcrumb-bar {
    padding: 140px 0px 0px;
}
.out-five-part h5 {
    font-size: 25px;
}
 .review-section-now {
    padding: 0;
}
 .review-section-now span {
    font-size: 24px;
}
 .client-review-1 span.text-review-text {
    font-size: 20px !important;
    margin-top: -4px;
}
.client-review-1 {
    padding: 20px 0;
}
}

@media(max-width:767px){
.unique-logo.single-unique-logo {
    padding: 0px 20px;
    margin: 20px 0;
}
 .comment-part h4 {
    font-size: 18px;
}
 .logo-unique {
    max-width: 33%;
    flex: 0 0 33%;
}
 .single-product-section .col-md-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
}
.client-view {
    justify-content: flex-start;
    flex-wrap: wrap;
}
.profile-pic-comment {
    padding: 0px 0px 0px 16px;
}
.profile-pic-icon {
    padding: 20px 0   0;
}
.review-section span {
    font-size: 18px;
}
.main-comment-part {
    padding: 20px 0px 0px;
}
 .breadcrumb-bar .breadcrumb-text ul li a {
    padding: 0px 20px 0px 0px;
}
}
@media(max-width:568px){
.breadcrumb-bar {
    padding: 72px 0px 0px;
}
 .breadcrumb-bar .breadcrumb-text ul li a {
    font-size: 12px;
}
 .breadcrumb-bar .breadcrumb-text ul li a::after {
    font-size: 12px;

}
.left-side-text h2 {
    font-size: 26px;
}
.single-product-section .price-section h3 {
    font-size: 20px;
}
.single-product-section span.availabe-text {
    font-size: 16px;
}
.left-side-text h5 {
    font-size: 16px;
    line-height: 24px;
}
.accordian-section button {
    font-size: 18px;
    padding: 10px 0;
}
 .accordian-section button.btn.btn-link::after {
    top: 5px;
}
 .accordian-section button.btn.btn-link.collapsed::after {
    top: 9px;
}
.product-details-now h5 {
    font-size: 22px;
}
.logo-unique {
    max-width: 48%;
    flex: 0 0 48%;
}
 .sort-part-button a {
    height: 43px;
    font-size: 16px;

}
.profile-pic-client {
    width: 40px;
    height: 40px;
    font-size: 18px;
}
 .profile-pic-comment h3 {

    font-size: 16px;
}
 .profile-pic-icon {
    padding: 20px 0px 0px;
    width: 100%;
}
 .unique-logo.single-unique-logo .logo1 {
    max-width: 78px;
    height: 78px;
}
 .unique-logo .logo-unique img {
    max-width: 83px;
    margin: 10px 0 0;
}
 span.sort-text {
    font-size: 20px;
}
.out-five-part h5 {
    font-size: 20px;
}
.unique-logo.single-unique-logo p {
    font-size: 16px;
    letter-spacing: -0.5px;

}
.profile-pic-icon h6 {
    font-size: 16px;
}
.rating-comment span {
    font-size: 16px;
}
span.date {
    font-size: 16px !important;
}
.comment-part h4 {
    font-size: 16px;
}
.review-section-now span {
    font-size: 16px !important;
}
.client-review-1 span.text-review-text {
    font-size: 14px !important;
}
 .accordian-section p {
    font-size: 16px;
    line-height: 24px;
}
 .main-comment-part {
    margin-bottom: 10px;
}
}

.cursor_style {
    cursor:pointer;
}
`;