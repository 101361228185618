import { useOutletContext } from "react-router-dom";
import withoutAuth from "../../services/Auth/withoutAuth";
import LoginForm from "./components/LoginForm"
import useLoginRegisterHook from "./components/LoginRegister.hook";
import MainLoginRegisterStyle from "./style/MainLoginRegister.style";

const LoginRegisterPage = (props) => {
    const { cartValues } = useOutletContext();
    const { LoginValidationSchema, _onHandleLoginSubmit, errorMsg, loginState } = useLoginRegisterHook({
        ...props, cartValues
    });
    return (
        <MainLoginRegisterStyle>
            <div className="maine_loginRegister">
                <LoginForm {...{ errorMsg, LoginValidationSchema, loginState, _onHandleLoginSubmit, ...props }} />
            </div>
        </MainLoginRegisterStyle>
    )
}

export default withoutAuth(LoginRegisterPage);