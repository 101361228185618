import styled from "styled-components";

const AboutSectionTwoStyleCss = styled.div`

.about_second_container  {
        
    .ourTeam_section {
        text-align: center;
        margin: 80px 0px 100px;
        display: flex;
        flex-direction: column;
        @media(max-width:1199px){
            margin: 40px 0px;
        }
        @media(max-width:767px){
            margin:15px 0px;
        }

        h1 {
            font-size: 60px;
            font: normal normal bold 60px/70px Playfair Display;
            color: #2A533F;
            margin-bottom: 0;
        }
        h3 {
            font-size: 24px;
            font: normal normal normal 20px/50px Helvetica;
            color: #39332E;
            padding: 12px 0 68px;
            @media(max-width:1199px){
                padding: 12px 0;
        }
        @media(max-width:767px){
                padding: 12px 0;
                font: 20px / 31px Helvetica;
        }
        }
            
        .allexecutive {
            display: flex;
            margin: 0 auto;
            flex-wrap: wrap;
            gap: 40px;
            width: 100%;
            max-width: 1152px;
            @media(max-width:1199px){
                gap: 20px;
                width: 100%;
                max-width: 100%;
                justify-content: center;
                        }
            .member_detail {
                width: 357px;
                height: 100%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 20px 70px #0000001A;
                border-radius: 10px;

                .executive_image {
                    width: 357px;
                    height: auto;
                    @media(max-width:1199px){
                        height: auto;
                        }

                    img {
                        width: 100%;
                        height: 100%;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        object-fit: cover;
                    }
                }
                .executive_bio {
                    text-align: left;
                    padding: 17px 25px;
                    @media(max-width:1199px){
                        padding: 15px;
                        }

                    h3,p {
                        margin: 0;
                    }
                    h3 {
                        font: normal normal bold 24px/34px Helvetica Neue LT Std;
                        color: #39332E;
                        padding: 0;
                    }
                    p{
                        &:first-child {
                            font-size: 20px;
                            font: normal normal medium 20px/24px Helvetica Neue LT Std;
                            letter-spacing: 0.1px;
                            color: #555555;
                        }
                    }
                    .education_plus {
                        display: flex;
                        justify-content: space-between;
                        p {
                            font-size: 16px;
                            &.first_svg {
                                transform: rotate(45deg);
                                margin-bottom: 10px;
                                cursor: pointer;
                            }
                            @media(max-width:1199px){
                                font-size: 14px;
                        }
                            
                        }
                    }
                }
            }
        }
    }
}
.frequently_asked_question {
    background: #F4F0EB66 0% 0% no-repeat padding-box;
    padding-bottom: 130px;
    @media(max-width:991px){
        padding-bottom:50px;
    }
    .container {
            max-width: 1152px;
            
        }
    .frequantly_heading {
        text-align: center;
        padding: 70px 0 0;
        @media(max-width:1199px){
            padding:0;
                        }
        h2{
            margin-bottom:14px;
            font: normal normal bold 60px/70px Playfair Display;
            color: #2A533F;
            padding: 45px 0 0;
            @media(max-width:1199px){
          line-height: normal;
                        }
        }
        h3 {
            font: normal normal normal 24px/50px Helvetica;
            color: #39332E;
        }
    }
    .frequantlyAccordian {
        h3 {
            font: normal normal bold 30px/37px Helvetica;
            color: #39332E;
            margin-top: 50px;
            @media(max-width:767px){
                font: bold 22px / 37px Helvetica;
                   margin-top: 20px;
            }
        }
        .card {
             border-top: none;
             & button {
                text-decoration: none;
                 }
                 button.btn.btn-link:active {
                color: #2a533f;
                font-weight: 600;
                    }
                    button.btn.btn-link {
                    color: #2a533f;
                    font-weight: 600;
                }
                button.btn.btn-link.collapsed {
                color: rgb(112, 112, 112);
                font-weight: 400;
            }
    }
  
}

}
.certificate_of_analysis {
    position: relative;
    background-size:cover;
    height: 601px;
    .image {
        width:100%;
        height: 601px;
        img {
            width:100%;
            height:100%;
        }
    }
    .content_of_certificate {
        text-align: right;
         right: 0px;
        padding: 60px 0;
        h3 {
            font-size: 60px;
            font: normal normal bold 60px/70px Playfair Display;
            color: #2A533F;
            text-shadow: 5px 9px 30px #FFFFFFE6;
            margin-bottom: 10px;
        }
         button{
            &:hover {
		background: transparent;
        color: #2A533F;
        border:2px solid #2A533F;
	     }
        }
    }
}
@media  (max-width:1500px){
.about_first_container .feature_launch_product {
    width: 100%;
    max-width: 1150px;
}
}

@media  (max-width:1439px){
.about_second_container .ourTeam_section .allexecutive .member_detail {
    width: 100%;
    max-width: 30%;
}
.about_second_container .ourTeam_section .allexecutive .member_detail .executive_image {
    width: 100%;
}
 .about_second_container .ourTeam_section h1 {
    font-size: 45px;
}
.certificate_of_analysis {
    height: 480px;
}
.frequently_asked_question .frequantly_heading h2{
    font-size: 45px;
}
.certificate_of_analysis .content_of_certificate h3{
    font-size: 45px;
}
}

@media  (max-width:1280px){
.charitable_discription {
    max-width: 1000px;
}
}

@media(max-width:991px){
    .about_second_container .ourTeam_section .allexecutive .member_detail {
    max-width:48%;
} 
}
@media(max-width:767px){
    .frequently_asked_question .frequantly_heading h2{
    font-size:26px;
}
.certificate_of_analysis .content_of_certificate h3{
    font-size: 26px;
}
.accordian-section button {
    font-size: 20px;
}
.accordian-section button.btn.btn-link.collapsed::after {
    width: 20px;
    height: 20px;
}
.accordian-section button.btn.btn-link::after {
    width: 20px;
    height: 20px;
}
.certificate_of_analysis {
    height: 270px;
}
.about_second_container .ourTeam_section h1 {
    font-size: 30px;
}
}

@media(max-width:568px){
.about_second_container .ourTeam_section .allexecutive .member_detail {
    max-width: 78%;
}
.frequently_asked_question .frequantly_heading h2 {
    font-size: 22px;
}
 .about_second_container .ourTeam_section h1 {
    font-size: 22px;
}
.about_second_container .ourTeam_section .allexecutive .member_detail .executive_bio h3 {
    font: bold 22px / 24px "Helvetica Neue LT Std";

}
 .frequently_asked_question .frequantly_heading h3 {
    font: 18px / 24px Helvetica;
}
.accordian-section button {
    font-size: 18px;
}
.certificate_of_analysis button {
    font-size: 16px;
    max-width: 150px;
}
}
`;
export default AboutSectionTwoStyleCss;