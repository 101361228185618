import { useOutletContext } from "react-router-dom";
import ViewCart from "./components/ViewCart";
import useViewCartHook from "./components/ViewCart.hook";

const Cart = (props) => {
    const { setCartValues, cartValues } = useOutletContext();
    const data = useViewCartHook({ cartValues });
    return (
        <>
        
            <ViewCart {...{ setCartValues, ...data, ...props }} />
        </>
    )
}

export default Cart;