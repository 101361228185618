import styled from 'styled-components'

export const EnrollmentStyle = styled.div`
.linear-form {
    padding: 200px 0 100px;
    .MuiBox-root {
    max-width: 1500px;
    margin: 0 auto;
}
span.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    display: block;
    border-color: #295340;
    border-top-style: solid;
    border-top-width: 11px;
}
span.MuiStepLabel-root.MuiStepLabel-horizontal {
    flex-wrap: wrap;
    margin: 24px 0px 0px;
    justify-content: center;

}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active {
    color: #295340;
}
.linear-form  .MuiStepLabel-iconContainer > svg {
    position: relative;
    border: 4px solid var(--blue);
    font-size: 40px;
    fill: #fff;
    border-radius: 50%;
    background: #fff;
    z-index: 1;
}
.linear-form  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root{
    width: 36px;
    height: 36px;
}
.linear-form  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root {
    width: 30px;
    height: 30px;  
}
.linear-form  .MuiStepLabel-label {
    text-align: center;
    padding-top: 10px;
    font-size: 16px;
    text-transform: uppercase;
}
.linear-form  .MuiStepConnector-horizontal {
    top: 15px;
    left: calc(-50% + 14px);
    right: calc(50% + 14px);
    flex: 1 1 auto;
    position: absolute;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root {
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 20px;
    background: #fff;
}
span.MuiStepLabel-label.Mui-disabled {
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
}
span.MuiStepLabel-label {
    position: relative;
    top: 10px;
    font-size: 18px;
}
span.MuiStepLabel-label.Mui-active {
    text-align: center;
    text-transform: uppercase;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
    margin: 50px auto;
    max-width: 82%;
    border-radius: 15px;
    box-shadow: 0 0 10px #ddd;
    padding: 40px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 .container {
    margin-top: 0 !important;
}
.email_address_field input {
    height: 16px;
    border: 1px solid #ddd;
}
.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
    background: transparent;
}

.pack-image img {
    height: 200px;
}

.MuiStepper-root.MuiStepper-horizontal {
    position: relative;
    .my-step-cstm {
        width: 100%;
        position: relative;
    }
}



.MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {
    position: absolute;
    top: 35px;
    left: 50%;    
    transform: translateX(-50%);
    width: 100%;
}

.MuiStep-root.MuiStep-horizontal {
    width: 100%;
    position: relative;
    z-index: 2;
}

/* .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(2) {
    width: 20%;
    left: 140px;
}

.MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(4) {
    width: 20%;
    left:20%;
    transform: translateX(-38%);
}

.MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(6) {
    width: 20%;
    left: 45%;
    right: 200px;
} */

.MuiStep-root.MuiStep-horizontal svg {
    fill: #fff;
    color: #000;
    stroke: #000;
    stroke-width: 1px;
}
.email_address_field span {
    color: red;
    position: relative;
    top: -16px;
}

.user-deatils .md-3-field {
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom:20px;
}
.user-deatils  .row-form {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}
.user-deatils  label[data-shrink=false]+.MuiInputBase-formControl {
    border-radius: 25px;
    height: 50px !important;
}

form.user-deatils label {
    top: 7px;
    color: #555;
    font-size: 14px;
}
span.MuiStepLabel-iconContainer.Mui-disabled svg circle {
    display: none;
}
span.MuiStepLabel-iconContainer.Mui-active svg circle {
    display: none;
}
 .linear-form svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active {
    background: #fff;
}
.errors-px span {
    color: red;
    font-size: 12px;
}
.roproducts-step .products {
    text-align: center;
}
.roproducts-step .products .pack-image {
    margin-bottom: 12px;
}
.pack-price {
    font-size: 18px;
}
.roproducts-step  h3 {
    font-weight: bold;
}
form.user-deatils button {
    margin-right: 10px;
}
.pack-both {
    margin: 20px 0;
    text-align: center;
}
.pack-both button{
  margin-right: 10px;
}
.roproducts-step button {
    background: transparent;
    border: 1px solid #295340;
    color: #000;
}
/* .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {
    max-width: 82%;
    left: 130px;
} */
}

.linear-form form .row-form {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    gap: 15px;
    margin-bottom: 15px;
}
.linear-form form .row-form .md-3-field {
    max-width: 50%;
    flex: 0 0 50%;
}
.linear-form form .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    background: transparent;
}
.linear-form form  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    background: transparent;
}
.linear-form form  .MuiFormControl-root.MuiTextField-root input {
    border: 1px solid #ddd !important;
    height: 15px;
}
.linear-form form label {
    top: -1px;
}
.linear-form form .row-form select {
    width: 100%;
    border: 1px solid #ddd;
    height: 50px;
    padding: 0 8px;
    color: #000;
}
.products_section .row {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 20px;

}
.products h3 {
    color: #000;
    line-height: 29px;
    padding: 20px 0 0px;
    height: 115px;
}
.pack-price,.express-shipping {
    margin-bottom: 8px;
}
.products {
    max-width:32%;
    flex: 0 0 32%;
    margin-bottom: 0;
    box-shadow: 0 0 10px #ddd;
    padding: 17px 10px;
}
 .linear-form span.MuiStepLabel-label {
    text-align: center;
}
.linear-form .select {
    max-width: 50%;
    flex: 0 0 50%;
}
.linear-form .all-products img {
    padding: 0 20px;
}
.order-summary h2 {
    font-size: 24px;
    margin: 30px 0;
}
.order-data tr.all-products {
    box-shadow: 0 0 10px #ddd;
    padding: 10px;
    display: flex;
    flex-wrap: nowrap;
}
.order-data table {
    width: 100%;
}
tr.all-products {
    margin-bottom: 20px;
    display: block;
}
.product-button-back {
    margin: 20px 0 0;
    text-align: center;
}
.products_section .products button {
    width: 100% !important;
    margin: 0 !important;
}
td.products-details-order {
    max-width: 70%;
    flex: 0 0 70%;
}
td.table-product {
    max-width: 20%;
    flex: 0 0 20%;
}
.userDetails_section table tr {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
}
.userDetails_section table tr td:first-child {
    font-weight: 600;
}
.not-found {
    text-align: center;
    font-weight: 600;
}
.userDetails_section table tr td:nth-child(2) {
    padding: 0 10px;
}
.userDetails_section table {
    width: 100%;
    box-shadow: 0 0 10px #ddd;
}
.autoship-now {
    display: flex;
    width: 100%;
    padding: 0 10px;
    align-items: center;
    margin-bottom: 20px;
}
.autoship-now label {
    font-weight: 600;
    padding-right: 10px;
}
select#autoship_selection {
    width:59%;
    height: 34px;
    border-radius: 10px;
}
.products_section .product-button-back button {
    margin: 2px 10px;
}

.quality-box {
    .input-group {
        margin: 0 auto;
        margin-bottom: 10px;
    }
}

.checkout_summery  {
    .checkout {
        padding: 0;
        .container {
            max-width: 100%;
        }
    }
    .checkout-left {
        max-width: 50%;
        flex: 0 0 50%;
        h2 {
            font-size: 25px;
            margin-bottom: 20px;
        }
    }
    .order-summary {
        position: relative;
        right: 0px;
        max-width: 60%;
        flex: 0 0 48%;

        h2 {
            font-size: 24px;
            margin: 0p;
        }
    }
    .order-data {
        width: 100%;
        height: auto;
    }
    .checkout-main {
        gap: 30px;
        @media(max-width:767px){
            position: relative;
            .place-order {
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }
    }
    .order-data tr.all-products {
        box-shadow: none;
    }
    td.products-details-order {
        max-width: 45%;
        flex: 0 0 45%;
    }
    .price-calulate div {
        margin: 0px 0px 4px;
    }
    .shipping-form input {
        border: none !important;
    }
    .coupon {
        margin: 30px 40px 5px;
    }
    .product-button-back {
        display: flex !important;
    }
    .checkout-left form div.field-class:first-child,  .checkout-left form div.field-class:nth-child(2) {
        max-width: 100%;
        flex: 0 0 48%;
    }
    .checkout-left .field-class.neweletter-subscrib {
        font-size: 20px;
    }
    .express-shipping.ship-address {
        font-weight: 600;
        margin-bottom: 20px;
    }
    .shipping-form h3 {
        padding-bottom: 11px;
        margin-bottom: 14px;
        font-size: 23px;
    }
    .shipping.securely-account {
        margin: 0px;
        border-bottom: 1px solid rgb(196, 196, 196);
        padding-bottom: 16px;
    }
    .shipping {
        margin: 15px 0px 30px;
    }
    .shipping.sezzle-chekbox {
        padding-bottom: 16px;
    }
    .place-order button {
        max-width: 228px !important;
        width: 100% !important;
        margin: 60px 22px 60px 0px;
    }
    .price-calulate div {
        font-size: 22px;
        line-height: 33px;
    }
}
.products_section  .checkout_summery .order-summary {
    position: unset;
    right: 0px;
    max-width: 100%;
    flex: unset;
}
 .products_section span.price-note {
    font-size: 16px;
    font-weight: bold;
    font-family: "Helvetica Neue LT Std";
}
tr.all-products td img {
    object-fit: cover;
}
.products_section .mainorder_summery {
    position: relative;
    max-width: 50%;
    flex: 0 0 50%;
    position: sticky;
    top: 40px;
    height: 100%;
}
.products_section   .products-details-order h4 {
    font-size: 16px;

}
.products_section  span.item-qty {
    font-size: 16px;
}

.checkout_summery .card-details div:first-child {
    max-width: 100%;
    flex: 0 0 100%;
}
.checkout_summery .card-details {
    margin: 20px 0px 0px;
    display: flex;
    flex-wrap: wrap;
}
.checkout_summery .card-details .textField {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 6px 10px;
}
.email_address_field {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}
p.MuiFormHelperText-root.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained {
    color: red;
}
.checkout_summery .checkout .container {
    max-width: 100%;
}
.checkout_summery .checkout-left {
    max-width: 50%;
    flex: 0 0 50%;
}
.checkout_summery .order-summary {
    position: relative;
    right: 0px;
    max-width: 60%;
    flex: 0 0 48%;
}
.checkout_summery   .order-data {
    width: 100%;
    height: auto;
}
.checkout_summery   td.products-details-order {
    max-width:79%;
    flex: 0 0 79%;
}
.checkout_summery  .checkout-main {
    gap: 30px;
}
.checkout_summery .order-summary h2 {
    font-size: 24px;
    margin: 0px;
}
.checkout_summery .order-data tr.all-products {
    box-shadow: none;
}
.checkout_summery  .price-calulate div {
    margin: 0px 0px 4px;
}
.checkout_summery .coupon {
    margin: 30px 40px 5px;
}
.checkout_summery .card-details {
    border: none;
}
.checkout_summery .product-button-back {
    display: flex;
}
.checkout_summery  .checkout-left form div.field-class:first-child,  .checkout_summery .checkout-left form div.field-class:nth-child(2) {
    max-width: 100%;
    flex: 0 0 48%;
} .checkout_summery .price-calulate div {
    font-size: 19px;
    line-height: 25px;
}
.checkout_summery .checkout-left .field-class.neweletter-subscrib {
    font-size: 20px;

}
.checkout_summery .express-shipping.ship-address {
    font-weight: 600;
    margin-bottom: 20px;
}
.checkout_summery  .shipping-form h3 {
    padding-bottom: 11px;
    margin-bottom: 14px;
    font-size: 23px;

}
.price-calulate {
    padding: 0px;
}
.linear-form span.MuiStepLabel-label {
    text-transform: uppercase;
}
.checkout_summery  .shipping.securely-account {
    margin: 0px;
    border-bottom: 1px solid rgb(196, 196, 196);
    padding-bottom: 16px;
}
.checkout_summery .shipping {
    margin: 15px 0px 30px;
}
.checkout_summery .shipping.sezzle-chekbox {
    padding-bottom: 16px;
}
.checkout_summery  .place-order button {
    max-width: 228px !important;
    width: 100% !important;
    margin: 60px 22px 60px 0px;
}
.checkout_summery   .price-calulate div {
    font-size: 19px;
    line-height: 25px;
    padding: 0 10px 10px;
}

.main_otp_div {

    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    .otp-field {
        width: 38px;
        margin-right: 10px;
        padding-left: 12px;
        height: 40px;
    }
    }
    .linear-form.checkout_main_div_add .product-button-back button {
    margin-left: 0 !important;
}
.card-details .textField {
    border-radius: 6px;
    margin-bottom: 10px;
    max-width: 100%;
    flex: 0 0 100%;
}

form .product-button-back {
    display: flex;
    justify-content: center;
    gap: 10px;
}

    @media(max-width:1366px){
     .linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(2) {
    left: 119px;
}
}
@media(max-width:1365px){
.products {
    max-width: 31%;
    flex: 0 0 31%;
}
 .autoship-now {
    flex-wrap: wrap;
}
 select#autoship_selection {
    width: 100%;
    height: 40px;
    margin: 15px 0 0;
}
 .products h3 {
    height: 120px;
}
}
@media(max-width:1240px){
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(2) {
    left: 94px;
}
 .linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {
    max-width: 80%;
    left: 130px;
}
}



@media(max-width:1199px){
 .linear-form span.MuiStepLabel-label {
    font-size: 16px;
}
.linear-form {
    padding: 150px 0px 100px;
}
.products {
    max-width: 48%;
    flex: 0 0 48%;
}
 .checkout_summery .checkout-left {
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
}
.checkout_summery .order-summary {
    max-width: 100%;
    flex: 0 0 100%;
}
.checkout-main {
    display: flex;
    flex-wrap: wrap;
}
 .products_section .mainorder_summery {
    max-width: 100%;
    flex: 0 0 100%;
}
}


@media(max-width:1050px){
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {

    left: 100px;
}
.linear-form .MuiStep-root.MuiStep-horizontal {
    padding: 0;
}
 .products h3 {
    height: 126px;
}
.products_section .products {
    max-width: 100%;
    flex: 0 0 100%;
}
}

@media(max-width:991px){
.checkout_summery input[type="checkbox"] , .checkout_summery input[type="radio"]  {
    height: 20px;
}
.checkout_summery .checkout-left .field-class.neweletter-subscrib {
    font-size: 16px;
}
.credit-card {
    font-size: 19px;
}
.checkout_summery .checkout-main {
    gap: initial;
}
 .shipping, .express-shipping {
    font-size: 19px;
}
.place-order button {
    font-size: 18px;
}
.order-summary h2 {
    margin: 3px 0px;
}
}
@media(max-width:900px){
.linear-form span.MuiStepLabel-label {
    font-size: 14px;
}
 .linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(2) {
    left: 76px;
}
.products_section .container {
    padding: 0;
}
 .checkout_summery .place-order button {
    margin: 30px 0px 0;
}
 .shipping, .express-shipping {
    font-size: 18px;
}
}



@media(max-width:767px){
 .linear-form .MuiStepper-root.MuiStepper-horizontal {
    flex-wrap: wrap;
}
.linear-form .MuiStep-root.MuiStep-horizontal {
    /* max-width: 33.3%; */
}
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {
    max-width: 67%;
    left: 130px;
}
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(4) {
    width: 11%;
}
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(2) {
    left: 111px;
}
.MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(4) {
    top: 121px !important;
    width: 100%;
    height: 30px;
}
.MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(5) {
    top: 121px !important;
    width: 100%;
    height: 30px;
}
.MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(6) {
    top: 121px !important;
    width: 100%;
    height: 30px;
}
 .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(6) {
    width: 39% !important;
}
 .linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(4) {
    width: 29%;
    left: 200px;
}
 .linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {
    max-width: 63%;
    left: 130px;
}
.linear-form .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
    max-width: 100%;
    padding: 15px;
}

.checkout_summery .checkout-main {
    gap: 0;
}
.products {
    padding: 20px 10px;
}
.linear-form form .row-form {
    flex-wrap: wrap;
}
 .linear-form form .row-form .md-3-field {
    max-width: 100%;
    flex: 0 0 100%;
}
.linear-form .select {
    max-width: 100%;
    flex: 0 0 100%;
}
}
@media(max-width:700px){
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(4) {
    width: 45%;
}
}

@media(max-width:615px){
 .linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(2) {
    left: 86px;
}
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {
    max-width: 59%;
}
}

@media(max-width:568px){
.linear-form {
    padding: 90px 0;
}
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(4) {
    width: 57%;
}
 .products {
    max-width: 100%;
    flex: 0 0 100%;
}
.products_section button {
    max-width: 190px;
    width: 100%;
}
 .products h3 {
    height: auto;
}
 .checkout_summery .checkout-left form div.field-class:first-child,  .checkout_summery .checkout-left form div.field-class:nth-child(2) {
    max-width: 100%;
    flex: 0 0 100%;
}
.checkout_summery .shipping-form h3 {
    font-size: 18px;
}
.checkout_summery .order-data tr.all-products {
    box-shadow: none;
    flex-wrap: wrap;
}
.checkout_summery td.products-details-order {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 10px;
}
span.delete-icon {
    margin: 0 0 10px;
}
td.table-product {
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
}
 td.products-details-order {
    max-width: 100%;
    flex: 0 0 100%;
}
 .item-icon {
    padding-right: 0px;
}
}


@media(max-width:520px){
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(2) {
    left: 66px;
}
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {
    max-width: 58%;
}
}


@media(max-width:490px){

.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal:nth-child(6) {
    width: 20%;
    left:14%;
    right: 160px;
}
.linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {
    max-width: 52%;
}

.linear-form button {
    font-size: 16px;
}
p {
    font-size: 16px !important;

}
}

@media(max-width:400px){
    .linear-form span.MuiStepLabel-label {
    font-size: 12px;
}
 .linear-form .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root.MuiStepConnector-horizontal {
    max-width: 43%;
}
}
.mainorder_summery {
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: absolute;
    right: 0;
}
span.checkbox-label-text {
    margin-left: 8px;
}

.isDistributer_main_dialog div#alert-dialog-title {
    padding: 0;
    background: #102219;
}
.isDistributer_main_dialog div#alert-dialog-title h2.MuiTypography-root.MuiTypography-h6 {
    color: #fff;
    text-align: left;
    text-transform: uppercase;
    font-family: 'Helvetica Neue LT Std';
    padding: 0 10px;
}
.MuiPaper-root.MuiDialog-paper.isDistributer {
    overflow-y: initial;
}
.isDistributer_main_dialog svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    position: absolute;
    opacity: 1 !important;
    top: 0px;
    right: 0 !important;
}
button.MuiButtonBase-root.MuiIconButton-root.close_button {
    position: absolute;
    right: 4px;
    top: -10px;
    background: #fff;
    z-index: 9999;
}
div#alert-dialog-title {
    background: #10281c;
}




`;
