import { useEffect } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import EnrollmentFirstSection from "./components/EnrollmentFirstSection"
import EnrollmentSecondSection from "./components/EnrollmentSecondSection";

const EnrollmentPage = (props) => {
    const { navigate } = useOutletContext();
    const location = useLocation();

    useEffect(() => {
        if (location?.hash) {
            document.getElementById(location?.hash)?.scrollIntoView();
        }
    }, [location?.hash])
    return (
        <>
            <EnrollmentFirstSection />
            <EnrollmentSecondSection {...{ navigate, scrollId: location?.hash }} />
        </>
    )
}
export default EnrollmentPage;