import { useEffect } from "react";
import { useState } from "react"
import _ from "lodash";

const useSingleProductHook = (props) => {

    const { id, setOpenMiniCart, ProductsDetail } = props;
    const [qty, setQty] = useState(1);
    const [singleProductsDetail, setSingleProductDetail] = useState({});
    const [selectedPrice, setSelectedPrice] = useState('oneTime');

    function _IncreaseDecreaseFunction(section) {
        if (qty > 0) {
            let QTY = "";
            if (section === "sub") {
                QTY = (qty - 1) > 0 ? qty - 1 : 1;
            } else {
                QTY = qty + 1;
            }
            setQty(QTY);
        }
    }

    useEffect(() => {
        window.scroll(0, 0);
        if (id) {
            const DISPLAY_DATA = _.find(ProductsDetail, { itemId: +id }) || {};
            setSingleProductDetail(DISPLAY_DATA);
        }
    }, [id, ProductsDetail]);

    function _addToCart() {
        try {
            const CART_VALUES = {
                id: singleProductsDetail?.itemId,
                productName: singleProductsDetail?.title,
                image: singleProductsDetail?.mediumImage || require("../assets/single-prduct.png"),
                prices: singleProductsDetail?.itemPricing,
                quantity: qty,
                autoship: selectedPrice,
                itemCode: singleProductsDetail?.fullItemCode,
                isEligibleForAutoOrder: singleProductsDetail?.isEligibleForAutoOrder
            }
            const CartData = localStorage.getItem('cartData');
            if (CartData) {
                const FIND_VALUE = _.find(JSON.parse(CartData), { id: +id });
                if (FIND_VALUE) {
                    const REJECT_CURRENT = _.reject(JSON.parse(CartData), FIND_VALUE);
                    FIND_VALUE['quantity'] = FIND_VALUE['quantity'] + CART_VALUES?.quantity
                    localStorage.setItem('cartData', JSON.stringify([...REJECT_CURRENT, FIND_VALUE]))
                } else {
                    localStorage.setItem('cartData', JSON.stringify([...JSON.parse(CartData), CART_VALUES]))
                }
            } else {
                localStorage.setItem('cartData', JSON.stringify([CART_VALUES]))
            }
            setOpenMiniCart(true);
        } catch (e) {
            console.log(e)
        }
    }

    return ({
        qty,
        _IncreaseDecreaseFunction,
        selectedPrice,
        setSelectedPrice,
        singleProductsDetail,
        _addToCart
    })
};

export default useSingleProductHook;