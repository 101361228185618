import { HomeThirdSectionCss } from '../style/HomePage.style';
import SelectComponent from '../../../inputs/SelectComponent';
import TextFieldComponent from '../../../inputs/TextFieldComponent';
import ButtonComponent from '../../../inputs/ButtonCompnent';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SuccessDisplay } from '../../../services/Methods/normalMethods';
import { CircularProgress } from '@mui/material';

const HomeThirdSection = ({ loading, _sendGridContactForm, successMesssage }) => {

    const BIRTHMOTHS = [
        { text: "Jan", value: "jan" },
        { text: "Feb", value: "feb" },
        { text: "March", value: "march" },
        { text: "April", value: "april" },
        { text: "May", value: "may" },
        { text: "June", value: "june" },
        { text: "July", value: "july" },
        { text: "Aug", value: "aug" },
        { text: "Sept", value: "sept" },
        { text: "Oct", value: "oct" },
        { text: "Nov", value: "nov" },
        { text: "Dec", value: "dec" }
    ];

    return (
        <HomeThirdSectionCss>
            <div className="orgainisation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="organisation-text">
                                <h2>Charitable Organization Collaboration</h2>
                                <div className="orgaination-logo">
                                    <img src={require("../assets/SwinginHorse.png")} />
                                </div>
                                {/* <h3 style={{ fontSize: "20px" }}>Abeytu´&reg; Naturals&trade; Product Line donates from every product order placed to ourrescue.org, to
                                    assist in the rescue and rehabilitation of children who are victims of human trafficking</h3> */}
                                <h3 style={{ fontSize: "20px" }}>
                                    We take pride in being more than just a skincare company; we're a movement dedicated to making a positive impact on the world. With our commitment to natural and sustainable beauty, we're excited to introduce an initiative that goes beyond skincare – our partnership with Swingin D Horse Rescue.
                                </h3>
                                <div className="show-all">
                                    <a href="https://swingindhorserescue.com/take-action/donate/" target="_blank" className="btn btn-show">Donate Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="community-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="community-text">
                                <h2>Join Our Abeytu´&reg; Community</h2>
                                <h3>Get the latest updates by adding yourself to our monthly newsletter.</h3>
                                <div className="community-form">
                                    <Formik
                                        initialValues={{
                                            email: "",
                                            birth_month: "",
                                            name: "",
                                            lastname: ""
                                        }}
                                        validationSchema={
                                            Yup.object().shape({
                                                email: Yup.string().trim().required('E-mail is required').email('Please enter valid email'),
                                                name: Yup.string().trim().required('Name field is required'),
                                                lastname: Yup.string().trim().required('Lastname field is required'),
                                                birth_month: Yup.string().trim().required('Birth month is required')
                                            })
                                        }
                                        onSubmit={(values, actions) => {
                                            _sendGridContactForm(values, actions)
                                        }}
                                    >
                                        {({ values, handleChange, handleBlur, touched, errors }) => (
                                            <Form style={{
                                                marginBottom: '10px'
                                            }}>
                                                <div className="form-group">
                                                    <TextFieldComponent
                                                        type="email"
                                                        value={values?.email}
                                                        onChange={handleChange}
                                                        error={!!(touched?.email && errors?.email)}
                                                        errorMessage={errors?.email}
                                                        className="form-control"
                                                        label="Email" name="email" />

                                                </div>
                                                <div className="form-group">
                                                    <div className="birhmonth">
                                                        <SelectComponent
                                                            name="birth_month"
                                                            options={BIRTHMOTHS}
                                                            label="Birthday Month"
                                                            value={values?.birth_month}
                                                            onChange={handleChange}
                                                            error={!!(touched?.birth_month && errors?.birth_month)}
                                                            errorMessage={errors?.birth_month}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <TextFieldComponent
                                                        name="name"
                                                        value={values?.name}
                                                        onChange={handleChange}
                                                        error={!!(touched?.name && errors?.name)}
                                                        errorMessage={errors?.name}
                                                        className="form-control"
                                                        label="Name" />
                                                </div>
                                                <div className="form-group">
                                                    <TextFieldComponent
                                                        name="lastname"
                                                        value={values?.lastname}
                                                        onChange={handleChange}
                                                        error={!!(touched?.lastname && errors?.lastname)}
                                                        errorMessage={errors?.lastname}
                                                        className="form-control"
                                                        label="Last Name" />
                                                </div>
                                                <ButtonComponent type="submit" className="btn btn-primary btn-all">{loading ? <CircularProgress /> : "Subscribe"}</ButtonComponent>
                                            </Form>
                                        )}
                                    </Formik>
                                    {successMesssage && <SuccessDisplay message={successMesssage} />}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HomeThirdSectionCss >
    )
}
export default HomeThirdSection;