import React from 'react'

const LoaderComponent = () => {
    return (
        <div>
            <div className="flexbox">
                <div className="">
                    <div className="bt-spinner"></div>
                </div>
            </div>

        </div>
    )
}

export default LoaderComponent;