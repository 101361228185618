import React from 'react'
import ButtonComponent from '../../../inputs/ButtonCompnent'
import _ from 'lodash';
import { FloatValues, _productPrice } from '../../../services/Methods/normalMethods';
import LoaderComponent from '../../../Layout/components/Footer/components/Loader';
import { CircularProgress } from '@mui/material';

const AutoshipPack = (props) => {
    const {
        selectedAutoshipProducts, orderNowProduct, _increaseQuantity, autoshipQuantity,
        _orderTodayFunction, _addAutoshipProducts, ProductsDetail, _handleOnAutoshipChange
    } = props;

    const PRODUCT_STRUCTURE = _.map(ProductsDetail, (row) => ({
        id: row?.itemId,
        image: row?.mediumImage || <LoaderComponent />,
        productName: row?.title,
        price: _productPrice(row?.itemPricing, 'autoship', row?.isEligibleForAutoOrder),
        quantity: _.find(autoshipQuantity, { id: row?.itemId })?.quantity || 1,
        autoship: "30Days",
        isEligibleForAutoOrder: row?.isEligibleForAutoOrder
    }));

    return (
        <div className='container' style={{ marginTop: "100px", }}>
            <div className='row' style={{ display: "flex" }}>
                <h3 style={{ display: "flex", justifyContent: "center" }} >Save By Subscribing</h3>
                {PRODUCT_STRUCTURE?.length > 0 ?
                    _.map(_.filter(PRODUCT_STRUCTURE, { isEligibleForAutoOrder: true }), (row) => (
                        <React.Fragment key={row?.id}>
                            <div className='products' key={row?.id}>
                                <div className='pack-image'>
                                    <img src={row?.image} />
                                </div>
                                <h3>{row?.productName}</h3>
                                <div className='pack-price'>
                                    <span>Price: </span><span>${FloatValues(row?.price)}</span>
                                </div>
                                <div className="quality-box">
                                    <div className="input-group">
                                        <span className="input-group-btn">
                                            <button type="button" className="quantity-left-minus btn btn-danger btn-number"
                                                onClick={() => _increaseQuantity(row?.id, 'sub')}>
                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                        </span>
                                        <input type="text" id="quantity" name="quantity" className="form-control input-number"
                                            value={_.find(autoshipQuantity, { id: row?.id })?.quantity || 1}
                                            min="1" max="100" onChange={(e) => console.log(e)} />
                                        <span className="input-group-btn">
                                            <button type="button" className="quantity-right-plus btn btn-success btn-number"
                                                onClick={() => _increaseQuantity(row?.id, 'add')}>
                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div className='express-shipping ship-address'>
                                    <input type="checkbox" name="oneTime" checked={_.find(orderNowProduct?.checkedItems, { id: row?.id }) || false}
                                        onChange={(e) => {
                                            _orderTodayFunction(e.target.checked, row)
                                        }}
                                    /> Today's Order
                                </div>
                                <div className='autoship-now'>
                                    <label htmlFor='autoship_selection'>Select Autoship:</label>
                                    <select id="autoship_selection" onChange={(e) => {
                                        if (_.find(selectedAutoshipProducts, { id: row?.id })) {
                                            _handleOnAutoshipChange(row?.id, e.target.value, 'dropdown');
                                        } else {
                                            row['autoship'] = e.target.value;
                                        }
                                    }}>
                                        <option value="30Days">30 Days</option>
                                        <option value="60Days">60 Days</option>
                                    </select>
                                </div>
                                <div className='action_button'>
                                    <ButtonComponent onClick={() => _addAutoshipProducts(row)}>
                                        {_.find(selectedAutoshipProducts, { id: row?.id }) ? 'Remove' : 'Add'}
                                    </ButtonComponent>
                                </div>
                            </div>
                        </React.Fragment>
                    ))
                    :
                    <CircularProgress />
                }
            </div>
        </div >

    )
}
export default AutoshipPack;
