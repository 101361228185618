import _ from "lodash";
import AboutSectionTwoStyleCss from "../style/AboutSectionTwo.style";
import { ReactComponent as PlusSvg } from '../assets/plusSvg.svg';
import AccordianComponent from "../../../inputs/AccordianComponent";
import parse from 'html-react-parser';
import { useState } from "react";

const AboutSectionTwo = () => {
    const [showDescription, setShowDescription] = useState(null);
    const MEMBER_DETAIL = [
        {
            id: 1, image: require('../assets/maria.jpg'), name: "Maria Crisler", rank: "CEO", education: "A.S., B.S., CLS(NCA), MBA/HCM",
            description: `<p>She is a successful medical business owner and has a great deal of experience in the subjects of Clinical Microbiology and Immunology. 
        In addition to management activities, Maria engages in clinical product design and creation. 
        She assists in recognizing and facilitating applications for patients with chronic ailments via the supplement, medical, and pharmaceutical industries.
        <br/>Maria works directly with our CSO on product development specifics and timelines, and implements marketing coordination in parallel.</p>`
        },
        {
            id: 2, image: require('../assets/mark.jpg'), name: "Mark Schleiger", rank: "Chief Operating Officer", education: "B.S., M.S.",
            description: `<p>Mark Schleiger is our Chief Operations Officer and implements all elements of our supply chain. Over the past 15 years, he has successfully helped mentor, launch, and develop dozens of startups in a wide range of markets. Mark excels at keeping things simple and focused to drive innovative products to market.</p>`
        },
        {
            id: 3, image: require('../assets/emma.jpg'), name: "Dr. Emma DiPonio", rank: "Chief Medical Officer", education: "B.A./M.D.",
            description: `<p>Dr. Emma is a specialist in anatomical and molecular imaging in the field of diagnostic radiology. She received both undergraduate and graduate degrees from the University of Michigan in Ann Arbor, MI. She has special training in vascular ultrasound and image guided procedures. She has taken a special interest in natural health aids for vascular health promotion and is our Medical Director assessing safety and efficacy of each product. Dr DiPonio also brings her gift of detail oriented, patient centered review of all facets of our medically applied business progression. In addition to her bilingual communication abilities, Dr. DiPonio is an experienced medical practice owner and operator, and oversees Puerto Rico business production operations.</p>`
        },
        // {
        //     id: 4, image: require('../assets/terri-bos.jpg'), name: "Terri Bos", rank: "Chief Marketing Officer", education: "",
        //     description: `<p>Terri’s experiences include leading the marketing, advertising, digital and community efforts at AutoNation Inc. America’s Largest Automotive Retailer for 11 years. She directed numerous marketing implementations and solved many of the challenges facing business owners today. In building over $50 million annual marketing plans for over 70 locations she has a razor-sharp understanding of spearheading systems that support organizational growth while delivering efficiencies and customer service. Her knowledge and experience are in-depth for all marketing strategies and yet she holds the operational expertise to see executions succeed. Terri has lived in Atlanta for 25 years however has multi-market experience nationwide in categories such as Automotive, Food Services, Home Development & Real Estate and Legal Services.</p>`
        // },
        {
            id: 5, image: require('../assets/beverly.jpg'), name: "Beverly Holcomb", rank: "Corporate Liaison", education: "",
            description: `<p>She is a highly passionate and dynamic Functional Health Coach & Corporate Speaker; with an extensive background in the design and facilitation of Nutrition & Lifestyle Programs. Her coaching programs and workshops have helped to produce profound and lasting change in the lives of people all over the country. Her methodology utilizes a ‘root-cause’ holistic approach facilitating accountability; and long-term habit & behavior change. Her presentations, workshops, and programs help individuals and organizations address all areas of health and human potential.</p>`
        }
    ];

    const SPECIFIC_PRODUCT = [
        {
            title: "How Do I Store The Products Since They Don’t Have Preservatives?", content: ` 
            Please store all products at room temperature. Shake our tinctures well before each use. You may store at refrigerated temperature but allow the liquids to come to room temperature before using.        `, active: true, for: "desktop_view"
        },
        {
            title: "What Is The Shelf Life?", content: ` 
            We make fresh batches of all products frequently. Shelf life is 12-18months.
        `, for: "desktop_view"
        },
        {
            title: "Can I Add Water Or Mix The Tinctures With Anything Else?", content: ` 
            We do not recommend.  The formulations are encapsulated naturally in a phospholipid so adding another product or ingredient will not work to assist the formulation.  If you would like to make sure everything is out of your bottle certainly add water at the end.    `, for: ""
        },


        {
            title: "Instructions For Use?", content: ` 
            Please refer to the product itself. Administration is there.
            `, for: "desktop_view"
        },
        {
            title: "Will I Turn Positive On A Urine Test For Thc?", content: ` 
            No, You will not show up positive on a urine drug screening. We use Cannabinoid isolate and remain below the threshold of allowable legal limits.
                `, for: "desktop_view"
        },
        {
            title: "Why Do You Use Cannabinoid?", content: ` 
            CBD is used as part of our patent pending delivery method. It is a CRITICAL piece of each formulation and necessary for the incredible results we achieve.    `, for: ""
        },


        {
            title: "Do You Ship Overseas?", content: ` 
            We do! We are in the process of setting up our overseas EU partner for faster delivery and to accommodate our distributor direct sales network.
        `, for: "desktop_view"
        },
        {
            title: "Can I Ask You A Medical Question?", content: ` 
            We are a wellness product company so we are not allowed to cure, treat, or diagnose from a company standpoint.  We have many testimonials from customers and suggest you try the products out personally or visit with another distributor as to his/her personal experiences with our products. 
        `, for: "desktop_view"
        },


        {
            title: "Is There A Number I Can Call?", content: ` 
            We prefer to put everything in writing. We choose to utilize people for our communications vs bots. Customer service is generally very good about same day responses, 7am-7pm CST Monday -Friday.
        `, for: "desktop_view"
        },


        {
            title: "Can I Return My Product?", content: ` 
            We accept returns for shipping damage or damaged product, but you must write in to info@abeytunaturals.com and send us a photo within 7 days of receipt of your product.
            `, for: "desktop_view"
        },
        {
            title: "Is There A Coupon Code For First Time Users?", content: ` 
            We no longer offer coupon codes unless we are hosting an event.  The distributor portal and loyalty program are wonderful offerings for you to earn money for distributing our products, as well as receive points and free product for your purchases. 
        `, for: "desktop_view"
        },

    ];
    return (
        <AboutSectionTwoStyleCss>
            <div className="about_second_container container" id="#ourTeam">
                <div className="ourTeam_section">
                    <h1>Our Team</h1>
                    <h3>Our creative development team is actively involved in the medical, and hemp industries.</h3>
                    <div className="allexecutive">
                        {_.map(MEMBER_DETAIL, ({ id, name, rank, education, image, description }) => (
                            <div className="member_detail" key={name}>
                                <div className="executive_image">
                                    <img src={image} alt="ExecutiveImage" />
                                </div>
                                <div className="executive_bio">
                                    <h3>{name}</h3>
                                    <div className="rank_discription">
                                        <p>{rank}</p>
                                        <div className="education_plus">
                                            {education ? <p>{education}</p> : <p></p>}
                                            {(showDescription !== id) ? <p className="first_svg"><PlusSvg onClick={() => setShowDescription(id)} /></p> : <p><PlusSvg onClick={() => setShowDescription(null)} /></p>}
                                        </div>
                                    </div>
                                    <br />
                                    {(showDescription === id) &&
                                        <div className="description">
                                            {parse(description)}
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="frequently_asked_question" id="#FAQs">
                <div className="container">
                    <div className="frequantly_heading">
                        <h2>Frequently Asked Questions</h2>
                        <h3>Need help? Got questions? We are just a click away!</h3>
                    </div>
                    {_.map([
                        { id: 1, heading: "", arrayValue: SPECIFIC_PRODUCT },

                    ], ({ arrayValue, heading }, index) => (
                        <div key={'accordian' + index} className="frequantlyAccordian">

                            <div className="accordian-section">
                                <div id="accordion">
                                    {_.map(arrayValue, ({ title, content, active }) => (
                                        <AccordianComponent key={title} {...{ title, content, active: active ? active : false }} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </AboutSectionTwoStyleCss>
    )
}

export default AboutSectionTwo;