import * as React from 'react';
import { InputLabel, MenuItem, FormHelperText, FormControl, Select } from '@mui/material';
import _ from 'lodash';

const SelectComponent = (
    {
        label,
        value,
        defaultValue,
        readOnly,
        name,
        required,
        onChange,
        errorMessage,
        error,
        options,
        classes,
        ...props
    }
) => {

    return (
        <FormControl className='select_component' error={error} sx={{ m: 1, minWidth: 120 }} >
            {label &&
                <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
            }
            <Select
                labelId="demo-simple-select-helper-label"
                required={required}
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                className={classes}
            >
                {_.map(options, ({ text, value }) => (
                    <MenuItem key={text} value={value}>{text}</MenuItem>
                ))}

            </Select>
            {errorMessage &&
                <FormHelperText id="component-error-text">{errorMessage}</FormHelperText>
            }
        </FormControl>
    );
}

export default SelectComponent;