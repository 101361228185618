import MainContactUs from "./components/MainContectUs";
import ContactUsStyleCss from "./style/ContactUs.style";

const ContectUsPage = (props) => {
    return (
        <ContactUsStyleCss className="contact_use_main">
            <MainContactUs {...props} />
        </ContactUsStyleCss>
    )
}

export default ContectUsPage;