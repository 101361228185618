import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAutoShipOrders, getOrders } from "../../../services/Redux/Reducer/OrdersSlice";

const useThankYouHook = (props) => {

    const { orderId, autoshipId } = props;
    const dispatch = useDispatch();

    const { data, autoshiporder } = useSelector((state) => state?.OrdersSlice);


    useEffect(() => {
        if (orderId) {
            dispatch(getOrders(orderId, null))
        }
        if (autoshipId) {
            dispatch(getAutoShipOrders(autoshipId, null));
        }
    }, [orderId, autoshipId])


    return { data, autoshiporder }
}
export default useThankYouHook;