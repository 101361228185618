import React from 'react'
import { Box, Stepper, Step, StepLabel, Grid } from '@mui/material';
import EmailVerify from './components/EmailVerify';
import UserInfo from './components/UserInfo';
import ProdcutPack from './components/ProdcutPack';
import AutoshipPack from './components/AutoshipPack';
import { EnrollmentStyle } from './components/Enrollment.style';
import useEnrollmentSignUpHook from './components/Enrollment.hook';
import { Form, Formik } from 'formik';
import ButtonComponent from '../../inputs/ButtonCompnent';
import SummerySection from './components/SummerySection';
import { useOutletContext } from 'react-router-dom';
import OneTimePass from './components/OneTimePass';
import _ from 'lodash';
import LoaderComponent from '../../Layout/components/Footer/components/Loader';
import { REFFERAL_VALUES } from '../../services/Methods/normalMethods';
import withRouter from '../../services/HOC/withRouterHOC';
import withPayment from '../../services/HOC/withPaymentsHOC';


const formContent = (step, { countryCodes, payload, location, ProductsDetail, cartValues, userCanLogin, setCartValues, currentInitialValue, fieldValue, }) => {
    const {
        selectedAutoshipProducts, _addAutoshipProducts, _handleOnAutoshipChange, _handleSelect, packProductList, selectedProduct,
        handleChangeFunction, setActiveStep, checkoutObject, _validateRefferalFunction, webAliesError,
        setCountryState, countryState, emailExistError, validate, setOtp, handleChangeValidate, otp, valuesShip,
        _orderTodayFunction, orderNowProduct, autoshipQuantity, _increaseQuantity, _deleteProductFunction, _increseCheckoutQuantity,
        paymentFormHtml, paymentsOptions
    } = payload;

    if (process.env.REACT_APP_OTP_ENABLE === "true") {
        switch (step) {
            case 0:
                return <EmailVerify {...{ emailExistError, location, fieldValue }} />;
            case 1:
                return <UserInfo {...{ countryCodes, userCanLogin, _validateRefferalFunction, webAliesError, countryState, setCountryState, fieldValue, section: "enrollment" }} />;
            case 2:
                return <OneTimePass {...{ setActiveStep, otp, setOtp, handleChangeValidate }} />
            // case 3:
            //     return <ProdcutPack {...{ _handleSelect, packProductList, selectedProduct, fieldValue }} />
            case 3:
                return <AutoshipPack {...{
                    orderNowProduct, autoshipQuantity, _orderTodayFunction, ProductsDetail, _addAutoshipProducts,
                    selectedAutoshipProducts, _handleOnAutoshipChange, fieldValue, _increaseQuantity
                }} />;
            case 4:
                return <SummerySection {...{
                    countryCodes, paymentFormHtml, paymentsOptions,
                    selectedAutoshipProducts, currentInitialValue, cartValues, setCartValues, checkoutObject, selectedProduct,
                    fieldValue, valuesShip, handleChangeFunction, _deleteProductFunction, _increseCheckoutQuantity,
                }} />;
            default:
                return <div className='not-found'>404: Not Found</div>
        }
    } else {
        switch (step) {
            case 0:
                return <EmailVerify {...{ emailExistError, location, fieldValue }} />;
            case 1:
                return <UserInfo {...{ countryCodes, userCanLogin, _validateRefferalFunction, webAliesError, countryState, setCountryState, fieldValue, section: "enrollment" }} />;
            // case 2:
            //     return <ProdcutPack {...{ _handleSelect, packProductList, selectedProduct, fieldValue }} />
            case 2:
                return <AutoshipPack {...{
                    orderNowProduct, autoshipQuantity, _orderTodayFunction, ProductsDetail, _addAutoshipProducts,
                    selectedAutoshipProducts, _handleOnAutoshipChange, fieldValue, _increaseQuantity
                }} />;
            case 3:
                return <SummerySection {...{
                    countryCodes, paymentFormHtml, paymentsOptions,
                    selectedAutoshipProducts, currentInitialValue, cartValues, setCartValues, checkoutObject, selectedProduct,
                    fieldValue, valuesShip, handleChangeFunction, _deleteProductFunction, _increseCheckoutQuantity,
                }} />;
            default:
                return <div className='not-found'>404: Not Found</div>
        }
    }

};

const EnrollmentSignUp = ({ location, ...props }) => {
    const { cartValues, setCartValues, valuesShip, ProductsDetail, token, countryCodes, Dates } = useOutletContext();
    const { paymentFormHtml, paymentsOptions, ...otherProps } = props;
    const {
        commonLoader, steps, isLastStep, activeStep, setActiveStep, _handleSubmit, _handleBack, currentValidationSchema, currentInitialValue, scrollToRef,
        _handleCallCalculate, _handleOtpValidate, signUpLoading, checkoutLoading, errorMsg, userCanLogin, selectedAutoshipProducts, ...others
    } = useEnrollmentSignUpHook({ paymentFormHtml, token, cartValues, Dates, setCartValues, ProductsDetail, valuesShip, ...otherProps });

    return (
        <EnrollmentStyle>
            {(signUpLoading || checkoutLoading || commonLoader) && <LoaderComponent />}
            <div className={activeStep === 4 ? 'linear-form checkout_main_div_add' : 'linear-form'}>
                <Box sx={{ margin: "auto" }}>
                    <Stepper activeStep={activeStep} alternativeLabel orientation="horizontal">
                        {steps?.map((label, index) => (
                            // <div className="my-step-cstm">
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                            // </div>
                        ))}
                    </Stepper>
                    <Box component="div">
                        <Grid item xs={12} sx={{ padding: '20px' }}>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    ...currentInitialValue,
                                    refferal: REFFERAL_VALUES?.webalies,
                                }}
                                validationSchema={currentValidationSchema}
                                onSubmit={(values, action) => _handleSubmit(values, action)}
                            >
                                {({ isSubmitting, ...fieldValue }) => (
                                    <>
                                        {(activeStep < 2) ?
                                            <>
                                                <Form>
                                                    {formContent(activeStep, {
                                                        ...{
                                                            currentInitialValue, valuesShip, setCartValues, cartValues, location, countryCodes,
                                                            setActiveStep, payload: {
                                                                ...others, paymentFormHtml, paymentsOptions, selectedAutoshipProducts
                                                            }, fieldValue: fieldValue, ProductsDetail, userCanLogin,
                                                        }
                                                    })}
                                                    <div className="product-button-back">
                                                        {(activeStep !== 0 && !token?.token) && (
                                                            <ButtonComponent onClick={_handleBack} className="">Back</ButtonComponent>
                                                        )}
                                                        <ButtonComponent type="submit" classes="">Next</ButtonComponent>
                                                    </div>
                                                </Form>
                                                {<p className='' style={{ color: "red" }}>{errorMsg}</p>}
                                            </>
                                            :
                                            <div className='products_section'>
                                                {formContent(activeStep, {
                                                    ...{
                                                        currentInitialValue, valuesShip, setCartValues, cartValues, location, countryCodes,
                                                        setActiveStep, payload: {
                                                            ...others, paymentFormHtml, paymentsOptions, selectedAutoshipProducts
                                                        }, fieldValue: fieldValue, ProductsDetail, userCanLogin
                                                    }
                                                })}
                                                <div className="product-button-back" ref={scrollToRef}>
                                                    {_.includes(["Autoship", "Summary"], steps[activeStep]) && (
                                                        <ButtonComponent onClick={_handleBack} className="">Back</ButtonComponent>
                                                    )}
                                                    {
                                                        (!isLastStep) &&
                                                        (<ButtonComponent classes=""
                                                            // disabled={(steps[activeStep]) === 'Autoship' ? !(steps[activeStep] === 'Autoship' && selectedAutoshipProducts?.length > 0) : false}
                                                            onClick={() => {
                                                                if (isLastStep) {

                                                                } else if (others?.validate === "VALID" && steps[activeStep] === "Validate") {
                                                                    _handleOtpValidate();
                                                                } else {
                                                                    if (((steps[activeStep]) === 'Autoship')) {
                                                                        // if ((steps[activeStep]) === 'Products Pack') {
                                                                        _handleCallCalculate();
                                                                    }
                                                                    setActiveStep(activeStep + 1);
                                                                }
                                                            }} >Next</ButtonComponent>)
                                                    }
                                                    {
                                                        ((steps[activeStep]) === 'Products Pack' && _.intersection(_.map(others?.selectedProduct, 'id'), _.map(others?.packProductList, 'id'))?.length === 0) &&
                                                        (<ButtonComponent classes="" onClick={() => setActiveStep(activeStep + 1)}>Skip</ButtonComponent>)
                                                    }
                                                </div>
                                                {<p className='' style={{ color: "red" }}>{errorMsg}</p>}
                                            </div>
                                        }
                                    </>
                                )}
                            </Formik>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </EnrollmentStyle >
    )
}

export default withRouter(withPayment(EnrollmentSignUp));