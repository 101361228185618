// EL === Enrollment , loyality program

import _ from "lodash";
import { getAllProductApi } from "../../../services/Redux/Reducer/AllProductSlice";
import { CalculateApi, CalculateAutoshipApi, CreateAutoshipOrderApi } from "../../../services/Redux/Reducer/CheckoutSlice";
import { getAllCustomerData } from "../../../services/Redux/Reducer/LoginSlice";
import { _paymentPayload, calculateDataUserPayload, calculateReouccringPayload, _checkoutAddressFormat } from "../../../services/Methods/checkoutPayloads";
import moment from "moment";
import { getCustomerSavedCard } from "../../../services/Redux/Reducer/CustomerSlice";
import { _scriptFunctionCall } from "../../../services/Methods/commonPayload";

export const autoshipProduct = (data) => _.map(data, (item, index) => ({     /** autoship order */
    parentItemId: 0, "parentLineNumber": 0, orderLineNumber: index + 1, itemId: item?.id || item?.itemId, itemCode: item?.fullItemCode || item?.itemCode, quantity: item?.quantity,
}));

export const detailsArray = (data) => _.map(data, (item, index) => ({
    parentItemId: 0, orderLineNumber: index + 1, itemId: (item?.id || item?.itemId), itemCode: item?.fullItemCode, quantity: item?.quantity,
}));


export function _shippingChangeFunction(data, section = "Today's order", parameters) {
    const {
        setSelectedCheck, setSelectedAutoshipCheck, calculateAutoshipData, calculateDataUser, detailsArrayData, _handleCommonLoader, dispatch, autoshipData, couponCode,
    } = parameters;

    const SELECTED_SHIPPING = {
        shipCarrierId: data?.shipCarrierId,
        shipMethodType: data?.shipMethodType
    };

    if (section === "Today's order") {
        setSelectedCheck(SELECTED_SHIPPING)
        calculateDataUser["details"] = detailsArray(detailsArrayData);
        calculateDataUser['shipMethod'] = _.assign(calculateDataUser?.shipMethod, SELECTED_SHIPPING);
        calculateDataUser['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;
        calculateDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
        dispatch(CalculateApi(calculateDataUser, (data) => _handleCommonLoader(data)))
    } else {
        setSelectedAutoshipCheck(SELECTED_SHIPPING);
        calculateAutoshipData["details"] = autoshipProduct(autoshipData);
        calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;           /** autoship order */
        calculateAutoshipData['shipMethod'] = _.assign(calculateAutoshipData?.shipMethod, SELECTED_SHIPPING);
        calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
        dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')))
    }
}

export function _callWhenUserLoginEL(parmetersCall) {
    const {
        section, setUserCanLogin, token, activeStep, useDefaultAddressOrNot, setActiveStep, setFormData, setUserData, setCountryStateCheckout,
        detailsArrayData, autoshipArrayData, orderNowProduct, dispatch, userData, selectedCheck, selectedAutoshipCheck, setCommonLoader,
        _handleCommonLoader, setCountryState, countryStateCheckout, setSelectedOptionForPayment, setSelectedCard, selectedCard, couponCode
    } = parmetersCall;
    if (token?.token) {
        if (useDefaultAddressOrNot === null) {
            if (activeStep === 0) {
                setActiveStep(1);
            }
            if (section === "enrollment") {
                dispatch(getAllProductApi('enrollmentPack'));
                dispatch(getAllProductApi('memberShipPack'));
            }
        }
        dispatch(getAllCustomerData((data) => {
            try {
                setFormData({
                    email: data?.emailAddress,
                    firstName: data?.firstName,
                    lastName: data?.lastName,
                    phone: data?.phoneNumbers?.cellPhone,
                    webAlias: data?.webAlias,
                    city: useDefaultAddressOrNot?.city || data?.shippingAddress?.city,
                    zipcode: useDefaultAddressOrNot?.postal_code || data?.shippingAddress?.postalCode,
                    address: useDefaultAddressOrNot?.address_1 || data?.shippingAddress?.address1,
                    address_2: useDefaultAddressOrNot?.address_2 || data?.shippingAddress?.address2,
                    company: data?.companyName,
                });
                setUserCanLogin(data?.userCanLogIn);
                const BILLING_DETAIL = _checkoutAddressFormat(data, userData, useDefaultAddressOrNot, countryStateCheckout);
                setUserData(BILLING_DETAIL);
                let calculateDataUser = calculateDataUserPayload(BILLING_DETAIL, selectedCheck, useDefaultAddressOrNot, orderNowProduct?.selectedItems);
                let calculateAutoshipData = calculateReouccringPayload(BILLING_DETAIL, selectedAutoshipCheck, useDefaultAddressOrNot)
                setCountryStateCheckout((prv) => ({
                    ...prv,
                    shipping_country: useDefaultAddressOrNot?.country || prv?.shipping_country || data?.countryCode,
                    shipping_state: useDefaultAddressOrNot?.state_region || prv?.shipping_state || data?.shippingAddress?.regionProvState,
                }));

                setCountryState((prv) => ({
                    ...prv, country: data?.countryCode, state: data?.shippingAddress?.regionProvState
                }));

                if (useDefaultAddressOrNot !== null && useDefaultAddressOrNot !== false) {
                    if (detailsArray(detailsArrayData)?.length > 0) {                                           /*************** normal order */
                        calculateDataUser["details"] = detailsArray(detailsArrayData);
                        calculateDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
                        dispatch(CalculateApi(calculateDataUser, () => { }))
                    }
                    if (autoshipProduct(autoshipArrayData)?.length > 0) {                                        /************** autoship order */
                        calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;           /** autoship order */
                        calculateAutoshipData['details'] = autoshipProduct(autoshipArrayData);  /** autoship order */
                        calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
                        dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
                    }
                }
            } catch (e) {
                setUserCanLogin(false);
            }
        }, token?.token));
        dispatch(getCustomerSavedCard(token?.token, (values) => {
            if (values?.length > 0)
                setSelectedOptionForPayment(1);
            if (selectedCard === null) {
                setSelectedCard(values[0]);
            }
        }));
    }
}

export const callBackAutoshipOrder = (AutoshipProduct, otherdata) => {
    try {
        const { values, orderid, sucess, callBack, calculateAutoshipData, paymentFormHtml, dispatch, selectedPaymentOption, selectedCard, couponCode } = otherdata;
        if (AutoshipProduct?.length > 0) {
            _scriptFunctionCall(paymentFormHtml, (tokenId) => {
                if (tokenId) {
                    let autoshipCartPayload = _paymentPayload({ id: tokenId }, values, 'autoship', selectedPaymentOption, selectedCard);
                    var date = new Date();
                    date.setDate(date.getDate() + (!sucess ? 30 : 60))
                    calculateAutoshipData['startDate'] = moment(date).utc().format();
                    calculateAutoshipData['frequencyType'] = !sucess ? 1 : 7;

                    if (autoshipProduct(AutoshipProduct)?.length > 0) {
                        calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;
                        calculateAutoshipData["details"] = autoshipProduct(_.reject(AutoshipProduct, { autoship: "oneTime" }))
                        calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
                        calculateAutoshipData['payments'] = [{ ...autoshipCartPayload, "maxAmount": calculateAutoshipData?.orderTotal, }];
                        dispatch(CreateAutoshipOrderApi(calculateAutoshipData, null, orderid, callBack))
                    }
                }
            });


        }
    } catch (e) {
        console.log(e, "payment Error")
    }
};

export function _callAutoshipFor3060Days(values) {
    try {
        const { autoshipData, ...parameterValue } = values;

        const THIRTY_DAYS = _.filter(autoshipData, { autoship: "30Days" });
        const SIXTY_DAYS = _.filter(autoshipData, { autoship: "60Days" });
        if (THIRTY_DAYS?.length > 0 && SIXTY_DAYS?.length > 0) {
            callBackAutoshipOrder(THIRTY_DAYS, {
                ...parameterValue, callback: (res) => {
                    if (SIXTY_DAYS?.length > 0 && res?.data) {
                        callBackAutoshipOrder(SIXTY_DAYS, { ...parameterValue, sucess: true });
                    }
                }, sucess: false
            });
        } else if (THIRTY_DAYS?.length > 0) {
            callBackAutoshipOrder(THIRTY_DAYS, { ...parameterValue, sucess: false });
        } else if (SIXTY_DAYS?.length > 0) {
            callBackAutoshipOrder(SIXTY_DAYS, { ...parameterValue, sucess: true });
        }
    } catch (e) {
        console.log(e, "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
    }
}

