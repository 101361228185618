import styled from "styled-components";

const EnrollmentSecondStyleCss = styled.div`
.enrollment_second_section {

    .first_container_for_second {
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        background: #F4F0EB 0% 0% no-repeat padding-box;
        @media(max-width:1199px){
            padding: 0 30px;
        }
        @media(max-width:767px){
            padding: 0 20px;
        }
   
        .partnershipProgram {
            padding-top: 70px;
            margin-bottom: 50px;
            @media(max-width:568px){
                padding-top: 30px;
            margin-bottom: 20px;
                        }
         h2 {
            color: #2A533F;
            @media(max-width:568px){
              margin--bottom: 20px;
                        }
        }
        
        }

        .main_cardSection {
            display: flex;
            justify-content: center;
            column-gap: 40px;
            padding-bottom: 120px;
            @media(max-width:1199px){
                padding-bottom: 70px;
                column-gap:15px;
            }
            @media(max-width:991px){
                flex-wrap: wrap;
            }

            .cardSection {
                display: flex;
                flex-direction: column;
                gap: 20px;
                justify-content: space-between;
                align-items: center;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 40px #0000001A;
                border-radius: 10px;
                padding-bottom: 56px;
                @media(max-width:991px){
                    margin-bottom: 20px;
                    max-width: 45%;
                    flex: 0 0 45%;
                    padding: 20px;
                 }
                 @media(max-width:767px){
                    max-width: 85%;
                    flex: 0 0 85%;
                 }
                 @media(max-width:568px){
                    max-width: 100%;
                    flex: 0 0 100%;
                 }
            }
                
                .card_mage {
                    width: 100%;
                    max-width: 428px;
                    @media (max-width:1439px){
                        max-width: 380px;
                    }
            
                    @media (max-width:1280px){
                        max-width: 350px;
                    }
                    @media (max-width:568px){
                        max-width: 100%;
                    }

                    img {
                        width: 100%;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        height: 250px;
                        @media (max-width:568px){
                            height: auto;
                    }
                    }
                }
                .card_content {
                    width: 100%;
                    max-width: 300px;
                    @media(max-width:1199px){
                        padding: 0 20px;
                    }
                    @media(max-width:767px){
                        max-width: 100%;
                    }

                    h3 {
                        font-size: 31x;
                        font-family: Playfair Display;
                        font-weight: bold;
                        color: #000;
                        @media(max-width:1199px){
                            font-size: 26px;
                    }
                    @media(max-width:568px){
                            font-size: 18px;
                    }
                    }
                    p{
                        margin-bottom:0;
                        font-size: 20px;
                        color: #555555;
                        line-height: 27px;
                        padding: 15px 0 25px;
                        @media(max-width:1199px){
                            font-size: 18px;
                    }
                    }
                }
            }
        }
    }
  button{
    border: 1px solid transparent;
  &:hover {
    background: transparent;
    color: rgb(42, 83, 63);
    border: 1px solid rgb(42, 83, 63);
}
}
`;
export default EnrollmentSecondStyleCss;