class LocalStorageStorage {
    getSingleItem(key) {
        if (key) {
            return localStorage.getItem(key) ? localStorage.getItem(key) : null;
        }
    }

    getItem(key) {
        const items = localStorage.getItem(key);
        if (items) {
            return JSON.parse(items);
        }
        return null;
    }

    setItem(key, values) {
        localStorage.setItem(key, values);
    }

    removeItem(key) {
        localStorage.removeItem(key);
    }

    clearAllItem() {
        localStorage.clear();
    }

}

const localStorageCall = () => new LocalStorageStorage();
export default localStorageCall; 
