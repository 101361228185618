import styled from "styled-components";

const EnrollmentFirstStyleCss = styled.div`

.enrollment_banner_section {
    height: 860px;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
    background-position:top;

    @media(max-width:1199px){
        height: 590px;
        object-fit: cover;
    }
    @media(max-width:767px){
                height:450px;
    }
    @media(max-width:568px){
                height:320px;
    }
    @media(max-width:480px){
                height:275px;
    }
    .banner-text {
        font-size: 100px;
        width: 100%;
        margin-bottom: 0;
        font-family: var(--common-font);
        z-index: 99;
        padding: 230px 45px;
        @media(max-width:767px){
                padding:150px 0;
    }
    @media(max-width:568px){
                padding:98px 0;
    }

        h1 {
            margin-bottom:24px;
            font: normal normal bold 60px/70px Playfair Display;
             color: #2A533F;
             @media(max-width:767px){
                font: normal normal bold 30px/24px Playfair Display;
    }
    @media(max-width:568px){
                font: normal normal bold 26px/24px Playfair Display;
    }
        }
    }
}
.main_subscriptionProgram {
    padding: 100px 0 50px;
    @media(max-width:1199px){
    padding: 46px 0px;
    }
    h2 {
        width: 100%;
        max-width: 630px;
        margin: 0 auto;
        color: #2A533F;
        @media(max-width:1199px){
         max-width:100%;
         text-align: center;
        }
    }
    .subscriptionProgram {
        margin: 70px auto;
        max-width:1270px;
        display: flex;
        justify-content: center;
        column-gap: 60px;
        @media(max-width:1439px){
            max-width: 1140px;
        }
        @media(max-width:1199px){
            margin: 34px 30px 20px;
            nax-width:100%;
            @media (max-width:991px){
                max-width: 100%;
                display: flex;
                flex-wrap: wrap;
            }
            @media (max-width:767px){
                margin: 34px 20px 20px;
            }
        }
        .image_section {
            img {
            max-width: 709px;
            width: 100%;
            border-radius: 10px;
            @media(max-width:1199px){
            max-width:100%;
            }
        }
    }
        .description_of_subscription{
            padding: 26px 30px;
            @media(max-width:1199px){
             padding: 13px 0px;;
            }
         h3 {
            letter-spacing: 0.26px;
            color: rgb(57,51,46);
            font-family: 'Playfair Display';
            font-size: 22px;
            font-weight: 500;
            line-height: 40px;
            @media(max-width:1199px){
                line-height: 32px;
            }
            @media(max-width:767px){
                font-size: 24px;
            }
            @media(max-width:568px){
                font-size:18px;
            }
        }
        ul{
            padding: 12px 25px;
            list-style: none;
            li{
                letter-spacing: 0.24px;
                color: rgb(85,85,85);
                font-size: 18px;
                line-height: 40px;
                font-family: 'Helvetica Neue LT Std';
                position: relative;
                @media(max-width:767px){
                font-size: 20px;
            }
            @media(max-width:568px){
                font-size: 16px;
                line-height:38px;
            }
                &::after{
                    position: absolute;
                    content: "";
                    background: #555555;
                    width: 6px;
                    height: 6px;
                    left: -22px;
                    border-radius: 50px;
                    top: 18px;
                }
            }
        }
        }

        .how_to_use_section {
            width: 100%;
            max-width: 502px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 40px #0000001A;
            border-radius: 10px;
            text-align: center;
            padding: 40px 51px;
            @media (max-width:1199px){
                padding: 20px 25px;
                max-width: 100%;
            }
            @media (max-width:991px){
                    max-width: 100%;
            flex: 0 0 100%;
                }
            h3 {
                color: #2a533f;
                font-size: 40px;
                font-weight: bold;
                font-family: 'Playfair Display';
                text-align: left;
                @media (max-width:991px){
                    font-size: 26px;
                }
                @media (max-width:568px){
                    font-size: 22px;
                }
            }
            .commonEnrollment {
                text-align: left;
                margin: 40px 0;
                font-size: 18px;
                line-height: 22px;
                display: flex;
                @media (max-width:991px){
                    text-align: center;
                    flex-direction: column;
                }
                .number {
                    background: #fff;
                    border-radius: 50px;
                    border: 9px solid #F4F0EB;
                    max-width: 70px;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #2A533F;
                    font-size: 40px;
                    font-family: 'Helvetica Neue LT Std';
                    font-weight: bold;
                    margin-bottom: 20px;
                    padding-top: 0;
                    width: 100%;
                    margin-right: 10px;

                    span {
                        display: block;
                        margin-top: 10px;
                        line-height: 1;
                    }

                    @media(max-width:991px){
                        margin: 0 auto 20px;
                    }
                }
                .content{ 
                    margin-top: 15px;

                    @media(max-width:991px){
                        margin-top: 0px;
                    }
                h4 {
                    color: rgb(0, 0, 0);
                    font-size: 24px;
                    font-family: 'Helvetica Neue LT Std';
                    font-weight: 600;
                    line-height: 27px;
                    @media (max-width:767px){
                    font-size: 18px;
                }
                }
                p{
                    color: #555555;
                        font-size: 18px;
                        line-height: 20px;
                        font-family: 'Helvetica Neue LT Std';
                        @media(max-width:991px){
                            line-height:28px;
                        }
                        @media(max-width:568px){
                            font-size:16px;
                        }
                }
            }
            }
        }

    }
    }


 

`;
export default EnrollmentFirstStyleCss;