import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAutoShipOrders } from "../../../services/Redux/Reducer/OrdersSlice";
import { useEffect } from "react";
import { autoshipProduct } from "../../EnrollmentSignup/components/reUseFunction";
import { calculateReouccringPayload, _checkoutAddressFormat, _paymentPayload } from "../../../services/Methods/checkoutPayloads";
import { CalculateAutoshipApi, ModifyCalculateAutoshipApi } from "../../../services/Redux/Reducer/CheckoutSlice";
import _ from "lodash";
import moment from "moment";
import { getCustomerSavedCard } from "../../../services/Redux/Reducer/CustomerSlice";
import { _scriptFunctionCall } from "../../../services/Methods/commonPayload";
import { _getPaymentHtml } from "../../../services/Redux/Reducer/PaymentSlice";


// setAddressDetails
const useAutoshipEditHook = (props) => {

    const { autoshipId, token, paymentFormHtml } = props;
    const dispatch = useDispatch();
    const { autoshiporder } = useSelector((state) => state?.OrdersSlice);
    const { savedCards } = useSelector(state => state?.CustomerSlice);

    const { errorAddressData, normalAddressError } = useSelector((state) => state?.CheckoutSlice);

    /****************************-- Autohship Product --*************************** */

    const [autoshipOrderDetail, setAutohsipOrderDetail] = useState([]);
    const [openProductAdd, setOpenProductAdd] = useState(false);
    const [frequencyTYpe, setFrequencyType] = useState('1');
    const [shippingMethods, setShippingMethods] = useState([]);

    const [selectedAutoshipCheck, setSelectedAutoshipCheck] = useState({ shipCarrierId: null, shipMethodType: null });
    const [selectedDate, setSelectedDate] = useState(new Date());


    /****************************-- Autohship Address Edit --*************************** */

    const [addressEditOpen, setAddressEditOpen] = useState(false);
    const [addressDetails, setAddressDetails] = useState({
        shipping_urname: "", shipping_lastname: "", shipping_country: "", shipping_street: "", shipping_city: "", shipping_state: "", shipping_zipcode: "", shipping_phone: "",
    });
    const [countryState, setCountryState] = useState({
        country: "", state: "", countryError: "", stateError: ""
    });
    const [updateSuccess, setUpdateSuccess] = useState('');
    const [deletePopUp, setDeletePopup] = useState({ id: null, open: false });
    const [processDate, setProcessDate] = useState()

    /****************************-- All Data --*************************** */

    const [useDefaultAddressOrNot, setUseDefaultAddressOrNot] = useState(null);
    const [calculatedAutoshipData, setCalculatedAutoshipData] = useState(null);  /** autoship order */
    let calculateAutoshipData = calculateReouccringPayload(addressDetails, selectedAutoshipCheck, useDefaultAddressOrNot)
    const [commonLoader, setCommonLoader] = useState(false);
    const [openDialog, setOpenDialog] = useState(null);

    // ****************** commmon function ************************//
    // _setUseDefaultAddressFunction(null, null, userDataPayload(selectAddress))

    function _setUseDefaultAddressFunction(data, useDefaultAddressOrNot, userData) {
        const CHANGE_COUNTRY = userData?.shipping_country ? {
            shipping_country: userData?.shipping_country, shipping_state: userData?.shipping_state
        } : countryState;
        const BILLING_DETAIL = _checkoutAddressFormat(data, userData, useDefaultAddressOrNot, CHANGE_COUNTRY);
        setAddressDetails((pre) => ({ ...pre, ...BILLING_DETAIL }));
        _callAutoshipCalculateApi(autoshipOrderDetail, selectedAutoshipCheck, userData, useDefaultAddressOrNot, null)
    }

    function _callAutoshipCalculateApi(autoshipData, shippingChecks, userInfo, defaultAddress, callback) {
        if (autoshipData?.length > 0) {
            calculateAutoshipData = calculateReouccringPayload(userInfo, shippingChecks, defaultAddress)
            calculateAutoshipData = { ...calculateAutoshipData, details: autoshipData, customerId: localStorage?.getItem('Token') ? localStorage?.getItem('Token') : 0 }
            dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => {
                _handleCommonLoader(data);
                if (callback) { callback(data); }
            }));
        }
    }

    // ********** saved card , payment section *****************
    const [selectedCard, setSelectedCard] = useState({ previousAmount: null, nextAmount: null, accountType: null });
    const [savedThisCard, setSavedThisCard] = useState({ checked: true, readOnly: true });
    const [selectedOptionForPayment, setSelectedOptionForPayment] = useState(2);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);

    /************** Change frequency of autoship *******************/
    function _handleOnSelectedAutoshipChange(data, section) {
        setCommonLoader(true);
        const SHIPPING_METHODS = { shipCarrierId: data?.shipCarrierId, shipMethodType: data?.shipMethodType }
        _callAutoshipCalculateApi(autoshipOrderDetail, SHIPPING_METHODS, addressDetails, useDefaultAddressOrNot);
    }

    // *************** Handle address changes submit ***************//
    function _handleAddressEditSubmit(values, actions) {
        if (countryState?.country && countryState?.state) {
            values['shipping_country'] = countryState?.country;
            values['shipping_state'] = countryState?.state;
            setAddressDetails(values);
            setAddressEditOpen(false);
            _callAutoshipCalculateApi(autoshipOrderDetail, selectedAutoshipCheck, values, useDefaultAddressOrNot);

        } else {
            if (countryState?.country === "") {
                setCountryState((prv) => ({ ...prv, countryError: "Please select your country" }))
            }
            if (countryState?.state === "") {
                setCountryState((prv) => ({ ...prv, stateError: "Please select your state" }))
            }
        }
    }

    // ************* Product Add Function **********************
    function _handleProductAdd(data) {
        setCommonLoader(true);
        const ProductAdd = [{ itemId: data?.itemId, quantity: 1 }];
        const AUTOSHIP_DATA = autoshipProduct([...autoshipOrderDetail, ...ProductAdd]);
        _callAutoshipCalculateApi(AUTOSHIP_DATA, selectedAutoshipCheck, addressDetails, useDefaultAddressOrNot, (data) => {
            if (data) {
                setAutohsipOrderDetail(AUTOSHIP_DATA);
                setOpenProductAdd(false);
                setCommonLoader(false);
            }
        });
    }

    // ************* Product Delete Function ********************* //
    function _handleProductDelete(id) {
        const REJECTED_DATA = _.reject(autoshipOrderDetail, { itemId: +id })
        const AUTOSHIP_DATA = autoshipProduct(REJECTED_DATA);
        _openCloseAskPopup(null, 'close');
        _callAutoshipCalculateApi(AUTOSHIP_DATA, selectedAutoshipCheck, addressDetails, useDefaultAddressOrNot, (data) => {
            if (data) {
                setAutohsipOrderDetail(AUTOSHIP_DATA);
            }
        });
    }

    function _handleCardChange(data, section) {
        if (section === "savedCard") {
            setSelectedPaymentOption(null);
            setSelectedCard((prv) => ({ ...prv, previousAmount: data, nextAmount: data }));
        } else {
            setSelectedCard((prv) => ({ ...prv, previousAmount: null, nextAmount: null }));
            setSelectedPaymentOption(data);
            if (data === 1) {
                dispatch(_getPaymentHtml(data));
            }
        }
    }

    //open close deleteFunction

    function _openCloseAskPopup(id, switches = "open") {
        if (autoshipOrderDetail?.length > 1) {
            setDeletePopup({ id, open: switches === 'open' ? true : false });
        }
    }

    function handleChangeFunction(event, values) {
        let USER_DETAILS = {};
        if (event) {
            const { name, value } = event.target;
            USER_DETAILS = { ...values, [name]: value };
            setAddressDetails(USER_DETAILS);
        } else {
            USER_DETAILS = values;
        }
        calculateAutoshipData = calculateReouccringPayload(USER_DETAILS, selectedAutoshipCheck, useDefaultAddressOrNot);

        if (values?.shipping_city && values?.shipping_street && values?.shipping_zipcode) {

            if (autoshipProduct(autoshipOrderDetail)?.length > 0) {
                calculateAutoshipData['details'] = autoshipProduct(autoshipOrderDetail);  /** autoship order */
                dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
            }
        }
    }

    // ************** Product quantity increase and decrease *************** //
    function _handleQuantiyIncDec(id, section) {
        setCommonLoader(true);
        const SHIPPING_METHODS = { shipCarrierId: null, shipMethodType: null };
        const FIND_INDEX = _.findIndex(autoshipOrderDetail, { itemId: +id });
        const FIND_PRODUCT = _.find(autoshipOrderDetail, { itemId: +id });

        setShippingMethods()
        if (section === 'sub') {
            if (FIND_PRODUCT['quantity'] - 1 > 0) {
                FIND_PRODUCT['quantity'] = (FIND_PRODUCT['quantity'] - 1) > 0 ? FIND_PRODUCT['quantity'] - 1 : 1;
                autoshipOrderDetail[FIND_INDEX] = FIND_PRODUCT;
            } else {
                _openCloseAskPopup(+id, 'open');
                setCommonLoader(false);
            }
        } else {
            FIND_PRODUCT['quantity'] = FIND_PRODUCT['quantity'] + 1;
            autoshipOrderDetail[FIND_INDEX] = FIND_PRODUCT;
        }
        setSelectedAutoshipCheck(SHIPPING_METHODS);
        const AUTOSHIP_DATA = autoshipProduct(autoshipOrderDetail);
        _callAutoshipCalculateApi(AUTOSHIP_DATA, SHIPPING_METHODS, addressDetails, useDefaultAddressOrNot, (data) => {
            if (data) { setAutohsipOrderDetail(AUTOSHIP_DATA); }
        });
    }

    function _handleCommonLoader(data) {
        if (data) {
            setCommonLoader(false);
            setCalculatedAutoshipData(data?.data?.crmOrder);
            setShippingMethods(data?.data?.shipMethods);
            setSelectedAutoshipCheck({ shipCarrierId: data?.data?.crmOrder?.shipCarrierId, shipMethodType: data?.data?.crmOrder?.shipMethodType });
        }
    }
    // **************** Update Autoship Product ********************
    function _handleUpdateAutoship() {
        setCommonLoader(true);
        let autoshipCartPayload = _paymentPayload(null, addressDetails, 'autoship', selectedPaymentOption, selectedCard);
        calculateAutoshipData = {
            ...calculateAutoshipData,
            details: autoshipOrderDetail,
            nextProcessDate: selectedDate ? selectedDate : moment.utc().format(),
            frequencyType: +frequencyTYpe
        };
        if (selectedCard?.nextAmount) {
            autoshipCartPayload['token'] = selectedCard?.nextAmount?.token;
            autoshipCartPayload['customerAccountId'] = selectedCard?.nextAmount?.customerAccountId;
            autoshipCartPayload['recurringPaymentActionType'] = 2;
            if (autoshipOrderDetail?.length > 0) {
                calculateAutoshipData['payments'] = [{ ...autoshipCartPayload, "maxAmount": calculatedAutoshipData?.orderTotal, }];
                dispatch(ModifyCalculateAutoshipApi(
                    _.omit(calculateAutoshipData, ['startDate']), autoshipId, (val) => {
                        setCommonLoader(false);
                        if (val?.data) {
                            setUpdateSuccess('you have successfully changed you autoship order.');
                            setTimeout(() => setUpdateSuccess(''), 4000)
                        }
                    }
                ))
            }
        } else {
            _scriptFunctionCall(paymentFormHtml, (tokenId) => {
                if (autoshipOrderDetail?.length > 0) {
                    autoshipCartPayload["token"] = tokenId;
                    autoshipCartPayload['recurringPaymentActionType'] = 1;
                    calculateAutoshipData['payments'] = [{ ...autoshipCartPayload, "maxAmount": calculatedAutoshipData?.orderTotal, }];
                    dispatch(ModifyCalculateAutoshipApi(
                        _.omit(calculateAutoshipData, ['startDate']), autoshipId, (val) => {
                            setCommonLoader(false);
                            if (val?.data) {
                                setUpdateSuccess('you have successfully changed you autoship order.');
                                setTimeout(() => {
                                    setUpdateSuccess('');
                                    window.location.reload();
                                }, 5000);
                            }
                        }
                    ))
                }
            });
        }
    }

    //************** add new payment card *********** */
    function _addNewCard(section) {
        if (section === "add") {
            // loadForm();
            setSelectedCard((prv) => ({ ...prv, nextAmount: null }));
        } else {
            setSelectedCard((prv) => ({ ...prv, nextAmount: prv?.previousAmount }));
        }
    }

    useEffect(() => {
        if (autoshipId) {
            dispatch(getAutoShipOrders(autoshipId, null, (res) => {
                setProcessDate(res?.data?.[0]?.nextProcessDate);
                if (res?.data?.length > 0) {
                    const { shipCountryCode, shipAddress1, payments, shipMethod, shipCity, shipFirstName, shipLastName, shipPostalCode, shipRegionProvState, details, frequencyType } = res?.data[0];
                    const SHIPPING_DETAILS = {
                        shipping_urname: shipFirstName,
                        shipping_lastname: shipLastName,
                        shipping_country: shipCountryCode,
                        shipping_street: shipAddress1,
                        shipping_city: shipCity,
                        shipping_state: shipRegionProvState,
                        shipping_zipcode: shipPostalCode,
                        shipping_phone: "",
                    };
                    setCountryState({ country: shipCountryCode, state: shipRegionProvState });
                    const SELECTED_SHIPPING = { shipCarrierId: shipMethod?.shipCarrierId, shipMethodType: shipMethod?.shipMethodType };
                    const AUTOSHIP_DATA = autoshipProduct(details);
                    setFrequencyType(frequencyType);
                    setAddressDetails(SHIPPING_DETAILS);
                    setAutohsipOrderDetail(AUTOSHIP_DATA);
                    if (payments?.length > 0) {
                        setSelectedCard({ accountType: 1, previousAmount: payments[0], nextAmount: payments[0] });
                    }
                    _callAutoshipCalculateApi(AUTOSHIP_DATA, SELECTED_SHIPPING, SHIPPING_DETAILS);
                    dispatch(getCustomerSavedCard(token?.token));
                }
            }));
        }
    }, [autoshipId]);

    return ({
        _handleUpdateAutoship, updateSuccess,
        autoshipOrderDetail, _handleQuantiyIncDec,
        _handleProductAdd, _handleProductDelete,
        countryState, setCountryState,
        addressEditOpen, setAddressEditOpen,
        openProductAdd, setOpenProductAdd,
        _handleAddressEditSubmit,
        autoshiporder,
        commonLoader,
        calculatedAutoshipData,
        addressDetails, setAddressDetails,
        dispatch,
        shippingMethods,
        selectedAutoshipCheck,
        _handleOnSelectedAutoshipChange,
        errorAddressData,
        normalAddressError,
        useDefaultAddressOrNot, setUseDefaultAddressOrNot,
        frequencyTYpe, setFrequencyType,
        _callAutoshipCalculateApi,
        savedThisCard, setSavedThisCard,
        savedCards, _handleCardChange,
        selectedCard: selectedCard?.nextAmount, setSelectedCard,
        _addNewCard, setOpenDialog, openDialog,
        deletePopUp, _openCloseAskPopup,
        selectedOptionForPayment, setSelectedOptionForPayment, selectedPaymentOption,
        selectedDate, setSelectedDate,
        handleChangeFunction, _setUseDefaultAddressFunction
    });
}

export default useAutoshipEditHook;