import React from 'react'
import styled from 'styled-components'

const PointCollectionCss = styled.div`
    margin: 22px 0 30px;
    width: 100%;
    max-width: 50%;
    .inner_point_container {
        padding: 20px 20px;
        width: 100%;
        box-shadow: 0 0 5px 0px #ddd;

        h3 {
            padding-bottom: 10px;
            margin-bottom: 10px;
        }

        .point_and_collect {
            
            .available_points {
                font-size: 20px;
            }

            .checkbox_label {
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 20px 0px 15px;

                p {
                    margin:0;
                    font-size:18px;
                    letter-spacing: 1px;
                }

                input[type='checkbox']{
                    width: 24px;
                    height: 24px;
                    position: relative;
                    top: -4px;
                }
            }
        }

        .fillCount {
            display: grid;
            gap: 6px;

            input[type='text'] {
                border:1px solid rgb(196, 196, 196) !important;
                padding: 10px 10px 5px;
                font-size: 18px;
                width: 100%;
                min-height: 45px;
            }
        }
    }
    @media (max-width: 660px){
        max-width: 100%;
    }

`;

const PointCollection = ({ calculatedData, usePoint, setUsePoint }) => {
    const pointAmount = calculatedData?.pointAccounts?.length > 0 ? calculatedData?.pointAccounts[0] : 0
    return (
        <PointCollectionCss className='main_pointSection'>
            <div className='inner_point_container'>
                <h3>Points</h3>
                <div className='point_and_collect'>
                    <div className='available_points'>
                        <span>Available : </span> <span>$ {pointAmount?.maxAmount}</span>
                    </div>
                    <label htmlFor='useCheck' className='checkbox_label'>
                        <input
                            id="useCheck"
                            type="checkbox"
                            checked={usePoint?.wantToUSePoint}
                            onChange={(e) => {
                                setUsePoint((prv) => ({
                                    wantToUSePoint: e.target.checked,
                                    paymentObject: { ...prv?.paymentObject, maxAmount: '', pointAccountType: pointAmount?.pointGroupId }
                                }));
                            }}
                        />
                        <p>Use Points</p>
                    </label>
                </div>
                {(usePoint?.wantToUSePoint) &&
                    <div className='fillCount'>
                        <label htmlFor='writeAmount'>Fill the amount</label>
                        <input
                            type="text"
                            value={String(usePoint?.paymentObject?.maxAmount)}
                            id="writeAmount"
                            onChange={(e) => {
                                if ((e.target.value !== '') && (+e.target.value !== 0)) {
                                    if (!isNaN(e.target.value)) {
                                        if (!(+e.target.value > +pointAmount?.maxAmount)) {
                                            setUsePoint((prv) => ({ ...prv, paymentObject: { ...prv?.paymentObject, maxAmount: +e.target.value } }));
                                        }
                                    } else {
                                        setUsePoint((prv) => ({ ...prv, paymentObject: { ...prv?.paymentObject, maxAmount: '' } }));
                                    }
                                } else {
                                    setUsePoint((prv) => ({ ...prv, paymentObject: { ...prv?.paymentObject, maxAmount: '' } }));
                                }
                            }} />
                    </div>
                }
            </div>
        </PointCollectionCss>
    )
}

export default PointCollection