import React from 'react';
import propTypes from 'prop-types';
import { NavLink } from 'react-router-dom';


function NavLinkComp({ children, activeClassName, url }) {
    return (
        <NavLink
            to={url}
            className={({ isActive }) => isActive ? activeClassName : ''}
        // style={({ isActive }) =>
        //     isActive ? activeStyle : undefined
        // }
        >
            {children}
        </NavLink>
    );
}

NavLinkComp.propTypes = {
    activeClassName: propTypes.string,
    url: propTypes.string,
    text: propTypes.string
};
export default NavLinkComp;