import _ from 'lodash';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { storeAssigned } from '../../services/Methods/normalMethods';
import DetoxificationStyleCss from './style/Detoxification.style'

const Detoxification = () => {
    const navigate = useNavigate();
    const Unique_SECTION = [
        { image: require('./assets/Group1.png'), para: "Our premium quality products are manufactured in Utah, USA." },
        { image: require('./assets/Group2.png'), para: "Most innovated beauty product featured in Elle Magazine." },
        { image: require('./assets/Group3.png'), para: "Quality Management System" },
        { image: require('./assets/Group4.png'), para: "Superlative Quality & Compliance" },
    ];

    const RecommendedProduct = () => {
        navigate(`/${storeAssigned()}/shop/products/1077/liver_cleanse_program™`)
    }

    const CalciumDisodium = () => {
        navigate(`/${storeAssigned()}/shop/products/1108/abeytu_organism_detox_package`)
    }
    const KronuitFire = () => {
        navigate(`/${storeAssigned()}/shop/products/1110/detoxify_and_renew_package`)
    }


    return (
        <DetoxificationStyleCss>
            <>
                <section>
                    <div className="allProduct_banner-section">
                        <figure>
                            <img className='img-fluid w-100' src={require("./assets/landingpageHero_new.jpg")} alt="allproduct page" />
                        </figure>
                    </div>
                </section>

                <section className='detoxification_sec'>
                    <div className="wellness-capsule-section container">
                        <h2 className="text-center common_h2">
                           Abeytu´ Naturals&reg; Detoxification and Regeneration Programs
                        </h2>
                        <p className="text-center common_p">We don’t want to scare you in to detoxing your body;<br></br> we want to empower you.</p>
                    </div>

                </section>

                <section className='Why_detox' style={{
                    backgroundImage: `url(${require('./assets/naturesetting-min.jpg')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: '100% 100%',
                }}>
                    <div className="container">
                        <div className="detox_sec">
                            <h2 className="text-center common_h2">Why Detox?</h2>
                            <p className='common_p'>We live in a toxic world but moreso a very environmentally toxic country; as most of us know, “by design”.
                                “The <a href='#'>Deagel Corporation </a>is a minor branch of US military intelligence, one of the many secretive organizations which collect data for high-level
                                decision-making purposes and prepares confidential briefing documents for agencies like the National Security Agency, the United Nations,
                                and the World Bank.” <a href='#'>Deagel’s Depopulation Forecast confirmed by Heavily Censored Pfizer Documents – The Expose (expose-news.com). </a>
                                This report forecasts a reduction in the United States population by 68.5% by 2025, a reduction of 77.1% in the UK, Germany by 65.1%,
                                and Australia by 34.6%.
                                <b>Ah….. but we don’t live in fear, we live in strength,
                                    awareness, love, and perseverance. We are ready for this time in history! We are going to rebuild and defend our biology.</b></p>
                        </div>
                    </div>
                </section>

                <section className='empowerment_sec'>
                    <div className="container">
                        <div className="wrapper_sec d-flex flex-wrap">
                            <div className="left-sec">
                                <h2 className='common_h2'>Empowerment Through Detoxification</h2>
                                <p className='common_p'>
                                    The Abeytu´ Naturals&reg; scientific team has been working for nearly 4 years to help people recover from, and cope with; symptoms from the “plandemic”
                                    and genetic experimentation that has been carried out on global populations. Through microscopic analysis and testing; along with collaborative
                                    relationships over the years, we are proud to recommend tailored detoxification and regenerative protocols. These protocols assist with purification
                                    of the body, elevating consciousness, and taking back our innate internal power one cell at a time.</p>
                            </div>
                            <div className="right-sec">
                                <img className='img-fluid w-100' src={require("./assets/womenMeditation.jpg")} alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className='our_premium'>
                    <div className="container">
                        <div className="inner_wraper">

                            {_.map(Unique_SECTION, ({ image, para }, index) => (
                                <div className="box">
                                    <img src={image} />
                                    {/* <h6>{para}</h6> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className='key_prog_sec'>
                    <div className="container">
                        <div className="inner_sec">
                            <h2 className='common_h2 text-center'> Key Programs and Products</h2>
                            <p className='common_p text-center'>Abeytu´ Naturals&reg; website hosts a range of the best detoxification products on the market that we have personally tested. When you purchase one of our
                                detoxification or regenerative products, you receive a detailed protocol on best practices for use.</p>
                            <div className="inner_div d-flex flex-wrap">
                                <div className="card-sec bg-white">
                                    <img className='img-fluid' src={require("./assets/Image-min.png")} alt="" />
                                    <div className="descrip">
                                        <div className='first_div'>
                                            <h3>Liver Cleanse by Global Healing </h3>
                                            <p>A comprehensive plan to cleanse the liver and eliminate toxins.</p>
                                            <a className='read-link' href={`/${storeAssigned()}/shop/products/1077/liver_cleanse_program™`}>Read More</a>
                                        </div>
                                        <div className="last_sec">
                                            <p>Recommended Product</p>
                                            <h4>InsuLess™</h4>
                                            <button className='link_button_new' onClick={RecommendedProduct}> Shop Now</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-sec bg-white">
                                    <img className='img-fluid' src={require("./assets/Image-1-min.png")} alt="" />
                                    <div className="descrip">
                                        <div className='first_div'>
                                            <h3>Abeytu´ Organism Detox Package </h3>
                                            <p>Tailored to target synthetic
                                                organisms and contaminants.</p>
                                            <a className='read-link' href={`/${storeAssigned()}/shop/products/1108/abeytu_organism_detox_package`}>Read More</a>
                                        </div>
                                        <div className="last_sec">
                                            <p>Products Included:</p>
                                            <h4>InsuLess™, Para 1, Calcium
                                                Disodium EDTA, Toxin Binder &
                                                Paratrex II</h4>
                                            <button className='link_button_new' href={`/${storeAssigned()}/shop/products/1108/abeytu_organism_detox_package`} onClick={CalciumDisodium}> Shop Now</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-sec bg-white">
                                    <img className='img-fluid' src={require("./assets/Image-2-min.png")} alt="" />
                                    <div className="descrip">
                                        <div className='first_div'>
                                            <h3>Detoxify and Renew Package </h3>
                                            <p>Focuses on eliminating inorganic tech contaminants and supporting a healthy microbiome.</p>
                                            <a className='read-link' href={`/${storeAssigned()}/shop/products/1110/detoxify_and_renew_package`}>Read More</a>
                                        </div>
                                        <div className="last_sec">
                                            <p>Partner Products:</p>
                                            <h4>Belage, Kronuit Fire, and Inner 7,
                                                combined with InsuLess™ for enhanced effects.</h4>
                                            <button className='link_button_new' onClick={KronuitFire} > Shop Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className='liver-cleanse-section'>
                    <div className='container'>
                        <h3>Liver Cleanse by Global Healing</h3>
                        <div className='liver-clearnse-content-section'>
                            <div className="wrapper_sec d-flex justify-content-between align-items-center flex-wrap">
                                <div className="left-sec">
                                    <img className='img-fluid w-100' src={require("./assets/Image-4-min.png")} alt="" />
                                </div>
                                <div className="right-sec">
                                    {/* <h2 className='common_h2'>Empowerment Through Detoxification</h2> */}
                                    <p className='common_p'>
                                        Step 1! This comprehensive plan involves adhering to a healthful diet and liver-supporting supplements, stimulating your liver and softening buildup, followed by a detoxifying flush to eliminate toxins. We STRONGLY recommend InsuLess™ by Abeytu´ Naturals&reg; as a ride along product during your liver cleanse, to support phase 1 & 2 detox pathways and tissue regeneration.
                                    </p>
                                    <button className='link_button_new' onClick={RecommendedProduct} > Shop Liver Cleanse</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='orgisim-detox-section'>
                    <div className='container'>
                        <h3>Abeytu´ Organism Detox Package</h3>
                        <div className='orgisim-detox-content-section'>
                            <div className="wrapper_sec d-flex flex-wrap justify-content-between" >
                                <div className="left-sec">
                                    {/* <img className='img-fluid' src={require("./assets/landingpageHero.jpg")} alt="" /> */}
                                    <div className="descrp">
                                        <p className='common_p pt-5'>
                                            1.5 yrs went into testing and perfecting the right products to make the most impact on organic, hybrid, and synthetic organisms. This detox package includes Mimosa Pudica, MicroSomal® InsuLess™, MicroSomal®EDTA, MicroSomal®Toxin Binder, and MicroSomal®Paratrex II. Once purchased, you will receive a detailed Abeytu´ suggested protocol. We suggest being committed to at least 6 months of organism detoxing, and of course go at your own pace.
                                        </p>
                                        <p className='common_p pt-4'>
                                            Para 1 Mimosa Pudica. CellCore Para 1 consists of mimosa pudica for the purpose of suffocating and dragging the organisms from the intestinal wall.
                                        </p>

                                    </div>

                                </div>

                                <div className="right-sec">
                                    {/* <h2 className='common_h2'>Empowerment Through Detoxification</h2> */}
                                    <img className='img-fluid mb-2 w-100' src={require("./assets/Image-3-min.png")} alt="" />
                                    <button className='link_button_new mt-5' onClick={CalciumDisodium}> Shop Organism Detox Package</button>
                                </div>

                                <div className="full_sec w-100 pt-5">
                                    <p className='common_p'>
                                        InsuLess™. Critical to control glucose during detox to reduce stress on the immune system. The immune system is responsible in part for repairing tissue using the 4 pillars of anti -aging. InsuLess™ works simultaneously through the Liver, Kidneys, Immune, Metabolic systems to rebuild tissue. Contains essential nutrients intracellular glutathione and selenium to help with phase 1 & 2 liver detoxification. Assists the body to help itself with inflammatory modulation. Sugar is the most prevalent monomer; builds polymers, which hydrogel blood contaminants are composed of. Parasites are fueled by sugar AND Polymers
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='sec-sugar'>
                    <div className="container">
                        <div className='orgisim-center-image'>
                            <img className='img-fluid mx-auto d-flex' src={require("./assets/OrganismDetoxDiagram-min.png")} alt="" />
                        </div>
                        <div className='orgisim-other-contents-cstm pt-5'>
                            <p className='common_p pb-3'>Paratrex II MicroSomal®. Concentrated essential oils targeting various lifecycles of organisms INCLUDING hybrid intestinal organisms using synthetic means and products for proliferation.</p>


                            <p className='common_p pb-3'>
                                EDTA MicroSomal®. Assisting with metals and hydrogels in the body. Harmful organisms depend in part on a highly acidic environment. Many organic and inorganic organism/tech contaminants depend on a low PH environment. Pair with Vitamin C for enhanced remediation.
                            </p>


                            <p className='common_p pb-3'>
                                Toxin Binder MicroSomal®. Incredible blend of Ozonated Activated Charcoal, Supercharged C60 carbon nano onion(binds graphene), Organic broccoli sprouts, Fulvic and Humic acid blend, Chlorella, and more. Toxin Binder is utilized to bind and attract inorganic technology and remove heavy metals.
                            </p>
                            <small>**Not currently in our kit but available separately on our website and necessary, Oxy Powder. Oxy Powder is a tesla formulation by Global Healing used as a GI cleanse, and to help remove the hybrid organisms from the intestinal tract.</small>
                        </div>
                    </div>
                </section>

                <section className='renew-package-section'>
                    <div className='container'>
                        <h3>Abeytu´ Detoxify and Renew Package</h3>
                        <div className='liver-clearnse-content-section'>
                            <div className="wrapper_sec d-flex flex-wrap justify-content-between">
                                <div className="left-sec">
                                    <img className='img-fluid w-100' src={require("./assets/Image-5-min.png")} alt="" />
                                    <button className='link_button_new' onClick={KronuitFire}> Shop Detoxify and Renew Package</button>

                                </div>
                                <div className="right-sec">
                                    <p className='common_p'>
                                        Recent findings suggest a strong adversary against inorganic technology and regeneration of a healthy microbiome using the products in this package. We know a healthy microbiome and gut brain relationship has a great deal to do with our longevity and prevention of disease. We have partnered with Sanki Global to carry the products we feel are the very best at assisting to recolonize and repair the gut; while also breaking down (in some cases dissolving) inorganic tech contaminants. We strongly suggest using InsuLess with the Sanki products to maximize the effects on the Nrf2 longevity signaling pathway.
                                    </p>
                                </div>

                            </div>
                            <div className='renew-packages-other-content'>
                                <p className='common_p'>
                                    ***Sanki is a multilevel marketing company just beginning to make their mark in the USA. After 40 yrs and 40,000 clinical studies,
                                    Sanki is proving to be a leader in longevity and protection of our microbiome. If you purchase a Detox and Renew package and love
                                    the way you feel, you have the opportunity to also distribute Sanki products.
                                    Please write to info@abeytunaturals.com for a referral link which will allow you discounts on your Sanki products.
                                </p>
                            </div>

                            <div className='renew-packages-key-points'>
                                <h3><b>Belage</b></h3>
                                <p className='common_p'>Enhance with bioactive Postbiotics extracted from Olive, Rosemary, and Blue Spirulina. </p>
                                <p className='common_p pt-[15px]'>
                                    100% Natural. We suggest using InsuLess with Belage for maximum energy and cellular support. </p>

                                <div className='renew-list-points'>
                                    <p className='common_p'>
                                        Main Benefits:
                                    </p>
                                    <p className='common_p' >
                                        <ul>
                                            <li>Boosts natural energy levels by rejuvenating mitochondria. </li>
                                            <li>Contributes to microcirculation and helps reduce inflammation. </li>
                                            <li>Assists in reactivating the antioxidant system . </li>
                                        </ul>
                                    </p>
                                </div>


                            </div>

                            <div className='renew-packages-key-points'>
                                <h3><b>Kronuit Fire </b></h3>
                                <p className='common_p'>Harnesses the critical overnight hours to optimize your body’s natural metabolic processes, enhancing health and supporting weight management. </p>
                                {/* <p className='common_p pt-[15px]'>
                                    100% Natural. We suggest using InsuLess with Belage for maximum energy and cellular support. 
                                    </p> */}

                                <div className='renew-list-points'>
                                    <p className='common_p'>
                                        Main Benefits:
                                    </p>
                                    <p className='common_p' >
                                        <ul>
                                            <li>Helps reduce the absorption of carbohydrates and sugars from food.</li>
                                            <li> Stimulates the activation of the natural thermogenesis process & melatonin release. </li>
                                            <li>Contributes to restful sleep while helping decrease oxidation. </li>
                                        </ul>
                                    </p>
                                </div>


                            </div>


                            <div className='renew-packages-key-points'>
                                <h3><b>Inner 7 </b></h3>
                                <p className='common_p'>This product is designed to combat mineral deficiencies while promoting balance and health in various bodily systems. 60+ minerals, 174 post biotic metabolites, award winning magnesium delivery.</p>
                                {/* <p className='common_p pt-[15px]'>
                                    100% Natural. We suggest using InsuLess with Belage for maximum energy and cellular support. 
                                    </p> */}

                                <div className='renew-list-points'>
                                    <p className='common_p'>
                                        Main Benefits:
                                    </p>
                                    <p className='common_p' >
                                        <ul>
                                            <li> Aids in maintaining the body’s alkalinity to balance pH levels.</li>
                                            <li> Promotes stability of the Hormonal System. </li>
                                            <li>Supports healthy digestion.</li>
                                        </ul>
                                    </p>
                                </div>


                            </div>


                        </div>
                    </div>
                </section>


                <section className='revitize-health-section'>
                    <div className='container'>
                        <div className='revitize-content-section'>
                            <p className='common_p'>
                                Discover the benefits of our Detoxify Package and feel the difference!
                            </p>
                            <h2>Revitalize Your Health Today!</h2>
                        </div>
                    </div>
                </section>

                <div className="footer_sec">
                    <div className="container">
                        <div className="inner_wrapper">
                            <div className="img_sec">
                                <img src='/images/headerLogo.png' alt="headerLogo" />

                            </div>

                            <div className="discription_sec">
                                <p>For questions and additional information, contact us at info@abeytunaturals.com.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </DetoxificationStyleCss>
    )
}
export default Detoxification;