import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import withoutAuth from "../../services/Auth/withoutAuth";
import NewPassWord from "./components/NewPassword";
import ResetPassWord from "./components/ResetPassWord";
import ResetPasswordhook from "./components/ResetPassWord.hook";
import ResetEmailSectionStyle from './style/ResetEmail.style';

const ResetPasswordPage = (props) => {
    const { emailvalidation, _onHandleEmailSubmit, newpasswordvalidation, _onHandlePasswordSubmit, emailState } = ResetPasswordhook(props);

    const getEmailFromLocalStorage = () => {
        const data = localStorage.getItem('TokenEmail');
        return data ? data : '';
    };
    const [email, setEmail] = useState(getEmailFromLocalStorage());

    return (
        <ResetEmailSectionStyle>
            <div className="maine_loginRegister">
                {!email &&
                    <ResetPassWord {...{ emailvalidation, _onHandleEmailSubmit, emailState, ...props }} />
                }
                {email &&
                    <NewPassWord {...{ newpasswordvalidation, _onHandlePasswordSubmit, emailState, ...props }} />}

            </div>
        </ResetEmailSectionStyle>
    )
}

export default withoutAuth(ResetPasswordPage);