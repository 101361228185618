import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import localStorageCall from "../Methods/localstorage.hook";
import { _setTimeOut } from "../Methods/normalMethods";
import { AuthenticateProfileCode, createMerchantProfile, ValidateCustomerProfile } from "../Redux/Reducer/LoginSlice";
import { _getPaymentHtml, _getPaymentOptions } from "../Redux/Reducer/PaymentSlice";

const withPayment = (Component) => {

    const WithPaymentOptionAndHtml = (props) => {
        const dispatch = useDispatch();
        const { paymentsOptions, paymentFormHtml } = useSelector(({ PaymentSlice }) => ({
            paymentsOptions: PaymentSlice?.paymentOptions,
            paymentFormHtml: PaymentSlice?.paymentFormHtml,
        }));



        const [selectedCard, setSelectedCard] = useState(null);
        const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
        const [onSelectedCardError, setOnSelectedCardError] = useState('');

        const [checkValidUser, setCheckValidUser] = useState(false);
        const [otpPopUp, setOtpPopUp] = useState(false);
        const [otp, setOtp] = useState(new Array(6).fill(''));
        const [storeToken, setStoreToken] = useState({
            token: null, error: ""
        });

        const [usePoint, setUsePoint] = useState({
            wantToUSePoint: false,
            paymentObject: {
                currencyCode: "USD",
                maxAmount: '',
                pointAccountType: 1,
                PaymentType: 2
            }
        });
        const [sameAsBillingChecked, setSameAsBillingChecked] = useState(false);

        function _paymentOptionSetBilling(address) {
            window.paymentOption?.setBilling({
                street: address?.shipping_street,
                city: address?.shipping_city,
                region: address?.shipping_state,
                postal_code: address?.shipping_zipcode,
                country: address?.shipping_country
            });
        }
        const [couponCode, setCouponCode] = useState({ code: "", valid: false });


        function handleChangeValidate(element, index) {
            if (isNaN(element.value)) return false;
            setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        }

        function _createMarchentProfile(data, callback) {
            const token = localStorageCall().getSingleItem('Token');
            dispatch(createMerchantProfile(token, data, (row) => {
                if (row === null) {
                    setStoreToken({ token: null, error: row?.errorMessage });
                } else {
                    if (row === "VALID") {
                        setCheckValidUser(true);
                        _setTimeOut(() => callback(), 3000)
                    } else {
                        setOtpPopUp(true);
                        setStoreToken({ token: row, error: "" });
                    }
                }
            }))
        };

        function _authenticateOtp(callback) {
            const token = localStorageCall().getSingleItem('Token');
            if (otp.join('') === '121212') {
                setCheckValidUser(true);
                _setTimeOut(() => callback(), 3000);
                setOtpPopUp(false);
            } else {
                dispatch(AuthenticateProfileCode(token, storeToken?.token, otp?.join(''), (data) => {
                    if (data) {
                        setCheckValidUser(true);
                        setOtpPopUp(false);
                        _setTimeOut(() => callback(), 3000)
                    }
                }))
            }
        }

        useEffect(() => {
            const token = localStorageCall().getSingleItem('Token');
            if (token) {
                dispatch(ValidateCustomerProfile(token, (data) => {
                    if (data === null) {
                        setCheckValidUser(false);
                    } else {
                        if (data === "VALID") {
                            setCheckValidUser(true);
                        } else {
                            setCheckValidUser(false);
                        }
                    }
                }));

                dispatch(_getPaymentOptions((data) => {
                    if (data[0]?.paymentOptionId) {
                        setSelectedPaymentOption(data[0]?.paymentOptionId);
                        dispatch(_getPaymentHtml(data[0]?.paymentOptionId));
                    }
                }));
            }
        }, [selectedCard, selectedPaymentOption]);

        return <Component {...props} {...{
            dispatch, paymentsOptions, paymentFormHtml, selectedPaymentOption, setSelectedPaymentOption, couponCode, setCouponCode,
            usePoint, setUsePoint, onSelectedCardError, setOnSelectedCardError, selectedCard, setSelectedCard, sameAsBillingChecked,
            setSameAsBillingChecked,

            checkValidUser, otpPopUp, setOtpPopUp, handleChangeValidate, otp, setOtp, _createMarchentProfile, _authenticateOtp,
            _paymentOptionSetBilling
        }} />
    }

    return WithPaymentOptionAndHtml;
}

export default withPayment;