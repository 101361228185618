import withAuthRedirect from "./withAuthRedirect";

const withoutAuth = (WrappedComponent, location = "/") => {
    return withAuthRedirect({
        WrappedComponent,
        expextedAuth: false,
        location,
    });
};

export default withoutAuth;
