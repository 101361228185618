import { CircularProgress, Rating } from '@mui/material';
import _ from 'lodash';
import SlickSliderComp from '../../../Components/SlickSliderComp';
import { HomeSecondSectionCss } from '../style/HomePage.style';
import { handleRoute, storeAssigned } from '../../../services/Methods/normalMethods';


const HomeSecondSection = (props) => {

    const { CUSTOMER_RATING, sliderRef, next, creamData, previous, navigate, scrollId } = props;

    return (
        <HomeSecondSectionCss>
            {/* <div className="top-rated-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="top-rated-text">
                                <h2>Top Rated Creams</h2>
                                <div className="creams-text">
                                    {(creamData?.data?.length > 0)
                                        ?
                                        creamData?.data?.map((items, index) => {
                                            return (
                                                <div className="product1" key={"toprated" + index}>
                                                    <div className="img-product">
                                                        <img src={items?.mediumImage} style={{ width: "90%" }} />
                                                    </div>
                                                    <h3>{items?.title}</h3>
                                                    <div className="show-all">
                                                        <a href={`/${storeAssigned()}/shop/Products/${items?.itemId}/${_.lowerCase(items?.title)?.replaceAll(/ /g, '_')}`} className="btn btn-show">Shop Now</a>                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <CircularProgress />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="ingredients-section">
                <div className="ingredients-text">
                    <iframe src="https://player.vimeo.com/video/832318193?autoplay=1&loop=1&autopause=0&muted=1" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className="ingredients-text-now"><p>Made in the USA with premium ingredients.</p>
                    <div className='ingredients-btn'><a className="btn btn-gredients" onClick={() => handleRoute('ourStory', 'about_us')}>READ MORE</a></div>

                </div>
            </div>

            <div className="client-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="client-text">
                                <h3>CUSTOMER TESTIMONIALS</h3>
                                <h2>What our clients are saying…</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testimonial-slider" id={['#review'].includes(scrollId) ? scrollId : ''}>
                    <div className="owl-carousel owl-theme">
                        <SlickSliderComp ref={sliderRef} firstTimeDisplay={3}>
                            {_.map(CUSTOMER_RATING, ({ rating, message, name, product_name }, index) => (

                                <div className="item" key={"rating" + index}>
                                    <div className="client-review">
                                        <div className="client-name">
                                            {/* <p style={{ marginBottom: 0 }}><b>{product_name}</b></p>*/}
                                        </div>
                                        <div className="star-review">
                                            <Rating name="rating" value={rating} readOnly
                                            // onChange={(event, newValue) => {
                                            //     setRating(newValue);
                                            // }}
                                            />
                                        </div>
                                        <h6>{message}</h6>
                                        <div className="client-name">
                                            <h3>{name}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </SlickSliderComp>
                    </div>
                </div>
            </div>
        </HomeSecondSectionCss>
    )
}
export default HomeSecondSection;