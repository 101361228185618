import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import * as Sentry from "@sentry/react";

// ...

const sentryReduxEnhancer = Sentry.createReduxEnhancer();
const store = configureStore({
    reducer: rootReducer,
    // middleware: (gDM) => gDM().concat(logger)
    enhancers: [sentryReduxEnhancer]
})

export default store;