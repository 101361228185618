import React from 'react'
import { Box, Stepper, Step, StepLabel, Grid, CircularProgress } from '@mui/material';
import { LoyaltyProgramStyle } from './components/LoyaltyProgram.style';
import { Form, Formik } from 'formik';
import ButtonComponent from '../../inputs/ButtonCompnent';
import { useOutletContext } from 'react-router-dom';
import _ from 'lodash';
import LoaderComponent from '../../Layout/components/Footer/components/Loader';
import { REFFERAL_VALUES } from '../../services/Methods/normalMethods';
import SummerySection from '../EnrollmentSignup/components/SummerySection';
import UserInfo from '../EnrollmentSignup/components/UserInfo';
import EmailVerify from '../EnrollmentSignup/components/EmailVerify';
import OneTimePass from '../EnrollmentSignup/components/OneTimePass';
import AutoshipPack from '../EnrollmentSignup/components/AutoshipPack';
import useLoyalitySignUpHook from './components/LoyaltyProgram.hook';
import withPayment from '../../services/HOC/withPaymentsHOC';

const formContent = (step, { payload, userCanLogin, ProductsDetail, cartValues, setCartValues, currentInitialValue, fieldValue, countryCodes }) => {
    const {
        selectedAutoshipProducts, _addAutoshipProducts, _handleOnAutoshipChange, paymentFormHtml, paymentsOptions,
        handleChangeFunction, setActiveStep, checkoutObject, _validateRefferalFunction, webAliesError,
        setCountryState, countryState, emailExistError, validate, setOtp, handleChangeValidate, otp, valuesShip,
        _orderTodayFunction, orderNowProduct, autoshipQuantity, _increaseQuantity, _deleteProductFunction, _increseCheckoutQuantity,
    } = payload;

    if (process.env.REACT_APP_OTP_ENABLE === "true") {
        switch (step) {
            case 0:
                return <EmailVerify {...{ emailExistError, fieldValue }} />;
            case 1:
                return <UserInfo {...{ countryCodes, userCanLogin, _validateRefferalFunction, webAliesError, countryState, setCountryState, fieldValue, section: "loyalty" }} />;
            case 2:
                return <OneTimePass {...{ setActiveStep, otp, setOtp, handleChangeValidate }} />
            case 3:
                return <AutoshipPack {...{
                    orderNowProduct, autoshipQuantity, _orderTodayFunction, ProductsDetail, _addAutoshipProducts,
                    selectedAutoshipProducts, _handleOnAutoshipChange, fieldValue, _increaseQuantity
                }} />;
            case 4:
                return <SummerySection {...{
                    countryCodes, selectedAutoshipProducts, currentInitialValue, cartValues, setCartValues, checkoutObject, fieldValue, valuesShip,
                    handleChangeFunction, paymentFormHtml, paymentsOptions,
                    _deleteProductFunction, _increseCheckoutQuantity
                }} />;
            default:
                return <div className='not-found'>404: Not Found</div>
        }
    } else {
        switch (step) {
            case 0:
                return <EmailVerify {...{ emailExistError, fieldValue }} />;
            case 1:
                return <UserInfo {...{ countryCodes, userCanLogin, _validateRefferalFunction, webAliesError, countryState, setCountryState, fieldValue, section: "loyalty" }} />;
            case 2:
                return <AutoshipPack {...{
                    orderNowProduct, autoshipQuantity, _orderTodayFunction, ProductsDetail, _addAutoshipProducts,
                    selectedAutoshipProducts, _handleOnAutoshipChange, fieldValue, _increaseQuantity
                }} />;
            case 3:
                return <SummerySection {...{
                    countryCodes, selectedAutoshipProducts, currentInitialValue, cartValues, setCartValues, checkoutObject, fieldValue, valuesShip,
                    handleChangeFunction, paymentFormHtml, paymentsOptions,
                    _deleteProductFunction, _increseCheckoutQuantity
                }} />;
            default:
                return <div className='not-found'>404: Not Found</div>
        }
    }

};

const LoyaltyProgramSignup = (props) => {
    const { cartValues, setCartValues, valuesShip, ProductsDetail, token, countryCodes, Dates } = useOutletContext();

    const { paymentFormHtml, paymentsOptions, ...otherProps } = props;
    const {
        commonLoader, steps, isLastStep, activeStep, setActiveStep, _handleSubmit, _handleBack, currentValidationSchema,
        currentInitialValue, _handleCallCalculate, _handleOtpValidate, signUpLoading, checkoutLoading, errorMsg, userCanLogin, ...others
    } = useLoyalitySignUpHook({ token, Dates, countryCodes, cartValues, setCartValues, paymentFormHtml, ProductsDetail, valuesShip, ...otherProps });


    return (
        <LoyaltyProgramStyle>
            {(signUpLoading || checkoutLoading || commonLoader) && <LoaderComponent />}
            <div className={activeStep === 3 ? 'linear-form checkout_main_div_add' : 'linear-form'}>
                <Box sx={{ maxWidth: '600px', padding: 2 }}>
                    <Stepper activeStep={activeStep} orientation="horizontal">
                        {steps?.map((label, index) => (
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box component="div">
                        <Grid item xs={12} sx={{ padding: '20px' }}>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    ...currentInitialValue,
                                    refferal: REFFERAL_VALUES?.webalies,
                                }}
                                validationSchema={currentValidationSchema}
                                onSubmit={(values, action) => {
                                    _handleSubmit(values, action)
                                }}
                            >
                                {({ isSubmitting, ...fieldValue }) => (
                                    <>
                                        {(activeStep < 2) ?
                                            <>
                                                <Form>
                                                    {formContent(activeStep, {
                                                        ...{
                                                            currentInitialValue, valuesShip, setCartValues, cartValues, userCanLogin,
                                                            setActiveStep, payload: { ...others, paymentFormHtml, paymentsOptions }, fieldValue: fieldValue, ProductsDetail, countryCodes
                                                        }
                                                    })}
                                                    <div className="product-button-back">
                                                        {(activeStep !== 0 && !token?.token) && (
                                                            <ButtonComponent onClick={_handleBack} className="">Back</ButtonComponent>
                                                        )}
                                                        <ButtonComponent type="submit" classes="">Next</ButtonComponent>
                                                    </div>
                                                </Form>
                                                {<p className='' style={{ color: "red" }}>{errorMsg}</p>}
                                            </>
                                            :
                                            <div className='products_section'>
                                                {formContent(activeStep, {
                                                    ...{
                                                        currentInitialValue, valuesShip, setCartValues, cartValues, userCanLogin,
                                                        setActiveStep, payload: { ...others, paymentFormHtml, paymentsOptions }, fieldValue: fieldValue, ProductsDetail, countryCodes
                                                    }
                                                })}
                                                <div className="product-button-back">
                                                    {_.includes(["Autoship", "Summary"], steps[activeStep]) && (
                                                        <ButtonComponent onClick={_handleBack} className="">Back</ButtonComponent>
                                                    )}
                                                    {
                                                        (!isLastStep) &&
                                                        (<ButtonComponent classes="" onClick={() => {
                                                            if (isLastStep) {

                                                            } else if (others?.validate === "VALID" && steps[activeStep] === "Validate") {
                                                                _handleOtpValidate();
                                                            } else {
                                                                if (((steps[activeStep]) === 'Autoship')) {
                                                                    _handleCallCalculate();
                                                                }
                                                                setActiveStep(activeStep + 1);
                                                            }
                                                        }}>Next</ButtonComponent>)
                                                    }
                                                </div>
                                                {<p className='' style={{ color: "red" }}>{errorMsg}</p>}
                                            </div>
                                        }
                                    </>
                                )}
                            </Formik>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </LoyaltyProgramStyle >
    )
}

export default withPayment(LoyaltyProgramSignup);