import { Rating } from "@mui/material";
import parse from 'html-react-parser';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import ButtonComponent from "../inputs/ButtonCompnent";
import withRouter from '../services/HOC/withRouterHOC';
import { storeAssigned, _productPrice } from '../services/Methods/normalMethods';
import { getAllProductApi } from "../services/Redux/Reducer/AllProductSlice";
import { CircularProgress } from '@mui/material';


const TopRelatedProductCss = styled.div`
    .second_container_for_second {
        padding: 100px 0;
        @media(max-width:1199px){
            padding: 30px 0px;
        }
        h2{        
            text-align: center;
        }

        .circular-gifs{
            display: flex;
            gap: 85px;
            position: absolute;
            left: 0px;
            right: 0px;
            margin: 0px auto;
            -webkit-box-pack: center;
            justify-content: center;
        }
        

        .main_cardSection {
            display: grid;
            margin: 70px 0px;
            -webkit-box-pack: justify;
            justify-content: space-between;
            gap: 20px;
            grid-template-columns: repeat(3, 1fr);
            @media(max-width:991px){
                grid-template-columns: repeat(2, 1fr);
            }
            @media(max-width:767px){
                grid-template-columns: repeat(1, 1fr);
                max-width:85%;
                margin: 0 auto;
            }
            @media(max-width:568px){
                max-width:100%;
                margin: 20px auto 0;
            }
            .cardSection{
                box-shadow: 0px 20px 70px #0000001A;
                text-align: center;
                position: relative;
                padding-bottom: 46px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                .button_css{
                    position: absolute;
                    bottom: 15px;
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                }
            }
            .card_mage {
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                .card_content {
                    background: #fff;
                    padding: 0 27px;
                }
            }
            .card_mage  img{
                max-width: 380px;
                height: 380px;
                width: 100%;
                object-fit: contain;
                padding: 30px;
                @media (max-width:1280px){
                    max-width: 320px;
                    height: 320px;
                }
            }
            .card_content {
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h3 {
                    color: #2A533F;
                    font-size: 28px;
                    text-align: center;
                    font-weight: 600;
                    max-width: 280px;
                    margin: 0 auto;
                    font-family: "Playfair Display";
                    @media(max-width:767px){
                        font-size:18px;
                        max-width:100%  ;
                    }
                }
                span.MuiRating-root.MuiRating-sizeMedium.Mui-readOnly.MuiRating-readOnly.css-ryrseu-MuiRating-root {
                    margin: 20px 0;
                }
                p {
                    strong{
                        font-size: 24px;
                    }
                    font-size: 20px;
                    font-family: "Helvetica Neue LT Std";
                    font-weight: 500;
                    color: rgb(57, 51, 46);
                    height: 75px;
                }

            }
        }

    } 
    @media(max-width:568px){
        .cardSection button{
            font-size:16px ;
            width: 150px ;
            height: 45px ;
        }
        .profile-pic-icon h6 {
            font-size: 16px;
        }
    } 
`;

const TopRelatedProducts = ({ navigate }) => {

    const dispatch = useDispatch();
    const { topProducts } = useSelector(state => state?.AllProductSlice)

    useEffect(() => {
        dispatch(getAllProductApi('topProduct'));
    }, [])


    return (
        <TopRelatedProductCss>
            <div className="second_container_for_second">
                <div className="container">
                    <div className="partnershipProgram">
                        <h2>Top Selling Products</h2>
                    </div>

                    <div className="main_cardSection">
                        {(topProducts?.data?.length > 0) ?


                            topProducts?.data?.map((row, index) => {
                                return (
                                    <div className="cardSection" key={index + 1}>

                                        <div className="card_mage">
                                            <img src={row?.mediumImage} alt="Affiliate Program" />
                                        </div>
                                        <div className="card_content">
                                            <h3>{row?.title}</h3>
                                            <Rating value={5} readOnly />
                                            <p>
                                                {(index === 2) ?
                                                    <>${_productPrice(row?.itemPricing, 'autoship', row?.isEligibleForAutoOrder)} - available on subscription</>
                                                    :
                                                    <>${_productPrice(row?.itemPricing, 'oneTime', row?.isEligibleForAutoOrder)} - available on subscription from ${_productPrice(row?.itemPricing, 'autoship', row?.isEligibleForAutoOrder)} / month</>
                                                }
                                            </p>
                                        </div>
                                        <div className="button_css">
                                            <ButtonComponent onClick={() => navigate(`/${storeAssigned()}/shop/products/${row?.itemId}/${_.lowerCase(row?.title)?.replaceAll(/ /g, '_')}`)}>Select Options</ButtonComponent>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className="circular-gifs">
                                <CircularProgress />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </TopRelatedProductCss>
    )
}

export default withRouter(TopRelatedProducts);