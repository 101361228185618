import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

const ReactPaginateStyle = styled.div`
&&
  .main_pagination_section_class {
    .order_pagination {
      text-align: center;
      width: 100%;
      max-width: 100%;
      margin: 0 auto 0;
      display: flex;
      justify-content: center;
      ul {
        &.pagination {
          width: auto;
          float: left;
          justify-content: center;
          display: flex;
          background: var(--bs-green);
          margin-left: 28px;
          margin: 20px 0;
          padding: 7px 10px;
          border-radius: 25px;
          
          @media (max-width: 1199px){
            max-width: max-content;
             margin-top: 20px !important;
          }
          @media (max-width: 767px){ 
            margin-bottom: 0 !important;
            margin-top: 30px !important; 
            gap: 10px;
          }
          li {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin-bottom: 0;
            &.active {
              background: #fff;
              color: #00356a !important;
              a {
                color: #00356a !important;
              }
            }
            a {
              padding: 5px 10px;
              display: block;
              background: none;
              color: #fff;
              border: none;
              text-transform: capitalize;
              width: 100%;
            }
            &.previous,
            &.next {
              &.disabled {
                display: block !important;
                background-color: #dddd;
                cursor: not-allowed;
                &.svg {
                  cursor: not-allowed;
                }
              }
              background: #fff;
              a {
                color: rgb(42, 83, 63);
                border-radius: 50%;
                height: 100%;
                width: 100%;
                padding: 8px 5px 7px 7px;
                background: #f1f1f1;
              }
            }
          }
        }
      }
    }
  }
`;
const ReactpaginateComp = ({ handlePageClick, pageCount, currentPage }) => {
  return (
    <ReactPaginateStyle>
      <div className="main_pagination_section_class">
        <div className="order_pagination">
          <ReactPaginate
            previousLabel={<ChevronLeft />}
            nextLabel={<ChevronRight />}
            breakLabel={"..."}
            forcePage={currentPage ? currentPage - 1 : 0}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages_pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </ReactPaginateStyle>
  );
};
export default ReactpaginateComp;
