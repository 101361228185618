import styled from "styled-components";

const AboutSectionOneStyleCss = styled.div`

.About_banner_section {
    height: 832px;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: top 140px center;
    .banner-text {
        font-size: 100px;
        width: 100%;
        max-width: 800px;
        margin-bottom: 0;
        font-family: var(--common-font);
        z-index: 99;
        padding: 230px 45px;
        @media(max-width:1199px){
            padding: 150px 15px;
        }
        @media(max-width:568px){
            padding: 76px 15px;
               
        }

        h1 {
            margin-bottom:24px;
            font: normal normal bold 60px/70px Playfair Display;
            letter-spacing: 0px;
            color: #FFFFFF;
            @media(max-width:1199px){
                font: normal normal bold 45px/normal Playfair Display;
                margin-bottom:10px;
        }
        @media(max-width:767px){
                font: normal normal bold 30px/normal Playfair Display;
               
        }
        @media(max-width:568){
                font: normal normal bold 26px/normal Playfair Display;
               
        }
        }

        .text {
            p {
                font-size: 24px;
                margin-bottom: 0;
                color:#F4F0EB;
                font: normal normal normal 24px/36px Helvetica;
                @media(max-width:767px){
                    font: normal normal normal 18px/28px Helvetica;
               
        }
        @media(max-width:568){
            font: normal normal normal 16px/24px Helvetica;
               
        }
            }
        }
    }
}
 
.about_first_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 107px 0 40px;
    max-width:1300px;
    @media (max-width: 1199px){
    max-width: 100%;
    padding: 30px;
}
@media (max-width: 767px){
    padding: 20px;
}

    .feature_launch_product {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        p {
            color: rgb(57,51,46);
            font-size: 20px;
            text-align: center;
            font-weight: 600;
            line-height: 30px;
            @media (max-width: 1199px){
                font-size:24px;
           }
           @media (max-width: 767px){
            font-size: 20px;
    line-height: 32px;
           }
           @media (max-width: 568px){
            font-size: 16px;
            line-height:24px;
           }
        }
    }
    .award_winning {
        display: flex;
        padding: 73px 0;
        @media (max-width: 1199px){
            padding:20px 0;
           }
           @media (max-width: 767px){
         flex-wrap: wrap;
           }

     
        .product_image { 
            max-width: 350px;
             height: 505px;
            width: 100%;
            margin-right: 85px;
            @media (max-width: 1199px){
                max-width: 100%;
            margin-right:38px;
                 }
                 @media (max-width: 767px){
                    margin-right: 0;
                    height: auto;
                 }
            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                @media (max-width: 767px){
                    max-height: 250px;
                    object-fit: contain;
                    margin-bottom: 20px;
                 }
            }
        }
        .product_description {
           max-width: 100%;
           width:100%;

            p {
                font-size: 20px;
                font-family: 'Helvetica';
                font-weight: 400;
                color: rgb(85,85,85);
                letter-spacing: 0.24px;
                margin-bottom: 14px;
                line-height: 32px;
            }
        }
    }
}

.charitable_organization {
    background: #F4F0EB;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 79px;
    margin: 12px 0 0;
    @media (max-width: 1199px){
        padding:30px !important;
           }
           @media (max-width: 767px){
        padding:20px !important;
           }

    h1 {
        letter-spacing: 0;
        font: normal normal bold 30px/50px Playfair Display;
        letter-spacing: 0px;
        color: #2A533F;
        opacity: 1;
        padding: 50px 0 45px;
        @media(max-width:1199px){
            padding: 30px 0;
}
@media(max-width:767px){
    font-size: 26px !important;
    padding: 10px 0;
}
@media(max-width:568px){
    font-size: 22px !important;
    line-height:normal;
}
        }
    }

    .charitable_discription {
        .char_para {
            display: flex;
            justify-content: center;
            gap: 30px;
            @media (max-width:767px){
                gap: 0;
                flex-wrap: wrap;
            }
            p {
                font: normal normal normal 20px/30px Helvetica;
                letter-spacing: 0px;
                color: rgb(85,85,85);
                opacity: 1;
                width: 100%;
                max-width: 622px;
                /* height: 456px; */
                text-align: left;
                margin-bottom:50px;
                font-family: 'Helvetica';
                /* font-weight: 400; */
                @media(max-width:1199px){
                    font: normal normal normal 19px/30px Helvetica;
                       }
                       @media(max-width:767px){
                        max-width: 100%;
                        padding:0;
                   margin-bottom:20px;
                   text-align: center;
                       }
                       @media(max-width:568px){
    font-size: 16px;
}
            }
        }
        button{
            &:hover {
		background: transparent;
        color: #2A533F;
        border:2px solid #2A533F;
	     }
        }
    }



@media(max-width:1800px){
.About_banner_section {
    height: 832px;
}
}

@media(max-width:1500px){
.about_first_container {
    max-width: 1200px;
}
.charitable_discription {
    max-width: 1200px;
    margin: 0 auto;
}
.about_first_container .award_winning .product_description p {
    font-size: 19px;
}
.cEREan .charitable_organization .charitable_discription .char_para p {
    width: 100%;
    max-width: 50%;
    font-size: 20px;
}
}

@media(max-width:1439px){
.about_first_container {
    max-width: 1100px;
    padding: 40px 0px;
}
.about_second_container .ourTeam_section .allexecutive .member_detail .executive_image {
    width: 100%;
}
.About_banner_section {
    height: 740px;
}
.charitable_organization h1    {
    font-size: 45px;
}
}
@media(max-width:1280px){
.about_first_container {
    max-width: 1000px;
}
.About_banner_section {
    height: 620px;
}
}

@media(max-width:1199px){
.operationImage img {
    width: 100% !important;
    max-width: 255px;
}
.about_first_container {
    max-width: 100%;
    padding:30px;
}
}

@media(max-width:1079px){
.About_banner_section {
    background-position: center top 115px;
}
}
@media(max-width:991px){
.About_banner_section {
    height: 400px;
}
}


@media(max-width:767px){
    .about_first_container {
    padding:20px;
}
}
@media(max-width:568px){
.About_banner_section {
    height: 280px;
}
.about_first_container .award_winning .product_description p {
    font-size: 16px;
    line-height: 24px;
}

/* .about_first_container .feature_launch_product p {
    color: rgb(57,51,46);
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    line-height: 30px;
} */
.charitable_discription button {
    font-size: 16px;
    max-width: 200px;
    width: 100%;
}
.About_banner_section {
    background-position: center top 62px;
}
.About_banner_section .banner-text .text p {
    font: 16px / 24px Helvetica;
}
}
@media(max-width:479px){
.about_second_container .ourTeam_section .allexecutive .member_detail {
    max-width: 100%;
}
.About_banner_section {
    height: 217px;
}
 .About_banner_section .banner-text .text p {
    font: 15px / 19px Helvetica;
}
 .About_banner_section .banner-text {
    padding: 76px 0;
}
}

`;

export default AboutSectionOneStyleCss;