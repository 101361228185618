import React from 'react';
import { FloatValues, _deleteProductFromCart } from '../../../services/Methods/normalMethods';
import { Delete } from '@mui/icons-material';
import _ from 'lodash';

const OrderSummery = ({ cartData, setCartValues, _increseCheckoutQuantity, _deleteProductFunction, calculate, subTotal, section, usePoint }) => {
    return (
        <div className='order-summary' style={{ padding: "0" }}>
            <h2>{section} Order Summary</h2>
            <div className='order-data'>
                <table>
                    <tbody>
                        {cartData?.map((items) => {
                            return (
                                <React.Fragment key={items?.id}>
                                    <tr className='all-products' >

                                        <td className='products-details-order'>
                                            <h4> {items?.productName} </h4>
                                            {(!(['member', 'now']?.includes(items?.autoship)) && _increseCheckoutQuantity) ?
                                                <div className="quality-box">
                                                    <div className="input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="quantity-left-minus btn btn-danger btn-number"
                                                                onClick={() => _increseCheckoutQuantity(items?.id, 'sub', items?.autoship)}>
                                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                                            </button>
                                                        </span>
                                                        <input type="text" id="quantity" name="quantity" className="form-control input-number"
                                                            value={items?.quantity}
                                                            min="1" max="100" onChange={(e) => console.log(e)} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="quantity-right-plus btn btn-success btn-number"
                                                                onClick={() => _increseCheckoutQuantity(items?.id, 'add', items?.autoship)}>
                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                                :
                                                <span className='item-qty'>QTY:{items?.quantity}</span>
                                            }
                                            <span className='iteam-para'>{items?.para}</span>
                                        </td>
                                        <td className='item-icon'>
                                            {(!(['member']?.includes(items?.autoship)) && _deleteProductFunction) &&
                                                <span className='delete-icon' onClick={() => {
                                                    _deleteProductFunction(+items?.id, items?.autoship);
                                                }}> <Delete /></span>
                                            }
                                            <span className='price-note'>${items?.price ? FloatValues(items?.price) : ""}</span>
                                        </td>
                                    </tr>
                                    {_.map(items?.subProducts, (row) => (
                                        <tr className='all-products sub_products_values' key={row?.id + 1}>
                                            <td></td>
                                            <td className='products-details-order'>
                                                <h4> {row?.productName} </h4>
                                                {!(['member', 'now']?.includes(row?.autoship)) &&
                                                    <span className='item-qty'>QTY:{row?.quantity}</span>
                                                }
                                                <span className='iteam-para'>{row?.para}</span>
                                            </td>
                                            <td className='item-icon'>
                                                {/*  <span className='price-note'>${row?.price ? FloatValues(row?.price) : ""}</span>*/}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
                <div className='coupon'>
                    {/* <input type="text" name="coupon_id" placeholder="Coupon Code" />
            <button type="button">Apply</button> */}
                </div>

                <div className='price-calulate'>
                    <div className='subtotal-item'>Subtotal: ${FloatValues(calculate?.subTotal)}</div>
                    <div className='subtotal-item'>Shipping: ${calculate?.shippingTotal ? FloatValues(calculate?.shippingTotal) : 0.00}</div>
                    <div className='subtotal-item'>Taxes: ${calculate?.taxTotal ? FloatValues(calculate?.taxTotal) : 0.00}</div>
                    {usePoint?.paymentObject?.maxAmount &&
                        <div className='subtotal-item'>Points Redeemed: -${FloatValues(usePoint?.paymentObject?.maxAmount)}</div>
                    }
                    <div className='subtotal-item'>Total: ${usePoint?.paymentObject?.maxAmount ? FloatValues(calculate?.orderTotal - usePoint?.paymentObject?.maxAmount) : FloatValues(calculate?.orderTotal)}</div>
                </div>
            </div >
        </div >
    )
};
export default OrderSummery