import React from 'react';
import LoginStyle from '../../LoginRegister/style/Login.style';
import TextFieldComponent from '../../../inputs/TextFieldComponent';
import { useFormik } from 'formik';
import { storeAssigned } from '../../../services/Methods/normalMethods';
import EmailStyle from '../style/EmailSection.style';


const initialValues = {
    email: "",
};

const ResetPassWord = ({ emailvalidation, _onHandleEmailSubmit, emailState }) => {

    const { values, errors, touched, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema: emailvalidation,
            onSubmit: (values, action) => {
                _onHandleEmailSubmit(values);
                action.resetForm();
            },
        });

    return (
        <EmailStyle>
            <div className='parent' >
                <form className='form-class' onSubmit={handleSubmit} >
                    <div className='login-section'>
                        <h4 className='pass-recover1' >Hello!</h4>
                        <h3 className='pass-recover'>Password Recovery.</h3>
                    </div>
                    <div className="field-class">
                        <TextFieldComponent
                            type="email"
                            className='input-container'
                            name="email" label='Email address'
                            value={values?.email}
                            onChange={handleChange}
                            error={!!errors?.email}
                            errorMessage={errors?.email}
                        />
                    </div>

                    <button className='button-container-pass' type="submit">GET TEMPORARY CODE</button>
                    {emailState?.emailerror && <div style={{ color: "red", fontSize: "14px", textAlign: "center" }}>{emailState?.emailerror}</div>}

                    <div className='email-temp' style={{ fontSize: "13px", textAlign: "center" }}>An email with a temporary key will be sent to the email address associated with your account</div>
                </form>
            </div >

        </EmailStyle >
    )
}
export default ResetPassWord;