import styled from "styled-components";

const DetoxificationStyleCss = styled.div`

*{
    font-family: 'Playfair Display';
}

h2, h3, h4, h5, h6, p, figure{
    margin:0;
    padding:0;
}
a{
    color: #000;
}
.link_button_new {
    font-family: "Playfair Display";
    font-size: 28px;
    height: auto;
    font-weight: 700;
    line-height: normal;
    padding: 5px 23px 11px;
    max-width: max-content;
    border-radius: 15px;
    color: #fff;
    background: #2a533f;
    margin-top: 30px;
    display: block;
    border: transparent;
}
.common_p{
   color: #2D2D2D;
   font-size:32px;
   line-height:44px;
   font-weight:400;
   @media (max-width:1200px){
    font-size:28px;
   line-height:40px;

}
@media (max-width:767px){
    font-size:24px;
   line-height:36px;

}
}

@media (min-width:1400px){
    .container{
        max-width:1620px
    }
}
@media (max-width:1680px){
    .container{
        max-width:1320px
    }
}
@media (max-width:1365px){
    .container{
        max-width:1170px
    }
}
@media (max-width:1200px){
    .container{
        max-width:970px
    }
}
@media (max-width:991px){
    .container{
        max-width:740px
    }
}
@media (max-width:767px){
    .container{
        max-width:540px
    }
}
@media (max-width:575px){
    .container{
        max-width:100%
    }
}
.common_h2 {
    font-size: 75px;
    color: #2A533F;
    @media (max-width:1365px){
        font-size:60px;
        line-height:1.1;
    }
    @media (max-width:1200px){
        font-size:50px;
    }
    @media (max-width:991px){
        font-size:40px;
    }
    @media (max-width:575px){
        font-size:30px;
    }
}
section.detoxification_sec {
    padding: 70px 0;
    @media (max-width:767px){
        padding: 50px 0;
    }

    .common_h2 {
        margin-bottom: 10px;
        font-style: italic;
        color: #2D2D2D;
    }
    p {
        font-size: 46px;
        line-height: 1.1;
        color: #2D2D2D;
        @media (max-width:1200px){
        font-size:40px;
    }
    @media (max-width:991px){
        font-size:30px;
        & br{
            display: none;
        }
    }
    @media (max-width:767px){
        font-size:26px;
      
    }
    }
    
}


section.Why_detox {
    background: #e3e3e3;
    /* min-height: 600PX; */
    padding:121px 0 140px;
    @media (max-width:991px){
        padding:70px 0 70px;
    }
    .detox_sec {
        max-width: 1123px;
        margin-left: auto;
        background: #FFFFFFD9;
        padding: 50px;
        h2 {
            margin-bottom: 10px;
        }
        @media (max-width:767px){
            padding: 40px 15px;
        }
    }
    .container {
       min-height: 600px;
       display: flex;
       align-items: center;
    }
       .common_p{
       font-size:28px;
       color: #000;       
       a{
        text-decoration: underline;
       }
       @media (max-width:991px){
        font-size:24px;
       }
       @media (max-width:767px){
        font-size:22px;
       }
       }
}



.empowerment_sec{
    background: #F2EDE7;
    padding: 90px 0 50px;
    h2{
        font-size:65px;
        margin-bottom:10px;
    }
    p{
        max-width:855px;
    }
    .wrapper_sec{
        & .left-sec {
        width: 60%;
        align-items: center;
        padding-right:30px;
        @media (max-width:991px){
            width:100%;
            padding-right: 0;
        padding-top: 30px;
        }
    }
    & .right-sec {
        width: 40%;
        align-items: center;
        img{
            height: 100%;
            object-fit: cover;
            
        }
        @media (max-width:991px){
            width:100%;
        }
    }
    @media (max-width:991px){
        flex-direction: column-reverse;
        }
    }
    @media (max-width:1365px){
        h2{
            font-size:60px;
        }
    }
    @media (max-width:1200px){
        h2{
            font-size:46px;
        }
    }
    @media (max-width:991px){
        h2{
            font-size:35px;
        }
    }
    @media (max-width:767px){
        h2{
            font-size:30px;
        }
    }
} 
section.our_premium {
    background: #F2EDE7;
    padding-bottom:50px;
    .inner_wraper {
        display: flex;
        gap: 65px;
        -webkit-box-pack: center;
        justify-content: center;
        max-width: 1120px;
        margin-inline: auto;
        flex-wrap: wrap;
        .box {
           max-width: 20%;
           flex: 0 0 20%;
           @media (max-width:991px){
            max-width: 30%;
           flex: 0 0 30%;
           }
           @media (max-width:767px){
            max-width: 100%;
           flex: 0 0 100%;
           text-align: center;
           }
           h6 {
               letter-spacing: 0.07px;
               padding: 20px 0px;
               margin: 0px auto;
               width: 100%;
               line-height: 20px;
               color: #555;
               text-align: center;
               line-height: 1.2;
               font-family:"Helvetica Neue LT Std";
               font-size:16px;
           }
        }
    }
    @media (max-width:1200px){
        .inner_wraper {
            gap: 50px;
        }
}
@media (max-width:991px){
        .inner_wraper {
            gap: 15px;
        }
}
}

section.key_prog_sec {
    background: #2a533f;
    padding:70px 0 134px;
    h2 {
        color: #fff !important;
        font-size:60px;
        padding-bottom: 30px;
        @media (max-width:991px){
            font-size:50px;
        }
        @media (max-width:575px){
            font-size:30px;
        }
    }
    .common_p{
    color:#FFFFFF;
    padding:0px 0px 100px;
    font-size:34px;
    max-width:1381px;
    margin-inline: auto;
    @media (max-width:991px){
        padding:0px 0px 50px;
    }
    @media (max-width:575px){
        font-size:26px;
    }
    }
    .inner_div {
       gap: 30px;
        max-width: 1280px;
        margin-inline: auto;

        @media (max-width:1200px){
            gap: 15px;
        }

       img {
           height: 200px;
           margin-inline: auto;
           display: block;
       }
       .card-sec {
           width: calc(100% / 3 - 20px);
           display: flex;
            flex-direction: column;
            @media (max-width:1200px){
                width: calc(100% / 3 - 10px);
        }
        @media (max-width:991px){
                width: 100%;
        }
           h3 {
              font-size: 38px;
              color: #2D2D2D;
              line-height: 1.2 !important;
              font-weight: 700;
              padding-bottom: 15px;
              @media (max-width:1365px){
                font-size: 30px;
              }
              @media (max-width:767px){
                font-size: 26px;
              }
           }
           a.read-link {
               background: #f8bc6b;
               display: inline-block;
               padding: 2px 12px 4px;
               margin: 10px 0 50px;
               color: #000;
               border-radius: 50px;
               line-height: normal;
               margin-left: auto;
               margin-right: 0;
               display: block;
               max-width: max-content;
           }
           .descrip {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding:28px 45px 36px;
            @media (max-width:1200px){
                padding:28px 15px 36px;
              }
            p {
            font-size: 20px;
            color: #000;
    }
    & div{
        flex:1;
    }
    .last_sec {
    display: flex;
    flex-direction: column;
    .link_button_new {
        margin-top: auto;
    }
    h4 {
        padding-bottom: 30px;
        font-weight: 400;
        font-size: 20px;
    }
    button{
        border-radius: 0 !important;
        font-size: 20px !important;
    }
    p {
         font-style: italic;
     }
}

 

}
    }
    }
}





section.liver-cleanse-section{
    background: #F2EDE7;
    padding: 74px 0 128px;
    
    @media (max-width:991px){
        padding: 60px 0 60px;
    }
    h3 {
        color: #2A533F!important;
        font-weight:700;
        font-size:45px;
        margin-bottom:60px;
        line-height:1.1 !important;
        @media (max-width:575px){
            font-size:30px;
        }

    }
         & .liver-clearnse-content-section{
               /* padding: 50px 0; */
                .wrapper_sec{
                    & .left-sec {
                    width: 47%;
                    align-items: center;
                    @media (max-width:1200px){
                        width:100%;
                    }
                     img{
                        height: 100%;
                        object-fit: cover;
                    }
                }
                & .right-sec {
                    width: 50%;
                    align-items: center;
                     
                    & .common_p{
                      font-size:31px;
                    line-height:1.5;
                    @media (max-width:767px){
                            font-size:36px;
                        }   
                        @media (max-width:575px){
                            font-size:22px;
                        } 
                    }
                   & .link_button_new {
                        margin-inline: auto;
                        text-decoration:underline;
                        font-size:40px;
                        text-decoration-thickness:1px;
                        max-width:519px;
                        width:100%;
                        padding:10px 15px;
                        margin-top: 50px;
                        @media (max-width:767px){
                            font-size:30px;
                        }   
                        @media (max-width:575px){
                            font-size:22px;
                        } 
                    }
                    @media (max-width:1200px){
                        width:100%;
                        padding-top:30px;
                    }
            }
       }
    }
  @media (max-width:1200px){
    .liver-clearnse-content-section{
        .wrapper_sec{
            flex-wrap: wrap:

        }
    }


    h3 {
        text-align: center;

    }

  }
}


section.orgisim-detox-section{
    // background: #F2EDE7;
    padding: 80px 0 0;
    h3 {
        color: #2A533F !important;
        font-weight:700;
        font-size:45px;
        text-align:right;
        line-height:1.1 !important;
        @media (max-width:1200px){
            text-align:center;
        }
        @media (max-width:767px){
            font-size:40px;
        }
        @media (max-width:575px){
            font-size:28px;
        }
    }
         & .orgisim-detox-content-section{
               padding: 50px 0 0;
               @media (max-width:1200px){
                padding: 0;
        }
                .wrapper_sec{
                    & .common_p{
                      font-size:31px !important;
                      @media (max-width:767px){
                        font-size:26px !important;
                        padding-top:15px !important ;
                      }
                      @media (max-width:575px){
                        font-size:22px!important;
                      }

                    }
                }
                    
                    & .link_button_new {
                        margin-inline: auto;
                         text-decoration:underline;
                         font-size:40px;
                         text-decoration-thickness:1px;
                         @media (max-width:1200px){
                            font-size:32px;
                         }
                         @media (max-width:767px){
                            font-size:30px;
                         }
                         @media (max-width:575px){
                            font-size:24px;
                         }
                      }
                         img{
                        object-fit: cover;
                        width:100%;
                        border:1px solid;
                    }
                   .wrapper_sec{
                    .left-sec {
                    width: 50%;
                        @media (max-width:1200px){
                            width:100%;
                        }
                    }
                    .right-sec {
                    width: 46%;
                    @media (max-width:1200px){
                            width:100%;
                            padding-top:30px;
                        }
                    }
                   }

                 }
             }


.sec-sugar {
    padding-bottom: 70px;
    p{
        font-size: 31px;
        @media (max-width:767px){
            font-size:26px;
        }
        @media (max-width:576px){
            font-size:22px;
        }
    }
    small{
        font-size:18px;
    }
}


section.renew-package-section{
    background: #F2EDE7;
    padding: 50px 0;
    h3 {
        color: #2A533F !important;
        font-weight:700;
        font-size:45px;
        line-height:1.1;
        @media (max-width:1200px){
            text-align: center;
        }
        @media (max-width:991px){
            font-size:40px;
        }
        @media (max-width:575px){
            font-size:30px;
        }
    }
         & .liver-clearnse-content-section{
               padding: 50px 0;
                .wrapper_sec{
                    & .left-sec {
                    width: 46%;
                    align-items: center;
                   @media (max-width:1200px){
                    width:100%;
                   }
                     img{
                        object-fit: cover;
                    }
                    & .link_button_new {
                        margin-inline: auto;
                        text-decoration:underline;
                        text-decoration-thickness:1px;
                        font-size:40px;
                        @media (max-width:1365px){
                            font-size:28px;
                        }
                        @media (max-width:767px){
                            font-size:30px;
                        }
                        @media (max-width:575px){
                            font-size:24px;
                        }
                    }
                }
                & .right-sec {
                    width: 50%;
                    align-items: center;
                    @media (max-width:1200px){
                    width:100%;
                    padding-top:30px;
                   }
                    & .common_p{
                      font-size:31px !important;
                      @media (max-width:1200px){
                        font-size:28px !important;
                   }
                   @media (max-width:767px){
                        font-size:24px !important;
                   }
                    }

                        
                   
            }

           
           
            }

               & .renew-packages-other-content {
                        padding-top: 44px;
                    }     

            & .renew-packages-key-points{

                padding-top:30px;
                    
                h3{

                color: #2D2D2D !important;
                font-weight: 700;
                line-height:1.1;

                }
                 & .common_p{
                      padding-top:20px;

                    }

                    & .renew-list-points{
                    padding-left:80px;
                    padding-top:20px;
                    @media (max-width:1200px){
                        padding-left:0px;
                    }

                    & .common_p{
                      padding-top:unset !important;

                    }

                    ul{

                    padding-left:30px;

                     li{
                     padding-top:20px;
                     }
                    
                    }
                    }
       }
    }
    .liver-clearnse-content-section {
    padding-bottom: 0;
}
}




section.revitize-health-section {
    padding: 77px 0px;
    background-color: rgb(42, 83, 63);
    text-align: center;

   & .revitize-content-section{
           .common_p{
        color: #F2EDE7 !important;
        font-size:50px;
         line-height:1.1;   
            @media (max-width:991px){
                font-size:35px;
            }
            @media (max-width:767px){
                font-size:28px;
            }
        }
        h2{
        color: #F2EDE7 !important;
        padding-top:30px;
        line-height: 1.2;
        @media (max-width:991px){
                font-size:30px;
                padding-top:20px;
            }
            @media (max-width:767px){
                font-size:28px;
            }
        }
   }
 
}



.why_cho_sec {
    background: #8080801f;
    .why_chosse {
        max-width: 1280px;
        margin-inline: auto;
    }
}


section.why_cho_sec{
    padding: 70px 0;
    .iiner_div {
    max-width: 690px;
    margin-left: auto;
    h2 {
        font-size: 50px;
        margin-bottom: 15px;
    }
    }
    button{
        margin-inline: auto;
    }
}


section.why_cho_sec{
    padding: 80px 0;
    .iiner_div {
    max-width: 690px;
    margin-left: auto;
    
     h2 {
        font-size: 30px !important;
        text-align: center;
        max-width: 1060px;
        margin-inline: auto;
        font-weight: 500;
    }
    .start_u_sec button{
        margin-inline: auto;
    }
}

}

section.start_u_sec {
    padding: 80px 0;
    h2 {
        font-size: 32px !important;
        max-width: 940px;
        text-align: center;
        margin-inline: auto;
    }
    button {
        margin-inline: auto;
    }
}


section.bigger_sec {
    padding:80px 0;
    background: #80808024;
    .the_bigger_sec {
        max-width: 790px;
    }
    h2 {
       font-size: 65px;
       margin-bottom: 20px;
    }
    p {
        font-size: 36px;
        line-height: 1.3;
    }
}

.footer_sec {
    padding: 47px 0;

.inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    @media (max-width:767px){
        flex-wrap: wrap;
        justify-content: center;
    }
    img {
       filter: invert(1);
       max-width: 358px;
       @media (max-width:991px){
        max-width: 170px;
       }
    }
    .discription_sec {
        max-width: 613px;
        width: 100%;
    }
    .discription_sec p {
       margin: 0;
       font-family: 'Playfair Display';
       font-size: 30px;
       line-height: 36px;
       color: #2D2D2D;
       text-align: end;
       @media (max-width:1200px){
        font-size: 26px;
       }
       @media (max-width:991px){
        font-size: 22px;
       }
       @media (max-width:767px){
        text-align: center;
       }
}

}

}



`;
export default DetoxificationStyleCss;
