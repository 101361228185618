import _ from "lodash";
import ButtonComponent from "../../../inputs/ButtonCompnent";
import PaymentSection from "../../Checkout/components/PaymentSection";
import SavedCard from "../../Checkout/components/SavedCard";

const PaymentAndSavedCard = (props) => {
    const {
        token, paymentsOptions, paymentFormHtml, savedCards, _addNewCard, _handleCardChange, selectedCard, setSelectedCard, savedThisCard, setSavedThisCard,
        selectedOptionForPayment, setSelectedOptionForPayment, selectedPaymentOption, sameAsBillingChecked, setSameAsBillingChecked, _paymentOptionSetBilling, addressDetails
    } = props;
    return (
        <div className="main_PaymentSaved_section">
            <div className="payment_heading">
                <h3>Payment</h3>
            </div>
            <div className="PaymentSaved_css">
                <div className="saved_add_section">
                    {token?.token &&
                        <SavedCard {...{
                            setSameAsBillingChecked,
                            paymentsOptions, selectedOptionForPayment, setSelectedOptionForPayment, selectedPaymentOption,
                            _handleCardChange, selectedCard, setSelectedCard, savedCards, section: 'autoship'
                        }} />
                    }

                    <div className="payment_fields">
                        {(selectedOptionForPayment === 2) &&
                            <>
                                <div className="mt-4 d-flex sameAsShipping-checkbox field-class neweletter-subscrib ">
                                    <input
                                        type="checkbox"
                                        className=''
                                        style={{ top: '-2px' }}
                                        name="sameAsBilling"
                                        checked={sameAsBillingChecked}
                                        onChange={(e) => {
                                            if (e?.target?.checked) {
                                                setSameAsBillingChecked(e?.target?.checked);
                                                _paymentOptionSetBilling(addressDetails);

                                            } else {
                                                setSameAsBillingChecked(e?.target?.checked);
                                                window.paymentOption?.clearBilling();
                                            }
                                        }} />
                                    <p className='' style={{ fontSize: '20px', margin: 0 }}>Same as shipping</p>
                                </div>

                                <PaymentSection {...{ minHeight: "630px", paymentFormHtml, savedThisCard, setSavedThisCard, }} />
                            </>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}

export default PaymentAndSavedCard;