// import { ErrorMessage } from "formik";
import _ from "lodash";
import config from "../../config";
import Cookies from 'js-cookie';
import localStorageCall from "./localstorage.hook";
import * as Sentry from "@sentry/react";

/*** convert into Base 64 ***/
export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const REFFERAL_VALUES = Cookies.get('refferal_link') ? JSON.parse(Cookies.get('refferal_link')) : "";  /*** get Refferal value from cookiee ***/
export const CUSTOMER_TYPE = localStorage.getItem('customerTypeDescr') ? JSON.parse(localStorage.getItem('customerTypeDescr')) : { priceType: 1, customerType: 'Customer' };
export const MIDDLE_CREDENTIAL = { 'Content-Type': 'application/x-www-form-urlencoded' };
export const CacheHeader = { 'Cache-control': 'no-cache' };

/******* get Login user token ********/
export function _getToken() {
    try {
        const token = localStorageCall().getItem('Token') ? localStorageCall().getItem('Token') : false;
        return ({ token, status: !_.isEmpty(1528) });
    } catch (e) {
        return ({ token: null, status: false });
    }
}

export function _setTimeOut(callback, seconds = 3000) {   /****** setTime out function ******/
    setTimeout(() => callback(), seconds);
}
export const ButtonCssClass = "relative f-q-heavy text-xs sm:text-[8px] md:text-[10px] lg:text-[11px] xl:text-[18px] w-[140px] h-[40px] sm:w-[85px] sm:h-[28px] md:w-[100px] md:h-[31px] lg:w-[135px] lg:h-[38px] xl:w-[170px] xl:h-[50px]  pt-0.5 z-10 inline-block rounded-full border-2 bg-button-color border-button-color text-white hover:bg-white hover:text-button-color transition-colors duration-200";

export const FloatValues = (value, count = 2) => parseFloat(value)?.toFixed(count);  /***** convert price into float value *****/

export const ErrorDisplay = ({ message }) => (<span className='error' style={{ color: "red" }}>{message}</span>);   /**** display error message ****/

export const SuccessDisplay = ({ message }) => (<span className='text_sucess' style={{ color: "green" }}>{message}</span>);  /**** display success message ****/

export const SEQUENCE_PRODUCTS = ['cv well', 're genesis', 'gummies', 'wellness essentials', 'cream'];

/****** logout user function ******/
export function _logoutFunction() {
    localStorage.removeItem('customerTypeDescr');
    localStorage.removeItem('Token');
    setTimeout(() => window.location.assign(`/${storeAssigned()}/login`), 1000);
    Sentry.setUser(null);

}

/****** current store of product ******/
export function storeAssigned() {
    const CURRENT_STORE = sessionStorage.getItem('store') || config?.defaultStore;
    return CURRENT_STORE
}

/******* Delete cart values from localstorage *******/
export function _deleteProductFromCart(id, setProduct) {
    const PRODUCT_DATA = localStorage.getItem('cartData') ? JSON.parse(localStorage.getItem('cartData')) : [];
    const FINDPRODUCT = _.find(PRODUCT_DATA, { id });
    if (FINDPRODUCT) {
        const REMAIN_DATA = _.reject(PRODUCT_DATA, FINDPRODUCT);
        localStorage.setItem('cartData', JSON.stringify(REMAIN_DATA))
        setProduct(JSON.parse(localStorage.getItem('cartData')))
    } else {
        setProduct(JSON.parse(localStorage.getItem('cartData')))
        localStorage.removeItem("CartData")
    }
}

export function handleRoute(id, section) {                                /****** Routing with store ******/
    window.location.assign(`/${storeAssigned()}/${section}#${id}`)
}

export function _selectedEnrollmentProduct() {
    return localStorage.getItem('selectedEnrollmentPack') ? JSON.parse(localStorage.getItem('selectedEnrollmentPack')) : [];
}

export function _convertStringKeyValueInObject(errorMessage) {
    const objectData = {};
    _.forEach(_.compact(errorMessage?.split("\n")), (value) => {
        const INNER_SPLIT = _.split(value, ":");
        _.assign(objectData, { [_.lowerCase(INNER_SPLIT[0])?.replaceAll(/\s/g, "_")]: INNER_SPLIT[1]?.trim() });
    });
    return objectData;
}

// ******************* points
export function _pointsPrice(priceList, selectedPrice, isEligibleForAutoOrder) {
    const CUSTOMER_TYP = localStorage.getItem('customerTypeDescr') ? JSON.parse(localStorage.getItem('customerTypeDescr')) : {};
    let bb = (CUSTOMER_TYP?.priceType && CUSTOMER_TYP?.priceType !== 1 && isEligibleForAutoOrder) ? CUSTOMER_TYP?.priceType : (selectedPrice === "autoship" && isEligibleForAutoOrder) ? 2 : 1;
    const PRICE = _.find(priceList, ({ countryCode, priceType }) => {
        return (countryCode === _.upperCase(storeAssigned()) && priceType === bb)
    })?.points || null;
    return PRICE;
}

/****** Product price based on customer  profile type ******/
export function _productPrice(priceList, selectedPrice, isEligibleForAutoOrder) {
    const CUSTOMER_TYP = localStorage.getItem('customerTypeDescr') ? JSON.parse(localStorage.getItem('customerTypeDescr')) : {};
    let bb = (CUSTOMER_TYP?.priceType && CUSTOMER_TYP?.priceType !== 1 && isEligibleForAutoOrder) || CUSTOMER_TYP?.priceType === 3 ? CUSTOMER_TYP?.priceType : (selectedPrice === "autoship" && isEligibleForAutoOrder) ? 2 : 1;
    const PRICE = _.find(priceList, ({ countryCode, priceType }) => {
        return (countryCode === _.upperCase(storeAssigned()) && priceType === bb)
    })?.itemPrice || 0;
    return FloatValues(PRICE);
}