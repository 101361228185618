
import { ReactComponent as LocationSvg } from '../assets/locationsvg.svg';
import { ReactComponent as PhoneSvg } from '../assets/phoneSvg.svg';
import { ReactComponent as EmailSvg } from '../assets/emailSvg.svg';
import ButtonComponent from '../../../inputs/ButtonCompnent';
import TextFieldComponent from '../../../inputs/TextFieldComponent';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { ErrorDisplay, MIDDLE_CREDENTIAL, SuccessDisplay } from '../../../services/Methods/normalMethods';
import api from '../../../services/AxiosHelper';
import { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const MainContactUs = () => {
    const [successMsg, setSuccessMsg] = useState('');
    const reCaptchaRef = useRef(null);
    return (
        <>
            <div className="contact_banner-section">
                <img src={require('../assets/ContactUs.jpg')} />
            </div>
            <div className="main_contact_us container">
                <div className="contact_hading_section">
                    <h2 style={{ marginBottom: "20px" }}>Contact us</h2>
                    <h3>Need help? Got questions? We are just a click away!</h3>
                </div>
                <div className="location_Section">
                    <div className="contact_paragraph_line">
                        <p>Get in touch and we’ll get back to your as soon a we can.</p>
                        <p>We look forward to hearing from you.</p>
                    </div>
                    <div className="loction_address_phone_email">
                        <div className="contact_address common_css">
                            <p><LocationSvg /></p>
                            <p>965 N Main Street, <br />Springville, UT 84663</p>
                            <div style={{ fontStyle: "italic" }}> *Manufacturer's Address</div>
                        </div>
                        {/* <div className="contact_phonenumber common_css">
                            <p><PhoneSvg /></p>
                            <p>(470) 785-2950</p>
                        </div> */}
                        <div className="contact_email common_css">
                            <p><EmailSvg /></p>
                            {/* <p> info@abeytunaturals.com </p> */}
                            <p><a href="mailto:info@abeytunaturals.com" className='contact-mail-cstm'>info@abeytunaturals.com</a></p>

                        </div>
                    </div>
                </div>
                <div className='contact_form_section'>
                    <Formik
                        enableReinitialize
                        initialValues={{ name: "", email: "", subject: "", message: "" }}
                        validationSchema={yup.object().shape({
                            name: yup.string().trim().required('Name is required'),
                            email: yup.string().trim().required('Name is required').email('Enter valid email'),
                            subject: yup.string().trim().required('Subject is required'),
                            message: yup.string().trim().required('Message is required')
                        })}
                        onSubmit={async (values, action) => {
                            const recaptchaValue = reCaptchaRef.current.getValue();
                            if (recaptchaValue) {
                                await api().root(process.env.REACT_APP_COMPANY_TOKEN).setHeaders(MIDDLE_CREDENTIAL).post('/contactus.php').data(values)
                                    .success((res) => {
                                        if (res?.status === 1) {
                                            setSuccessMsg("Thank you! your request has been submitted successfully., we’ll get back to your as soon");
                                            setTimeout(() => setSuccessMsg(''), 3000);
                                            action.resetForm({ name: "", email: "", subject: "", message: "" });
                                        }
                                    }).error(() => { }).send()
                            }
                        }}
                    >
                        {({ values, handleChange, errors, touched }) => (
                            <Form>
                                <div className='d-flex email_name'>
                                    <div className="form-group">
                                        <TextFieldComponent
                                            value={values?.name}
                                            error={!!(touched?.name || errors?.name)}
                                            onChange={handleChange}
                                            errorMessage={errors?.name}
                                            className="form-control"
                                            name="name"
                                            label="Your Name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextFieldComponent
                                            value={values?.email}
                                            error={!!(touched?.email || errors?.email)}
                                            onChange={handleChange}
                                            errorMessage={errors?.email}
                                            className="form-control"
                                            name="email"
                                            label="Email"
                                        />
                                    </div>
                                </div>
                                <div className='subject_section'>
                                    <div className="form-group">
                                        <TextFieldComponent
                                            value={values?.subject}
                                            error={!!(touched?.subject || errors?.subject)}
                                            onChange={handleChange}
                                            errorMessage={errors?.subject}
                                            className="form-control"
                                            name="subject"
                                            label="Subject"
                                        />
                                    </div>
                                </div>
                                <div className='contact_textArea'>
                                    <label htmlFor='message'>Message</label>
                                    <textarea rows={6} name="message" onChange={handleChange} className="form-control" cols={30} />
                                    <ErrorDisplay message={errors?.message} />
                                </div>
                                <div>
                                    <ReCAPTCHA
                                        ref={reCaptchaRef}
                                        // onChange={(e) => console.log(e, reCaptchaRef)}
                                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
                                    />
                                </div>
                                <div className='submitButton'>
                                    <ButtonComponent type='submit'>Submit</ButtonComponent><br />
                                    <SuccessDisplay message={successMsg} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default MainContactUs;