import { useOutletContext } from "react-router-dom";
import useCheckoutHook from "./components/Checkout.hook";
import MainCheckoutSection from "./components/MainCheckoutSection"
import { setErrorAddressData } from "../../services/Redux/Reducer/CheckoutSlice";
import _ from "lodash";
import { AddressSuggestionDialog } from "../../services/Methods/commonPayload";
import withPayment from "../../services/HOC/withPaymentsHOC";
import styled from "styled-components";

const OTPCSS = styled.div`
    .main_otp_div {
        .field_sections {
            display: flex;
            column-gap: 10px;
            justify-content: center;
            margin: 25px;
            .otp-field {
                width: 100%;
                max-width: 40px;
                padding: 6px;
                text-align: center;
                border: 1px solid #285440;
            }
        }
    }
    button:not(.resent_otp_again) {
        width:150px !important;
    }
    button.resent_otp_again {
        text-align: center;
    }
`;

const CheckoutPage = (props) => {
    const { cartValues, setCartValues, token, alluserData, countryCodes, Dates, } = useOutletContext();
    const data = useCheckoutHook({ token, alluserData, Dates, cartValues, setCartValues, ...props });
    const { errorAddressData, useDefaultAddressOrNot, normalAddressError, userData, setUseDefaultAddressOrNot, openDialog, setOpenDialog } = data;
    return (
        <>
            <MainCheckoutSection {...{ countryCodes, token, cartValues, setCartValues, OTPCSS, alluserData, ...data, ...props }} />
            <AddressSuggestionDialog {...{
                errorAddressData, useDefaultAddressOrNot, closeFunction: () => props?.dispatch(setErrorAddressData({ objectData: null, section: "object" })),
                setUseDefaultAddressOrNot, userData, normalAddressError, setOpenDialog, section: "maincheckout",
            }} />

        </>
    )
}
export default withPayment(CheckoutPage);