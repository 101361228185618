import styled from "styled-components";

export const ViewCartStyle = styled.div`
  .view-cart{
        padding: 210px 0 ;
        .view-cart-part {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 4px 40px #0000001A;
            border-radius: 10px;
            padding: 40px 80px;
            @media(max-width:991px){
           padding: 210px 0px 98px;

            }
            h2{
                @media(max-width:991px){
                text-align: center;
            }
        }
        }
  }
  .table-data {
    color: #707070;
    font-size: 24px;
    text-align: left;
    margin: 50px 0;
}
.table-data table {
    border-collapse: separate;
    width: 100%;
}
.delete-product {
    display: flex;
    justify-content: center;
    /* padding-bottom: 14px; */
    padding-bottom: 6px;
    .delete-icon {
        svg {
            color:red;
        }
    }
}
.view-cart-part table th {
    font-size: 24px;
    font-weight: 500;
    font-family: 'Helvetica Neue LT Std';
    line-height: 28px;
    color: #707070;
    text-align: center;
    /* padding-bottom: 28px; */
    padding: 10px;
    @media(max-width:991px)  {
        display: none;
    }
}
.coupon button {
    background: #2A533F;
    border-radius: 28px;
    width: 88px;
    color: #fff; 
}
.product-image img {
    max-width: 103px;
    height: 180px;
    width: 100%;
    object-fit: cover;
    margin-right: 37px;
    /* position: relative;
    top: 70px; */
    @media(max-width:1199px){
        top: 25px;
    }
 
}
.product-image span {
    font-size: 22px;
    color: #39332E;
    font-family: 'Playfair Display';
    font-weight: bold;
}
.view-cart-part table {
    border-collapse: collapse;
}
/* .product-image {
    padding: 60px 0 0;
} */
.coupon input{
    width: 409px;
    height: 60px;
    border-radius:5px;

}
input#quantity {
    /* max-width: 34px; */
    width: 100%;
    border: none;
    /* height: 40px; */
    height: auto;
    background: transparent !important;
    color: #707070;
    font-size: 20px;
    text-align: center;
    padding:0.375rem 0.25rem !important;
    flex: unset;
    display: block;
    margin: 0;
    line-height: 1;
    padding: 6px 4px 2px !important;
    
}
.view-cart-part .input-group button {
    background: transparent;
    border: none;
    color: #000;
    padding: 5px 10px 3px;
    line-height: 1;
}
.price-calulate{
     text-align: right;
}
.coupon button {
    background: rgb(42, 83, 63);
    border-radius: 28px;
    width: 88px;
    color: rgb(255, 255, 255);
    height: 40px;
    box-shadow: none;
    border: none;
    margin: 0 0 0 20px;
    font-size: 18px;
    font-weight: 500;
}
.view-cart-part .input-group {
    border: 1px solid #39332E;
    max-width: 112px;
    border-radius: 28px;
    height: 40px;
    align-items: center;
    flex-wrap: nowrap;
    @media (max-width:1199px) {
        flex-wrap: nowrap;
    }
}
.view-cart-part table td {
    border-bottom: 1px solid #55555566;
    padding: 10px;
    /* vertical-align: bottom; */
    /* padding-bottom: 99px; */
    /* position: relative;
    bottom: 32px; */
    @media (max-width:991px) {
       display:flex;
       width: 100%;
       justify-content: flex-start;
       align-items: center;
       bottom:0;
    /* padding-bottom:45px; */
    }
}
.view-cart-part table tr:first-child .product-image {
    padding: 0px;
}
.view-cart-part .input-group i {
    color: #707070;
    font-weight: 300;
}
.view-cart th:last-child {
    text-align: right;
}
.sub-total {
    color: rgb(57, 51, 46);
    font-weight: 600;
    text-align: center;
    font-size: 30px;
    letter-spacing: 0.3px;
    font-family: "Helvetica Neue LT Std";
    line-height: 1.1;
    padding-top: 6px;
    display: block;
}
.one-time {
    border: 1px solid rgb(117, 117, 117);
    display: flex;
    justify-content:center;
    width: 100%;
}
.selected {
    background: rgb(112 112 112 / 51%);
}
.common_css {
    padding: 0 12px !important;
    cursor: pointer;
    width:calc(100%/3);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    @media(max-width:1199px){
        padding: 0 8px !important;
    }
}
.second-time.common_css {
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
}
/* .first-time.common_css p {
    padding-top: 31px;
} */
.price-calulate {
    padding: 70px 0 30px;
}
/* .second-time {
    
} */
.view-cart .product-image span {
    max-width: 240px;
    width: 100%;
    word-wrap: break-word;
    @media(max-width:991px){
        max-width: 100%;
    font-size: 24px;
    }
}
.view-cart .price h6 {
    padding: 5.5px 0 0;
}
.most-prices {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
    max-width: 370px;
    margin: 0 auto;
}
.price-bar h6 {
    margin-bottom: 0;
}
.one-time p {
    padding:0;
    color: #39332E;
    font-size: 18px;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 500;
    margin-bottom: 0;
    @media(max-width:1199px){
        font-size: 16px;
    }
}
.one-time strong {
    color: #39332E;
    font-size: 24px;
    margin: 0px 0 10px;
    display: block;
    font-family: 'Helvetica Neue LT Std';
    /* text-align: center; */
    @media(max-width:1199px){
        font-size: 18px;
    }

}
.coupon input {
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    padding: 0 30px;
    color: #39332E;
    font-size: 20px;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 500;
}
.price-calulate div {
    font-size: 30px;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 600;
    margin: 0 0 35px;
    color: #39332E;
}
.coupon {
    margin: 50px;
}
.checkout-btn button {
    background: #2a533f;
    border-radius: 28px;
    border: none;
    color: #fff;
    max-width: 292px;
    width: 100%;
    height: 54px;
    font-size: 24px;
}
.coupon button:hover {
    background: transparent;
    border: 2px solid #2a533f;
    color: #2a533f;
}
.checkout-btn button:hover {
    background: transparent;
    border: 2px solid #2a533f;
    color: #2a533f;
}
.view-cart-part th:first-child {
    text-align: left;
}
span.every {
    font-size: 14px;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: block;
    padding: 10px 0 0;
    font-family: 'Helvetica Neue LT Std';
}
.first-time {
    padding: 30px  0 0;
}
.view-cart-part table tbody tr .product-image {
    vertical-align: bottom;
    display: flex;
    /* align-items: flex-end; */
    align-items: center;
    width: 100% !important;

}
.view-cart-part table tr:first-child .product-image {
    padding: 0;
}
.price-bar {
    background: rgb(42, 83, 63);
    text-align: center;
    color: rgb(255, 255, 255);
    /* max-width: 209px; */
    width: calc(100%/3 * 2);
    /* width: 100%; */
    padding: 4px 0px;
    line-height: normal;
    /* position: relative;
    right: -53px; */
    @media(max-width:1199px){
    /* max-width: 161px; */
    right: -41px;
    }
}

/* media starts */
/* responsive */
@media (max-width:1500px){
.sub-total {
    font-size: 24px;
}
.view-cart-part table th {
    font-size: 20px;
}
.view-cart .product-image span {
    max-width: 220px;
    width: 100%;
}
}
@media (max-width:1439px){
.product-image span {
    font-size: 18px;
}
 .product-image img {
    margin-right: 21px;
}
.view-cart .view-cart-part {
    padding: 40px;
}
}



@media (max-width:1280px){
.view-cart-part table th {
    font-size: 18px;
}
}
@media (max-width:1200px){
    .product-image img {
    max-width: 70px;
    height: 150px;
    margin-right: 0px;
}
.product-image span {
    font-size: 14px;
}
}

@media (max-width:991px){
.view-cart-part table th {
    font-size: 18px;
}
.price {
    max-width: 400px;
    width: 100%;
}
 .most-prices {
    display: block;
    justify-content: left;
    max-width: 100% ;
}
/* .common_css {
    padding: 0px 34px !important;
} */
.price-bar {
    max-width: 100%;
    right: 0;
    float: right;
}
.view-cart-part table td {
    bottom: 0px;
    /* padding-bottom: 30px; */
    border-bottom: none ;
}
.view-cart .product-image span {
    line-height: normal;
    /* flex: 0 0 100%; */
    flex: unset;
    display: block;
    max-width: 80%;
    text-align: center;
}
.product-image img {
    margin: 0;
    height: 110px;
}
.view-cart-part table tbody tr .product-image {
    align-items: center;
    vertical-align: top;
    flex-direction: column;
}
/* .view-cart-part table td:first-child {
    padding-bottom: 70px;
} */
.price-calulate div {
    font-size: 24px;
    margin: 0px 0px 18px;
    text-align: center;
}
 .price-calulate {
    padding: 20px;
    background: #ddd;
}
.price-calulate div {
    font-size: 17px;
}
.table-data {
    margin: 10px 0 0;
}
.table-data thead {
    display: none;
}
.view-cart-part table,
.view-cart-part table tbody,
.view-cart-part table tr {
    display: block;
}
.view-cart-part table td>div, .view-cart-part table tbody tr .product-image {
    width: calc(100% - 150px) !important;
}
.view-cart-part table td .price {
    width: 100%;
    margin: 0 auto;
}
.view-cart-part table td:before {
    content: "PRODUCT:";
    display: block;
    font-size: 20px;
    color: #000;
    width: 150px;
}
.view-cart-part table td:nth-child(2):before {
    content: "PRICE:";
}
.view-cart-part table td:nth-child(3):before {
    content: "QUANTITY:";
}
.view-cart-part table td:nth-child(4):before {
    content: "SUBTOTAL:";
}
.view-cart-part table td:last-child:before {
    content: "ACTIONS:";
}
.view-cart-part .input-group {
    margin: 0 auto;
}
.view-cart-part table tr:not(:last-child) {
    margin-bottom: 30px;
}
}
@media(max-width:767px){
    .view-cart-part table td::before {
        font-size: 18px;
    }
    span.every {
        font-size: 11px;
    }
}
@media(max-width:568px){
   .view-cart .view-cart-part {
    padding: 10px;
}


.product-image {
    flex-wrap: wrap;
    justify-content: center;
}
/* .view-cart-part table td:first-child {
    padding-bottom: 23px;
} */
.one-time {
    justify-content: left;
}
 .view-cart {
    padding: 120px 0px;
}
 .checkout-btn button {
    max-width: 159px;
    height: 46px;
    font-size: 18px;
}
.product-image img {
    margin-right: 0;
}
.view-cart .product-image span {
    font-size: 16px;
    margin: 10px 0px 0px;
    max-width: 100%;
    word-break: break-all;
}
.view-cart-part table td {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.view-cart-part table td::before {
    text-align: center;
    padding-top: 0;
    line-height: 1;
    font-size: 16px;
    margin-bottom: 10px;
    display: none;
}
.view-cart-part table td > div, .view-cart-part table tbody tr .product-image {
    width: 100% !important;
}
span.every {
    font-size: 11px;
}
.one-time p {
    font-size: 14px;
}
.one-time strong {
    font-size: 16px;
}
}
@media(max-width:459px){
 /* .common_css {
    padding: 0px 25px !important;
} */
}
@media(max-width:767px){
    .horizontal-line0{
        border-bottom: 1px solid rgba(85, 85, 85, 0.4);
    }
}
@media(max-width:568px){
    .horizontal-line0{
        border-bottom: 1px solid rgba(85, 85, 85, 0.4);
    }
}
@media(max-width:405px){
/* .common_css {
    padding: 0px 17px !important;
} */
}
  `;