import styled from "styled-components";

const WellnessLandingStyleCss = styled.div`

.container {
    max-width: 1620px;
    margin: 0px auto;
    width: 100%;
}



.Main-wellness-landing {
  .allProduct_banner-section {
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        /* height: 1203px; */
        position: relative;

        figure {
            margin: 0;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    
    }
 .wellness-content-section{
    .wellness_container {

        .wellness_product_content_section {
            display: flex;
            width: 100%;
            justify-content: space-between;            
            @media(max-width: 1280px){
                gap: 15px;
            }
            /* @media(min-width: 1280px){
                gap: 60px
            } */

            .image_container {
                /* height: 715px; */
                width: 100%;
                max-width: 487px;
                img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                    min-height: 649px;
                    @media (max-width:1200px){
                        min-height: 500px;
                    }
                }
                .link_button{
                    font-family: 'Playfair Display';
                    margin-top:70px;
                    margin-inline: auto;
                    font-size: 40px;
                    height: auto;
                    font-weight: 700;
                    line-height: normal;
                    padding: 6px 10px 13px;
                    max-width: 275px;
                    border-radius: 15px;
                    @media (max-width:1365px){
                        margin-top:30px;
                        font-size: 30px;
                    }
                }
            }
            .text_container {
                width: calc(100% - 487px - 43px);
                @media (max-width:1200px){
                    width: calc(100% - 487px - 15px);
                    }
                    @media (max-width:991px){
                    width: 100%;
                    padding-top:20px;
                    }
                
                .year_sec{
                    font-size:40px;
                    font-style: normal;
                    @media (max-width:1440px){
                        font-size:30px;
                    }
                    @media (max-width:1200px){
                        line-height: 1.1;
                    }
                }
                
                p {
                    font-size: 32px;
                    font-family: 'Playfair Display';
                    color: #2D2D2D;
                    font-style: italic;
                    line-height:44px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    @media (max-width:1200px){
                        line-height: 1.5;
                    }
                }
                .inner {
                font-style: normal;
                font-weight: 400;
                padding-top: 10px;
                }
                    }

            @media(max-width: 1440px){
                   gap: 15px;
                    .text_container {
                        p{
                            font-size: 20px;
                        } 
                    }
            }

            @media(max-width: 1280px){
                .text_container {
                    p{
                        font-size: 19px;
                    } 
                }
            }

            @media(max-width: 1024px){
                .text_container {
                    p{
                        font-size: 18px;
                    } 
                } 
            }

            @media(max-width: 991px){
                flex-direction : column;
                .image_container {
                    max-width: 100%;
                }   
                .text_container {
                    max-width: 100%;
                    p{
                        font-size: 21px;
                    } 
                    p:last-child{
                       margin-bottom:10px;
                    } 
                } 
            }
            @media (max-width:575px){
                text-align: center;
            }
        }

        .wellness-product-journet {
            display: flex;
            gap: 40px;
            padding-bottom: 40px;
            @media (max-width:1365px){
                gap: 20px;
            padding-bottom: 20px;
            }
            @media (max-width:575px){
                flex-wrap: wrap;
                justify-content: center;
                gap:0;
            }
         }
    }
    .wellness-capsule-section h1{
        text-align: center;
        font: italic normal bold 75px/85px Playfair Display;
        letter-spacing: 0px;
        color: #2D2D2D;
        opacity: 1;
        margin-bottom: 0;
        padding: 50px 0 35px;
        @media (max-width:1365px){
            font-size: 60px;
            line-height: 1.2;
        }
        @media (max-width:1200px){
            font-size: 50px;
        }
        @media (max-width:991px){
            font-size: 40px;
            padding-bottom:0;
        }
        @media (max-width:767px){
            font-size: 32px;
            max-width: 540px;
            margin-inline: auto;
        }

      }

    
}
        .community-section {
        background: #F4F0EB;
        padding: 62px 0 0px;
        text-align: center;
        @media(max-width:767px){
            padding: 40px 0px 0px;
            }


        h1{
            text-align: center;
            font: normal normal bold 75px/85px Playfair Display;
            letter-spacing: 0px;
            color: #2A533F;
            text-transform: uppercase;
            opacity: 1;
            padding-bottom:20px;
            padding-inline:15px;
            @media (max-width:1365px){
            font-size: 60px;
            line-height: 1.2;
        }
        @media (max-width:1200px){
            font-size: 50px;
        }
        @media (max-width:991px){
            font-size: 40px;
        }
        @media (max-width:767px){
            font-size: 32px;
        }
        }

        h3{
            text-align: center;
            font: normal normal bold 40px/44px Playfair Display;
            letter-spacing: 0px;
            color: #2A533F;
            opacity: 1;
            @media (max-width:991px){
            font-size: 30px;
        }
            @media (max-width:1200px){
            font-size: 30px;
        }
        @media (max-width:991px){
            padding-inline:15px;
        }
        .experience-content-section .experience-inner h3 {
        padding-inline: 0 !important;
    }
    @media (max-width:767px){
            max-width: 540px;
            margin-inline: auto;
            font-size:28px;
            line-height: 1.2 !important;
        }
       }

      .unique-logo {
          padding-top:70px;
          padding-bottom:20px;
          .unique-logo-inner{
              display: flex;
              gap:65px;
              justify-content:center;
                max-width:1120px;
                margin-inline: auto;
                flex-wrap: wrap;
                @media (max-width:1365px){
                    max-width:100%;
                    gap:30px;
                }
            }
        }

           .logo-unique {
        max-width: 20%;
        flex: 0 0 20%;
        @media(max-width:1280px){
            max-width: 33%;
            flex: 0 0 33%;
        }
        @media(max-width:568px){
            max-width: 100%;
            flex: 0 0 100%;
    
        }
        img {
            object-fit: cover;
            @media(max-width:1199px){
                width: 100%;
                max-width: 120px;
                height: 120px;
                object-fit: cover;
            }
        }
        p {
            letter-spacing: 0.07px;
            padding: 20px 0;
            margin: 0 auto;
            width: 100%;
            line-height: 20px;
        }
    }

 .why-choose-content-section {
    display: flex;
    padding-top:44px;
    @media (max-width:991px){
        flex-wrap: wrap;
        flex-direction: column-reverse;
    gap: 50px;
    }
    ul {
    padding-left: 100px;
    margin:0;
    @media (max-width:1200px){
        padding-left: 30px;
    }
}
    
        li{
            text-align: left;
            font: normal normal bold 32px/44px Playfair Display;
            letter-spacing: 0px;
            color: #2D2D2D;
            opacity: 1;
            padding-bottom:15px;
            &:last-child{
                padding-bottom:0;
            }
            @media (max-width:1200px){
                font-size:22px;
                line-height:normal;
            }
        }
        p{
            padding-top:42px;
            text-align: left;
            font: normal normal normal 32px/44px Playfair Display;
            letter-spacing: 0px;
            color: #2D2D2D;
            opacity: 1;
            margin-bottom:0;
            @media (max-width:1200px){
                font-size:22px;
                line-height:1.4;
            }
            @media (max-width:991px){
                font-size:20px;
                padding-top:25px;
            }
        }

        
}

.why-choose-content-section .left.content {
    width: 59%;
    padding-right: 84px;
    @media (max-width:991px){
        width: 100%;
        padding-right: 0px;
    }
}

.why-choose-content-section .right-content-section {
    width: 41%;
    img{
        width:100%;
    }
    @media (max-width:991px){
        width: 100%;
        padding: 0px;
    }
}

.key-ingredentes-section{
        background: #2A533F 0% 0% no-repeat padding-box;
        opacity: 1;
        padding:70px 0;

        h3{
        text-align: center;
        font: normal normal bold 60px/44px Playfair Display;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-bottom:30px;
        @media (max-width:1200px){
            font-size:50px;
            margin-bottom: 60px;
        }
        @media (max-width:767px){
            font-size:32px;
            margin-bottom: 30px;
        }
        }

            .key-ingredentes-content-section {
            display: flex;
            gap: 75px;
            align-items: center;
            margin-bottom:57px;
            &:last-child{
                margin-bottom:0;
            }
            @media (max-width:991px){
                flex-wrap: wrap;
    justify-content: center;
            }

        .right-content-section{
            h4{
                font-family: "Playfair Display";
                color: #fff;
                text-align: left;
                font-size:32px;
                font-weight:700;
                margin-bottom:8px;
                @media (max-width:991px){
                    text-align: center;
            }
            @media (max-width:767px){
                font-size:30px;
            }
            }
            p{
            text-align: left;
            font: normal normal normal 32px/44px Playfair Display;
            letter-spacing: 0px;
            color: #FFFFFF;
            margin-bottom:0;
            @media (max-width:991px){
                    text-align: center;
            }
            @media (max-width:767px){
                font-size:26px;
                line-height:1.3;
            }
            }
        }
        @media (max-width:1200px){
            gap: 30px;
        }
            }
            .key_inner {
    max-width: 1400px;
    margin-inline: auto;
}
.try-wellness-button{
    font-family: "Playfair Display";
    font-size: 30px;
    color: #2A533F;
    background: #F2EDE7;
    border-radius: 15px;
    font-weight: 700;
    max-width: 571px;
    width: 100%;
    line-height: normal;
    margin-top: 80px;
    @media (max-width:991px){
        margin-top: 60px;
        font-size: 22px;
        }
        @media (max-width:767px){
        margin-top: 40px;
        font-size: 18px;
        }
}

}
    }

    .statement-content-section{
        padding:88px 0;
        @media (max-width:1200px){
            padding:60px 0;
            }
        .container{
            max-width:1392px;
            @media (max-width:1680px){
                max-width:1320px;
            }
            @media (max-width:1365px){
                max-width:1170px;
            }
            @media (max-width:1200px){
                max-width:970px;
            }
            @media (max-width:991px){
                max-width:760px;
            }
            @media (max-width:767px){
                max-width:540px;
            }
            @media (max-width:575px){
                max-width:100%;
            }
        }
        p{
            text-align: center;
            font: normal normal normal 32px/44px Playfair Display;
            letter-spacing: 0px;
            color: #2D2D2D;
            opacity: 1;
            margin-bottom:0;
            @media (max-width:767px){
                font-size:26px;
            }
        }
        h6 {
    font-family: 'Playfair Display';
    font-size: 32px;
    font-weight: 400;
    color: #2D2D2D;
    padding-top: 30px;
}
    }
.experience-section{
    padding: 252px 0 188px;

    @media(max-width:1680px){
        padding: 100px 0 100px;
    }
    @media(max-width:1365px){
        padding: 40px 0 40px;
    }
    @media(max-width:1200px){
        background-position: 20% 100% !important;
    }
    @media(max-width:991px){
        background-position: 50% 100% !important;
    }
    @media(max-width:575px){
        background-position: 85% 100% !important;
        position: relative;
        &:after{
            content: "";
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 49%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
        }
    }
    .experience-content-section{
    text-align:left;
    .experience-inner {
    max-width: 985px;
    margin-left: 0;
    margin-right: auto;
    width:100%;
    @media(max-width:1680px){
        max-width: 785px;
    }
    @media(max-width:1200px){
        max-width: 640px;
    }
    @media(max-width:991px){
        max-width: 500px;
    }
    @media(max-width:767px){
        max-width: 410px;
    }
    @media(max-width:575px){
        max-width: 100%;
        z-index: 2;
    position: relative;
    }
  
}


        h3{
            text-align: left;
    font: normal normal bold 50px/44px Playfair Display;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 3px 3px 6px #0000004D;
    opacity: 1;
    line-height:1.2;
    @media (max-width:991px){
    font-size: 40px;

    }
    @media (max-width:767px){
    font-size: 32px;

    }
            }
    p{
    padding-top:20px;
    text-align: left;
    font: normal normal bold 40px/55px Playfair Display;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 3px 3px 6px #00000029;
    opacity: 1;
    max-width: 940px;
    padding: 30px 0 60px;
    margin: 0;
  
    @media (max-width:1200px){
    font-size: 30px;
    line-height: 1.4;
    padding: 15px 0px 30px;

    }
    @media (max-width:991px){
    font-size: 25px;

    }
    }

    a {
    background: #F2EDE7 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    text-align: center;
    font: normal normal bold 40px/55px Playfair Display;
    letter-spacing: 0px;
    color: #2A533F;
    padding: ;
    padding: 8px 78px 11px 78px;
    border: unset;
    @media (max-width:1365px){
        font-size:30px;
        padding: 8px 30px 11px 30px;
    }
    @media (max-width:991px){
        font-size:22px;
        line-height:1.8;
    }
}
    }
}




.footer_sec {
    padding: 47px 0;

.inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    @media (max-width:767px){
        flex-wrap: wrap;
        justify-content: center;
    }
    img {
       filter: invert(1);
       max-width: 358px;
       @media (max-width:991px){
        max-width: 170px;
       }
    }
    .discription_sec {
        max-width: 613px;
        width: 100%;
    }
    .discription_sec p {
       margin: 0;
       font-family: 'Playfair Display';
       font-size: 30px;
       line-height: 36px;
       color: #2D2D2D;
       text-align: end;
       @media (max-width:1200px){
        font-size: 26px;
       }
       @media (max-width:991px){
        font-size: 22px;
       }
       @media (max-width:767px){
        text-align: center;
       }
}

}

}

@media (max-width:1680px){
    .container {
    max-width: 1320px;
}

}
@media (max-width:1365px){
    .container {
    max-width: 1170px;
}

}

@media (max-width:1200px){
    .container {
    max-width: 970px;
}

}

@media (max-width:991px){
    .container {
    max-width: 740px;
}
.experience-section .experience-inner h3 {
    padding-inline: 0 !important;
}
}

@media (max-width:767px){
    .container {
    max-width: 540px;
}

}

@media (max-width:575px){
    .container {
    max-width: 100%;
}

}




`;
export default WellnessLandingStyleCss;
