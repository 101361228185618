import React from 'react';
import LoginStyle from '../style/Login.style';
import TextFieldComponent from '../../../inputs/TextFieldComponent';
import { Formik } from 'formik';
import { storeAssigned } from '../../../services/Methods/normalMethods';

const LoginForm = (props) => {
    const { LoginValidationSchema, loginState, _onHandleLoginSubmit } = props;

    return (
        <LoginStyle>
            <Formik
                enableReinitialize
                initialValues={{ email: "", password: "", subject: "" }}
                validationSchema={LoginValidationSchema}
                onSubmit={(values, actions) => _onHandleLoginSubmit(values)}
            >
                {({
                    handleSubmit,
                    handleChange,
                    resetForm,
                    values,
                    errors
                }) => (
                    <div className='parent' >
                        <form className='form-class' onSubmit={handleSubmit}>
                            <div className='login-section'>
                                <label>Login</label>
                            </div>
                            <div className="field-class">
                                <TextFieldComponent
                                    type="email"
                                    className='input-container'
                                    name="email" label='Email address'
                                    value={values?.email}
                                    onChange={handleChange}
                                    error={!!errors?.email}
                                    errorMessage={errors?.email}
                                />
                            </div>
                            <div className="field-class">
                                <TextFieldComponent
                                    type="password"
                                    className='input-container'
                                    name="password" label='Password'
                                    value={values?.password}
                                    onChange={handleChange}
                                    error={!!errors?.password}
                                    errorMessage={errors?.password}
                                />
                            </div>
                            <div className='check-forgot' style={{ display: "flex" }}>
                                <div className='checkbox'>
                                    <input type="checkbox" /> Remember me
                                </div>
                                <div className='forgot-pass'><a href={`/${storeAssigned()}/reset-password`}>Forgot Password</a></div>

                            </div>
                            <button className='button-container' type="submit" >Login</button>
                            {loginState?.emailerror && <p style={{ textAlign: "center", color: "red" }}>{loginState?.emailerror}</p>}
                        </form>
                    </div>
                )}
            </Formik>
        </LoginStyle>
    )
}
export default LoginForm;