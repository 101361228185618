import _ from "lodash";
import { CreateObject } from "../../../services/Methods/checkoutPayloads";
import { CircularProgress } from "@mui/material";
import SelectComponent from "../../../inputs/SelectComponent";
import ShippingSection from "../../Checkout/components/ShippingSection";
import { FloatValues } from "../../../services/Methods/normalMethods";
import ButtonComponent from "../../../inputs/ButtonCompnent";
import { Delete } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const AutoshipProductDetail = ({ children, ...props }) => {

    const {
        selectedDate, setSelectedDate,
        calculatedAutoshipData, setOpenProductAdd, shippingMethods, _openCloseAskPopup, _handleQuantiyIncDec,
        selectedAutoshipCheck, setFrequencyType, frequencyTYpe, _handleOnSelectedAutoshipChange
    } = props;

    const PARENTS_PRODUCT_AUTOSHIP = _.filter(calculatedAutoshipData?.details, (value) => value?.parentItemId === null);
    const AUTOSHIP_PRODUCTS = _.map(PARENTS_PRODUCT_AUTOSHIP, (row) => {
        const values = CreateObject(row);
        return ({
            ...values, subProducts: _.map(_.filter(calculatedAutoshipData?.details, (r) => r?.parentItemId === row?.itemId), (v) => CreateObject(v)),
            autoship: "autoship"
        })
    });

    const SHIPPING_SECTION = [
        { text: "Subscription Every 30 Days", value: "1" },
        { text: "Subscription Every 60 Days", value: "7" },
    ];

    const { autoshiporder } = useSelector((state) => state?.OrdersSlice);

    const nextProcessDate = autoshiporder?.[0]?.nextProcessDate;

    useEffect(() => {
        if (nextProcessDate) {
            const selectDate = new Date(nextProcessDate);
            setSelectedDate(selectDate)
        }
    }, [nextProcessDate])

    function isWeekday(date) {
        const day = new Date(date).getDate();
        return !_.includes([29, 30, 31], day);
    }

    return (
        <div className="main_product_detail_section">
            <div className="Autoship_heading_section">
                <h3>Products</h3>
            </div>
            <div className="allProduct_listing">
                <div className="product_detail">
                    {(AUTOSHIP_PRODUCTS?.length > 0)
                        ?
                        <>
                            <table>
                                {/* <caption>Statement Summary</caption> */}
                                <thead>
                                    <tr>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.map(AUTOSHIP_PRODUCTS, (row, index) => (
                                        <tr key={row?.id}>
                                            <td scope={(index === 2) ? "row" : ""} data-label="Product Name">{row?.productName}</td>
                                            <td data-label="Quantity">
                                                <div className="quality-box">
                                                    <div className="input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="quantity-left-minus btn btn-danger btn-number"
                                                                onClick={() => _handleQuantiyIncDec(row?.id, 'sub')}>
                                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                                            </button>
                                                        </span>
                                                        <input type="text" id="1quantity" name="quantity" className="form-control input-number"
                                                            value={row?.quantity}
                                                            min="1" max="100" onChange={(e) => console.log(e)} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="quantity-right-plus btn btn-success btn-number"
                                                                onClick={() => _handleQuantiyIncDec(row?.id, 'add')}>
                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-label="Price">${FloatValues(row?.price)}</td>
                                            <td data-label="Action">
                                                <span className='delete-icon' onClick={() => _openCloseAskPopup(+row?.id, 'open')}> <Delete /></span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="addProducts">
                                <ButtonComponent classes="addProduct" onClick={() => setOpenProductAdd(true)}>Add Products</ButtonComponent>
                            </div>
                        </>
                        :
                        <div className="loader_progress">
                            <CircularProgress />
                        </div>
                    }
                </div>
                <div className="main_shippingAndfrequency">
                    <div className="shippingMethod_section">
                        {children}
                    </div>
                    <div className="frquency_section">
                        <ShippingSection
                            section="Autoship"
                            shippingMethods={shippingMethods}
                            selectedCheck={selectedAutoshipCheck}
                            _handleShippingChange={(data, section) => _handleOnSelectedAutoshipChange(data, section)}
                        />
                        <div className="shipping_dropdown">
                            <h3>Change Your Frequency</h3>
                            <SelectComponent
                                name="frequency"
                                classes="frequency_dropdown"
                                options={SHIPPING_SECTION}
                                label=""
                                value={String(frequencyTYpe)}
                                onChange={(e) => setFrequencyType(+e.target.value)}
                            />
                            <p>* you can change your current frequency type.</p>
                        </div>
                        <div className="data_change">
                            <h3>Next Recurring Order Date</h3>
                            <DatePicker
                                className="time_change"
                                showIcon
                                toggleCalendarOnIconClick
                                selected={selectedDate}
                                minDate={new Date()}
                                onChange={(date) => setSelectedDate(date)}
                                filterDate={isWeekday}
                            />
                        </div>
                    </div>
                </div>
                <div className="calculationSection">
                    <table>
                        <tbody>
                            <tr><th>Subtotal :</th><td>${FloatValues(calculatedAutoshipData?.subTotal || 0)}</td></tr>
                            <tr><th>Shipping Amount :</th><td>${FloatValues(calculatedAutoshipData?.shippingTotal || 0)}</td></tr>
                            <tr><th>Tax Amount :</th><td>${FloatValues(calculatedAutoshipData?.taxTotal || 0)}</td></tr>
                            <tr><th>Amount Paid :</th><td>${FloatValues(calculatedAutoshipData?.orderTotal || 0)}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AutoshipProductDetail;