import styled from "styled-components";

const ButtonStyle = styled.button`
    /* width: 206px; */
    width: auto;
    height: 55px;
    border: unset;
    background: var(--unnamed-color-2a533f) 0% 0% no-repeat padding-box;
    background: #2A533F 0% 0% no-repeat padding-box;
    border-radius: 28px;
    opacity: 1;
    font: normal normal bold 24px/36px Helvetica;
    color: #fff;
    padding: 10px 20px;
    line-height: 1.4;

    @media(max-width: 480px) {
        font-size: 18px;
    }
    @media(max-width: 392px) {
        font-size: 15px;
    }
`;

const ButtonComponent = ({
    children,
    onClick,
    classes,
    type,
    ...props
}) => {
    return (
        <ButtonStyle
            className={`button_css ${classes}`}
            {...(type !== "submit") ? onClick = { onClick } : {}}
            type={type}
            {...props}
        >
            {children}
        </ButtonStyle>
    )
}

ButtonComponent.defaultProps = {
    type: "button",
    children: "Button",
    classes: ""
}
export default ButtonComponent;