import ButtonComponent from "../../../inputs/ButtonCompnent";
import AboutSectionOneStyleCss from "../style/AboutSectionOne.style";


const AboutSectionOne = () => {

    return (
        <AboutSectionOneStyleCss>
            <div className="About_banner_section" style={{ backgroundImage: `url(${require('../assets/maria-emma.jpeg')})` }} id="#ourStory">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-text">
                                <h1>Our Story</h1>
                                <div className="text">
                                    <p>The Excitement Around Our Solutions Is Spreading Quickly.</p>
                                    <p>We Are Excited For You To Feel Complete Relief!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about_first_container container">
                <div className="feature_launch_product">
                    <p>
                        The feature launch product, CV Well Immuno-Boosting Tincture, is a patent pending solution containing micronutrients that attempt to restore cellular health and immunity. Submitted for patent in Spring of 2019, the compounds in this formula and the mechanism at which we deliver the product, could be a solution for natural health warriors.
                    </p>
                </div>
                <div className="award_winning">
                    <div className="product_image">
                        <img src={require('../assets/cv-well-awardjpg.jpg')} alt="product_image" />
                    </div>
                    <div className="product_description">
                        <p>
                            As consumers we encounter problems such as lack of “access” to care and resources. The public is frustrated with antiquated processes and disconnections between western medical intervention and homeopathic applications. There is be a better way to work synergistically between the two in order to address discomforts. Abeytu´&reg; Naturals base formulary was created to serve as a natural health alternative bridge.
                        </p>
                        <p>
                            We started a journey and wrote patents that could improve lives of people all over the world simply by answering the call for plant based alternatives. Shaman products will be delivered to people worldwide, and provide access to more alternative forms of maintaining health and wellness. Abeytu´&reg; for You! is not just a tagline. We believe strongly that we have something for everyone and can’t wait to start helping to change lives by providing solutions for those natural health warriors. “Plants Do It Better”<sup>TM</sup>
                        </p>
                    </div>
                </div>
            </div>
            <div className="charitable_organization">
                <div className="operationImage">
                    <img src={require('../assets/image-19.jpg')} alt="operationImage" style={{ width: "600px" }} />
                </div>
                <h1>Charitable Organization Collaboration</h1>
                <div className="charitable_discription">
                    <div className="char_para">
                        <p>
                            Did you know a horse's heart has 5 times the electromagnetic field of power as a human being? This field can extend/project a distance outward of 9 meters. We have a special interest in helping to save as many of these majestic & critically needed animals as possible. The heart math institute has performed many studies on the effects of being in the presence of a herd of horses that are in coherence while being free. A human in their presence experiences an overwhelming feeling of peace and joy. This is also why the loss of a horse can be so devastating.
                            <br /><br />
                            Abeytu´&reg; Naturals is more than a natural health & wellness company. We are a movement dedicated to making a positive impact on the world. This new initiative considers horses, our fellow stewards of the planet.
                        </p>
                        <p>
                            Giving back to the animal community and making a difference in the lives of those who need it most is also one of our top priorities. We have pledged to donate proceeds every month to Swingin D Horse Rescue; an organization dedicated to the rescue, rehabilitation, and rehoming of horses in need. We invite you to do the same.
                            <br /> <br />
                            When you choose Abeytu´&reg; Naturals, you are choosing nature-inspired health & wellness; but also contributing to the well-being of these majestic animals. Join us in our mission to make a positive impact.
                            <br /> <br />
                            Experience the beauty of giving with Abeytu´&reg; Naturals – where your purchase and or donation, becomes a step toward a brighter, more compassionate future.
                        </p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}> <a href="https://swingindhorserescue.com/take-action/donate/" target="_blank" className="btn btn-show">Donate now</a></div>
                </div>
            </div>
        </AboutSectionOneStyleCss>
    )
}

export default AboutSectionOne;