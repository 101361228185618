import { useEffect } from "react";
import TextFieldComponent from "../../../inputs/TextFieldComponent";
import { storeAssigned } from "../../../services/Methods/normalMethods";

const EmailVerify = (props) => {
    const { fieldValue, emailExistError, location } = props;

    return (
        <div className="container" style={{ marginTop: "100px" }}>
            <div className="email_address_field">
                <TextFieldComponent
                    name="email"
                    label="Email"
                    fullWidth
                    error={!!(fieldValue?.touched?.email && fieldValue?.errors?.email) || emailExistError}
                    onChange={fieldValue?.handleChange}
                    value={fieldValue?.values?.email}
                    errorMessage={fieldValue?.errors?.email}
                />
                {emailExistError ? <p style={{
                    marginLeft: '10px',
                    marginTop: '5px',
                    color: "#f00"
                }}>it looks like you already have an account would you like to <a href={'/' + storeAssigned() + `/login?redirectTo=${location?.pathname}`} onClick={() => localStorage.removeItem("Token")}>log in</a></p> : ''}
            </div>

        </div >
    )
}

export default EmailVerify;