import styled from "styled-components";

const MainLoginRegisterStyle = styled.div`
$color_1: #55555566;
$font-family_1: 'Helvetica Neue LT Std';
.maine_loginRegister {
    padding: 266px 0;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    column-gap: 112px;
}
.MuiTextField-root.input-container {
    width: 100%;
}
input[type="checkbox"]:checked {
    accent-color: #2a533f;
}
@media  (max-width:1500px){
    .maine_loginRegister {
    max-width: 1280px;
    column-gap: 38px;
    padding: 220px 0px;
}
}
@media (max-width:1439px){
    .maine_loginRegister {
    max-width: 1170px;
}
}
@media (max-width:1280px){
    .maine_loginRegister {
    max-width: 1050px;
}
}
@media (max-width:1199px){
    .maine_loginRegister {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: space-around;
    column-gap: 20px;
}
}
@media (max-width:991px){
    .maine_loginRegister {
    flex-wrap: wrap;
    padding:160px 0 0
}
}


@media (max-width:767px){
form.form-class .field-class input {
    height: 20px;
}

}
@media (max-width:568px){
    .maine_loginRegister {
    flex-wrap: wrap;
    padding:100px 0 0
}
}
`;

export default MainLoginRegisterStyle;