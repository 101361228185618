import styled from "styled-components";

export const MainCheckOutStyle = styled.div`
.order-data{
    width: 674px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 40px #0000001A;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    opacity: 1;
}
.loader_order {
    text-align: center;
    > span {
        margin: 20px;
    }
}
.coupon button {
    background: #2A533F;
    border-radius: 28px;
    width: 88px;
    color: #fff; 
    height: 38px;
    box-shadow: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    margin-left: 30px;
}
.price-calulate{
    align-items: center;
    font-size: 30px;
}
.checkout {
    padding: 200px 0 0;
}
.checkout h2 {
    color: #2A533F;
}
.checkout-main {
    display: flex;
    position: relative;
    
    @media(max-width:1198px){
        position: relative;
        .place-order {
            position: absolute;
            top: calc(100% - 140px);
            width: 100%;
            z-index: 2;
            left: 0;
            padding-left: 10px;
        }
        .mainorder_summery {
            padding-bottom: 100px;
        }
        .place-order button {
            margin : 0;
        }
        .place-order button:last-child {
            margin-left : 10px
        }
    }

    @media only screen and (max-width: 480px) {
        .place-order button {
            margin: 0px;
            width: 100%;
            max-width: 100%;
        }
        .place-order button:last-child {
            margin-left: 0;
            margin-top: 10px;
        }
    }
}
/* .order-summary {
    position: absolute;
    right: 0;
} */
.order-summary h2 {
    background: #F4F0EB;
    /* margin-top: -51px; */
    padding: 40px;
    text-align: center;
    margin-bottom: 0;
    font-size: 46px;
}
.shipping_fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    
}


.checkout-left form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
}
.checkout-left form div.field-class:first-child  , .checkout-left form div.field-class:nth-child(2){
    max-width: 100%;
    flex: 0 0 100%;
}
.checkout-left {
    max-width: 900px;
    width: 100%;
    /* width: 100%;
    border: 1px solid;
    max-width: 60%;

    h2 {
        text-align: center;
    } */
}
.checkout-left form .field-class {
    max-width: 100%;
    flex: 0 0 100%;
}
.checkout-left h2 {
    margin: 0 0 60px;
}
.order-summary .all-products {
    display: flex;
    gap: 35px;
}
 .order-summary .all-products {
    display: flex;
    gap: 35px;
    border-bottom: 1px solid #55555566;
    padding: 60px 40px;
}
.products-details-order h4 {
    font-size: 22px;
    font-family: "Playfair Display", serif;
    font-weight: bold;
    width: 100%;
    margin-bottom: 0;
}

span.item-qty {
    width: 100%;
    color: #555555;
    font-size: 20px;
    font-family: 'Helvetica Neue LT Std';
    line-height: 28px;
    
}
span.iteam-para {
    color: #39332E;
    font-size: 20px;
    font-family: 'Helvetica Neue LT Std';
    font-weight:500;
    padding: 12px 0 0;
}
span.delete-icon svg {
    width: 35px;
    height: 35px;
    color: #cfcece;
}
span.price-note {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Helvetica Neue LT Std';
}
.products-details-order {
    display: flex;
    flex-wrap: wrap;
}
 span.delete-icon {
    margin-bottom: 29px;
    display: block;
    text-align: right;
    cursor: pointer;
}
.item-icon {
    padding-right: 28px;
}
.coupon {
    margin: 30px 40px 50px;
}
span.delete-icon {
    margin: 0 0 30px;
    display: block;
}
 .coupon input {
    width: 383px;
    height: 60px;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
    padding: 0 20px;
    font-size: 20px;
    color: #39332E;
    font-family: 'Helvetica Neue LT Std';
}
.price-calulate {
    padding: 0 40px;
}
.price-calulate div {
    font-size: 30px;
    line-height: 42px;
    margin: 0 0 42px;
    color: #39332E;
    font-family: 'Helvetica Neue LT Std';
}
.price-calulate div:last-child {
    border-top: 1px solid #55555566;
    padding: 20px 0;
}
  .checkout-left form .password-now{
    max-width: 48%;
    flex: 0 0 48%;
}
.checkout-left  .field-class.confirm-password-now {
    max-width: 48%;
    flex: 0 0 48%;
}
.checkout-left label {
    font-size: 18px;
    color: #55555566;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 300;
}
.checkout-left select {
    padding: 10px 14px;
    font-size: 18px;
    color: #55555566;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 300;
}
.neweletter-subscrib {
    display: flex;
    width: 100%;
}
.checkout-left textarea {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    height: 136px;
    font-size: 18px;
    color: #55555566;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 300;
}
.checkout-left .neweletter-subscrib input {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    position: relative;
    top: 4px;
}
.checkout-left .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.css-q0jhri-MuiInputBase-root-MuiInput-root::before {
    border: none;
}
.checkout-left  .field-class.neweletter-subscrib {
    font-size: 24px;
    color: #707070;
    font-family: 'Helvetica Neue LT Std';
    margin: 10px 0 0;
}
.checkout-left .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.css-q0jhri-MuiInputBase-root-MuiInput-root::after {
    border-bottom: none !important;
}
.neweletter-subscrib input[type="radio"] , .credit-card input[type="radio"]{
    accent-color: #5b8c22;
}
.shipping-form {
    margin: 30px 0;
    width: 100%;

    .shipping_option_div {
        margin-bottom: 50px;
        width:100%;
    }
    .condition-checkout {
        .shipping {
            max-width: 100% !important;
        }

        .error {
            color:#d32f2f;
            font-size: 0.90rem;
        }
    }
}

.shipping  , .express-shipping {
    display: flex;
}
div#card-name iframe {
    height: 60px !important;
    margin-bottom:20px;
}
div#card-number iframe {
    height: 60px !important;
    margin-bottom:20px;
}
div#card-cvc iframe {
    height: 60px !important;
    margin-bottom:20px;
}
div#card-expiry iframe {
    height: 60px !important;
    margin-bottom:20px;
}
.shipping input , .shipping input , .express-shipping input {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    position: relative;
    top: 4px;
    border: 2px solid #707070;
}
.shipping {
    margin: 40px 0 30px;
}
.shipping-form h3 {
    border-bottom: 1px solid rgb(196, 196, 196);
    width: 100%;
    font-weight: 600;
    padding-bottom: 25px;
    margin-bottom: 30px;
    font-size: 30px;
    font-family: 'Helvetica Neue LT Std';
    color: #39332E;
}
.express-shipping {
    margin: 0 0 50px;
}
.shipping , .express-shipping {
    font-size: 24px;
    color: #707070;
    font-family: 'Helvetica Neue LT Std';
}
.express-shipping.ship-address {
    font-weight: 600;
    margin-bottom: 50px;
} 
.credit-card input {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    position: relative;
    top: 4px;
    border: 2px solid rgb(112, 112, 112);
}
.credit-card {
    font-size: 24px;
    color: #707070;
    font-family: 'Helvetica Neue LT Std';
    display: flex;
    margin: 35px 0 0;
}
.card-details fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
}
.card-details {
    border: 1px solid rgb(196, 196, 196);
    margin: 20px 0px 0px;
    display: flex;
}
.card-details div {
    max-width:100%;
    flex: 0 0 100%;
}
.card-details {
	display: flex;
	flex-wrap: wrap;
    border:none;
}
.card-details .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl {
	border: 1px solid rgb(196, 196, 196);
    border-radius: 5px;
	margin-bottom: 15px;
}
.card-details .MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained {
	position: relative;
	top: -14px;
}
.card-details p.MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained {
    white-space: nowrap;
}
.shipping.securely-account {
    margin: 0;
    border-bottom: 1px solid rgb(196, 196, 196);
    padding-bottom: 40px;
}
.shipping.sezzle-chekbox{
    border-bottom: 1px solid rgb(196, 196, 196);
    padding-bottom: 40px;
}
.place-order button {
    max-width: 287px;
    width: 100%;
    height: 57px;
    font-size: 24px;
    margin: 60px 40px 60px 0;
}
.checkout-main button:hover {
    border: 2px solid rgb(42, 83, 63);
    background: transparent;
    color: rgb(42, 83, 63) !important;
}
input.MuiInputBase-input.MuiInput-input.css-1x51dt5-MuiInputBase-input-MuiInput-input:checked {
    accent-color: rgb(91, 140, 34);
}
.checkout-left input {
    border: 1px solid rgb(196, 196, 196);
    border-radius: 5px;
}

.checkout-left .card-details input {
    border: none;
}
.ship-method {
    display: flex;
    gap: 10px;
    align-items: center;
}
.order-data table {
    width: 100%;
}
tr.all-products {
    width: 100%;
}
td.products-details-order {
    max-width: 58%;
    flex: 0 0 58%;
}
.card-details .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    background: transparent;
}
.checkout .form-info .row-form {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    gap: 15px;
    justify-content: space-between;
}
.checkout .form-info .row-form .select select {
    width: 100%;
    background: transparent;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 5px;
    color: #000;
}
.checkout .form-info .row-form .select {
    max-width: 49%;
    flex: 0 0 49%;
}
input[type="checkbox"] {
    accent-color: rgb(91, 140, 34);
}
.shipping_section {
    flex-wrap: wrap;
    gap: 10px !important;
}
.checkout_summery .price-calulate div {
    padding: 0px 10px 10px;
}

.mainorder_summery {
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: absolute;
    right: 0;
    height: 100%;
    top: 0px;

    .sticky-wrap {
        position: sticky;
        top: 10px;
    }
}

@media(max-width:1860px){
    .checkout-left {
            max-width: 860px;
       
    }
}


@media(max-width:1760px){
.checkout-left {
    max-width: 800px;
}
.card-details {
    justify-content:space-between;
}
}
@media(max-width:1660px){
.checkout-left {
    max-width: 730px;
}
}

@media(max-width:1560px){
.checkout-left {
    max-width: 695px;
}
}


@media(max-width:1500px){
.checkout-left {
    max-width: 50%;
}
.checkout-left h2 {
    font-size: 45px;
}
.order-summary h2 {
    font-size: 36px;
}
.place-order button {
    max-width: 253px;
    margin: 60px 18px 60px 0px;
}
}


@media(max-width:1439px){
.order-data {
    width: 605px;
}

}
@media(max-width:1300px){
 .order-summary {
	max-width: 46%;
	width: 100%;
	/* flex: 0 0 46%; */
}
.order-data {
	width: 100%;
	max-width: 100%;
}
.order-summary .all-products {
    gap: 22px;
    padding: 60px 15px;
}
 .coupon input {
    max-width: 340px;
    width:100%;
}

.order-summary {
    max-width: 100%;
    width: 100%;
    /* flex: 0 0 100%; */
}
.checkout-left {
    max-width: 46%;
    margin-right: 34px;
}

.mainorder_summery {
    width: 50%;
}
/* .mainorder_summery {
    flex-direction: row;
    flex-wrap: wrap;
} */
}

@media(max-width:1200px){
.place-order button {
    max-width: 247px;
    margin: 60px 8px 60px 0px;
}

}

@media(max-width:1199px){
    .container {
        padding:0 10px !important;
    }
    .mainorder_summery {
       position: relative !important;
       height: auto;
       width: 100%;

       .sticky-wrap {
        position: unset !important;
       }
    }    
}


@media(max-width:1198px){
.checkout-main {
    flex-wrap: wrap;
}
 .checkout-left {
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0;
}
.order-summary {
    position: relative;
    right: 0px;
    max-width: 100%;
    /* flex: 0 0 100%; */
}
.order-data {
    max-width: 100%;
    width: 100%;
}
.order-data table {
    width: 100%;
}
 .products-details-order {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
.order-data {
    max-width: 100%;
    width: 100%;
    height: auto;
}
 .price-calulate div {
    font-size: 25px;
    line-height: normal;
    margin: 0px 0px 20px;
}
.order-summary h2 {
    font-size: 28px;
    padding: 20px;
    margin-top: 0;
}
.mainorder_summery {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 50px;
    /* position: absolute;
    top: 40px;
    height: 100%; */
}
}
@media(max-width:991px){
    .checkout {
    padding: 160px 0px 0px;
}
 .shipping-form h3 {
    padding-bottom: 18px;
    margin-bottom: 20px;
    font-size: 24px;
}
 .shipping.sezzle-chekbox {
    padding-bottom: 10px;
}
 .shipping.securely-account {
    padding-bottom: 10px;
}
 .express-shipping.ship-address {
    margin-bottom: 24px;
}
.shipping-form {
    margin: 10px 0px;
}
 .checkout-left select {
    padding: 10px 14px;
    font-size: 16px;
}
.checkout-left label {
    font-size: 16px;
    top: -6px;
}
 .checkout-main input {
    font-size: 16px;
    height: 10px;
}
.checkout input[type="checkbox"] , .checkout input[type="radio"] {
    height:16px;
}
 .checkout_summery .checkout-left .field-class.neweletter-subscrib {
    font-size: 16px;
}
.credit-card {
    font-size: 16px;
}
 .checkout-left .field-class.neweletter-subscrib {
    font-size: 16px;
}
.shipping, .express-shipping {
    font-size: 16px;
    color: rgb(112, 112, 112);
    font-family: "Helvetica Neue LT Std";
}
.shipping {
    margin: 20px 0;
}
}

@media(max-width:568px){
tr.all-products {
    flex-wrap: wrap;
    justify-content: center;
}
.checkout {
    padding: 90px 0px 0px;
}
.checkout-left h2 {
    font-size:26px;
    margin-bottom: 24px;
}
.checkout-left form .password-now {
    max-width: 100%;
    flex: 0 0 100%;
}
.checkout-left .field-class.confirm-password-now {
    max-width: 100%;
    flex: 0 0 100%;
}
.price-calulate {
    padding: 0;
    text-align: center;
}
.shipping,  .express-shipping {
    font-size: 16px;
}
 .credit-card {
    margin: 10px 0px 0px;
}

.shipping-form h3 {
    padding-bottom: 15px;
    margin-bottom: 0px;
    font-size: 20px;
}
.products-details-order h4 {
    font-size: 17px;
    text-align: center;
}
 span.item-qty {
    font-size: 20px;
    padding: 10px 0 0;
    text-align: center;
}



span.price-note {
    font-size: 20px;
}
.item-icon {
    padding-right: 0;
}
.order-summary .all-products {
    gap: 10px;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;
}
 .place-order {
    margin: 0px auto;
    text-align: center;
    flex-wrap: wrap;
    display: flex;
}
.place-order button {
    max-width: 178px;
    margin: 20px auto 0px;
    width: 100%;
    height: 40px;
    font-size: 16px;
}
.order-summary h2 {
    margin-top: 20px;
}
 .price-calulate div {
    font-size: 18px;
    margin: 0px 0px 10px;
}
.coupon {
    margin:15px 0;
}

.card-details .textField {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 10px;
}
.checkout-left .card-details input {
    padding-left: 10px;
}
.card-details div:first-child {
    max-width: 100%;
    flex: 0 0 100%;
}
.shipping {
    margin: 19px 0px;
}
.products-details-order h4 {
    font-size: 16px;
    text-align: center;
    word-break: break-all;
}
.order-summary .all-products {
    flex-wrap: wrap;
}
td.products-details-order {
    max-width: 100%;
    flex: 0 0 100%;
}
 td.table-product {
    max-width: 100%;
    flex: 0 0 100%;
    text-align: center;
}
.card-details {
    flex-wrap: wrap;
    border: none;
}
.card-details .textField {
    margin: 0 0 10px;
}
span.delete-icon {
    margin: 0 0 10px;
}
.checkout .form-info .row-form {
    flex-wrap: wrap;
    gap: 20px;

}
.checkout .form-info .row-form .select {
    max-width: 100%;
    flex: 0 0 100%;
}
}


.wholesaler_error{
    color: white;
    background: red;
    padding: 8px;
    border-radius: 10px;
    font-size: 17px;
    font-weight:bold;
}


 
`;