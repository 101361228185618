import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AboutSectionOne from "./components/AboutSectionOne";
import AboutSectionTwo from "./components/AboutSectionTwo";
import MainAboutStyleCss from "./style/MainAbout.style"

const AboutPage = (props) => {

    const location = useLocation();

    useEffect(() => {
        if (location?.hash) {
            document.getElementById(location?.hash)?.scrollIntoView();
        }
    }, [location?.hash])

    return (
        <MainAboutStyleCss>
            <AboutSectionOne {...props} />
            <AboutSectionTwo {...props} />
        </MainAboutStyleCss>
    )
}
export default AboutPage;