import styled from "styled-components";

const AutoshipProductsAddStyle = styled.div`
 .product_listing {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;

    .product_section {
        /* border: 1px solid; */
        max-width: 360px;
        text-align: center;
        min-width: 360px;
        height: 470px;
        position: relative;

        .product_image {
            width: 100%;
            height: 300px;

            img {
                width:100%;
                object-fit: contain;
                height: 100%;
            }
        }

        .select_option {
            position: absolute;
            bottom: 0px;
            margin: 0px auto;
            left: 0px;
            right: 0px;
             a {
                margin: 0 auto;
                color: #fff;
                height: 45px;
             }
        }

        .description_section {
            min-height: 120px;
            max-height: 120px;
            height: 100%;
        }
    }
 }

`;

export default AutoshipProductsAddStyle;