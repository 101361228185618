
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import _ from "lodash";

export default function SwiperSlideComp({ arrayData }) {
    return (
        <>
            <Swiper
                effect="fade"
                cssMode={true}
                navigation={true}
                pagination={false}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Mousewheel, Keyboard]}
                className="mySwiper"
            >
                {_.map(arrayData, (row, index) => (
                    <SwiperSlide key={index}>
                        {row?.image}
                    </SwiperSlide>
                ))}


            </Swiper>
        </>
    );
}
